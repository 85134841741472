define("ln-ember-form-elements/templates/components/flash-messages/flash-messages", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "Z9+Ih//Z",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[30,0,[\"visibleMessages\"]]],null]],null],null,[[[1,\"  \"],[1,[28,[35,2],null,[[\"message\",\"allMessages\",\"onHeightChange\",\"onRemove\",\"onCloseClick\"],[[30,1],[30,0,[\"visibleMessages\"]],[28,[37,3],[[30,0],\"setMessageHeight\",[30,1]],null],[28,[37,3],[[30,0],\"removeMessageHeight\",[30,1]],null],[28,[37,3],[[30,0],\"removeMessage\",[30,1]],null]]]]],[1,\"\\n\"]],[1]],null]],[\"message\"],false,[\"each\",\"-track-array\",\"flash-messages/flash-message\",\"action\"]]",
    "moduleName": "ln-ember-form-elements/templates/components/flash-messages/flash-messages.hbs",
    "isStrictMode": false
  });
});