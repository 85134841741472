define("ln-ember-toolkit/utils/media-matcher", ["exports", "@ember/object"], function (_exports, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * @class MediaMatcher
   */
  var _default = _exports.default = _object.default.extend({
    /**
     * @memberof MediaMatcher
     * @instance
     *
     * @method
     * @param {String} mediaQuery
     * @return {Boolean}
     */
    query(mediaQuery) {
      return window.matchMedia(mediaQuery).matches;
    },
    /**
     * @memberof MediaMatcher
     * @instance
     *
     * @method
     * @return {Boolean}
     */
    isSmall() {
      return this.query('only screen and (max-width: 736px)');
    },
    /**
     * @memberof MediaMatcher
     * @instance
     *
     * @method
     * @return {String}
     */
    getVersion() {
      return this.isSmall() ? 'Mobile' : 'Desktop';
    }
  }).create();
});