define("ln-ember-data-models/models/vdc/user-type", ["exports", "ember-data", "ln-ember-data-models/utils/enum-option"], function (_exports, _emberData, _enumOption) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.blockSizeOptions = void 0;
  const {
    attr,
    hasMany,
    belongsTo,
    Model
  } = _emberData.default;

  /**
   * TODO Description: what is this used for?
   *
   * @memberof Vdc/UserTypeModel
   * @static
   *
   * @type {EnumOption[]}
   */
  const blockSizeOptions = _exports.blockSizeOptions = [_enumOption.default.create({
    id: 'SalesExpert'
  }), _enumOption.default.create({
    id: 'SalesManager'
  }), _enumOption.default.create({
    id: 'ServiceExpert'
  }), _enumOption.default.create({
    id: 'ServiceManager'
  })];

  /**
   * @class Vdc/UserTypeModel
   * @extends DS.Model
   */
  const UserTypeModel = Model.extend({
    /**
     * @memberof Vdc/UserTypeModel
     * @instance
     *
     * @type {Number}
     */
    version: attr('number'),
    /**
     * @memberof Vdc/UserTypeModel
     * @instance
     *
     * @type {String}
     */
    name: attr('nullable-string'),
    /**
     * @memberof Vdc/UserTypeModel
     * @instance
     *
     * @type {Boolean}
     */
    trainable: attr('boolean', {
      defaultValue: () => false
    }),
    /**
     * @memberof Vdc/UserTypeModel
     * @instance
     *
     * @type {Boolean}
     */
    calculate_qualification_level: attr('boolean', {
      defaultValue: () => false
    }),
    /**
     * @memberof Vdc/UserTypeModel
     * @instance
     *
     * @type {Boolean}
     */
    show_in_experts_table: attr('boolean', {
      defaultValue: () => false
    }),
    /**
     * @memberof Vdc/UserTypeModel
     * @instance
     *
     * @type {Number}
     */
    client_id: attr('id'),
    /**
     * @memberof Vdc/UserTypeModel
     * @instance
     *
     * @type {Number}
     */
    attribute_set_id: attr('id'),
    /**
     * @memberof Vdc/UserTypeModel
     * @instance
     *
     * @type {Array}
     */
    attribute_ids: attr('number-array', {
      defaultValue: () => []
    }),
    /**
     * @memberof Vdc/UserTypeModel
     * @instance
     *
     * @type {String}
     */
    classification: attr('nullable-string'),
    /**
     * @memberof Vdc/UserTypeModel
     * @instance
     *
     * @type {Vdc/AttributeSetModel}
     */
    attribute_set: belongsTo('vdc/attribute-set', {
      readOnly: true
    }),
    /**
     * @memberof Vdc/UserTypeModel
     * @instance
     *
     * @type {Vdc/PictureModel[]}
     */
    pictures: hasMany('vdc/picture', {
      async: false
    })
  });
  UserTypeModel.reopenClass({
    /**
     * @memberof Vdc/UserTypeModel
     * @static
     *
     * @type {EnumOption[]}
     */
    blockSizeOptions
  });
  var _default = _exports.default = UserTypeModel;
});