define("ln-ember-data-models/models/vdc/fragments/type-configuration", ["exports", "ember-data", "ember-data-model-fragments/fragment", "ln-ember-data-models/utils/enum-option"], function (_exports, _emberData, _fragment, _enumOption) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.typeOptions = _exports.subtypeOptions = _exports.default = void 0;
  const {
    attr
  } = _emberData.default;

  /**
   * @name Vdc/fragments/TypeConfigurationFragment/TypeOptions
   * @type {EnumOption[]}
   */
  const typeOptions = _exports.typeOptions = [_enumOption.default.create({
    id: 'StoreType'
  }), _enumOption.default.create({
    id: 'StoreSubtype'
  }), _enumOption.default.create({
    id: 'UserType'
  }), _enumOption.default.create({
    id: 'TrainingType'
  }), _enumOption.default.create({
    id: 'TrainingSubtype'
  }), _enumOption.default.create({
    id: 'ProjectType'
  }), _enumOption.default.create({
    id: 'EventType'
  })];

  /**
   * @name Vdc/fragments/TypeConfigurationFragment/SubtypeOptions
   * @type {EnumOption[]}
   */
  const subtypeOptions = _exports.subtypeOptions = [_enumOption.default.create({
    id: 'StoreSubtype'
  })];

  /**
   * @class Vdc/fragments/TypeConfigurationFragment
   * @extends Fragment
   */
  const TypeConfigurationFragment = _fragment.default.extend({
    /**
     * @memberof Vdc/fragments/TypeConfigurationFragment
     * @instance
     *
     * @type {Number}
     */
    kpi_id: attr('number'),
    /**
     * @memberof Vdc/fragments/TypeConfigurationFragment
     * @instance
     *
     * @type {String}
     */
    configuration_type: attr('enum-string', {
      options: typeOptions
    }),
    /**
     * @memberof Vdc/fragments/TypeConfigurationFragment
     * @instance
     *
     * @type {Number}
     */
    special_type_id: attr('number'),
    /**
     * @memberof Vdc/fragments/TypeConfigurationFragment
     * @instance
     *
     * @type {String}
     */
    configuration_subtype: attr('enum-string', {
      options: subtypeOptions
    }),
    /**
     * @memberof Vdc/fragments/TypeConfigurationFragment
     * @instance
     *
     * This one is a JSON Object with arbitrary keys (key is type ID, value is array of subtype ids)
     *
     * @type {String}
     */
    type_subtype_mappings: attr({
      defaultValue: () => {}
    }),
    /**
     * @memberof Vdc/fragments/TypeConfigurationFragment
     * @instance
     *
     * @type {Number[]}
     */
    special_subtype_ids: attr('number-array'),
    /**
     * @memberof Vdc/fragments/TypeConfigurationFragment
     * @instance
     *
     * @type {Number[]}
     */
    ids: attr('number-array')
  });
  TypeConfigurationFragment.reopenClass({
    /**
     * @memberof Vdc/fragments/TypeConfigurationFragment
     * @static
     *
     * @type {EnumOption[]}
     */
    typeOptions,
    /**
     * @memberof Vdc/fragments/TypeConfigurationFragment
     * @static
     *
     * @type {EnumOption[]}
     */
    subtypeOptions
  });
  var _default = _exports.default = TypeConfigurationFragment;
});