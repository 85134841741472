define("ln-ember-data-models/models/vdc/contextual-role-assignment", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.contextualRoleTypes = void 0;
  const {
    attr,
    belongsTo,
    Model
  } = _emberData.default;

  /**
   * @name Vdc/ContextualRoleAssignmentModel/contextualRoleTypes
   * @type {String[]}
   */
  const contextualRoleTypes = _exports.contextualRoleTypes = ['Project', 'Market', 'Store', 'Country', 'ArticleCategory', 'Events'];

  /**
   * @class Vdc/ContextualRoleAssignmentModel
   * @extends DS.Model
   */
  const ContextualRoleAssignmentModel = Model.extend({
    /**
     * @memberof Vdc/ContextualRoleAssignmentModel
     * @instance
     *
     * @type {Number}
     */
    version: attr('number'),
    /**
     * @memberof Vdc/ContextualRoleAssignmentModel
     * @instance
     *
     * @type {Number}
     */
    user_id: attr('id'),
    /**
     * @memberof Vdc/ContextualRoleAssignmentModel
     * @instance
     *
     * @type {Number}
     */
    role_id: attr('id'),
    /**
     * @memberof Vdc/ContextualRoleAssignmentModel
     * @instance
     *
     * @type {String}
     */
    role_name: attr('nullable-string'),
    /**
     * @memberof Vdc/ContextualRoleAssignmentModel
     * @instance
     *
     * @type {String}
     */
    role_type: attr('nullable-string'),
    /**
     * @memberof Vdc/ContextualRoleAssignmentModel
     * @instance
     *
     * @type {Number}
     */
    client_id: attr('id'),
    /**
     * @memberof Vdc/ContextualRoleAssignmentModel
     * @instance
     *
     * @type {Number}
     */
    context_id: attr('id'),
    /**
     * @memberof Vdc/ContextualRoleAssignmentModel
     * @instance
     *
     * @type {String}
     */
    context_name: attr('nullable-string'),
    /**
     * @memberof Vdc/ContextualRoleAssignmentModel
     * @instance
     *
     * @type {Vdc/RoleModel}
     */
    role: belongsTo('vdc/role')
  });
  ContextualRoleAssignmentModel.reopenClass({
    /**
     * @memberof Vdc/ContextualRoleAssignmentModel
     * @static
     *
     * @type {String[]}
     */
    contextualRoleTypes
  });
  var _default = _exports.default = ContextualRoleAssignmentModel;
});