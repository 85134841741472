define("ln-ember-data-models/models/myliga/talk", ["exports", "ember-data", "ln-ember-data-models/utils/enum-option", "ember-data-model-fragments/attributes"], function (_exports, _emberData, _enumOption, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.statusOptions = _exports.default = void 0;
  const {
    attr,
    hasMany,
    Model
  } = _emberData.default;

  /**
   * @name Myliga/TalkModel/statusOptions
   * @type {EnumOption[]}
   */
  const statusOptions = _exports.statusOptions = [_enumOption.default.create({
    id: 'InProgress'
  }), _enumOption.default.create({
    id: 'Complete'
  })];

  /**
   * @class Myliga/TalkModel
   * @extends DS.Model
   */
  const TalkModel = Model.extend({
    /**
     * @memberof Myliga/TalkModel
     * @instance
     *
     * @type {Number}
     */
    version: attr('number'),
    /**
     * @memberof Myliga/TalkModel
     * @instance
     *
     * @type {Number}
     */
    user_id: attr('id'),
    /**
     * @memberof Myliga/TalkModel
     * @instance
     *
     * @type {Number}
     */
    jobprofile_id: attr('id'),
    /**
     * @memberof Myliga/TalkModel
     * @instance
     *
     * @type {Number}
     */
    superior_user_id: attr('id'),
    /**
     * @memberof Myliga/TalkModel
     * @instance
     *
     * @type {Number[]}
     */
    participant_user_ids: attr('number-array', {
      defaultValue: () => []
    }),
    /**
     * @memberof Myliga/TalkModel
     * @instance
     *
     * @type {String}
     */
    appointment_date: attr('date-time-string'),
    /**
     * @memberof Myliga/TalkModel
     * @instance
     *
     * @type {String}
     */
    closed_at: attr('date-time-string'),
    /**
     * @memberof Myliga/TalkModel
     * @instance
     *
     * @type {Number}
     */
    talk_type_id: attr('id'),
    /**
     * @memberof Myliga/TalkModel
     * @instance
     *
     * @type {String}
     */
    talk_type_name: attr('nullable-string'),
    /**
     * @memberof Myliga/TalkModel
     * @instance
     *
     * @type {String}
     */
    title: attr('string'),
    /**
     * @memberof Myliga/TalkModel
     * @instance
     *
     * @type {Boolean}
     */
    superior_feedback_visible: attr('boolean'),
    /**
     * @memberof Myliga/TalkModel
     * @instance
     *
     * @type {Boolean}
     */
    user_feedback_visible: attr('boolean'),
    /**
     * @memberof Myliga/TalkModel
     * @instance
     *
     * @type {Number[]}
     */
    talk_module_ids: attr('number-array', {
      defaultValue: () => []
    }),
    /**
     * @memberof Myliga/TalkModel
     * @instance
     *
     * @type {Number}
     */
    feedback_year: attr('number'),
    /**
     * @memberof Myliga/TalkModel
     * @instance
     *
     * @type {String}
     */
    status: attr('enum-string', {
      options: statusOptions
    }),
    /**
     * @memberof Myliga/TalkModel
     * @instance
     *
     * @type {Object[]}
     */
    participant_user_infos: attr({
      defaultValue: () => []
    }),
    /**
     * @memberof Myliga/TalkModel
     * @instance
     *
     * @type {Object[]}
     */
    talk_feedbacks: attr({
      defaultValue: () => []
    }),
    /**
     * @memberof Myliga/TalkModel
     * @instance
     *
     * @type {Myliga/TalkModuleModel[]}
     */
    talk_modules: hasMany('myliga/talk-module'),
    /**
     * @memberof Myliga/TalkModel
     * @instance
     *
     * @type {MyLiga/fragments/UserMeta}
     */
    user_info: (0, _attributes.fragment)('myliga/fragments/user-meta'),
    /**
     * @memberof Myliga/TalkModel
     * @instance
     * @type {MyLiga/fragments/UserMeta}
     */
    superior_user_info: (0, _attributes.fragment)('myliga/fragments/user-meta')
  });
  TalkModel.reopenClass({
    /**
     * @memberof Myliga/TalkModel
     * @static
     *
     * @type {EnumOption[]}
     */
    statusOptions
  });
  var _default = _exports.default = TalkModel;
});