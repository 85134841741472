define("ln-ember-form-elements/components/form-elements/price", ["exports", "ln-ember-form-elements/templates/components/form-elements/price", "@ember/component", "@ember/object", "@ember/service"], function (_exports, _price, _component, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class, _descriptor; // @ts-ignore
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let FormElementsPrice = _exports.default = (_dec = (0, _service.inject)('form-elements'), _dec2 = (0, _object.computed)('formElements.defaults.currency', 'currency'), _dec3 = (0, _object.computed)('scale'), _dec4 = (0, _object.computed)('formElements.defaults.locale', '_currency'), _class = class FormElementsPrice extends _component.default.extend({
    tagName: ''
  }) {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "layout", _price.default);
      _defineProperty(this, "currency", void 0);
      _defineProperty(this, "scale", void 0);
      _initializerDefineProperty(this, "formElements", _descriptor, this);
    }
    get _currency() {
      return this.currency || this.formElements.defaults.currency;
    }
    get _padFractionalZeros() {
      return this.scale !== 0;
    }
    get formatSettings() {
      return this._detectCurrencyFormatSettings(this.formElements.defaults.locale, this._currency);
    }
    _detectCurrencyFormatSettings(locale, currency) {
      const formatter = new Intl.NumberFormat(locale, {
        style: 'currency',
        currency,
        minimumFractionDigits: 0,
        minimumIntegerDigits: 1
      });
      const str = formatter.format(0);
      const standardIsSignBefore = str.indexOf('0') > 0;
      return {
        currencySign: standardIsSignBefore ? str[0] : str[str.length - 1],
        isSignBefore: true
      };
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "formElements", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "_currency", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "_currency"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "_padFractionalZeros", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "_padFractionalZeros"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "formatSettings", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "formatSettings"), _class.prototype), _class);
});