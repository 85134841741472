define("ln-ember-form-elements/components/form-elements/date-picker", ["exports", "jquery", "@ember/component", "@ember/object", "@ember/object/computed", "@ember/application/deprecations", "@ember/service", "@ember/runloop", "@ember/string", "moment", "ln-ember-form-elements/templates/components/form-elements/date-picker"], function (_exports, _jquery, _component, _object, _computed, _deprecations, _service, _runloop, _string, _moment, _datePicker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   *
   * Renders an input field that opens up a date picker on desktop screens.
   *
   * On mobile devices the native date inputs get used.
   *
   * ### Examples:
   *
   * ```Handlebars
   * {{form-elements/date-picker value="2016-05-19T16:01:41.565+02:00" showTime=false}}
   * ```
   *
   * ```Handlebars
   * {{form-elements/date-picker
   *    value="2016-05-19T16:01:41.565+02:00"
   *    min="2016-05-11T16:01:41.565+02:00"
   *    max="2016-05-25T16:01:41.565+02:00"
   *  }}
   * ```
   *
   * @class formElements/DatePickerComponent
   * @extends @ember/component
   */
  var _default = _exports.default = _component.default.extend({
    formElements: (0, _service.inject)(),
    layout: _datePicker.default,
    classNames: [
    // Deprecated: { id: ln-ember-form-elements-class-names, until: 3.0.0 }
    'date-picker-component', 'form-elements--date-picker-component'],
    classNameBindings: ['hasFocus', 'hasValue', 'error:has-error', 'isActive:is-active:is-inactive', 'themeClass'],
    /**
     * @memberof formElements/DatePickerComponent
     * @instance
     *
     * @type String
     * @default light
     */
    theme: null,
    /**
     * if you open the datepicker today will be set by default.
     * default is true, for myliga backward compatibilty.
     *
     * @memberof formElements/DatePickerComponent
     * @instance
     *
     * @type Boolean
     * @default true
     */
    setDefaultValueOnOpen: true,
    // TODO What is this? Pickaday theme?
    themeName: 'default',
    picker: null,
    /**
     * Adds an time selection to the datepicker.
     *
     * @memberof formElements/DatePickerComponent
     * @instance
     *
     * @type Boolean
     * @default false
     */
    showTime: false,
    // Automatically show/hide the datepicker on field focus (default true if field is set)
    bound: false,
    // TODO documentation?
    error: null,
    /**
     * Earliest selectable date
     *
     * @memberof formElements/DatePickerComponent
     * @instance
     *
     * @type String
     * @default null
     */
    min: null,
    /**
     * Latest selectable date
     *
     * @memberof formElements/DatePickerComponent
     * @instance
     *
     * @type String
     * @default null
     */
    max: null,
    /**
     * If true sets pikaday's container as this component DOM element.
     * It will cause that pikday calendar will be contained inside this component.
     *
     * @memberof formElements/DatePickerComponent
     * @instance
     *
     * @type Boolean
     * @default false
     */
    componentIsContainer: false,
    /**
     * If `componentIsContainer` is `false`, you can pass an id for DOM element
     * which will be container for pikaday calendar
     * Example: `'custom-container'`
     *
     * @memberof formElements/DatePickerComponent
     * @instance
     *
     * @type String
     * @default null
     */
    customContainerId: null,
    /**
     * The label of the date picker
     *
     * @memberof formElements/DatePickerComponent
     * @instance
     *
     * @type String
     * @default null
     */
    label: null,
    isActive: true,
    /**
     * Activates week numbers in the date picker.
     *
     * @memberof formElements/DatePickerComponent
     * @instance
     *
     * @type Boolean
     * @default true
     */
    showWeekNumber: true,
    inputValue: null,
    inputMobileValue: null,
    hasFocus: false,
    defaultTime: null,
    /**
     * Fired when the input value changes.
     *
     * @memberof formElements/DatePickerComponent
     * @instance
     *
     * @type {function}
     * @default null
     */
    onChange: null,
    /**
     * Placeholdertext for the input field.
     *
     * @memberof formElements/DatePickerComponent
     * @instance
     *
     * @type String
     * @default "Select a date"
     */
    placeholder: (0, _string.loc)('Select a date'),
    isDisabled: (0, _computed.not)('isActive'),
    /**
     * @instance
     *
     * @type {String}
     * @default 'off'
     */
    autocomplete: 'off',
    hasValue: (0, _computed.bool)('date').readOnly(),
    themeClass: (0, _object.computed)('theme', function () {
      return `theme-${this.get('theme') || 'light'}`;
    }),
    mobileInputType: (0, _object.computed)('showTime', function () {
      return this.get('showTime') ? 'datetime-local' : 'date';
    }).readOnly(),
    // Format for the visible input (not mobile)
    dateDisplayFormat: (0, _object.computed)('showTime', function () {
      const key = this.get('showTime') ? 'formatDateTime' : 'formatDate';
      return this.get(`formElements.defaults.${key}`);
    }).readOnly(),
    // Format for value
    dateFormat: (0, _object.computed)('showTime', function () {
      return this.get('showTime') ? 'YYYY-MM-DD[T]HH:mm:ss.SSSZ' : 'YYYY-MM-DD';
    }).readOnly(),
    minDate: (0, _object.computed)('min', function () {
      const min = this.get('min');
      return min ? new Date(min) : null;
    }).readOnly(),
    maxDate: (0, _object.computed)('max', function () {
      const max = this.get('max');
      return max ? new Date(max) : null;
    }).readOnly(),
    // Should only be set from the outside the component via binding
    // Use setDate Instead!
    value: (0, _object.computed)('date', {
      get() {
        return this.format(this.get('date'));
      },
      set(key, dateStr) {
        if (this.get('isInitialized')) {
          const date = this.convertToDate(dateStr);
          (false && !(Boolean(this.get('onChange'))) && (0, _deprecations.deprecate)('[DatePickerComponent] Data binding is deprecated. onChange action should be handled.', Boolean(this.get('onChange')), {
            for: 'ln-ember-form-elements',
            id: 'date-picker-component-data-binding',
            since: '3.0.0-beta.115',
            until: '3.0.0'
          }));
          if (this.get('onChange')) {
            this.get('onChange')(dateStr);
          } else {
            this.set('date', date);
            this.pickerCall('setDate', date, true);
          }
        }
        return dateStr;
      }
    }),
    init() {
      this._super(...arguments);
      if (!this.get('theme')) {
        this.set('theme', this.get('formElements.defaults.theme'));
      }
      this.set('date', this.convertToDate(this.get('value')));
      if (this.get('date')) {
        this.set('inputMobileValue', this.convertToLocalDate(this.get('date')));
      }
      this.set('isInitialized', true);
    },
    didInsertElement() {
      this._super(...arguments);
      this.setupPikaday();
    },
    didUpdateAttrs() {
      this._super(...arguments);
      const min = this.get('min');
      const max = this.get('max');
      const minDate = min ? new Date(min) : null;
      const maxDate = max ? new Date(max) : null;

      // https://github.com/dbushell/Pikaday/issues/19
      // this enables us to dynamically change the date ranges
      this.get('picker').config({
        minDate,
        maxDate
      });
    },
    willDestroyElement() {
      this.pickerCall('destroy');
    },
    actions: {
      // Open Datepicker, if not already open
      open() {
        if (this.pickerCall('isVisible') !== false) {
          return;
        }
        if (this.get('setDefaultValueOnOpen')) {
          this.ensureHasValue();
        }
        this.pickerCall('show');
      },
      // Close Datepicker, if no time inputs are focused
      close() {
        if (this.pickerCall('isVisible') !== true) {
          return;
        }
        this.validateInput();
        this.closeIfhasNoFocus();
      },
      keyUp() {
        (0, _runloop.debounce)(this, this.validateInput, 1000);
      },
      mobileFocusIn() {
        this.set('hasFocus', true);
      },
      mobileFocusOut() {
        this.set('hasFocus', false);
        this.validateMobileInput();
      }
    },
    setDate(date) {
      date = this.stripDate(date);
      if (this.isChangedDate(date)) {
        this.set('date', date);
      }
    },
    setupPikaday() {
      const customContainer = this.get('customContainerId') && (0, _jquery.default)(`#${this.get('customContainerId')}`).get(0);
      const container = this.get('componentIsContainer') ? this.element : customContainer;
      (0, _runloop.once)('afterRender', () => {
        // See full list of options: https://github.com/owenmead/Pikaday
        this.set('picker', new Pikaday({
          field: (0, _jquery.default)('input', this.element).get(0),
          theme: this.get('themeName'),
          showTime: this.get('showTime'),
          useTimeInput: true,
          use24hour: true,
          bound: this.get('bound'),
          container,
          showWeekNumber: this.get('showWeekNumber'),
          firstDay: 1,
          // 0: Sunday, 1: Monday,...

          format: this.get('dateDisplayFormat'),
          minDate: this.get('minDate'),
          maxDate: this.get('maxDate'),
          i18n: {
            previousMonth: (0, _string.loc)('Prev'),
            nextMonth: (0, _string.loc)('Next'),
            months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'].map(m => (0, _string.loc)(m)),
            weekdays: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'].map(d => (0, _string.loc)(d)),
            weekdaysShort: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'].map(d => (0, _string.loc)(d))
          },
          onSelect: date => {
            if (!this.isChangedDate(date)) {
              return;
            }
            const isChangedDateDay = this.isChangedDateDay(date);
            this.setDate(date);
            (0, _runloop.next)(() => {
              if (this.get('showTime') && isChangedDateDay) {
                // New Date Clicked, focus the input
                this.ensureAnyInputHasFocus();
              }
              this.send('close');
            });
          },
          onDraw: () => {
            if (this.get('showTime')) {
              // When ever Pikaday rerenders assign blur listener to the time inputs
              const pickerEl = this.get('picker.el');
              (0, _jquery.default)('input.pika-select-minute', pickerEl).on('blur', () => this.send('close'));
              (0, _jquery.default)('input.pika-select-hour', pickerEl).on('blur', () => this.send('close'));
            }
          }
        }));
        this.pickerCall('setDate', this.get('date'));
        this.ensureDateIsVisible();
        this.pickerCall('hide');
      });
    },
    anyInputHasFocus() {
      return (0, _jquery.default)(this.element) && (0, _jquery.default)('.pika-select-hour, .pika-select-minute, .date-picker-input', this.element).is(':focus');
    },
    isChangedDate(date) {
      return this.get('value') !== this.format(date);
    },
    isValidDateStr(dateStr, key = 'date') {
      return (0, _moment.default)(dateStr, this.get(`${key}Format`), true).isValid();
    },
    isValidDate(date) {
      return date && (0, _moment.default)(date).isValid();
    },
    isChangedDateDay(date) {
      const lastDay = (0, _moment.default)(this.get('value')).format('YYYY-MM-DD');
      const newDay = (0, _moment.default)(date).format('YYYY-MM-DD');
      return lastDay !== newDay;
    },
    convertToDate(dateStr, key = 'date') {
      if (this.isValidDateStr(dateStr, key)) {
        return (0, _moment.default)(dateStr, this.get(`${key}Format`), true).toDate();
      }
      return null;
    },
    // See http://encosia.com/setting-the-value-of-a-datetime-local-input-with-javascript/
    convertToLocalDate(currentDate) {
      if (!currentDate) {
        return null;
      }
      if (this.get('showTime')) {
        const timezoneOffset = currentDate.getTimezoneOffset() * 60 * 1000;
        const localDate = new Date(currentDate.getTime() - timezoneOffset);
        const localDateISOString = localDate.toISOString().replace('Z', '');
        return localDateISOString;
      } else {
        const localDate = (0, _moment.default)(currentDate).format(this.get('formElements.defaults.formatDate'));
        return localDate;
      }
    },
    format(date, key = 'date') {
      if (this.isValidDate(date)) {
        return (0, _moment.default)(date).format(this.get(`${key}Format`));
      }
      return null;
    },
    stripDate(date) {
      return this.convertToDate(this.format(date));
    },
    pickerCall(func, ...args) {
      if (this.get('picker')) {
        return this.get('picker')[func](...args);
      }
    },
    ensureAnyInputHasFocus() {
      if (!this.anyInputHasFocus()) {
        (0, _jquery.default)('.date-picker-input', this.element).focus();
      }
    },
    ensureHasValue() {
      if (!this.get('hasValue')) {
        const date = new Date();
        if (this.get('defaultTime')) {
          const [hours, minutes] = this.get('defaultTime').split(':');
          date.setHours(hours, minutes);
        }
        this.setDate(date);
      }
    },
    ensureDateIsVisible() {
      const now = (0, _moment.default)();
      const maxDate = this.get('maxDate');
      const minDate = this.get('minDate');
      if (maxDate && now.endOf('day').toDate() > maxDate) {
        this.pickerCall('gotoDate', maxDate);
      } else if (minDate && now.startOf('day').toDate() < minDate) {
        this.pickerCall('gotoDate', minDate);
      }
    },
    validateInput() {
      (0, _runloop.once)('afterRender', () => {
        const dateStr = this.get('inputValue');
        if (!dateStr) {
          this.setDate(null);
        } else if (this.isValidDateStr(dateStr, 'dateDisplay')) {
          this.setDate(this.convertToDate(dateStr, 'dateDisplay'));
        } else {
          this.set('inputValue', this.format(this.get('date'), 'dateDisplay'));
        }
      });
    },
    validateMobileInput() {
      (0, _runloop.once)('afterRender', () => {
        const dateStr = this.get('inputMobileValue');
        let date = null;
        if (dateStr) {
          date = new Date(dateStr);
          date.setMinutes(date.getMinutes() + date.getTimezoneOffset());
        }
        this.setDate(date);
      });
    },
    closeIfhasNoFocus() {
      (0, _runloop.later)(this, () => {
        if (!this.anyInputHasFocus()) {
          this.pickerCall('hide');
        }
      }, 100);
    }
  });
});