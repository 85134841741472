define("ln-ember-form-elements/components/flash-messages/flash-messages", ["exports", "@ember/polyfills", "@ember/object", "@ember/object/computed", "@ember/service", "@ember/component", "@ember/array", "ln-ember-form-elements/templates/components/flash-messages/flash-messages"], function (_exports, _polyfills, _object, _computed, _service, _component, _array, _flashMessages) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Shows flash messages that are added to the `FlashMessagesService`. It should
   * be added to the `application.hbs` of the app.
   *
   * ### Example:
   *
   * `application.hbs`:
   *
   * ```Handlebars
   * {{flash-messages/flash-messages}}
   * ```
   *
   * @class flashMessages/FlashMessagesComponent
   * @extends @ember/component
   *
   * @see FlashMessagesService
   */
  var _default = _exports.default = _component.default.extend({
    flashMessages: (0, _service.inject)(),
    layout: _flashMessages.default,
    classNames: [
    // Deprecated: { id: ln-ember-form-elements-class-names, until: 3.0.0 }
    'flash-messages-component', 'flash-messages--flash-messages-component'],
    _messageClones: null,
    messages: (0, _computed.alias)('flashMessages.messages'),
    visibleMessages: (0, _object.computed)('messages.[]', function () {
      const messageClones = (0, _array.A)(this.get('_messageClones'));
      const messages = (0, _array.A)(this.get('messages'));
      messageClones.setEach('visible', false);
      messages.forEach(message => {
        const clone = messageClones.findBy('id', (0, _object.get)(message, 'id'));
        if (!clone) {
          messageClones.addObject((0, _polyfills.assign)({
            visible: true,
            height: null,
            original: message
          }, message));
        } else {
          (0, _object.set)(clone, 'visible', true);
        }
      });
      return messageClones;
    }),
    init() {
      this._super(...arguments);
      this.set('_messageClones', []);
    },
    actions: {
      setMessageHeight(message, height) {
        const messageClones = (0, _array.A)(this.get('_messageClones'));
        const clone = messageClones.findBy('id', (0, _object.get)(message, 'id'));
        if (clone) {
          (0, _object.set)(clone, 'height', height);
          return;
        }
      },
      removeMessageHeight(message) {
        const messageClones = (0, _array.A)(this.get('_messageClones'));
        const clone = messageClones.findBy('id', (0, _object.get)(message, 'id'));
        messageClones.removeObject(clone);
      },
      removeMessage(message, removeAll) {
        if (removeAll) {
          return this.get('flashMessages').removeAllMessages();
        }
        return this.get('flashMessages').removeMessage(message.original);
      }
    }
  });
});