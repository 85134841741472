define("ln-ember-data-models/models/vdc/plan-template", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    attr,
    Model
  } = _emberData.default;

  /**
   * @class Vdc/PlanTemplateModel
   * @extends DS.Model
   */
  var _default = _exports.default = Model.extend({
    /**
     * @memberof Vdc/PlanTemplateModel
     * @instance
     *
     * @type {Number}
     */
    version: attr('number'),
    /**
     * @memberof Vdc/PlanTemplateModel
     * @instance
     *
     * @type {String}
     */
    name: attr('nullable-string'),
    /**
     * @memberof Vdc/PlanTemplateModel
     * @instance
     *
     * @type {Number}
     */
    client_id: attr('id'),
    /**
     * @memberof Vdc/PlanTemplateModel
     * @instance
     *
     * @type {String}
     */
    context_type: attr('nullable-string'),
    /**
     * @memberof Vdc/PlanTemplateModel
     * @instance
     *
     * @type {Number}
     */
    context_id: attr('id')
  });
});