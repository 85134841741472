define("ln-ember-data-models/models/myliga/objective", ["exports", "ember-data", "ln-ember-data-models/utils/enum-option"], function (_exports, _emberData, _enumOption) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.typeOptions = _exports.sourceOptions = _exports.default = _exports.bonusPaymentTypeOptions = void 0;
  const {
    Model,
    attr,
    hasMany
  } = _emberData.default;

  /**
   * @name Myliga/Objective/typeOptions
   * @type {EnumOption[]}
   */
  const typeOptions = _exports.typeOptions = [_enumOption.default.create({
    id: 'Quantitative'
  }), _enumOption.default.create({
    id: 'Qualitative'
  })];

  /**
   * @name Myliga/Objective/bonusPaymentTypeOptions
   * @type {EnumOption[]}
   */
  const bonusPaymentTypeOptions = _exports.bonusPaymentTypeOptions = [_enumOption.default.create({
    id: 'Miles'
  }), _enumOption.default.create({
    id: 'EUR'
  }), _enumOption.default.create({
    id: 'MonthlySalary'
  })];

  /**
   * @name Myliga/Objective/sourceOptions
   * @type {EnumOption[]}
   */
  const sourceOptions = _exports.sourceOptions = [_enumOption.default.create({
    id: 'Account'
  }), _enumOption.default.create({
    id: 'Other'
  })];

  /**
   * @class Myliga/Objective
   * @extends DS.Model
   */
  const ObjectiveModel = Model.extend({
    /**
     * @memberof Myliga/Objective
     * @instance
     *
     * @type {Number}
     */
    version: attr('number'),
    /**
     * @memberof Myliga/Objective
     * @instance
     *
     * @type {String}
     */
    type: attr('enum-string', {
      options: typeOptions
    }),
    /**
     * @memberof Myliga/Objective
     * @instance
     *
     * @type {String}
     */
    source: attr('enum-string', {
      options: sourceOptions
    }),
    /**
     * @memberof Myliga/Objective
     * @instance
     *
     * @type {Number}
     */
    objective_agreement_id: attr('id'),
    /**
     * @memberof Myliga/Objective
     * @instance
     *
     * @type {String}
     */
    description: attr('nullable-string'),
    /**
     * @memberof Myliga/Objective
     * @instance
     *
     * @type {String}
     */
    comment: attr('nullable-string'),
    /**
     * @memberof Myliga/Objective
     * @instance
     *
     * @type {String}
     */
    bonus_payment_type: attr('enum-string', {
      options: bonusPaymentTypeOptions
    }),
    /**
     * @memberof Myliga/Objective
     * @instance
     *
     * @type {Number}
     */
    bonus_payment_value: attr('number'),
    /**
     * @memberof Myliga/Objective
     * @instance
     *
     * @type {show_project_forecast}
     */
    show_project_forecast: attr('boolean'),
    /**
     * @memberof Myliga/Objective
     * @instance
     *
     * @type {Number}
     */
    position: attr('number'),
    /**
     * @memberof Myliga/Objective
     * @instance
     *
     * @type {ObjectiveComponent}
     */
    components: hasMany('myliga/objective-component')
  });
  ObjectiveModel.reopenClass({
    /**
     * @memberof Myliga/Objective
     * @static
     *
     * @type {EnumOption[]}
     */
    typeOptions,
    /**
     * @memberof Myliga/Objective
     * @static
     *
     * @type {EnumOption[]}
     */
    bonusPaymentTypeOptions,
    /**
     * @memberof Myliga/Objective
     * @static
     *
     * @type {EnumOption[]}
     */
    sourceOptions
  });
  var _default = _exports.default = ObjectiveModel;
});