define("ln-ember-data-models/models/vdc/store-type", ["exports", "ember-data", "ln-ember-data-models/utils/store-colors"], function (_exports, _emberData, _storeColors) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    attr,
    hasMany,
    Model
  } = _emberData.default;

  /**
   * @class Vdc/StoreTypeModel
   * @extends DS.Model
   */
  const StoreTypeModel = Model.extend({
    /**
     * @memberof Vdc/StoreTypeModel
     * @instance
     *
     * @type {Number}
     */
    version: attr('number'),
    /**
     * @memberof Vdc/StoreTypeModel
     * @instance
     *
     * @type {String}
     */
    name: attr('nullable-string'),
    /**
     * @memberof Vdc/StoreTypeModel
     * @instance
     *
     * @type {Number}
     */
    client_id: attr('id'),
    /**
     * @memberof Vdc/StoreTypeModel
     * @instance
     *
     * @type {Number}
     */
    position: attr('number'),
    /**
     * @memberof Vdc/StoreTypeModel
     * @instance
     *
     * @type {Number[]}
     */
    store_subtype_ids: attr('number-array', {
      defaultValue: () => []
    }),
    /**
     * @memberof Vdc/StoreTypeModel
     * @instance
     *
     * @type {Boolean}
     */
    show_on_map: attr('boolean', {
      defaultValue: () => false
    }),
    /**
     * @memberof Vdc/StoreTypeModel
     * @instance
     *
     * @type {Boolean}
     */
    show_in_flyout_navigation: attr('boolean', {
      defaultValue: () => false
    }),
    /**
     * @memberof Vdc/StoreTypeModel
     * @instance
     *
     * @type {Number}
     */
    attribute_set_id: attr('id'),
    /**
     * @memberof Vdc/StoreTypeModel
     * @instance
     *
     * @type {Number[]}
     */
    attribute_ids: attr('number-array', {
      defaultValue: () => []
    }),
    /**
     * @memberof Vdc/StoreTypeModel
     * @instance
     *
     * @type {string}
     */
    store_marker_color: attr('enum-string', {
      options: _storeColors.default
    }),
    /**
     * @memberof Vdc/StoreTypeModel
     * @instance
     *
     * @type {Vdc/StoreSubtypeModel[]}
     */
    store_subtypes: hasMany('vdc/store-subtype'),
    /**
     * @memberof Vdc/StoreTypeModel
     * @instance
     *
     * @type {Vdc/PictureModel[]}
     */
    pictures: hasMany('vdc/picture', {
      async: false
    })
  });
  StoreTypeModel.reopenClass({
    /**
     * @memberof Vdc/StoreTypeModel
     * @static
     *
     * @type {EnumOption[]}
     */
    StoreColorOptions: _storeColors.default
  });
  var _default = _exports.default = StoreTypeModel;
});