define("ln-ember-contact-menu/services/micro-menu", ["exports", "@ember/service", "@ember/object"], function (_exports, _service, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _service.default.extend({
    elementId: null,
    component: null,
    _isEnabled: false,
    hasActiveItem: false,
    isActive: (0, _object.computed)('elementId', function () {
      return !!this.get('elementId');
    }),
    isEnabled: (0, _object.computed)('_isEnabled', function () {
      return this._isEnabled;
    }),
    _window: (0, _object.computed)('window', function () {
      return this.window || window;
    }),
    init() {
      this._super(...arguments);
      this._boundClose = this.close.bind(this);
    },
    close() {
      if (!this.isDestroying) {
        this.set('hasActiveItem', false);
        this.set('elementId', null);
        this.set('component', null);
      }
      this.get('_window').removeEventListener('resize', this._boundClose);
    },
    open(elementId, component) {
      if (this.get('_isEnabled')) {
        this.set('elementId', elementId);
        this.set('component', component);
        this.get('_window').addEventListener('resize', this._boundClose);
      }
    },
    enable() {
      this.set('_isEnabled', true);
    }
  });
});