define("ln-ember-form-elements/templates/components/form-elements/radio-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "+xRNSydG",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[30,0,[\"formatedOptions\"]]],null]],null],null,[[[1,\"  \"],[1,[28,[35,2],null,[[\"theme\",\"label\",\"value\",\"readonly\",\"onChange\"],[[30,0,[\"theme\"]],[30,1,[\"text\"]],[30,1,[\"value\"]],[30,1,[\"readonly\"]],[28,[37,3],[[30,0],\"onOptionChange\",[30,1,[\"id\"]]],null]]]]],[1,\"\\n\"]],[1]],null]],[\"option\"],false,[\"each\",\"-track-array\",\"form-elements/styled-checkbox\",\"action\"]]",
    "moduleName": "ln-ember-form-elements/templates/components/form-elements/radio-group.hbs",
    "isStrictMode": false
  });
});