define("ln-ember-form-elements/components/form-elements/date-range-input-base", ["exports", "moment", "@ember/object", "ln-ember-form-elements/components/form-elements/date-input-base", "ln-ember-form-elements/templates/components/form-elements/date-range-input"], function (_exports, _moment, _object, _dateInputBase, _dateRangeInput) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * @class formElements/DateRangeInputBaseComponent
   * @extends formElements/DateInputBaseComponent
   * @abstract
   */
  var _default = _exports.default = _dateInputBase.default.extend({
    /**
     * @memberof formElements/DateRangeInputBaseComponent
     * @instance
     *
     * @type {String}
     * @default null
     */
    startLabel: null,
    /**
     * @memberof formElements/DateRangeInputBaseComponent
     * @instance
     *
     * @type {String}
     * @default null
     */
    endLabel: null,
    /**
     * If this is a truthy value the input field will be set into the error state.
     *
     * @memberof formElements/DateRangeInputBaseComponent
     * @instance
     *
     * @type {any}
     * @default null
     */
    startError: null,
    /**
     * If this is a truthy value the input field will be set into the error state.
     *
     * @memberof formElements/DateRangeInputBaseComponent
     * @instance
     *
     * @type {any}
     * @default null
     */
    endError: null,
    classNames: ['form-elements--date-range-input-base-component'],
    layout: _dateRangeInput.default,
    date: null,
    /**
     * @memberof formElements/DateRangeInputBaseComponent
     * @instance
     *
     * @type {String}
     * @default "Select a date"
     */
    startPlaceholder: 'Select a date',
    /**
     * @memberof formElements/DateRangeInputBaseComponent
     * @instance
     *
     * @type {String}
     * @default "Select a date"
     */
    endPlaceholder: 'Select a date',
    displayValue: (0, _object.computed)('range.{start,end}', 'value.{start,end}', 'showDropDown', {
      get() {
        const {
          start,
          end
        } = this.get('value') || {};
        return {
          start: this.formatDate(start),
          end: this.formatDate(end)
        };
      },
      set(key, value) {
        return value;
      }
    }),
    range: (0, _object.computed)('value.{start,end}', function () {
      let {
        start,
        end
      } = this.get('value') || {};
      if (!start) {
        return {
          start: null,
          end: null
        };
      }
      if (!end) {
        end = start;
      }
      [start, end] = [start, end].sort((a, b) => (0, _moment.default)(a).isAfter(b));
      return {
        start,
        end
      };
    })
  });
});