define("ln-ember-form-elements/components/form-elements/option-button", ["exports", "@ember/component", "@ember/object", "@ember/service", "ln-ember-form-elements/templates/components/form-elements/option-button"], function (_exports, _component, _object, _service, _optionButton) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   *
   * Renders an option button.<br>
   *
   * ### Example Simple Options:
   *
   * ```JavaScript
   * Component.extend({
   *   options: [1, 2, 3],
   *   value: null,
   *   actions: {
   *     setValue(value) {
   *       this.set('value', value);
   *     }
   *   }
   * })
   * ```
   *
   * ```Handlebars
   * {{form-elements/option-button
   *   options=options
   *   action=(action "setValue")}}
   * ```
   *
   * ### Example Object Options:
   *
   * ```JavaScript
   * Component.extend({
   *   options: [
   *     { name: 'One', value: 1 },
   *     { name: 'Two', value: 2 }
   *   value: null,
   *   actions: {
   *     setValue(value, option) {
   *       this.set('value', value);
   *     }
   *   }
   * })
   * ```
   *
   * ```Handlebars
   * {{form-elements/option-button
   *   options=options
   *   optionValuePath="value"
   *   optionLabelPath="name"
   *   action=(action "setValue")}}
   * ```
   *
   * @class formElements/OptionButtonComponent
   * @extends @ember/component
   */
  var _default = _exports.default = _component.default.extend({
    formElements: (0, _service.inject)(),
    layout: _optionButton.default,
    classNames: [
    // Deprecated: { id: ln-ember-form-elements-class-names, until: 3.0.0 }
    'option-button-component', 'form-elements--option-button-component'],
    /**
     * Defines which attribute of an options object should be used as the value of the option.<br>
     * For example if the value is the id of the object the  `optionValuePath` should be set to `content.id`
     *
     * @memberof formElements/OptionButtonComponent
     * @instance
     *
     * @type String
     * @default null
     */
    optionValuePath: null,
    /**
     * Defines which attribute of an options object should be used as the label of the option.<br>
     * For example if the attribute `name` should be used as the label  `optionLabelPath` should be set to `content.name`
     *
     * @memberof formElements/OptionButtonComponent
     * @instance
     *
     * @type String
     * @default null
     */
    optionLabelPath: null,
    /**
     * Theme can be "light" or "dark".
     *
     * @memberof formElements/OptionButtonComponent
     * @instance
     *
     * @type string
     * @default "light"
     */
    theme: null,
    /**
     * The label of option button
     *
     * @memberof formElements/OptionButtonComponent
     * @instance
     *
     * @type String
     * @default null
     */
    label: null,
    /**
     * Actions to set the value. Arguments are `value` and `option`.
     *
     * @memberof formElements/OptionButtonComponent
     * @instance
     *
     * @type {function}
     */
    action: () => {},
    highlightTheme: (0, _object.computed)('theme', function () {
      return `${this.get('theme')}-highlight`;
    }),
    renderOptions: (0, _object.computed)('value', 'optionLabelPath', 'optionValuePath', 'options.[]', function () {
      const options = this.get('options') || [];
      return options.map(option => {
        let value = option;
        const valuePath = (this.get('optionValuePath') || '').replace(/^content\.?/, '');
        const labelPath = (this.get('optionLabelPath') || '').replace(/^content\.?/, '');
        if (valuePath) {
          value = (0, _object.get)(option, valuePath);
        }
        const label = labelPath ? (0, _object.get)(option, labelPath) : value;
        return {
          value,
          label,
          active: value === this.get('value'),
          option
        };
      });
    }),
    init() {
      this._super(...arguments);
      if (!this.get('theme')) {
        this.set('theme', this.get('formElements.defaults.theme'));
      }
    },
    actions: {
      setValue({
        value,
        option
      }) {
        this.get('action')(value, option);
      }
    }
  });
});