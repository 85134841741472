define("ln-ember-data-models/transforms/number-array", ["exports", "ember-data/transform", "@ember/utils"], function (_exports, _transform, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _transform.default.extend({
    deserialize(serialized) {
      return serialized;
    },
    serialize(deserialized) {
      if ((0, _utils.isNone)(deserialized)) {
        return null;
      }
      return deserialized.map(item => parseInt(item));
    }
  });
});