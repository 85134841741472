define("ln-ember-toolkit/mixins/scroll-events", ["exports", "jquery", "@ember/object/mixin", "@ember/runloop"], function (_exports, _jquery, _mixin, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let timeoutFn = setTimeout;
  if ('requestIdleCallback' in window) {
    timeoutFn = (...args) => window.requestIdleCallback(...args, {
      timeout: 0
    });
  }
  var _default = _exports.default = _mixin.default.create({
    lastScrollOffset: 0,
    lastScrollEventAt: 0,
    scrollSpeed: 0,
    didInsertElement() {
      this._super(...arguments);
      this.bindScrolling();
    },
    willDestroyElement() {
      this._super(...arguments);
      this.unbindScrolling();
    },
    bindScrolling() {
      const elId = this.elementId;
      (0, _jquery.default)('.main-scroll-container').on(`scroll.scroll-event-${elId}`, evt => {
        timeoutFn(() => {
          _runloop.run.throttle(this, this.triggerScroll, evt, 100);
          _runloop.run.throttle(this, this.updateScrollSpeed, evt, 200);
          _runloop.run.debounce(this, this.triggerDidScroll, evt, 250);
        });
      });
    },
    unbindScrolling() {
      const elId = this.elementId;
      (0, _jquery.default)('.main-scroll-container').off(`scroll.scroll-event-${elId}`);
    },
    updateScrollSpeed(e) {
      const delayInMs = e.timeStamp - this.lastScrollEventAt;
      const offset = e.target.scrollTop - this.lastScrollOffset;
      const speedInpxPerMs = Math.abs(offset / delayInMs);
      this.set('scrollSpeed', speedInpxPerMs);
      this.lastScrollEventAt = e.timeStamp;
      this.lastScrollOffset = e.target.scrollTop;
    },
    triggerDidScroll(e) {
      this.set('isScrolling', false);
      this.set('scrollSpeed', 0);
      this.trigger('didScroll', e);
    },
    triggerScroll(e) {
      this.set('isScrolling', true);
      this.trigger('scroll', e);
    }
  });
});