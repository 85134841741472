define("ln-ember-data-models/serializers/myliga/user-workload", ["exports", "ln-ember-data-models/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * @class Myliga/EventSerializer
   * @extends ApplicationSerializer
   * @mixes DS.EmbeddedRecordsMixin
   */
  var _default = _exports.default = _application.default.extend({
    extractId(_modelClass, resourceHash) {
      const {
        date_from,
        date_till,
        user_id
      } = resourceHash;
      return `${date_from}#${date_till}#${user_id}`;
    }
  });
});