define("ln-ember-toolkit/services/liga-os", ["exports", "@ember/service", "ln-ember-toolkit/utils/liga-os", "@ember/string", "@ember/object"], function (_exports, _service, _ligaOs, _string, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let Ligaos = _exports.default = (_dec = (0, _service.inject)('store'), _class = class Ligaos extends _service.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "store", _descriptor, this);
      _defineProperty(this, "config", {});
      _defineProperty(this, "session", {});
    }
    loadConfig() {
      return _ligaOs.default.getConfig().then(data => {
        Object.keys(data).forEach(responseKey => {
          this.config[responseKey] = data[responseKey];
        });
        return this.config;
      });
    }
    loadSession() {
      return _ligaOs.default.getSession().then(data => {
        Object.keys(data).forEach(responseKey => {
          const normalizedKey = (0, _string.camelize)(responseKey);
          (0, _object.set)(this.session, normalizedKey, data[responseKey]);
        });
        return this.session;
      });
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class); // DO NOT DELETE: this is how TypeScript knows how to look up your services.
});