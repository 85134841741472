define("ln-ember-form-elements/templates/components/form-elements/toggle-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "1SjZF0gZ",
    "block": "[[[10,\"input\"],[15,1,[30,0,[\"checkboxId\"]]],[15,\"checked\",[30,0,[\"value\"]]],[15,\"onchange\",[28,[37,0],[[30,0],\"toggleValue\"],null]],[14,4,\"checkbox\"],[12],[13],[1,\"\\n\"],[10,\"label\"],[15,\"for\",[30,0,[\"checkboxId\"]]],[12],[13],[1,\"\\n\"]],[],false,[\"action\"]]",
    "moduleName": "ln-ember-form-elements/templates/components/form-elements/toggle-button.hbs",
    "isStrictMode": false
  });
});