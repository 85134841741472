define("ln-ember-data-models/transforms/date-string", ["exports", "ember-data", "@ember/debug", "moment", "@ember/object"], function (_exports, _emberData, _debug, _moment, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Transform
  } = _emberData.default;
  const timeMap = {
    start: '00:00:00.000',
    end: '23:59:59.999'
  };

  /**
   * @class DateStringTransform
   * @extends DS.Transform
   */
  var _default = _exports.default = Transform.extend({
    dateTimeFormat: 'YYYY-MM-DDTHH:mm:ss.SSSZ',
    dateFormat: 'YYYY-MM-DD',
    deserialize(serialized, {
      timeOption = null
    } = {}) {
      if (!timeOption || serialized === undefined || serialized === null) {
        return serialized;
      }
      const dateFormat = this.get('dateFormat');
      return (0, _moment.default)(serialized).format(dateFormat);
    },
    serialize(deserialized, {
      timeOption = null
    } = {}) {
      if (!deserialized || !(0, _moment.default)(deserialized).isValid()) {
        return null;
      }
      if (!timeOption) {
        return deserialized;
      }
      const time = (0, _object.get)(timeMap, timeOption);
      (false && !(time) && (0, _debug.assert)('[date-string transform] `timeOption` has to be either "start" or "end".', time));
      const dateTimeFormat = this.get('dateTimeFormat');
      const dateTime = (0, _moment.default)(`${deserialized} ${time}`);
      return dateTime.isValid() ? dateTime.format(dateTimeFormat) : null;
    }
  });
});