define("ln-ember-form-elements/components/form-elements/-masked-input", ["exports", "imask", "@ember/object", "ln-ember-form-elements/components/form-elements/input", "@ember-decorators/component", "ln-ember-form-elements/templates/components/form-elements/input"], function (_exports, _imask, _object, _input, _component, _input2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _class2; // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  let FormElementsInput = _exports.default = (_dec = (0, _component.classNames)('FormMaskedInput'), _dec2 = (0, _object.computed)('value'), _dec(_class = (_class2 = class FormElementsInput extends _input.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "layout", _input2.default);
      _defineProperty(this, "_prevOptions", void 0);
      _defineProperty(this, "options", void 0);
      _defineProperty(this, "value", '');
      _defineProperty(this, "_mask", void 0);
      _defineProperty(this, "onAccept", void 0);
      _defineProperty(this, "onCopy", void 0);
    }
    didInsertElement() {
      super.didInsertElement();
      if (this.options) {
        this._initMask();
        this.maskValue = this.value;
        this._prevOptions = this.options;
      }
    }
    willDestroyElement() {
      if (this._mask) {
        this._mask.destroy();
      }
      super.willDestroyElement();
    }
    didUpdateAttrs() {
      super.didUpdateAttrs();
      if (this.options && this.options.mask) {
        if (this.options !== this._prevOptions) {
          if (this._mask) {
            this._mask.updateOptions(this.options);
          } else {
            this._initMask();
          }
        }
      } else if (this._mask) {
        this._mask.destroy();
        delete this._mask;
        this.notifyPropertyChange('value');
      }
      this._prevOptions = this.options;
      if (this._mask && this.value !== this._mask.unmaskedValue) {
        this.maskValue = this.value;
      }
    }
    get maskValue() {
      return this._mask ? this._mask.value : '';
    }
    set maskValue(value) {
      let _v;
      if (value === null || value === undefined) {
        _v = '';
      } else if (typeof value === 'number') {
        _v = value.toString();
      } else if (typeof value === 'string') {
        _v = value;
      } else {
        throw new Error(`Unknown value type passed: "${typeof value}"`);
      }
      this._mask.value = _v;
    }
    get displayValue() {
      return this._mask ? this._mask.masked.value : this.value;
    }
    _initMask() {
      const i = document.getElementById(this.inputId);
      this._mask = (0, _imask.default)(i, this.options);
      this._mask.on('accept', this._onAccept.bind(this)).on('complete', this._onComplete.bind(this));
    }
    _onAccept(e) {
      if (e) {
        // skip handler on initial rendering
        if (this.onAccept) {
          return this.onAccept(this._mask.unmaskedValue, e);
        }
        throw new Error('no input handler defined');
      }
    }
    _onComplete(..._args) {
      // there is no a known use case for it, so let's leave it empty for now
    }
    _onInput(_event) {
      // disable "oninput" and delegate to iMask `onAccept`
    }
    _onCopy(e) {
      if (this._mask) {
        return typeof this.onCopy === 'function' && this.onCopy(this._mask.unmaskedValue, e);
      }
    }
  }, _applyDecoratedDescriptor(_class2.prototype, "displayValue", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "displayValue"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "_onAccept", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "_onAccept"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "_onComplete", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "_onComplete"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "_onInput", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "_onInput"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "_onCopy", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "_onCopy"), _class2.prototype), _class2)) || _class);
});