define("ln-ember-contact-menu/components/contact-menu", ["exports", "@ember/application", "@ember/object", "@ember/component", "@ember/service", "@ember/object/proxy", "@ember/object/promise-proxy-mixin", "ln-ember-toolkit/utils/liga-os", "ln-ember-contact-menu/templates/components/contact-menu", "@ember/runloop", "@ember/object/computed", "ln-ember-toolkit/utils/media-matcher"], function (_exports, _application, _object, _component, _service, _proxy, _promiseProxyMixin, _ligaOs, _contactMenu, _runloop, _computed, _mediaMatcher) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const PromiseObject = _proxy.default.extend(_promiseProxyMixin.default);
  var _default = _exports.default = _component.default.extend({
    api: (0, _service.inject)(),
    store: (0, _service.inject)(),
    _config: (0, _object.computed)('config', function () {
      if (this.config) {
        return this.config;
      }
      const {
        APP: {
          config
        }
      } = (0, _application.getOwner)(this).resolveRegistration('config:environment');
      return _object.default.create(config);
    }),
    layout: _contactMenu.default,
    /**
     * Identifies user.
     * It's required if contactId is not passed.
     *
     * @type number
     */
    userId: null,
    /**
     * Identifies contact.
     * It's required if userId is not passed.
     *
     * @type number
     */
    contactId: null,
    /**
     * Action to be called on clicking a profile menu-item
     *
     * @type function
     */
    openProfile: null,
    /**
     * Holds active menu item name
     *
     * @private
     * @type string
     */
    activeItem: null,
    classNames: ['contact-menu-component'],
    classNameBindings: ['isPending', 'activeItem:has-active'],
    isPending: (0, _computed.alias)('contact.isPending'),
    _window: (0, _object.computed)('window', function () {
      return this.window || window;
    }),
    contact: (0, _object.computed)(function () {
      const user = this.get('userId');
      const contactId = this.get('contactId');
      return PromiseObject.create({
        promise: user ? this._loadContactByFilter(user) : this._loadContact(contactId)
      });
    }),
    title: (0, _object.computed)('contact.{forename,name}', function () {
      return `${this.get('contact.forename')} ${this.get('contact.name')}${this.get('userId') ? "'s Profile" : ''}`;
    }),
    didDestroyElement() {
      if (typeof this.onDestroyElement === 'function') {
        _runloop.run.scheduleOnce('render', () => this.onDestroyElement(this.element));
      }
    },
    noop() {
      return false;
    },
    processContact(contact) {
      contact.forename = contact.first_name;
      contact.phone_mobile = contact.mobile_phone;
      return contact;
    },
    _loadContactByFilter(userId) {
      return this.get('api').read('myliga', ['users', userId]).then(response => response);
    },
    _loadContact(id) {
      return this.get('api').read('myliga', ['liga3000_contacts', id]).then(response => this.processContact(response));
    },
    actions: {
      openProfile() {
        _ligaOs.default.openResource('user', this.get('userId'));
      },
      openCall(phoneNumber) {
        if (!phoneNumber) {
          return;
        }
        const desktopLinkPrefix = this.get('_config.telephoneLinks') || 'callto';
        const proto = _mediaMatcher.default.getVersion() === 'Mobile' ? 'tel' : desktopLinkPrefix;
        const link = `${proto}:${phoneNumber.replace(/ /g, '')}`;
        this.get('_window').open(link, '_top');
      },
      activateItem(name) {
        this.set('activeItem', name);
        if (typeof this.didActivateItem === 'function') {
          this.didActivateItem(name);
        }
        return false;
      }
    }
  });
});