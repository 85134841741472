define("ln-ember-data-models/transforms/id", ["exports", "@ember/utils", "ember-data/transform"], function (_exports, _utils, _transform) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * @class IdTransform
   * @extends DS.Transform
   */
  var _default = _exports.default = _transform.default.extend({
    deserialize(serialized) {
      return serialized;
    },
    serialize(deserialized) {
      return (0, _utils.isNone)(deserialized) ? null : Number(deserialized);
    }
  });
});