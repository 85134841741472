define("ln-ember-data-models/models/vdc/language", ["exports", "@ember/object", "@ember/object/computed", "ember-data"], function (_exports, _object, _computed, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr
  } = _emberData.default;

  /**
   * @class Vdc/LanguageModel
   * @extends DS.Model
   */
  var _default = _exports.default = Model.extend({
    /**
     * @memberof Vdc/LanguageModel
     * @instance
     *
     * @type {Number}
     */
    version: attr('number'),
    /**
     * @memberof Vdc/LanguageModel
     * @instance
     *
     * @type {String}
     */
    name: attr('nullable-string'),
    /**
     * @memberof Vdc/LanguageModel
     * @instance
     *
     * @type {String}
     */
    iso_code: attr('nullable-string'),
    /**
     * @memberof Vdc/LanguageModel
     * @instance
     *
     * @type {Number}
     */
    number: attr('number'),
    /**
     * @memberof Vdc/LanguageModel
     * @instance
     *
     * @type {String}
     */
    code: (0, _computed.alias)('iso_code'),
    /**
     * @memberof Vdc/LanguageModel
     * @instance
     *
     * @type {String}
     */
    fullName: (0, _object.computed)(function () {
      return `${this.get('name')} (${this.get('iso_code')})`;
    })
  });
});