define("ln-ember-data-models/adapters/vdc/address", ["exports", "ln-ember-data-models/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _application.default.extend({
    urlForQuery(query, modelName) {
      if (query && query.shared === true) {
        return this.buildURLFor('stores/shared_addresses', null, modelName);
      }
      return this._super(...arguments);
    }
  });
});