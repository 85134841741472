define("ln-ember-contact-menu/components/micro-menu-item", ["exports", "@ember/component", "@ember/object", "@ember/string", "ln-ember-contact-menu/templates/components/micro-menu-item", "@ember/runloop"], function (_exports, _component, _object, _string, _microMenuItem, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const NO_OP_HREF = (0, _string.htmlSafe)('javascript:void(0)');
  var _default = _exports.default = _component.default.extend({
    layout: _microMenuItem.default,
    tagName: 'li',
    classNames: ['micro-menu-item', 'micro-menu--item'],
    classNameBindings: ['description:has-description', 'namedClass', 'isDisabled', 'isFake'],
    name: '',
    title: '',
    titleTooltip: undefined,
    description: false,
    descriptionTooltip: undefined,
    hasIcon: true,
    href: (0, _object.computed)({
      get() {
        return NO_OP_HREF;
      },
      set(k, v) {
        return v;
      }
    }),
    target: (0, _object.computed)({
      get() {
        return '_self';
      },
      set(k, v) {
        return v;
      }
    }),
    isDisabled: (0, _object.computed)('onClick', 'href', function () {
      const href = this.get('href');
      return (!href || href === NO_OP_HREF) && typeof this.onClick !== 'function';
    }),
    namedClass: (0, _object.computed)('name', function () {
      const name = this.get('name');
      return name ? `item-${name}` : false;
    }),
    didInsertElement() {
      this._super(...arguments);
      this._setTooltipIfOverflown('title');
      this._setTooltipIfOverflown('description');
    },
    _setTooltipIfOverflown(fieldName) {
      const node = this.element.querySelector(`.micro-menu-item--${fieldName}`);
      if (node && isTextOverflown(node)) {
        (0, _runloop.run)(() => this.set(`${fieldName}Tooltip`, this.get(fieldName)));
      }
    },
    click() {
      if (this.get('isDisabled')) {
        return false;
      }
      if (typeof this.onClick === 'function') {
        return this.onClick();
      }
    }
  });
  /**
   * Checks if the text overflows element boundaries
   *
   * @param {HTMLElement} element
   * @returns {bool}
   */
  function isTextOverflown(element) {
    return element.scrollWidth > element.offsetWidth;
  }
});