define("ln-ember-data-models/serializers/vdc/fragments/article-review-marketing-object", ["exports", "ln-ember-data-models/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * @class VdcArticleReviewMarketingObjectFragmentSerializer
   * @extends ApplicationSerializer
   */
  const VdcArticleReviewMarketingObjectFragmentSerializer = _application.default.extend({
    extractAttributes(modelClass, resourceHash) {
      let attributeKey;
      const attributes = {};
      modelClass.eachAttribute(key => {
        attributeKey = this.keyForAttribute(key, 'deserialize');
        if (resourceHash[attributeKey] !== undefined) {
          attributes[key] = resourceHash[attributeKey];
        } else {
          if (attributeKey === 'model_attributes') {
            const attribute = {
              id: resourceHash.id,
              marketing_category_id: resourceHash.marketing_category_id,
              marketing_category_name: resourceHash.marketing_category_name,
              name: resourceHash.name
            };
            attributes[key] = attribute;
          }
        }
      });
      return attributes;
    },
    serializeAttribute(snapshot, json, key, attribute) {
      if (key === 'model_attributes') {
        Object.assign(json, snapshot.attr(key));
        return;
      }
      this._super(snapshot, json, key, attribute);
    }
  });
  var _default = _exports.default = VdcArticleReviewMarketingObjectFragmentSerializer;
});