define("ln-ember-contact-menu/templates/components/fake-contact-menu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "uV+gHrbz",
    "block": "[[[10,\"ul\"],[12],[1,\"\\n  \"],[1,[28,[35,0],null,[[\"isFake\"],[true]]]],[1,\"\\n\\n  \"],[1,[28,[35,0],null,[[\"isFake\"],[true]]]],[1,\"\\n\\n  \"],[10,\"ul\"],[14,0,\"micro-menu--group\"],[12],[1,\"\\n    \"],[1,[28,[35,0],null,[[\"isFake\",\"description\"],[true,true]]]],[1,\"\\n\\n    \"],[1,[28,[35,0],null,[[\"isFake\",\"description\",\"hasIcon\"],[true,true,false]]]],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[1,[28,[35,0],null,[[\"isFake\",\"description\"],[true,true]]]],[1,\"\\n\"],[13]],[],false,[\"micro-menu-item\"]]",
    "moduleName": "ln-ember-contact-menu/templates/components/fake-contact-menu.hbs",
    "isStrictMode": false
  });
});