define("ln-ember-toolkit/serializers/ln-rest", ["exports", "@ember/polyfills", "ember-data/serializers/rest", "ember-inflector", "@ember/string", "ln-ember-toolkit/models/versioned-model"], function (_exports, _polyfills, _rest, _emberInflector, _string, _versionedModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const SIDELOADED_KEY = '__include';
  var _default = _exports.default = _rest.default.extend({
    _isEmbeddedRelationship(key) {
      const attrs = this.attrs || {};
      const embeddedRecordsKeys = Object.keys(attrs).filter(key => {
        return attrs[key].serialize === 'records' || attrs[key].embedded === 'always';
      });
      return embeddedRecordsKeys.includes(key);
    },
    serializeIntoHash(hash, _typeClass, snapshot, options) {
      // get rid of root key for save requests
      // @see: https://ligadigital.atlassian.net/browse/EX30-386
      Object.assign(hash, this.serialize(snapshot, options));
    },
    serialize(snapshot, options) {
      // eslint-disable-next-line prefer-rest-params
      const json = this._super(...arguments);
      if (options && options.patch) {
        const keysToInclude = Object.keys(snapshot.changedAttributes()).map(this.keyForAttribute);
        keysToInclude.push('version');
        snapshot.eachRelationship((key, relationshipMeta) => {
          if (this.shouldIncludeRelationshipIntoPatch(snapshot, key, relationshipMeta.kind)) {
            keysToInclude.push(this.keyForRelationship(key, relationshipMeta.kind, 'serialize'));
          }
        });
        Object.keys(json).forEach(jsonKey => {
          if (!keysToInclude.includes(jsonKey)) {
            delete json[jsonKey];
          }
        });
      }
      return json;
    },
    shouldIncludeEmbeddedIntoPatch(snapshot, key, kind) {
      if (kind === 'belongsTo') {
        const relationship = snapshot.belongsTo(key);
        if (relationship) {
          const serialized = relationship.serialize({
            patch: true
          });
          return Object.keys(serialized).filter(k => k !== 'version').length > 0;
        }
      }
      if (kind === 'hasMany') {
        const relationships = snapshot.hasMany(key);
        if (relationships) {
          const serializedArray = relationships.map(rel => rel.serialize({
            patch: true
          }));
          const firstNonEmpty = serializedArray.find(serialized => {
            return Object.keys(serialized).filter(k => k !== 'version').length > 0;
          });
          return !!firstNonEmpty;
        }
      }
      return false;
    },
    shouldIncludeRelationshipIntoPatch(snapshot, key, kind) {
      if ((0, _versionedModel.isVersionedModel)(snapshot.record) && snapshot.record.isRelationshipDirty(key)) {
        return true;
      }
      if (this._isEmbeddedRelationship(key)) {
        return this.shouldIncludeEmbeddedIntoPatch(snapshot, key, kind);
      }
      return false;
    },
    normalizeArrayResponse(store, primaryModelClass, payload, id, requestType) {
      const meta = (0, _polyfills.assign)(payload.meta, {
        summary: payload.summary || {}
      });
      if (typeof meta.count !== 'undefined') {
        meta.result_count = meta.count;
      } else if (typeof meta.result_count !== 'undefined') {
        meta.count = meta.result_count;
      }
      const res = {
        meta,
        [this.typeKey(primaryModelClass)]: payload.result
      };
      return this._super(store, primaryModelClass, res, id, requestType);
    },
    normalizeSingleResponse(store, primaryModelClass, payload, id, requestType) {
      const sideloaded = {};
      if (payload && payload[SIDELOADED_KEY]) {
        Object.keys(payload[SIDELOADED_KEY]).forEach(k => {
          // Ember REST Serializer treats all the underscorder properties
          // as a sideloaded data collections which are pushed to the store
          sideloaded[`_${k}`] = payload[SIDELOADED_KEY][k];
        });
        delete payload[SIDELOADED_KEY];
      }
      payload = Object.assign({
        meta: payload && payload.meta || {},
        [this.typeKey(primaryModelClass)]: payload
      }, sideloaded);
      return this._super(store, primaryModelClass, payload, id, requestType);
    },
    typeKey(typeClass) {
      return (0, _emberInflector.pluralize)(typeClass.modelName);
    },
    serializeBelongsTo(snapshot, json, relationship) {
      this._super(snapshot, json, relationship);
      const key = this.keyForRelationship(relationship.key, relationship.kind);
      if (typeof json[key] === 'string') {
        // Sometimes API requires integer ids
        // if it can't be converted to a number, let's assume it's an UUID
        // and leave it as is
        json[key] = isNaN(Number(json[key])) ? json[key] : Number(json[key]);
      }
    },
    keyForRelationship(key, relationshipKind) {
      key = (0, _string.underscore)(key);
      if (relationshipKind === 'belongsTo') {
        return `${key}_id`;
      } else if (relationshipKind === 'hasMany') {
        return `${(0, _emberInflector.singularize)(key)}_ids`;
      } else {
        return key;
      }
    },
    /**
     * Converts camelCased attributes to underscored when serializing.
     * @method keyForAttribute
     * @param {String} attr
     * @return {String}
     */
    keyForAttribute(attr) {
      return (0, _string.underscore)(attr);
    }
  });
});