define("ln-ember-form-elements/components/form-elements/calendar", ["exports", "@ember/runloop", "moment", "@ember/component", "@ember/object", "ln-ember-form-elements/utils/computed-properties", "ln-ember-form-elements/templates/components/form-elements/calendar"], function (_exports, _runloop, _moment, _component, _object, _computedProperties, _calendar) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * @class formElements/Calendar
   * @extends @ember/component
   */
  var _default = _exports.default = _component.default.extend({
    /**
     * String formate should be `YYYY-MM-DD`.
     *
     * ❗️ If the `range` attribute is set `date` will be ignored.
     *
     * @memberof formElements/Calendar
     * @instance
     *
     * @type {string}
     * @default null
     */
    date: null,
    /**
     * @memberof formElements/Calendar
     * @instance
     *
     * @type {string}
     * @default null
     */
    theme: null,
    /**
     *
     *
     * @memberof formElements/Calendar
     * @instance
     *
     * @type {object}
     * @default null
     */
    range: null,
    /**
     * @memberof formElements/Calendar
     * @instance
     *
     * @type {boolean}
     * @default false
     */
    disabled: false,
    /**
     * @memberof formElements/Calendar
     * @instance
     *
     * @type {boolean}
     * @default false
     */
    allowHalfDays: false,
    /**
     * @memberof formElements/Calendar
     * @instance
     *
     * @type {component}
     * @default null
     */
    weekStatusComponent: null,
    /**
     * @memberof formElements/Calendar
     * @instance
     *
     * @type {boolean}
     * @default false
     */
    showCalendarWeek: false,
    layout: _calendar.default,
    showMonthList: false,
    classNames: ['form-elements--calendar-component'],
    classNameBindings: ['showMonthList'],
    visibleMonth: (0, _object.computed)('visibleDate', function () {
      return (0, _moment.default)(this.get('visibleDate')).format('YYYY-MM');
    }),
    actions: {
      onNavigationMonthClick() {
        this.toggleProperty('showMonthList');
      },
      onMonthChange(value, {
        clicked
      }) {
        this.set('visibleDate', (0, _moment.default)(value, 'YYYY-MM').toDate());
        if (clicked) {
          (0, _runloop.later)(() => this.set('showMonthList', false), 150);
        }
      }
    },
    /**
     * @memberof formElements/Calendar
     * @instance
     *
     * @type {function}
     * @default null
     */
    onSelect: (0, _computedProperties.defaultAction)(function (date) {
      this.set('date', date);
    }),
    /**
     * @memberof formElements/Calendar
     * @instance
     *
     * @type {function}
     * @default null
     */
    onNavigate: (0, _computedProperties.defaultAction)(function (visibleDate) {
      this.set('visibleDate', visibleDate);
    }),
    visibleDate: new Date()
  });
});