define("ln-ember-toolkit/services/host", ["exports", "rsvp", "@ember/service"], function (_exports, _rsvp, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * @deprecated
   * @class LigaOS
   * @extends Ember.Service
   */
  var _default = _exports.default = _service.default.extend({
    store: (0, _service.inject)(),
    /**
     * @memberof LigaOS
     * @instance
     *
     * @property {Number}
     */
    clientId: null,
    /**
     * @memberof LigaOS
     * @instance
     *
     * @return {Array}
     */
    clients: null,
    /**
     * @memberof LigaOS
     * @instance
     *
     * @return {Array}
     */
    loadClients() {
      if (this.clients && this.clients.length) {
        return (0, _rsvp.resolve)(this.clients);
      }
      return this.store.findAll('vdc/client').then(clients => {
        this.set('clients', clients);
        return clients;
      });
    },
    init() {
      this._super(...arguments);
      this.clients = [];
    }
  });
});