define("ln-ember-toolkit/components/resized-image", ["exports", "@ember/component", "@ember/service", "@ember/object", "@ember/runloop", "@ember/template", "jquery"], function (_exports, _component, _service, _object, _runloop, _template, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * ## Example
   *
   * ```
   * {{resized-image src="url/to/image.jpg"}}
   * ```
   *
   *
   * @class ResizedImage
   */
  var _default = _exports.default = _component.default.extend({
    resizer: (0, _service.inject)(),
    tagName: 'img',
    classNames: ['resized-image-component'],
    classNameBindings: ['animated', 'loaded:is-loaded:is-not-loaded', 'showBeforeLoaded'],
    attributeBindings: ['attrStyle:style', 'attrSrc:src', 'title'],
    /**
     * @memberof ResizedImage
     * @instance
     *
     * @type {String}
     */
    src: null,
    /**
     * Url to placeholder image
     *
     * @memberof ResizedImage
     * @instance
     *
     * @type {String}
     */
    placeholder: 'data:image/gif;base64,R0lGODlhAQABAPAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==',
    /**
     * An image to be inserted when original image fetching errored
     *
     * @memberof ResizedImage
     * @instance
     *
     * @type {String}
     */
    errorPlaceholder: null,
    /**
     * @memberof ResizedImage
     * @instance
     *
     * @type {String}
     * @default 'crop'
     */
    mode: 'crop',
    /**
     * @memberof ResizedImage
     * @instance
     *
     * @type {Boolean}
     * @default false
     */
    animated: false,
    /**
     * @memberof ResizedImage
     * @instance
     *
     * @type {Boolean}
     * @default false
     */
    showBeforeLoaded: false,
    /**
     * @memberof ResizedImage
     * @instance
     *
     * @type {String}
     */
    title: null,
    loadedSrc: null,
    hasError: false,
    width: (0, _object.computed)('size', function () {
      const size = (this.size || '').match(/^(\d*)x\d*$/);
      return size ? size[1] : 0;
    }),
    height: (0, _object.computed)('size', function () {
      const size = (this.size || '').match(/^\d*x(\d*)$/);
      return size ? size[1] : 0;
    }),
    attrStyle: (0, _object.computed)('width', 'height', 'animated', 'loaded', function () {
      const style = [];
      if (this.width || this.height) {
        let width = 'width';
        let height = 'height';
        const widthValue = this.width ? `${this.width}px` : 'auto';
        const heightValue = this.height ? `${this.height}px` : 'auto';
        if (this.mode === 'resize') {
          width = 'max-width';
          height = 'max-height';
        }
        style.push(`${width}: ${widthValue}`);
        style.push(`${height}: ${heightValue}`);
      }
      return (0, _template.htmlSafe)(style.join(';'));
    }),
    loaded: (0, _object.computed)('attrSrc', 'loadedSrc', function () {
      return this.attrSrc === this.loadedSrc;
    }),
    srcUrl: (0, _object.computed)('src', 'src.preview_url', function () {
      const src = this.get('src.preview_url') || this.src;
      if (src && typeof src === 'string') {
        return src;
      }
      return undefined;
    }),
    attrSrc: (0, _object.computed)('srcUrl', 'width', 'height', 'hasError', function () {
      if (!this.hasError) {
        const width = (this.width || 0) * 2;
        const height = (this.height || 0) * 2;
        if (width <= 0 && height <= 0) {
          return undefined;
        }
        const src = this.srcUrl;
        if (src) {
          const url = this.resizer.getUrl(this.mode, src, width, height);
          if (url) {
            return url;
          }
        }
        return this.placeholder;
      }
      return this.errorPlaceholder || this.placeholder;
    }),
    // eslint-disable-next-line ember/no-observers
    imageLoadObserver: (0, _object.observer)('attrSrc', function () {
      if (this.isDestroyed || !this.element) {
        return;
      }
      this.get('hasError', false);
      const {
        attrSrc
      } = this;
      if (this.loadedSrc !== attrSrc) {
        this.set('loadedSrc', null);
        _runloop.run.scheduleOnce('afterRender', () => {
          if (this.isDestroyed) {
            return;
          }
          if (this.element.complete) {
            this.set('loadedSrc', attrSrc);
          } else {
            (0, _jquery.default)(this.element).one('load', () => (0, _runloop.run)(() => {
              if (this.isDestroyed) {
                return;
              }
              if (attrSrc === this.attrSrc) {
                this.set('loadedSrc', attrSrc);
              }
            })).one('error', () => {
              if (this.isDestroyed) {
                return;
              }
              if (attrSrc === this.attrSrc) {
                this.set('hasError', true);
              }
            });
          }
        });
      }
    }),
    didInsertElement() {
      this._super(...arguments);
      this.imageLoadObserver();
    }
  });
});