define("ln-ember-data-models/models/myliga/talent", ["exports", "ember-data", "ln-ember-data-models/utils/enum-option", "ember-data-model-fragments/attributes"], function (_exports, _emberData, _enumOption, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.typeOptions = _exports.default = void 0;
  const {
    attr,
    Model
  } = _emberData.default;

  /**
   * @name Myliga/TalentModel/typeOptions
   * @type {EnumOption[]}
   */
  const typeOptions = _exports.typeOptions = [_enumOption.default.create({
    id: 'Competence'
  }), _enumOption.default.create({
    id: 'Skill'
  })];

  /**
   * @class Myliga/TalentModel
   * @extends DS.Model
   */
  const TalentModel = Model.extend({
    /**
     * @memberof Myliga/TalentModel
     * @instance
     *
     * @type {Number}
     */
    version: attr('number'),
    /**
     * @memberof Myliga/TalentModel
     * @instance
     *
     * @type {String}
     */
    name: attr('nullable-string'),
    /**
     * @memberof Myliga/TalentModel
     * @instance
     *
     * @type {Number}
     */
    category_id: attr('id'),
    /**
     * @memberof Myliga/TalentModel
     * @instance
     *
     * @type {String}
     */
    type: attr('enum-string', {
      options: typeOptions
    }),
    /**
     * @memberof Myliga/TalentModel
     * @instance
     *
     * @type {String}
     */
    description: attr('nullable-string'),
    /**
     * @memberof Myliga/TalentModel
     * @instance
     *
     * @type {Boolean}
     */
    active: attr('boolean'),
    /**
     * @memberof Myliga/TalentModel
     * @instance
     *
     * @type {Myliga/fragments/TalentLevelFragment[]}
     */
    levels: (0, _attributes.fragmentArray)('myliga/fragments/talent-level')
  });
  TalentModel.reopenClass({
    /**
     * @memberof Myliga/TalentModel
     * @static
     *
     * @type {EnumOption[]}
     */
    typeOptions
  });
  var _default = _exports.default = TalentModel;
});