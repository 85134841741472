define("ln-ember-data-models/models/vdc/role", ["exports", "ember-data", "ln-ember-data-models/utils/enum-option"], function (_exports, _emberData, _enumOption) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.typeOptions = _exports.notificationCheckTypes = _exports.default = void 0;
  const {
    attr,
    Model
  } = _emberData.default;

  /**
   * @name Vdc/RoleModel/typeOptions
   * @type {EnumOption[]}
   */
  const typeOptions = _exports.typeOptions = [_enumOption.default.create({
    id: 'General'
  }), _enumOption.default.create({
    id: 'Country'
  }), _enumOption.default.create({
    id: 'Project'
  }), _enumOption.default.create({
    id: 'Market'
  }), _enumOption.default.create({
    id: 'Store',
    name: 'POS'
  }), _enumOption.default.create({
    id: 'Event'
  }), _enumOption.default.create({
    id: 'ArticleCategory'
  })];

  /**
   * @name Vdc/RoleModel/notificationCheckTypes
   * @type {EnumOption[]}
   */
  const notificationCheckTypes = _exports.notificationCheckTypes = [_enumOption.default.create({
    id: 'Single'
  }), _enumOption.default.create({
    id: 'Group'
  })];

  /**
   * @class Vdc/RoleModel
   * @extends DS.Model
   */
  const RoleModel = Model.extend({
    /**
     * @memberof Vdc/RoleModel
     * @instance
     *
     * @type {Number}
     */
    version: attr('number'),
    /**
     * @memberof Vdc/RoleModel
     * @instance
     *
     * @type {String}
     */
    name: attr('nullable-string'),
    /**
     * @memberof Vdc/RoleModel
     * @instance
     *
     * @type {String}
     */
    description: attr('nullable-string'),
    /**
     * @memberof Vdc/RoleModel
     * @instance
     *
     * @type {Boolean}
     */
    system: attr('boolean', {
      defaultValue: () => false
    }),
    /**
     * @memberof Vdc/RoleModel
     * @instance
     *
     * @type {Array}
     */
    object_groups: attr({
      defaultValue: () => []
    }),
    /**
     * @memberof Vdc/RoleModel
     * @instance
     *
     * @type {Array}
     */
    rights: attr({
      defaultValue: () => []
    }),
    /**
     * @memberof Vdc/RoleModel
     * @instance
     *
     * @type {Number[]}
     */
    right_group_ids: attr('number-array', {
      defaultValue: () => []
    }),
    /**
     * @memberof Vdc/RoleModel
     * @instance
     *
     * @type {String}
     */
    type: attr('enum-string', {
      options: typeOptions
    }),
    /**
     * @memberof Vdc/RoleModel
     * @instance
     *
     * @type {String}
     */
    notification_check_type: attr('enum-string', {
      options: notificationCheckTypes
    }),
    /**
     * @memberof Vdc/RoleModel
     * @instance
     *
     * @type {Number}
     */
    client_id: attr('id'),
    /**
     * @memberof Vdc/RoleModel
     * @instance
     *
     * @type {Number}
     */
    application_id: attr('id')
  });
  RoleModel.reopenClass({
    /**
     * @memberof Vdc/RoleModel
     * @static
     *
     * @type {EnumOption[]}
     */
    typeOptions,
    /**
     * @memberof Vdc/RoleModel
     * @static
     *
     * @type {EnumOption[]}
     */
    notificationCheckTypes
  });
  var _default = _exports.default = RoleModel;
});