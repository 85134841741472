define("ln-ember-api-service/utils/url", ["exports", "@ember/array", "@ember/object", "@ember/utils", "@ember/string", "ember-inflector", "jquery-param"], function (_exports, _array, _object, _utils, _string, _emberInflector, _jqueryParam) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * @class Url
   */
  var _default = _exports.default = {
    /**
     * Example:
     *
     * ```
     * Url.hash('foo', 'bar', 1);           // => '#/foo/bar/1'
     * Url.hash(project, 'edit');           // => '#/project/13/edit'
     * Url.hash(project, article, 'edit');  // => '#/project/13/article/1/edit'
     * ```
     *
     * @memberof Url
     * @static
     *
     * @method hash
     * @return {string} url
     */
    hash() {
      const parts = this.append(['#'], arguments, false);
      return this.join(parts);
    },
    /**
     * Example:
     *
     * ```
     * Url.api('v1', 'foo', 'bar', 1);        // => '/v1/foo/bar/1'
     * Url.api('v1', project);                // => '/v1/projects/13'
     * Url.api('v1', project, 'pictures');    // => '/v1/projects/13/pictures'
     * ```
     *
     * @memberof Url
     * @static
     *
     * @method api
     * @return {string} url
     */
    api() {
      const parts = this.append([''], arguments, true);
      return this.join(parts);
    },
    /**
     * Example:
     *
     * ```
     * Url.api('http://example.org', 'foo', 'bar', {v: 1});
     * // => 'http://example.org/foo/bar? v=1'
     * ```
     *
     * @memberof Url
     * @static
     * @method join
     * @param {string|Object} parts
     * @returns {string}
     */
    join(parts) {
      if ((0, _utils.typeOf)(parts) !== 'array') {
        parts = Array.prototype.slice.call(arguments);
      }
      let params;
      if ((0, _utils.typeOf)(parts[parts.length - 1]) === 'object') {
        params = parts.pop();
        params = Object.keys(params).filter(key => !(0, _utils.isNone)(params[key])).reduce((data, key) => {
          if (Array.isArray(params[key])) {
            data[key] = (0, _array.A)(params[key]).uniq();
          } else {
            data[key] = params[key];
          }
          return data;
        }, {});
      }
      parts = parts.map(part => String(part).replace(/^\/+|\/+$/g, ''));
      const queryString = (0, _jqueryParam.default)(params || {});
      return this.replaceAllExceptFirst(`${parts.join('/')}${queryString ? `?${queryString}` : ''}`, '?', '&');
    },
    /**
     *
     *  Example:
     *
     * ```
     * Url.replaceAllExceptFirst('url?q=q?s=s&a=a', '?', '&')
     * // =>  'url?q=q&s=s&a=a
     *
     * @memberof Url
     *
     * @param {string} input
     * @param {string} toReplace
     * @param {string} replacement
     *
     * @returns {string}
     */
    replaceAllExceptFirst(input, toReplace, replacement) {
      const splitInput = input.split(toReplace);
      return splitInput.shift() + (splitInput.length ? toReplace + splitInput.join(replacement) : '');
    },
    /**
     * @memberof Url
     * @static
     *
     * @method append
     * @param  {Array} base
     * @param  {Array} parts
     * @param  {Boolean} shouldPluralize=false
     * @return {Array}
     */
    append(base, parts, shouldPluralize = false) {
      const url = [].concat(base);
      for (let i = 0, len = parts.length; i < len; i++) {
        const part = parts[i];
        if ((0, _utils.typeOf)(part) === 'instance' && (part.constructor.typeKey || part.typeKey)) {
          let key = part.constructor.typeKey || (0, _object.get)(part, 'typeKey');
          key = (0, _string.decamelize)(key);
          if (shouldPluralize) {
            key = (0, _emberInflector.pluralize)(key);
          }
          url.push(key);
          url.push(part.get('id'));
        } else {
          url.push(part);
        }
      }
      return url;
    }
  };
});