define("ln-ember-contact-menu/components/micro-menu", ["exports", "@ember/component", "@ember/object", "@ember/service", "ln-ember-contact-menu/templates/components/micro-menu", "@ember/runloop", "@ember/object/internals"], function (_exports, _component, _object, _service, _microMenu, _runloop, _internals) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const MM_MOBILE_SPACE_ALLOWED = 250;
  const MM_DESKTOP_SPACE_ALLOWED = 310;
  var _default = _exports.default = _component.default.extend({
    microMenu: (0, _service.inject)(),
    mediaMatcher: (0, _service.inject)(),
    router: (0, _service.inject)(),
    layout: _microMenu.default,
    classNames: ['micro-menu-component'],
    classNameBindings: ['microMenu.hasActiveItem:has-active'],
    component: _object.computed.alias('microMenu.component'),
    isRendered: false,
    targetElement: (0, _object.computed)('microMenu.elementId', function () {
      return document.getElementById(this.get('microMenu.elementId'));
    }),
    isNorthern: (0, _object.computed)('targetElement', function () {
      const targetElement = this.get('targetElement');
      const isMobile = this.get('mediaMatcher.isMobile');
      if (isMobile) {
        return hasEnoughSpaceBelow(targetElement, MM_MOBILE_SPACE_ALLOWED);
      } else {
        return hasEnoughSpaceBelow(targetElement, MM_DESKTOP_SPACE_ALLOWED);
      }
    }),
    init(...args) {
      this._super(...args);
      this.set('popoverId', `${(0, _internals.guidFor)(this)}-popover`);
    },
    didInsertElement() {
      this.router.on('routeWillChange', () => {
        if (this.microMenu.isActive) {
          this._close();
        }
      });
    },
    didRender() {
      if (this.get('microMenu.isActive')) {
        (0, _runloop.next)(() => {
          if (this.isDestroying) {
            return;
          }
          this.set('isRendered', true);
        });
      }
    },
    _close: (0, _object.action)(function () {
      this.get('microMenu').close();
    }),
    _onChangePopoverVisibility: (0, _object.action)(function (state) {
      // cleanup the micro-menu state if the popover is closed from the outside
      // (e.g. by pressing the escape key)
      if (!state) {
        this._close();
      }
    }),
    _didActivateItem: (0, _object.action)(function () {
      this.get('microMenu').set('hasActiveItem', true);
    })
  });
  /**
   * Checks if there is enough space below the element (at least 310px on desktop and 250px on mobile)
   *
   * @param {HTMLElement} element
   * @param {number} spaceAllowedBelow
   * @return {boolean}
   */
  function hasEnoughSpaceBelow(element, spaceAllowedBelow) {
    const elementRect = element.getBoundingClientRect();
    const spaceBelowElement = window.innerHeight - elementRect.bottom;
    return spaceBelowElement > spaceAllowedBelow;
  }
});