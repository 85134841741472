define("ln-ember-form-elements/components/form-elements/image-upload", ["exports", "@ember/component", "@ember/object/internals", "@ember/object", "rsvp", "@ember/runloop", "@ember/template", "ln-ember-form-elements/templates/components/form-elements/image-upload", "jquery"], function (_exports, _component, _internals, _object, _rsvp, _runloop, _template, _imageUpload, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   *
   * ### Example:
   *
   * ```Handlebars
   * {{form-elements/image-upload
   *    files=files
   *    addFiles=(action "addFiles")
   *    removeFile=(action "removeFile")
   *    label="Upload files"
   *  }}
   * ```
   *
   * @class formElements/ImageUploadComponent
   */
  var _default = _exports.default = _component.default.extend({
    /**
     * @memberof formElements/ImageUploadComponent
     * @instance
     *
     * @type {Array<(File|Object)>}
     */
    files: null,
    /**
     * @memberof formElements/ImageUploadComponent
     * @instance
     *
     * @type {String}
     */
    label: null,
    layout: _imageUpload.default,
    classNames: [
    // Deprecated: { id: ln-ember-form-elements-class-names, until: 3.0.0 }
    'form-elements-image-upload-component', 'form-elements--image-upload-component'],
    classNameBindings: ['fileObjects.length:has-files:has-no-files', 'label:has-label:has-no-label'],
    fileObjectsCache: null,
    /**
     * @memberof formElements/ImageUploadComponent
     * @instance
     *
     * @type {Function}
     */
    removeFile: () => {},
    fileObjects: (0, _object.computed)('files.[]', function () {
      const files = (this.get('files') || []).map(file => this.get('fileObjectsCache').find(obj => obj.get('file') === file) || FileObject.create({
        file
      }));
      this.set('fileObjectsCache', files);
      return files;
    }),
    init() {
      this._super();
      this.set('fileObjectsCache', []);
    },
    didInsertElement() {
      this._super(), (0, _jquery.default)(this.element).on('drop', event => this.onDrop(event));
    },
    willDestroyElement() {
      this._super(), (0, _jquery.default)(this.element).off('drop');
    },
    actions: {
      removeFile(file) {
        file.remove().then(() => this.get('removeFile')(file.get('file')));
      },
      clickDummy() {
        (0, _jquery.default)('.file-input', this.element).click();
        return false;
      }
    },
    onDrop(event) {
      event.preventDefault();
      const {
        dataTransfer: {
          files
        } = {}
      } = event;
      if (files) {
        this.triggerAddFiles(files);
      }
      return false;
    },
    change({
      target
    }) {
      if (target.className !== 'file-input') {
        return;
      }
      this.triggerAddFiles(target.files);
      (0, _jquery.default)(target, this.element).val('');
    },
    triggerAddFiles(files) {
      files = Array.prototype.filter.call(files, ({
        type
      }) => type.match(/^image.*/));
      this._compatibleSendAction('addFiles', files);
    },
    _compatibleSendAction(action = 'action', ...args) {
      if (typeof this.get(action) === 'string') {
        return this.sendAction(action, ...args);
      } else if (typeof this.get(action) === 'function') {
        return this.get(action)(...args);
      }
    }
  });
  const FileObject = _object.default.extend({
    isFile: false,
    id: (0, _object.computed)(function () {
      return (0, _internals.guidFor)(this);
    }),
    style: (0, _object.computed)('url', function () {
      const url = this.get('url');
      return url && (0, _template.htmlSafe)(`background-image: url(${url});`);
    }),
    init() {
      this._super();
      if (this.get('file') instanceof File) {
        if (!this.get('file.type').match(/^image.*/)) {
          return;
        }
        this.set('isFile', true);
        const reader = new FileReader();
        reader.onload = ({
          target: {
            result
          }
        }) => this.set('url', result);
        reader.readAsDataURL(this.get('file'));
      } else {
        this.set('url', this.get('file.preview_url'));
      }
    },
    remove() {
      return new _rsvp.Promise(resolve => {
        this.set('isRemoved', true);
        (0, _runloop.later)(resolve, 310);
      });
    }
  });
});