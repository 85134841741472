define("ln-ember-form-elements/components/form-elements/textarea", ["exports", "@ember/object", "@ember/runloop", "@ember/object/internals", "@ember-decorators/component", "@ember/application/deprecations", "ln-ember-form-elements/templates/components/form-elements/textarea", "ln-ember-form-elements/components/form-elements/expanding-textarea"], function (_exports, _object, _runloop, _internals, _component, _deprecations, _textarea, _expandingTextarea) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _class2;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const scope = 'FormTextarea';

  // @ts-expect-error Import compiled template.
  let Textarea = _exports.default = (_dec = (0, _component.classNames)('FormElement', scope), _dec2 = (0, _component.classNameBindings)('disabled', `_isExpanded:${scope}--isExpanded`, `_supportsCollapse:${scope}--supportsCollapse`, `error:${scope}--hasError`, `warning:${scope}--hasWarning`), _dec3 = (0, _object.computed)('supportsCollapse'), _dec4 = (0, _object.computed)('validationMaxLength', 'value'), _dec5 = (0, _object.computed)('error', 'warning'), _dec6 = (0, _object.computed)('name'), _dec(_class = _dec2(_class = (_class2 = class Textarea extends _expandingTextarea.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "layout", _textarea.default);
      _defineProperty(this, "error", void 0);
      _defineProperty(this, "focusIn", void 0);
      _defineProperty(this, "focusOut", void 0);
      _defineProperty(this, "onInput", void 0);
      _defineProperty(this, "supportsCollapse", void 0);
      _defineProperty(this, "validationMaxLength", void 0);
      _defineProperty(this, "warning", void 0);
      _defineProperty(this, "_controlId", `${(0, _internals.guidFor)(this)}-textarea`);
      _defineProperty(this, "_isFocused", false);
      _defineProperty(this, "_isMultiline", false);
      _defineProperty(this, "_scope", scope);
    }
    didRender() {
      this._super();
      if (this.readonly && this._supportsCollapse) {
        (0, _runloop.scheduleOnce)('afterRender', this, () => {
          const hiddenTextarea = this.element.querySelector('textarea');
          this.element.style.setProperty('--previewHeightExpanded', `${hiddenTextarea.scrollHeight}px`);
          this.set('_isMultiline', hiddenTextarea.scrollHeight > hiddenTextarea.clientHeight);
        });
      }
    }
    get _supportsCollapse() {
      return typeof this.supportsCollapse === 'boolean' ? this.supportsCollapse : true;
    }
    get _validationMaxLengthRemaining() {
      const valueLength = this.value?.length;
      if (typeof this.validationMaxLength !== 'number' || !valueLength) {
        return;
      }
      return `(${this.validationMaxLength - valueLength})`;
    }
    get _validationMessage() {
      return this.error ?? this.warning;
    }
    get 'data-test-field'() {
      return this.name;
    }
    _focusIn(...args) {
      if (!this.isDestroying) {
        this.set('_isFocused', true);
      }
      if (this.focusIn) {
        (false && !(false) && (0, _deprecations.deprecate)('FormElements::Textarea `focusIn` argument is deprecated. Use `onFocusIn` instead.', false, {
          for: 'ln-ember-form-elements',
          id: 'form-elements-textarea-focus-in',
          since: '3.0.0-beta.115',
          until: '3.0.0'
        }));
        this.focusIn(...args);
      } else if (this.onFocusIn) {
        this.onFocusIn(...args);
      }
    }
    _focusOut(...args) {
      if (!this.isDestroying) {
        this.set('_isFocused', false);
      }
      if (this.focusOut) {
        (false && !(false) && (0, _deprecations.deprecate)('FormElements::Textarea `focusOut` argument is deprecated. Use `onFocusOut` instead.', false, {
          for: 'ln-ember-form-elements',
          id: 'form-elements-textarea-focus-out',
          since: '3.0.0-beta.115',
          until: '3.0.0'
        }));
        this.focusOut(...args);
      } else if (this.onFocusOut) {
        this.onFocusOut(...args);
      }
    }
    _onInput(event) {
      this.onInput?.(event);
    }
    _toggleIsExpanded() {
      this.set('_isExpanded', !this._isExpanded);
    }
  }, _applyDecoratedDescriptor(_class2.prototype, "_supportsCollapse", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "_supportsCollapse"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "_validationMaxLengthRemaining", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "_validationMaxLengthRemaining"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "_validationMessage", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "_validationMessage"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, 'data-test-field', [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, 'data-test-field'), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "_focusIn", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "_focusIn"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "_focusOut", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "_focusOut"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "_onInput", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "_onInput"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "_toggleIsExpanded", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "_toggleIsExpanded"), _class2.prototype), _class2)) || _class) || _class);
});