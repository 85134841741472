define("ln-ember-data-models/models/vdc/cost-rule-condition", ["exports", "ember-data", "ln-ember-data-models/utils/enum-option"], function (_exports, _emberData, _enumOption) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.typeOptions = _exports.default = void 0;
  const {
    Model,
    attr
  } = _emberData.default;

  /**
   * @name Vdc/CostRuleConditionModel/typeOptions
   * @type {EnumOption[]}
   */
  const typeOptions = _exports.typeOptions = [_enumOption.default.create({
    id: 'Country'
  }), _enumOption.default.create({
    id: 'Article'
  }), _enumOption.default.create({
    id: 'EXW',
    name: 'Ex Works'
  }), _enumOption.default.create({
    id: 'LocalProduction'
  })];

  /**
   * @class Vdc/CostRuleConditionModel
   * @extends DS.Model
   */
  const CostRuleConditionModel = Model.extend({
    /**
     * @memberof Vdc/CostRuleConditionModel
     * @instance
     *
     * @type {Number}
     */
    version: attr('number'),
    /**
     * @memberof Vdc/CostRuleConditionModel
     * @instance
     *
     * @type {String}
     */
    type: attr('enum-string', {
      options: typeOptions
    }),
    /**
     * @memberof Vdc/CostRuleConditionModel
     * @instance
     *
     * @type {transforms/number-array}
     */
    country_ids: attr('number-array', {
      defaultValue: () => []
    }),
    /**
     * @memberof Vdc/CostRuleConditionModel
     * @instance
     *
     * @type {Array}
     */
    article_ids: attr('number-array', {
      defaultValue: () => []
    }),
    /**
     * @memberof Vdc/CostRuleConditionModel
     * @instance
     *
     * @type {Boolean}
     */
    pickup: attr('boolean', {
      defaultValue: () => false
    }),
    /**
     * @memberof Vdc/CostRuleConditionModel
     * @instance
     *
     * @type {Boolean}
     */
    local_production: attr('boolean', {
      defaultValue: () => false
    }),
    /**
     * @memberof Vdc/CostRuleConditionModel
     * @instance
     *
     * @type {Number}
     */
    cost_rule_id: attr('id')
  });
  CostRuleConditionModel.reopenClass({
    /**
     * @memberof Vdc/CostRuleConditionModel
     * @static
     *
     * @type {EnumOption[]}
     */
    typeOptions
  });
  var _default = _exports.default = CostRuleConditionModel;
});