define("ln-ember-data-models/adapters/myliga/event-participant", ["exports", "ln-ember-data-models/adapters/application", "@ember/object", "@ember/service"], function (_exports, _application, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // TODO Use abstract/SubResourceAdapter
  /**
   * @class Myliga/EventParticipantAdapter
   * @extends ApplicationAdapter
   */
  var _default = _exports.default = _application.default.extend({
    api: (0, _service.inject)(),
    buildURL(modelName, id, snapshot, requestType, query, pathOnly = false) {
      let url = [];
      const host = (0, _object.get)(this, 'host');
      const prefix = this.urlPrefix();
      if (snapshot) {
        url.push('events');
        url.push(snapshot.attr('event_id'));
      }
      url.push('participants');
      if (id) {
        url.push(encodeURIComponent(id));
      }
      if (prefix) {
        url.unshift(prefix);
      }
      url = url.join('/');
      if (!host && url && url.charAt(0) !== '/') {
        url = `/${url}`;
      }
      let baseUrl = '';
      if (!pathOnly) {
        baseUrl = this.baseUrlFor(modelName);
      }
      return `${baseUrl}${url}`;
    }
  });
});