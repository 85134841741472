define("ln-ember-toolkit/components/svg-icon", ["exports", "@ember/component", "@ember-decorators/component", "ln-ember-toolkit/templates/components/svg-icon"], function (_exports, _component, _component2, _svgIcon) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _SvgIcon; // @ts-expect-error ignore import of compiled template
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  let SvgIcon = _exports.default = (_dec = (0, _component2.tagName)(''), _dec(_class = (_SvgIcon = class SvgIcon extends _component.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "layout", _svgIcon.default);
    }
  }, _defineProperty(_SvgIcon, "positionalParams", ['name']), _SvgIcon)) || _class);
});