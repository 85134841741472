define("ln-ember-toolkit/services/resizer", ["exports", "@ember/application/deprecations", "@ember/object", "@ember/service", "@ember/utils", "rsvp"], function (_exports, _deprecations, _object, _service, _utils, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function regexQuote(string) {
    return string.replace(/[-\\^$*+?.()|[\]{}/]/g, '\\$&');
  }

  /**
   * @class Resizer
   * @extends Ember.Service
   */
  var _default = _exports.default = _service.default.extend({
    configuration: (0, _service.inject)(),
    /**
     *
     * Examples:
     *
     * ```javascript
     * ImageHelper.resize('http://example.org/image.png', 100, 100);
     * // => "http://resizer.example.org/resize/100x100/example.org/image.png"
     *
     * ImageHelper.resize('http://example.org/image.png', 100)
     * // => "http://resizer.example.org/resize/100x/example.org/image.png"
     *
     * ImageHelper.resize('http://example.org/image.png', null, 100)
     * // => "http://resizer.example.org/resize/x100/example.org/image.png"
     * ```
     *
     * @memberof Resizer
     * @instance
     *
     * @method resize
     * @param  {String}  url
     * @param  {Number}  width
     * @param  {Number} height
     * @return {string}
     */
    resize(url, width, height) {
      return this.getUrl('resize', url, width, height);
    },
    /**
     *
     * Examples:
     *
     * ```javascript
     * ImageHelper.shrink('http://example.org/image.png', 100, 100);
     * // => "http://resizer.example.org/shrink/100x100/example.org/image.png"
     * ```
     *
     * @memberof Resizer
     * @instance
     *
     * @method shrink
     * @param  {String}  url
     * @param  {Number}  width
     * @param  {Number} height
     * @return {String}
     */
    shrink(url, width, height) {
      return this.getUrl('shrink', url, width, height);
    },
    /**
     *
     * Examples:
     *
     * ```javascript
     * ImageHelper.crop('http://example.org/image.png', 100, 100);
     * // => "http://resizer.example.org/crop/100x100/example.org/image.png"
     * ```
     *
     * @memberof Resizer
     * @instance
     *
     * @method crop
     * @param  {string}  url
     * @param  {number}  width
     * @param  {Number} height
     * @return {string}
     */
    crop(url, width, height) {
      return this.getUrl('crop', url, width, height);
    },
    /**
     *
     * Examples:
     *
     * ```javascript
     * ImageHelper.resizeFit('http://example.org/image.png', 100, 100);
     * // => "http://resizer.example.org/crop/100x100/example.org/image.png"
     * ```
     *
     * @memberof Resizer
     * @instance
     *
     * @method resizeFit
     * @param  {string}  url
     * @param  {Number}  width
     * @param  {Number} height
     * @return {string}
     */
    resizeFit(url, width, height) {
      return this.getUrl('resizefit', url, width, height);
    },
    /**
     * @memberof Resizer
     * @instance
     *
     * @method getUrl
     * @param  {String}  mode
     * @param  {String}  url
     * @param  {Number}  width
     * @param  {Number} height
     * @return {String}
     */
    getUrl(mode, url, width, height) {
      if ((0, _utils.typeOf)(url) !== 'string') {
        url = (0, _object.get)(url, 'preview_url');
      }
      const resizerUrl = this.configuration.getConfig('resizer.url');
      if (resizerUrl && this._isResizableUrl(url) && (width || height)) {
        if (!url.match(/^https?:\/\//)) {
          url = location.href.replace(/\/?#.*/, '/') + url;
        }

        // Screws up URLs for the screenshot service, is it needed?
        // url = url.replace(/\?.*$/, '');

        if (url.match(/\.svg$/)) {
          return url;
        }
        url = this.stripResizer(url);
        const size = `${width || ''}x${height || ''}`;
        url = [resizerUrl, mode, size, url.replace(/^https?:\/\//, '')].join('/');
      }
      return url;
    },
    /**
     * @memberof Resizer
     * @instance
     *
     * @param  {String} url
     * @return {String}
     */
    stripResizer(url) {
      const resizerUrl = this.configuration.getConfig('resizer.url');
      const isSSL = resizerUrl.match(/^https:/);
      const regex = new RegExp(`^${regexQuote(resizerUrl)}/(resize|crop)/(d+xd+|xd+|d+x)/`);
      if (regex.test(url)) {
        url = url.replace(regex, isSSL ? 'https://' : 'http://');
      }
      return url;
    },
    /**
     * @memberof Resizer
     * @instance
     *
     * @param  {String} url
     * @return {Ember.RSVP.Promise}
     */
    getImage(url) {
      return new _rsvp.Promise((resolve, reject) => {
        if (url) {
          const img = new Image();
          img.src = url;
          if (this._isLoaded(img)) {
            resolve(img);
          } else {
            img.onload = () => resolve(img);
          }
        } else {
          reject();
        }
      });
    },
    getDimentions(url) {
      (false && !(false) && (0, _deprecations.deprecate)('[ResizerService] getDimentions(url) is deprecated use getDimensions(url) instead.', false, {
        id: 'resizer-service-get-dimentions',
        until: '3.0.0'
      }));
      return this.getDimensions(url);
    },
    /**
     * @memberof Resizer
     * @instance
     *
     * @param  {String} url
     * @return {Ember.RSVP.Promise}
     */
    getDimensions(url) {
      return new _rsvp.Promise((resolve /* , reject */) => {
        this.getImage(url).then(function (img) {
          resolve({
            width: img.width,
            height: img.height,
            ratio: img.width / img.height
          });
        });
      });
    },
    _isLoaded({
      complete,
      naturalWidth
    }) {
      if (!complete) {
        return false;
      }
      if (typeof naturalWidth !== 'undefined' && naturalWidth === 0) {
        return false;
      }
      return true;
    },
    _isResizableUrl(url) {
      url = url || '';
      return String(url).match(/^https?:\/\//) && !String(url).match(/^https?:\/\/(localhost|127\.0\.0\.1)/);
    }
  });
});