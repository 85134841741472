define("ln-ember-data-models/serializers/vdc/fragments/-fragment-marketing-category", ["exports", "ln-ember-data-models/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * @class Vdc/Fragments/-FragmentMarketingCategorySerializer
   * @extends ApplicationSerializer
   */
  var _default = _exports.default = _application.default.extend({
    extractAttributes(modelClass, resourceHash) {
      let attributeKey;
      const attributes = {};
      modelClass.eachAttribute(key => {
        attributeKey = this.keyForAttribute(key, 'deserialize');
        if (resourceHash[attributeKey] !== undefined) {
          attributes[key] = resourceHash[attributeKey];
        } else {
          if (attributeKey === 'marketing_category_id') {
            const attribute = resourceHash.marketing_category && resourceHash.marketing_category.id;
            if (attribute !== undefined) {
              attributes[key] = attribute;
            }
          }
        }
      });
      return attributes;
    },
    serialize(snapshot, options) {
      const json = this._super(snapshot, options);
      delete json[this.primaryKey];
      return json;
    }
  });
});