define("ln-ember-toolkit/mixins/resize-events", ["exports", "jquery", "@ember/object/mixin", "@ember/runloop"], function (_exports, _jquery, _mixin, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function triggerDidResize() {
    this.set('isResizing', false);
    this.trigger('didResize');
  }
  function triggerResize() {
    this.set('isResizing', true);
    this.trigger('resize');
  }
  var _default = _exports.default = _mixin.default.create({
    didInsertElement() {
      this._super(...arguments);
      this.bindResizing();
    },
    willDestroyElement() {
      this._super(...arguments);
      this.unbindResizing();
    },
    bindResizing() {
      const elId = this.elementId;
      (0, _jquery.default)(window).on(`resize.resize-event-${elId}`, () => {
        _runloop.run.throttle(this, triggerResize, 100);
        _runloop.run.debounce(this, triggerDidResize, 250, false);
      });
    },
    unbindResizing() {
      const elId = this.elementId;
      (0, _jquery.default)(window).off(`resize.resize-event-${elId}`);
    }
  });
});