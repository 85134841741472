define("ln-ember-form-elements/utils/computed-properties", ["exports", "@ember/object", "@ember/utils"], function (_exports, _object, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.assertValue = assertValue;
  _exports.defaultAction = defaultAction;
  _exports.resetOnChange = resetOnChange;
  function defaultAction(defaultHandler) {
    const bind = (that, handler) => (0, _utils.isNone)(handler) ? defaultHandler.bind(that) : handler;
    return (0, _object.computed)({
      get() {
        return bind(this);
      },
      set(key, handler) {
        return bind(this, handler);
      }
    });
  }
  function resetOnChange(dependentKey, defaultValue = null) {
    return (0, _object.computed)(dependentKey, {
      get() {
        return defaultValue;
      },
      set(key, value) {
        return value;
      }
    });
  }
  function assertValue(defaultValue, assertFn = () => {}) {
    return (0, _object.computed)({
      get() {
        return defaultValue;
      },
      set(key, value) {
        if (assertFn.call(this, value)) {
          return value;
        }
        return defaultValue;
      }
    });
  }
});