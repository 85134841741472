define("ln-ember-data-models/adapters/application", ["exports", "@ember/application/deprecations", "ember-data", "ember-inflector", "@ember/application", "@ember/debug", "@ember/object", "@ember/service", "@ember/string", "@ember/polyfills", "@ember/array", "rsvp"], function (_exports, _deprecations, _emberData, _emberInflector, _application, _debug, _object, _service, _string, _polyfills, _array, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    RESTAdapter,
    InvalidError,
    AdapterError
  } = _emberData.default;

  /**
   * @class ApplicationAdapter
   * @extends DS.ApplicationAdapter
   */
  var _default = _exports.default = RESTAdapter.extend({
    _token: null,
    config: (0, _object.computed)(function () {
      const config = (0, _application.getOwner)(this).resolveRegistration('config:environment');
      return config.APP.config;
    }),
    headers: (0, _object.computed)('_host.clientId', '_token', 'language', 'session.token', function () {
      const headers = {
        Authorization: `Bearer ${this._token}`,
        'Accept-Language': this.language
      };
      if (this.get('_host.clientId')) {
        headers['Client-Id'] = this.get('_host.clientId');
      }
      return headers;
    }),
    apiEvents: (0, _service.inject)(),
    _host: (0, _service.inject)('host'),
    init() {
      this._super(...arguments);
      const apiEvents = this.apiEvents;
      if (false === 'loadToken' in this) {
        // the whole api-events pattern is bad.
        // The recommended approach is to inject a "session" service into the ApiService
        // The only responsibility of the "session" service is to provide the token
        (false && !(false) && (0, _deprecations.deprecate)('"loadToken" is not defined', false, {
          id: 'ln-ember-api-service.missing-loadToken',
          until: '6.0.0',
          url: 'https://ligadigital.atlassian.net/browse/LGOS-764'
        }));
        this.set('session', apiEvents.getSession());
        apiEvents.on('session-change', session => {
          this.set('session', session);
        });
      }
      this.set('language', apiEvents.getLanguage());
      apiEvents.on('language-change', language => {
        this.set('language', language);
      });
    },
    // Modeltypes have the form of 'vdc/comment' or 'myliga/comment', but the vdc or myliga part is not included in the api path.
    // So we take out the namespace in here and format the actual modelname for the api
    pathForType(type) {
      (false && !(type.match('^[^/]+/[^/]+$')) && (0, _debug.assert)('All models must have a valid namespace: <api>/<resource>', type.match('^[^/]+/[^/]+$')));
      const [, path] = type.split('/');
      return (0, _emberInflector.pluralize)((0, _string.underscore)(path));
    },
    handleResponse(status, headers, payload) {
      if (this.isSuccess(status, headers, payload)) {
        return payload;
      }
      const {
        status_code,
        message,
        details
      } = this.normalizeErrorResponse(status, payload);
      this.apiEvents.triggerErrorResponse({
        status_code,
        message,
        details
      });
      if (status_code === 400) {
        return this._getErrorCodeWithStatus(new InvalidError(details, message), status_code);
      }
      return this._getErrorCodeWithStatus(new AdapterError(details, message), status_code);
    },
    normalizeErrorResponse(status, payload) {
      payload = payload || {
        status_code: status,
        message: 'Unknow error',
        details: []
      };

      // ⚠️ Error 500s in the myliga api are plain text (Content-Type:text/plain)
      if (typeof payload === 'string') {
        return {
          status_code: status,
          message: payload,
          details: []
        };
      }

      // ⚠️ Error responses look diffrent in myliga and vdc
      // - vdc:    `{ status_code, error_code, message, details }`
      // - myliga: `{ status_code, error_message, details }`

      let {
        status_code,
        error_message,
        message,
        details
      } = payload;

      // (1) Normalize all detailts to { reference, message }
      details = details.map(({
        reference,
        message,
        error_message
      }) => ({
        reference: reference && reference.replace(/^obj\./, ''),
        message: message || error_message
      }));

      // (2) Normalize the message and add details without a reference to it
      const unreferencedDetails = details.filter(({
        reference
      }) => !reference).map(({
        message
      }) => message);
      message = [message || error_message].concat(unreferencedDetails).join('\n');

      // (3) Remove details without a refernce
      details = details.filter(({
        reference
      }) => reference);
      return {
        status_code,
        message,
        details
      };
    },
    buildURL(modelName, id, snapshot, requestType, query) {
      switch (requestType) {
        case 'findRecord':
          return this.urlForFindRecord(id, modelName, snapshot);
        case 'findAll':
          return this.urlForFindAll(modelName, snapshot);
        case 'query':
          return this.urlForQuery(query, modelName, snapshot);
        case 'queryRecord':
          return this.urlForQueryRecord(query, modelName, snapshot);
        case 'findMany':
          return this.urlForFindMany(id, modelName, snapshot);
        case 'findHasMany':
          return this.urlForFindHasMany(id, modelName, snapshot);
        case 'findBelongsTo':
          return this.urlForFindBelongsTo(id, modelName, snapshot);
        case 'createRecord':
          return this.urlForCreateRecord(modelName, snapshot);
        case 'updateRecord':
          return this.urlForUpdateRecord(id, modelName, snapshot);
        case 'deleteRecord':
          return this.urlForDeleteRecord(id, modelName, snapshot);
        default:
          return this._buildURL(modelName, id, null, snapshot);
      }
    },
    urlForFindRecord(id, modelName, snapshot) {
      return this._buildURL(modelName, id, null, snapshot);
    },
    urlForFindAll(modelName, snapshot) {
      return this._buildURL(modelName, null, null, snapshot);
    },
    urlForQuery(query, modelName, snapshot) {
      return this._buildURL(modelName, null, null, snapshot);
    },
    urlForQueryRecord(query, modelName, snapshot) {
      return this._buildURL(modelName, null, null, snapshot);
    },
    urlForFindMany(id, modelName, snapshot) {
      return this._buildURL(modelName, id, null, snapshot);
    },
    urlForFindHasMany(id, modelName, snapshot) {
      return this._buildURL(modelName, id, null, snapshot);
    },
    urlForFindBelongsTo(id, modelName, snapshot) {
      return this._buildURL(modelName, id, null, snapshot);
    },
    urlForCreateRecord(modelName, snapshot) {
      let transactionId = null;
      if ((0, _object.get)(snapshot, 'adapterOptions.transaction')) {
        transactionId = snapshot.adapterOptions.transaction.get('id');
      }
      return this._buildURL(modelName, null, transactionId, snapshot, false);
    },
    urlForUpdateRecord(id, modelName, snapshot) {
      return this._buildURL(modelName, id, null, snapshot, false);
    },
    urlForDeleteRecord(id, modelName, snapshot) {
      return this._buildURL(modelName, id, null, snapshot, false);
    },
    _buildURL(modelName, id, transactionId, snapshot, addDefaultEmbeds = true) {
      if (modelName) {
        let path = this.pathForType(modelName);
        if (path) {
          let defaultEmbeds = null;
          if (id) {
            path += `/${encodeURIComponent(id)}`;
          }
          if (snapshot && addDefaultEmbeds) {
            if (snapshot.record) {
              defaultEmbeds = snapshot.record.get('defaultEmbeds');
            } else if (snapshot.snapshots().length > 0) {
              const snapshots = snapshot.snapshots();
              defaultEmbeds = snapshots[0].record.get('defaultEmbeds');
            }
          }
          return this.buildURLFor(path, transactionId, modelName, defaultEmbeds);
        }
      }
    },
    _getErrorCodeWithStatus(error, statusCode) {
      return (0, _polyfills.assign)(error, {
        statusCode
      });
    },
    buildURLFor(path, transactionId, modelName, defaultEmbeds) {
      let paramString = '';
      if (transactionId || defaultEmbeds) {
        paramString += '?';
      }
      if (transactionId) {
        paramString += `transaction=${transactionId}`;
      }
      if (defaultEmbeds) {
        defaultEmbeds.forEach((item, index) => {
          if (index === 0 && !transactionId) {
            paramString += `embed%5B%5D=${item}`;
          } else {
            paramString += `&embed%5B%5D=${item}`;
          }
        });
      }
      if (path.indexOf('http') === 0) {
        return path += paramString;
      }
      path = path.replace(/^\//, '');
      const url = [];
      url.push(this.baseUrlFor(modelName));
      url.push(path);
      let urlString = url.join('/');
      urlString += paramString;
      return urlString;
    },
    findHasMany(store, snapshot, path) {
      const defaultEmbeds = snapshot.record.get('defaultEmbeds');
      return this.ajax(this.buildURLFor(path, null, null, defaultEmbeds), 'GET');
    },
    // Ember Data 2.0 Reload behavior
    shouldReloadRecord() {
      return true;
    },
    shouldReloadAll() {
      return true;
    },
    shouldBackgroundReloadRecord() {
      return true;
    },
    shouldBackgroundReloadAll() {
      return true;
    },
    baseUrlFor(modelName = '') {
      (false && !(modelName.match('^[^/]+/[^/]+$')) && (0, _debug.assert)('All models must have a valid namespace: <api>/<resource>', modelName.match('^[^/]+/[^/]+$')));
      const [api] = modelName.split('/');
      const {
        url,
        version
      } = this.get(`config.api-${api}`) === undefined ? {} : this.get(`config.api-${api}`);
      (false && !(url && version) && (0, _debug.assert)(`There is no valid config for "api-${api}" for model "${modelName}"`, url && version));
      return `${url}/${version}`;
    },
    ajax(...args) {
      const tokenPromise = _rsvp.default.resolve('loadToken' in this ? this.loadToken() : this.get('session.token'));
      const _super = this._super.bind(this);
      return tokenPromise.then(token => {
        this.set('_token', token);
        return _super(...args);
      });
    },
    ajaxOptions(url, type, options) {
      this.modifyAjaxOptions(options);
      return this._super(url, type, options);
    },
    modifyAjaxOptions(options) {
      if (!options) {
        return;
      }
      const {
        data
      } = options;
      if (data && typeof data === 'object') {
        Object.keys(data).forEach(key => {
          if (Array.isArray(data[key])) {
            data[key] = (0, _array.A)(data[key]).uniq();
          }
        });
      }
      return {
        data
      };
    }
  });
});