define("ln-ember-toolkit/utils/liga-os", ["exports", "rsvp"], function (_exports, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.PREFIX_QUERY_PARAM_NAME = void 0;
  const PREFIX_QUERY_PARAM_NAME = _exports.PREFIX_QUERY_PARAM_NAME = 'ligaOsPrefix';
  const LigaOS = {
    lastIdx: 0,
    resolvers: {},
    eventHandlers: {},
    _parentWindow: null,
    window,
    get parentWindow() {
      if (this._parentWindow) {
        return this._parentWindow;
      }
      const parentWindow = window.parent.window;
      return parentWindow !== window ? parentWindow : null;
    },
    set parentWindow(value) {
      this._parentWindow = value;
    },
    /**
     * @memberof LigaOs
     * @instance
     *
     * @method isLigaOsPresent
     * @return {boolean}
     */
    isLigaOsPresent() {
      return !!this.parentWindow;
    },
    /**
     * @memberof LigaOs
     * @instance
     *
     * @method openUrl
     * @param  {String} url
     * @return {Promise}
     */
    openUrl(url) {
      return this.call('openUrl', [url]);
    },
    /**
     * @memberof LigaOs
     * @instance
     *
     * @method openResource
     * @param  {String} resource
     * @param  {Number} resourceId
     * @return {Promise}
     */
    openResource(resource, resourceId) {
      return this.call('openResource', [resource, resourceId]);
    },
    /**
     * @memberof LigaOs
     * @instance
     *
     * @method setUrl
     * @param  {String} url
     * @return {Promise}
     */
    setUrl(url) {
      return this.call('setUrl', [url]);
    },
    /**
     * @memberof LigaOs
     * @instance
     *
     * @method getConfig
     * @return {Promise}
     */
    getConfig() {
      return this.call('getConfig');
    },
    /**
     * @memberof LigaOs
     * @instance
     *
     * @method getSession
     * @return {Promise}
     */
    getSession() {
      return this.call('getSession');
    },
    /**
     * @memberof LigaOs
     * @instance
     *
     * @method validateSession
     * @return {Promise}
     */
    validateSession() {
      return this.call('validateSession');
    },
    /**
     * @memberof LigaOs
     * @instance
     *
     * @method logout
     * @return {Promise}
     */
    logout() {
      return this.call('logout');
    },
    /**
     * @method getLanguage
     * @return {Promise}
     */
    getLanguage() {
      return this.call('getLanguage');
    },
    /**
     * @memberof LigaOs
     * @instance
     *
     * @method setLanguage
     * @param {String} languageCode
     * @return {Promise}
     */
    setLanguage(languageCode) {
      return this.call('setLanguage', [languageCode]);
    },
    /**
     * @memberof LigaOs
     * @instance
     *
     * @method setTitle
     * @param {String} title
     * @return {Promise}
     */
    setTitle(title) {
      return this.call('setTitle', [title]);
    },
    /**
     * @memberof LigaOs
     * @instance
     *
     * @method setTitleCount
     * @param {Number} count
     * @return {Promise}
     */
    setTitleCount(count = 0) {
      return this.call('setTitleCount', [count]);
    },
    /**
     * @memberof LigaOs
     * @instance
     *
     * @method hideCommunicator
     * @return {Promise}
     */
    hideCommunicator() {
      return this.call('hideCommunicator');
    },
    /**
     * @memberof LigaOs
     * @instance
     *
     * @method showCommunicator
     * @return {Promise}
     */
    showCommunicator() {
      return this.call('showCommunicator');
    },
    /**
     * @memberof LigaOs
     * @instance
     *
     * @method notifyAppLoaded
     * @return {Promise}
     */
    notifyAppLoaded() {
      return this.call('notifyAppLoaded');
    },
    /**
     * @memberof LigaOs
     * @instance
     *
     * @method notifyInvalidSession
     * @return {Promise}
     */
    notifyInvalidSession() {
      return this.call('notifyInvalidSession');
    },
    /**
     * @memberof LigaOs
     * @instance
     *
     * @method notifyAccessDenied
     * @param {object} options
     * @return {Promise}
     */
    notifyAccessDenied(options = {}) {
      return this.call('notifyAccessDenied', [options]);
    },
    /**
     * @memberof LigaOs
     * @instance
     *
     * @method setBlur
     * @param {Boolean} state
     * @return {Promise}
     */
    setBlur(state) {
      return this.call('setBlur', [state]);
    },
    /**
     * @memberof LigaOs
     * @instance
     *
     * @method checkBrowserSupport
     * @param {Array} supportedBrowsers
     * @return {Promise}
     */
    checkBrowserSupport(supportedBrowsers) {
      return this.call('checkBrowserSupport', [supportedBrowsers]);
    },
    /**
     * @memberof LigaOsApi
     * @instance
     *
     * @method getTheme
     * @param {Object} theme
     * @return {Promise<Object>}
     */
    getTheme() {
      return this.call('getTheme', []);
    },
    /**
     * @memberof LigaOsApi
     * @instance
     *
     * @method getSetting
     * @param {string} key
     * @param {any} defaultValue
     * @return {Promise<any>} value
     */
    getSetting(key, defaultValue) {
      return this.call('getSetting', [key, defaultValue]);
    },
    /**
     * @memberof LigaOsApi
     * @instance
     *
     * @method getTheme
     * @param {String} key
     * @param {any} value
     * @return {Promise<any>} value
     */
    setSetting(key, value) {
      return this.call('setSetting', [key, value]);
    },
    /**
     * @memberof LigaOs
     * @instance
     *
     * @private
     * @method call
     * @param {String} method
     * @param {Array} args
     * @return {Promise}
     */
    call(method, args) {
      return new _rsvp.Promise((resolve, reject) => {
        const {
          parentWindow
        } = this;
        if (!parentWindow) {
          reject(new Error('Liga OS not reachable'));
        }
        this.lastIdx = this.lastIdx + 1;
        parentWindow.postMessage({
          idx: this.lastIdx,
          method,
          args
        }, '*');
        this.resolvers[this.lastIdx] = {
          resolve,
          reject
        };
      });
    },
    splitEventName(event) {
      const eventArray = event.split('.');
      const eventName = eventArray.shift();
      const namespace = eventArray.join('-') || 'no-namespace';
      return {
        eventName,
        namespace
      };
    },
    register(event, listener) {
      const handler = this.eventHandlers || {};
      const {
        eventName,
        namespace
      } = this.splitEventName(event);
      if (!handler[eventName]) {
        handler[eventName] = {};
      }
      if (!Array.isArray(handler[eventName][namespace])) {
        handler[eventName][namespace] = [];
      }
      handler[eventName][namespace].push(listener);
      this.eventHandlers = handler;
    },
    unregister(event) {
      const handler = this.eventHandlers || {};
      const {
        eventName,
        namespace
      } = this.splitEventName(event);
      if (!handler[eventName]) {
        return;
      }
      if (handler[eventName][namespace]) {
        delete handler[eventName][namespace];
      }
      this.eventHandlers = handler;
    },
    onMessageEvent({
      data: {
        idx,
        response,
        event: eventName
      }
    }) {
      this.handleResponse(idx, response);
      this.handleEvent(eventName);
    },
    handleResponse(idx, response) {
      const {
        resolvers
      } = this;
      if (idx && resolvers[idx]) {
        resolvers[idx].resolve(response);
        delete resolvers[idx];
      }
    },
    handleEvent(eventName) {
      if (eventName && this.eventHandlers && this.eventHandlers[eventName]) {
        const eventHandlers = this.eventHandlers[eventName];
        Object.keys(eventHandlers).forEach(namespace => {
          eventHandlers[namespace].forEach(listener => listener());
        });
      }
    }
  };
  LigaOS.window.addEventListener('message', evt => {
    LigaOS.onMessageEvent(evt);
  }, false);
  var _default = _exports.default = LigaOS;
});