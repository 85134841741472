define("ln-ember-form-elements/utils/enforce-array", ["exports", "@ember/array", "@ember/utils"], function (_exports, _array, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = enforceArray;
  /**
   * Unify the value from the select as an array for less checks on the value
   *
   * @param {any} value
   * @returns {array} cleanValues as array with no null or undefined
   **/
  function enforceArray(value) {
    if ((0, _utils.isNone)(value)) {
      return [];
    }
    return (0, _array.isArray)(value) ? (0, _array.A)(value).compact() : [value];
  }
});