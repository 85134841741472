define("ln-ember-data-models/models/myliga/like", ["exports", "ember-data", "@ember/object", "ember-data-model-fragments/attributes"], function (_exports, _emberData, _object, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr
  } = _emberData.default;

  /**
   * @class Myliga/LikeModel
   * @extends DS.Model
   */
  var _default = _exports.default = Model.extend({
    /**
     * @memberof Myliga/LikeModel
     * @instance
     *
     * @type {Number}
     */
    version: attr('number'),
    /**
     * @memberof Myliga/LikeModel
     * @instance
     *
     * @type {String}
     */
    forename: attr('nullable-string'),
    /**
     * @memberof Myliga/LikeModel
     * @instance
     *
     * @type {String}
     */
    name: attr('nullable-string'),
    /**
     * @memberof Myliga/LikeModel
     * @instance
     *
     * @type {MyLiga/fragments/PictureMeta}
     */
    picture: (0, _attributes.fragment)('myliga/fragments/picture'),
    /**
     * @memberof Myliga/LikeModel
     * @instance
     *
     * @type {String}
     */
    fullName: (0, _object.computed)('forename', 'name', function () {
      return [this.get('name'), this.get('forename')].filter(el => el).join(', ');
    })
  });
});