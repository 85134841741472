define("ln-ember-form-elements/components/form-elements/styled-button", ["exports", "@ember/component", "@ember/service", "@ember/object", "@ember/runloop", "ln-ember-form-elements/templates/components/form-elements/styled-button", "jquery"], function (_exports, _component, _service, _object, _runloop, _styledButton, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   *
   * Renders an styled button.<br>
   *
   * ### Examples:
   *
   * ```Handlebars
   * {{form-elements/styled-button icon="delete"}}
   * ```
   *
   * ```Handlebars
   * {{#form-elements/styled-button click=(action "refresh"") theme="dark"}}
   *    {{loc "Refresh"}}
   *  {{/form-elements/styled-button}}
   * ```
   *
   * @class formElements/StyledButtonComponent
   * @extends @ember/component
   */
  var _default = _exports.default = _component.default.extend({
    formElements: (0, _service.inject)(),
    layout: _styledButton.default,
    tagName: 'button',
    classNames: [
    // Deprecated: { id: ln-ember-form-elements-class-names, until: 3.0.0 }
    'button',
    // Deprecated: { id: ln-ember-form-elements-class-names, until: 3.0.0 }
    'styled-button-component', 'form-elements--styled-button-component'],
    classNameBindings: ['buttonType', 'disabled', 'loading', 'icon', 'saved', 'error:has-error', 'theme', 'themeClass'],
    attributeBindings: ['tabindex', 'disabled'],
    tabindex: undefined,
    /**
     * Disabled the button if set to true.
     *
     * @memberof formElements/StyledButtonComponent
     * @instance
     *
     * @type boolean
     * @default false
     */
    disabled: false,
    /**
     * Puts the button into the loading state if set to true
     *
     * @memberof formElements/StyledButtonComponent
     * @instance
     *
     * @type boolean
     * @default false
     */
    loading: false,
    /**
     * Puts the button into the error state if set to true
     *
     * @memberof formElements/StyledButtonComponent
     * @instance
     *
     * @type boolean
     * @default false
     */
    error: false,
    /**
     * Defines the icon of the button.<br>
     * Prepackaged icons are:
     * * `edit`
     * * `delete`
     * * `remove`
     * * `close`
     * * `settings`
     * * `settings-highlighted`
     *
     * For custom icons just pass in the name of the icon.
     * The button will have the class `icon` and the passed in name of the icon.
     *
     * @memberof formElements/StyledButtonComponent
     * @instance
     *
     * @type string
     * @default null
     */
    icon: null,
    /**
     * Defines the theme of the button.<br>
     * Predefined themes are:
     * * `light`
     * * `light-highlight`
     * * `dark`
     * * `dark-highlight`
     *
     * @memberof formElements/StyledButtonComponent
     * @instance
     *
     * @type string
     * @default light
     */
    theme: null,
    themeClass: (0, _object.computed)('theme', function () {
      return `theme-${this.get('theme') || 'light'}`;
    }),
    buttonType: (0, _object.computed)('icon', function () {
      return !this.get('icon') ? 'default' : 'icon';
    }),
    resetError: (0, _object.observer)('error', function () {
      if (!this.get('error') || !(0, _jquery.default)(this.element)) {
        return;
      }
      (0, _jquery.default)(this.element).one('animationend', () => {
        if (this.attrs && typeof this.attrs.errorAnimationEnd === 'function') {
          this.attrs.errorAnimationEnd();
        }
      });
    }),
    init() {
      this._super(...arguments);
      this.handleMouseEnter = (...args) => {
        this._compatibleSendAction('mouse-enter', ...args);
      };
      this.handleMouseLeave = (...args) => {
        this._compatibleSendAction('mouse-leave', ...args);
      };
      if (!this.get('theme')) {
        this.set('theme', this.get('formElements.defaults.theme'));
      }
    },
    didInsertElement(...args) {
      this._super(...args);
      this.element.addEventListener('mouseenter', this.handleMouseEnter);
      this.element.addEventListener('mouseleave', this.handleMouseLeave);
    },
    didUpdateAttrs() {
      const {
        value: saved
      } = this.attrs.saved || {};
      if (saved) {
        (0, _runloop.later)(() => {
          if (this.get('isDestroyed')) {
            return;
          }
          this.set('saved', false);
        }, 1000);
      }
    },
    willDestroyElement(...args) {
      this._super(...args);
      this.element.removeEventListener('mouseenter', this.handleMouseEnter);
      this.element.removeEventListener('mouseleave', this.handleMouseLeave);
    },
    actions: {
      confirm() {
        if (!this.get('disabled') && !this.get('loading')) {
          this._compatibleSendAction();
        }
        return false;
      },
      cancel() {
        this.set('dialogVisible', false);
        return false;
      }
    },
    click() {
      if (!this.get('disabled') && !this.get('loading')) {
        if (this.attrs.confirm) {
          this.set('dialogVisible', true);
        } else {
          this._compatibleSendAction();
        }
      }
      return false;
    },
    _compatibleSendAction(action = 'action', ...args) {
      if (typeof this.get(action) === 'string') {
        return this.sendAction(action, ...args);
      } else if (typeof this.get(action) === 'function') {
        return this.get(action)(...args);
      }
    }
  });
});