define("ln-ember-data-models/transforms/nullable-string", ["exports", "ember-data/transform", "@ember/utils"], function (_exports, _transform, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Trims the strings by default.
   * To disable trimming pass { shouldTrim: false } as options
   */
  var _default = _exports.default = _transform.default.extend({
    deserialize(serialized, options = {}) {
      let deserialized = (0, _utils.isEmpty)(serialized) ? null : String(serialized);
      if (options.shouldTrim !== false) {
        deserialized = deserialized && deserialized.trim();
      }
      return deserialized;
    },
    serialize(deserialized, options = {}) {
      let serialized = (0, _utils.isEmpty)(deserialized) ? null : String(deserialized);
      if (options.shouldTrim !== false) {
        serialized = serialized && serialized.trim();
      }
      return serialized;
    }
  });
});