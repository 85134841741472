define("ln-ember-form-elements/templates/components/form-elements/dropdown-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "UBV+Vc0b",
    "block": "[[[18,2,[[28,[37,1],null,[[\"button\",\"dropdown\"],[[50,\"form-elements/dropdown-button/button\",0,null,[[\"toggleDropdown\",\"isOpen\",\"loading\"],[[28,[37,3],[[30,0],\"toggleDropdown\"],null],[30,0,[\"dropdownOpen\"]],[30,1]]]],[50,\"form-elements/dropdown-button/dropdown\",0,null,[[\"isOpen\"],[[30,0,[\"dropdownOpen\"]]]]]]]]]],[1,\"\\n\"]],[\"@loading\",\"&default\"],false,[\"yield\",\"hash\",\"component\",\"action\"]]",
    "moduleName": "ln-ember-form-elements/templates/components/form-elements/dropdown-button.hbs",
    "isStrictMode": false
  });
});