define("ln-ember-form-elements/utils/moment-helper", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.dateToWeekData = dateToWeekData;
  _exports.isBetweenOrSame = isBetweenOrSame;
  _exports.range = range;
  _exports.roundToQuarterHours = roundToQuarterHours;
  _exports.weekDataToDate = weekDataToDate;
  _exports.weekKey = weekKey;
  _exports.weekKeyToDate = weekKeyToDate;
  _exports.weekStart = weekStart;
  /**
   * @module utils/moment-helper
   */

  /**
   * @param  {MomentObject} date
   * @return {MomentObject}
   */
  function roundToQuarterHours(date) {
    const minutes = Math.round(date.minutes() / 15) * 15;
    date.minutes(minutes).seconds(0).milliseconds(0);
    return date;
  }

  /**
   * Return `true` if `date` is between `startDay` and `endDay`, including start
   * and end day.
   *
   * Example:
   *
   * ```JavaScript
   * isBetweenOrSame(moment('2016-12-24'), '2016-12-24', '2016-12-26', 'day') // => `true`
   * isBetweenOrSame(moment('2016-12-23'), '2016-12-24', '2016-12-26', 'day') // => `false`
   * ```
   *
   * @param  {moment-like} date
   * @param  {moment-like} start
   * @param  {moment-like} end
   * @param  {object} options
   * @param  {string} options.type='day'
   * @return {boolean}
   */
  function isBetweenOrSame(date, start, end, {
    type = 'day'
  } = {}) {
    date = (0, _moment.default)(date);
    return date.isSameOrAfter(start, type) && date.isSameOrBefore(end, type);
  }

  /**
   * @param  {moment-like} start
   * @param  {moment-like} end
   * @param  {object} options
   * @param  {string} options.type="day"
   * @param  {number} options.step=1
   * @return {MomentObject[]}
   */
  function range(start, end, {
    type = 'day',
    step = 1
  } = {}) {
    const range = [];
    const date = (0, _moment.default)(start);
    while (date.isSameOrBefore(end)) {
      range.push((0, _moment.default)(date));
      date.add(step, type);
    }
    return range;
  }

  /**
   * @param  {moment-like} date
   * @return {Object} weekData
   */
  function dateToWeekData(date) {
    // Thursday determs, the iso week (ISO-8601)
    // See: https://en.wikipedia.org/wiki/Week#Week_numbering
    const weekDay = (0, _moment.default)(date).isoWeekday(4);
    return {
      year: weekDay.year(),
      week: weekDay.isoWeek()
    };
  }

  /**
   * @param  {Object} options
   * @param  {Number} options.year
   * @param  {Number} options.week
   * @return {MomentObject}
   */
  function weekDataToDate({
    year,
    week
  }) {
    return (0, _moment.default)(`${year}-${week}-1`, 'YYYY-W-E');
  }

  /**
   * @param  {moment-like} date
   * @return {String}
   */
  function weekKey(date) {
    const {
      year,
      week
    } = dateToWeekData(date);
    return `${year}-${week}`;
  }

  /**
   * @param  {String} key
   * @return {String}
   */
  function weekKeyToDate(key) {
    const [, year, week] = key.match(/^(\d+)-(\d+)$/);
    return weekDataToDate({
      year,
      week
    });
  }

  /**
   * @param  {moment-like} date
   * @return {MomentObject}
   */
  function weekStart(date) {
    return weekDataToDate(dateToWeekData(date));
  }
});