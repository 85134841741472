define("ln-ember-data-models/adapters/vdc/quality-audit-position", ["exports", "ln-ember-data-models/adapters/application", "@ember/object"], function (_exports, _application, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * @class Vdc/StoreAdapter
   * @extends ApplicationAdapter
   */
  var _default = _exports.default = _application.default.extend({
    buildURL(modelName, id, snapshot, requestType, query) {
      let path, qaAuditId;
      if (snapshot && snapshot.record) {
        qaAuditId = (0, _object.get)(snapshot.record, 'quality_audit_id');
      } else if (query.quality_audit_id) {
        qaAuditId = query.quality_audit_id;
        delete query.quality_audit_id;
      }
      let url = [this.baseUrlFor(modelName)];
      if (modelName) {
        path = `quality_audits/${qaAuditId}/quality_audit_positions`;
        url.push(path);
      }
      if (id) {
        url.push(encodeURIComponent(id));
      }
      url = url.join('/');
      return url;
    }
  });
});