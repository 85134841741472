define("ln-ember-data-models/adapters/abstract/myliga/sub-resource", ["exports", "ember-data", "@ember/debug", "@ember/service", "ln-ember-data-models/adapters/application", "ember-inflector"], function (_exports, _emberData, _debug, _service, _application, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // TODO add tests
  /**
   * @class abstract/SubResourceAdapter
   * @extends ApplicationAdapter
   */
  var _default = _exports.default = _application.default.extend({
    resourcePath: null,
    parentResource: null,
    api: (0, _service.inject)(),
    buildURL(modelName, id, snapshot, requestType, query, pathOnly = false) {
      const resource = this.get('resourcePath') || this.pathForType(modelName);
      const parentResource = this.get('parentResource');
      const parentIdProp = `${parentResource}_id`;
      (false && !(parentResource) && (0, _debug.assert)('[abstract/SubResourceAdapter] parentResource needs to be set in subclass.', parentResource));
      const url = [];
      const prefix = this.urlPrefix();
      if (prefix) {
        url.push(prefix);
      }
      if (snapshot) {
        url.push((0, _emberInflector.pluralize)(parentResource));
        url.push(snapshot.attr(parentIdProp));
      }
      url.push((0, _emberInflector.pluralize)(resource));
      if (id) {
        url.push(encodeURIComponent(id));
      }
      const urlString = url.join('/').replace(/^\/*/, '/');
      if (pathOnly) {
        return urlString;
      }
      return `${this.baseUrlFor(modelName)}${urlString}`;
    }
  });
});