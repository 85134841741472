define("ln-ember-form-elements/components/form-elements/percent", ["exports", "ln-ember-form-elements/templates/components/form-elements/percent", "@ember/component", "@ember/object"], function (_exports, _percent, _component, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class; // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const INPUT_SCALE_DIFFERENCE = 2;
  const ROUNDING_PRECISION = 2;
  let FormElementsPercent = _exports.default = (_dec = (0, _object.computed)('scale'), _dec2 = (0, _object.computed)('_inputScale'), _dec3 = (0, _object.computed)('unit'), _dec4 = (0, _object.computed)('unitSeparator'), _class = class FormElementsPercent extends _component.default.extend({
    tagName: ''
  }) {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "layout", _percent.default);
      _defineProperty(this, "scale", void 0);
      _defineProperty(this, "unit", void 0);
      _defineProperty(this, "unitSeparator", void 0);
    }
    get _inputScale() {
      return this.scale ?? 2;
    }
    get _scale() {
      return this._inputScale + INPUT_SCALE_DIFFERENCE;
    }
    get _unit() {
      return this.unit ?? '%';
    }
    get _unitSeparator() {
      return this.unitSeparator ?? '';
    }
    _deserialize(value) {
      return Math.round(value * 10 ** this._inputScale) / 10 ** this._scale;
    }
    _serialize(value) {
      if (value === null || typeof value === 'undefined') {
        return '';
      }
      const strVal = String(value).trim();
      const parsed = parseFloat(strVal);
      if (Number.isNaN(parsed)) {
        return strVal;
      }
      return Math.round(parsed * 10 ** (this._scale + ROUNDING_PRECISION)) / 10 ** (this._inputScale + ROUNDING_PRECISION);
    }
  }, _applyDecoratedDescriptor(_class.prototype, "_inputScale", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "_inputScale"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "_scale", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "_scale"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "_unit", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "_unit"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "_unitSeparator", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "_unitSeparator"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "_deserialize", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "_deserialize"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "_serialize", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "_serialize"), _class.prototype), _class);
});