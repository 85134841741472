define("ln-ember-data-models/models/vdc/message", ["exports", "ember-data", "ln-ember-data-models/utils/enum-option"], function (_exports, _emberData, _enumOption) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.priorityOptions = _exports.default = void 0;
  const {
    attr,
    belongsTo,
    hasMany,
    Model
  } = _emberData.default;

  /**
   * @name Vdc/MessageModel/priorityOptions
   * @type {EnumOption[]}
   */
  const priorityOptions = _exports.priorityOptions = [_enumOption.default.create({
    id: 'Normal'
  }), _enumOption.default.create({
    id: 'Urgent'
  })];

  /**
   * @class Vdc/MessageModel
   * @extends DS.Model
   */
  const MessageModel = Model.extend({
    /**
     * @memberof Vdc/MessageModel
     * @instance
     *
     * @type {Number}
     */
    version: attr('number'),
    /**
     * @memberof Vdc/MessageModel
     * @instance
     *
     * @type {String}
     */
    subject: attr('nullable-string'),
    /**
     * @memberof Vdc/MessageModel
     * @instance
     *
     * @type {String}
     */
    message: attr('nullable-string'),
    /**
     * @memberof Vdc/MessageModel
     * @instance
     *
     * @type {Number}
     */
    ancestor_id: attr('id'),
    /**
     * @memberof Vdc/MessageModel
     * @instance
     *
     * @type {String}
     */
    date_created: attr('date-time-string'),
    /**
     * @memberof Vdc/MessageModel
     * @instance
     *
     * @type {Number}
     */
    context_id: attr('id'),
    /**
     * @memberof Vdc/MessageModel
     * @instance
     *
     * @type {String}
     */
    context_type: attr('nullable-string'),
    /**
     * @memberof Vdc/MessageModel
     * @instance
     *
     * @type {String}
     */
    context_name: attr('nullable-string'),
    /**
     * @memberof Vdc/MessageModel
     * @instance
     *
     * @type {Boolean}
     */
    read_state: attr('boolean', {
      defaultValue: () => false
    }),
    /**
     * @memberof Vdc/MessageModel
     * @instance
     *
     * @type {Boolean}
     */
    draft: attr('boolean', {
      defaultValue: () => false
    }),
    /**
     * @memberof Vdc/MessageModel
     * @instance
     *
     * @type {Number}
     */
    sender_id: attr('id'),
    /**
     * @memberof Vdc/MessageModel
     * @instance
     *
     * @type {Number[]}
     */
    recipient_ids: attr('number-array', {
      defaultValue: () => []
    }),
    /**
     * @memberof Vdc/MessageModel
     * @instance
     *
     * @type {Array}
     */
    role_ids: attr('number-array', {
      defaultValue: () => []
    }),
    /**
     * @memberof Vdc/MessageModel
     * @instance
     *
     * @type {String}
     */
    priority: attr('nullable-string', {
      options: priorityOptions,
      defaultValue: 'Normal'
    }),
    /**
     * @memberof Vdc/MessageModel
     * @instance
     *
     * @type {Object}
     */
    sender: belongsTo('vdc/user'),
    /**
     * @memberof Vdc/MessageModel
     * @instance
     *
     * @type {Vdc/Message[]}
     */
    children: hasMany('vdc/message')
  });
  MessageModel.reopenClass({
    /**
     * @memberof Vdc/MessageModel
     * @static
     *
     * @type {EnumOption[]}
     */
    priorityOptions
  });
  var _default = _exports.default = MessageModel;
});