define("ln-ember-toolkit/services/configuration", ["exports", "@ember/application", "@ember/object", "@ember/service", "@ember/utils"], function (_exports, _application, _object, _service, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * @deprecated
   * @class Configuration
   * @extends Ember.Service
   */
  var _default = _exports.default = _service.default.extend({
    /**
     * @memberof Configuration
     * @instance
     *
     * @private
     * @property {Object}
     */
    data: (0, _object.computed)(function () {
      const configMain = (0, _application.getOwner)(this).resolveRegistration('config:main');
      if (configMain) {
        return configMain;
      }
      const {
        APP: {
          config
        }
      } = (0, _application.getOwner)(this).resolveRegistration('config:environment');
      return _object.default.create(config);
    }).readOnly(),
    /**
     * @memberof Configuration
     * @instance
     *
     * @param  {String} key
     * @param  {*} defaultValue
     * @return {*}
     */
    getConfig(key, defaultValue = null) {
      const value = this.get(`data.${key}`);
      if (!(0, _utils.isNone)(value)) {
        return value;
      }
      return defaultValue;
    }
  });
});