define("ln-ember-form-elements/components/form-elements/-html-element", ["exports", "@ember/component", "ln-ember-form-elements/templates/components/form-elements/-html-element"], function (_exports, _component, _htmlElement) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); } // @ts-ignore: Ignore import of compiled template
  class FormElementsHtmlElement extends _component.default.extend({
    // anything which *must* be merged to prototype here
  }) {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "attributeBindings", ['for', 'title']);
      _defineProperty(this, "layout", _htmlElement.default);
    }
  }
  _exports.default = FormElementsHtmlElement;
});