define("ln-ember-form-elements/templates/components/form-elements/styled-checkbox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "ZA+B0Gwo",
    "block": "[[[1,[28,[35,0],null,[[\"type\",\"checked\",\"id\",\"disabled\"],[\"checkbox\",[30,0,[\"inputValue\"]],[30,0,[\"checkboxId\"]],[30,0,[\"readonlyAttribute\"]]]]]],[1,\"\\n\"],[10,\"label\"],[15,\"for\",[30,0,[\"checkboxId\"]]],[12],[1,[30,0,[\"label\"]]],[13],[1,\"\\n\"]],[],false,[\"input\"]]",
    "moduleName": "ln-ember-form-elements/templates/components/form-elements/styled-checkbox.hbs",
    "isStrictMode": false
  });
});