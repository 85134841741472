define("ln-ember-contact-menu/templates/components/contact-menu-handle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "71O2fiJN",
    "block": "[[[41,[30,0,[\"isEnabled\"]],[[[1,\"  \"],[11,0],[17,1],[16,1,[30,0,[\"_elementId\"]]],[16,0,[29,[[30,2],\" ContactMenuHandle\"]]],[16,\"data-open\",[30,0,[\"isOpen\"]]],[24,\"tabindex\",\"0\"],[16,\"onclick\",[28,[37,1],[[30,0,[\"openContactMenu\"]],[50,\"contact-menu\",0,null,[[\"userId\"],[[30,3]]]]],null]],[12],[1,\"\\n    \"],[18,4,null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],[[[1,\"  \"],[11,0],[17,1],[16,0,[29,[[30,2],\" ContactMenuHandle\"]]],[12],[1,\"\\n    \"],[18,4,null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]],[\"&attrs\",\"@class\",\"@userId\",\"&default\"],false,[\"if\",\"fn\",\"component\",\"yield\"]]",
    "moduleName": "ln-ember-contact-menu/templates/components/contact-menu-handle.hbs",
    "isStrictMode": false
  });
});