define("ln-ember-form-elements/components/form-elements/styled-checkbox", ["exports", "@ember/component", "@ember/object", "@ember/application/deprecations", "@ember/object/internals", "@ember/service", "@ember/utils", "ln-ember-form-elements/templates/components/form-elements/styled-checkbox"], function (_exports, _component, _object, _deprecations, _internals, _service, _utils, _styledCheckbox) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   *
   * Renders an styled checkbox that behaves like a regular checkbox with a label.<br>
   *
   * ### Examples:
   *
   * ```Handlebars
   * {{form-elements/styled-checkbox label="I am checked!" value=true}}
   * ```
   *
   * @class formElements/StyledCheckboxComponent
   * @extends @ember/component
   */
  var _default = _exports.default = _component.default.extend({
    formElements: (0, _service.inject)(),
    layout: _styledCheckbox.default,
    /**
     * Label of the checkbox
     *
     * @memberof formElements/StyledCheckboxComponent
     * @instance
     *
     * @type string
     * @default null
     */
    label: null,
    /**
     * State of the checkbox
     *
     * @memberof formElements/StyledCheckboxComponent
     * @instance
     *
     * @deprecated
     *
     * @type boolean
     * @default false
     */
    checked: null,
    /**
     * @memberof formElements/StyledCheckboxComponent
     * @instance
     *
     * @type String
     * @default 'light'
     */
    theme: null,
    /**
     * Change action handler.
     *
     * ### Example:**
     *
     * ```JavaScript
     * Controller.extend({
     *   actions: {
     *     change(value) {
     *       console.log('Checkbox is checked:', value);
     *     }
     *   }
     * })
     * ```
     *
     * ```Handlebars
     * {{form-elements/styled-checkbox onChange=(action "change"}}
     * ```
     *
     *
     * @memberof formElements/StyledCheckboxComponent
     * @instance
     *
     * @type {function}
     */
    onChange: null,
    /**
     * If set to true the input field is readonly
     *
     * @memberof StyledInputComponent
     * @instance
     *
     * @type Boolean
     * @default false
     */
    readonly: false,
    classNames: [
    // Deprecated: { id: ln-ember-form-elements-class-names, until: 3.0.0 }
    'styled-checkbox-component', 'form-elements--styled-checkbox-component'],
    classNameBindings: ['themeClass', 'inputValue:checked:unchecked', 'readonlyAttribute:read-only'],
    /**
     * State of the checkbox
     *
     * @memberof formElements/StyledCheckboxComponent
     * @instance
     *
     * @type boolean
     * @default false
     */
    _value: (0, _object.computed)('checked', 'value', {
      get() {
        return this.get('value') ?? this.get('checked');
      }
    }),
    // Can't be false... See: https://github.com/emberjs/ember.js/issues/11828
    readonlyAttribute: (0, _object.computed)('readonly', 'disabled', function () {
      return Boolean(this.get('readonly') || this.get('disabled')) || undefined;
    }),
    inputValue: (0, _object.computed)('_value', {
      get() {
        return this.get('_value');
      },
      set(prop, value) {
        (false && !(Boolean(this.get('onChange'))) && (0, _deprecations.deprecate)('[StyledCheckboxComponent] Data binding is deprecated. onChange action should be handled.', Boolean(this.get('onChange')), {
          for: 'ln-ember-form-elements',
          id: 'styled-checkbox-component-data-binding',
          since: '3.0.0-beta.115',
          until: '3.0.0'
        }));
        if (this.get('onChange')) {
          this.get('onChange')(value);
        } else {
          this.set('checked', value);
        }
        return value;
      }
    }),
    checkboxId: (0, _object.computed)(function () {
      return `checkbox-${(0, _internals.guidFor)(this)}`;
    }),
    themeClass: (0, _object.computed)('theme', function () {
      return `theme-${this.get('theme') || 'light'}`;
    }),
    init() {
      this._super(...arguments);
      if (!this.get('theme')) {
        this.set('theme', this.get('formElements.defaults.theme'));
      }
    },
    didReceiveAttrs() {
      this._super(...arguments);
      (false && !((0, _utils.isNone)(this.get('checked'))) && (0, _deprecations.deprecate)('[StyledCheckboxComponent] The attribute `checked` is deprecated use `value` instead.', (0, _utils.isNone)(this.get('checked')), {
        for: 'ln-ember-form-elements',
        id: 'styled-checkbox-component-checked-attribute',
        since: '3.0.0-beta.115',
        until: '3.0.0'
      }));
    }
  });
});