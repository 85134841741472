define("ln-ember-form-elements/components/form-elements/calendar/month", ["exports", "moment", "@ember/component", "@ember/object", "ln-ember-form-elements/utils/moment-helper", "ln-ember-form-elements/templates/components/form-elements/calendar/month", "ln-ember-form-elements/utils/computed-properties"], function (_exports, _moment, _component, _object, _momentHelper, _month, _computedProperties) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    date: null,
    theme: null,
    range: null,
    selectedWeek: null,
    showCalendarWeek: false,
    showWeekHighlight: false,
    weekStatusComponent: null,
    layout: _month.default,
    classNames: ['form-elements--calendar--month-component'],
    classNameBindings: ['showCalendarWeek', 'weekStatusComponent:has-status', 'disabled:is-disabled', 'themeClass', 'showWeekHighlight'],
    themeClass: (0, _object.computed)('theme', function () {
      return `theme-${this.get('theme') || 'light'}`;
    }),
    weekdays: (0, _object.computed)('visibleDate', function () {
      const date = (0, _moment.default)(this.get('visibleDate') || new Date()).startOf('isoWeek');
      return [...Array(7)].map((x, i) => (0, _moment.default)(date).add(i, 'days').format('dd'));
    }),
    onSelect: (0, _computedProperties.defaultAction)(function (value) {
      this.set('date', value);
    }),
    visibleDate: new Date(),
    weeks: (0, _object.computed)('visibleDate', 'date', 'range.{start,startIsHalfDay,end,endIsHalfDay}', {
      get() {
        const visibleDate = this.get('visibleDate');
        const current = (0, _moment.default)(visibleDate).startOf('month').startOf('isoWeek');
        const last = (0, _moment.default)(visibleDate).endOf('month').endOf('isoWeek');
        const days = [];
        while (current.isBefore(last)) {
          days.push((0, _moment.default)(current));
          current.add(1, 'day');
        }
        const weeks = days.reduce((weeks, day) => {
          const weekIdx = (0, _momentHelper.weekKey)(day);
          const dayIdx = (day.isoWeekday() || 7) - 1;
          if (!weeks[weekIdx]) {
            weeks[weekIdx] = new Array(7);
          }
          weeks[weekIdx][dayIdx] = {
            day,
            date: day.date(),
            className: this.buildClassNames(day).join(' ')
          };
          return weeks;
        }, {});
        return Object.keys(weeks).map(key => ({
          start: (0, _momentHelper.weekKeyToDate)(key).toDate(),
          cw: (0, _momentHelper.weekKeyToDate)(key).isoWeek(),
          selected: this.get('selectedWeek') ? this.get('selectedWeek').isoWeek() === (0, _momentHelper.weekKeyToDate)(key).isoWeek() && this.get('selectedWeek').year() === (0, _momentHelper.weekKeyToDate)(key).year() : false,
          days: weeks[key]
        }));
      }
    }).readOnly(),
    buildClassNames(day) {
      let {
        start,
        end
      } = this.get('range') || {};
      start = start && (0, _moment.default)(start).startOf('day');
      end = end && (0, _moment.default)(end).startOf('day');
      if (start && end && start.isAfter(end)) {
        [start, end] = [end, start];
      }
      if (!start && end || start && !end) {
        end = start;
      }
      const date = this.get('date') && (0, _moment.default)(this.get('date')).startOf('day');
      const isStart = start && day.isSame(start, 'day');
      const isEnd = end && day.isSame(end, 'day');
      const classNames = [];

      // Visible, but not in the visible month
      if (!day.isSame(this.get('visibleDate'), 'month')) {
        classNames.push('is-not-in-month');
      }

      // Today
      if (day.isSame(new Date(), 'day')) {
        classNames.push('is-today');
      }

      // Selection highlights

      // Selected date or one day range
      if (date && day.isSame(date, 'day') || isStart && isEnd) {
        classNames.push('is-selected');
        if (this.get('range.startIsHalfDay')) {
          classNames.push('is-half');
        }

        // Start date of multi date range
      } else if (isStart) {
        classNames.push('is-range-start');
        if (this.get('range.startIsHalfDay')) {
          classNames.push('is-half');
        }

        // End date of multi date range
      } else if (isEnd) {
        classNames.push('is-range-end');
        if (this.get('range.endIsHalfDay')) {
          classNames.push('is-half');
        }

        // Dates in between of multi date range
      } else if (start && end && day.isBetween(start, end)) {
        classNames.push('is-range');
      }
      return classNames.sort();
    },
    actions: {
      onDayClick({
        day
      }) {
        if (!this.get('disabled')) {
          this.get('onSelect')(day.toDate());
        }
      }
    }
  });
});