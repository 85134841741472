define("ln-ember-data-models/models/myliga/comment", ["exports", "ember-data", "@ember/object", "@ember/object/evented", "@ember/service", "ember-data-model-fragments/attributes"], function (_exports, _emberData, _object, _evented, _service, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr
  } = _emberData.default;

  /**
   * @class Myliga/CommentModel
   * @extends DS.Model
   */
  var _default = _exports.default = Model.extend({
    /**
     * @memberof Myliga/CommentModel
     * @instance
     *
     * @type {Number}
     */
    version: attr('number'),
    /**
     * @memberof Myliga/CommentModel
     * @instance
     *
     * @type {String}
     */
    message: attr('nullable-string'),
    /**
     * @memberof Myliga/CommentModel
     * @instance
     *
     * @type {Number}
     */
    context_id: attr('id'),
    /**
     * @memberof Myliga/CommentModel
     * @instance
     *
     * @type {String}
     */
    context_type: attr('nullable-string'),
    /**
     * @memberof Myliga/CommentModel
     * @instance
     *
     * @type {String}
     */
    date_created: attr('date-string'),
    /**
     * @memberof Myliga/CommentModel
     * @instance
     *
     * @type {String}
     */
    date_changed: attr('date-string'),
    /**
     * @memberof Myliga/CommentModel
     * @instance
     *
     * @type {Number}
     */
    author_id: attr('id'),
    /**
     * @memberof Myliga/CommentModel
     * @instance
     *
     * @type {String}
     */
    author_name: attr('nullable-string'),
    /**
     * @memberof Myliga/CommentModel
     * @instance
     *
     * @type {MyLiga/fragments/PictureMeta}
     */
    author_picture: (0, _attributes.fragment)('myliga/fragments/picture'),
    /**
     * @memberof Myliga/CommentModel
     * @instance
     *
     * @type {Boolean}
     */
    deletable: (0, _object.computed)('author_id', '_hasDeleteRights', function () {
      const authorId = this.get('author_id');
      const currentUserId = this.get('apiEvents.session.user_id');
      if (authorId !== currentUserId) {
        return this.get('_hasDeleteRights');
      }
      return authorId === currentUserId;
    }),
    /**
     * @memberof Myliga/CommentModel
     * @instance
     *
     * @method
     */
    getDeleteRight: (0, _evented.on)('init', function () {
      this.get('rights').can('OTHER', 'pin.comment.delete').then(deletable => {
        this.set('_hasDeleteRights', deletable);
      });
    }),
    rights: (0, _service.inject)(),
    apiEvents: (0, _service.inject)(),
    _hasDeleteRights: false
  });
});