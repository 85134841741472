define("ln-ember-form-elements/components/form-elements/date-time-range-input", ["exports", "moment", "@ember/object", "@ember/utils", "ln-ember-form-elements/components/form-elements/date-range-input-base", "ln-ember-form-elements/templates/components/form-elements/date-time-range-input", "ln-ember-form-elements/utils/date-helper"], function (_exports, _moment, _object, _utils, _dateRangeInputBase, _dateTimeRangeInput, _dateHelper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   *
   * ### Value
   *
   * The value is a object holding `start` and `end` in the iso format or `null`.
   *
   * ### Examples
   *
   * #### Basic usage:
   *
   * For the basic usage the only required attributes are the current `value` and
   * handler for `onChange` events.
   *
   * ```Handlebars
   * {{form-elements/date-time-range-input
   *   value=dateValue
   *   onChange=(action "onDateChange")
   * }}
   * ```
   *
   * ```JavaScript
   * Component.extend({
   *   actions: {
   *     onDateChange(value) {
   *       this.set('dateValue', value)
   *     }
   *   }
   * })
   * ```
   *
   * ### Demo
   *
   * <iframe src="showcase/index.html#/demo/date-time-range-input" style="width: 360px;border: 1px solid black;height:450px;padding: 20px;"></iframe>
   *
   * @class formElements/DateTimeRangeInputComponent
   * @extends formElements/DateInputRangeBaseComponent */
  var _default = _exports.default = _dateRangeInputBase.default.extend({
    /**
     * @memberof formElements/DateInputRangeBaseComponent
     * @instance
     *
     * @type {object}
     * @property {string} start -  ISO date format
     * @property {string} end -  ISO date format
     * @default null
     */
    value: null,
    classNames: ['form-elements--date-time-range-input-component'],
    layout: _dateTimeRangeInput.default,
    timeValue: (0, _object.computed)('value.{start,end}', {
      get() {
        const {
          start,
          end
        } = this.get('value') || {};
        return {
          start: (0, _dateHelper.formatDate)(start, 'HH:mm'),
          end: (0, _dateHelper.formatDate)(end, 'HH:mm')
        };
      },
      set(key, value) {
        return value;
      }
    }),
    actions: {
      onCalendarChange(value) {
        let {
          start,
          end
        } = this.get('value') || {};
        const startTime = start ? (0, _moment.default)(start).format('HH:mm') : '00:00';
        let endTime = end ? (0, _moment.default)(end).format('HH:mm') : '00:00';
        if (start && end && start !== end || !start) {
          start = value;
          end = value;
          endTime = startTime;
        } else {
          end = value;
        }
        start = this.applyTime(start, startTime);
        end = this.applyTime(end, endTime);
        [start, end] = [start, end].sort((a, b) => (0, _moment.default)(a).isAfter(b) ? 1 : -1);
        this.triggerChange({
          start,
          end
        });
      },
      onInputChange(type, value) {
        if (!value) {
          return this.triggerChange(null);
        }
        const dateFormat = this.get('formElements.defaults.formatDateTime');
        const date = (0, _moment.default)(`${value} +0200`, `${dateFormat} ZZ`, true);
        if (!date.isValid()) {
          return;
        }
        const range = Object.assign({}, this.get('range'), {
          [type]: date.toISOString()
        });
        if (this.isValid(range)) {
          this.triggerChange(range);
        }
      },
      onTimeInputChange(type, time) {
        if (!/^\d\d:\d\d$/.test(time)) {
          return;
        }
        const range = Object.assign({}, this.get('range'), {
          [type]: this.applyTime(this.get(`range.${type}`), time)
        });
        if (this.isValid(range)) {
          this.triggerChange(range);
        }
      }
    },
    formatDate(date) {
      return (0, _dateHelper.formatDate)(date, this.get('formElements.defaults.formatDateTime'));
    },
    getVisibleDate() {
      return (0, _moment.default)(this.get('value.start') || undefined, _moment.default.ISO_8601).format('YYYY-MM-DD');
    },
    isValidInput(value) {
      const {
        start,
        end
      } = value || {};
      return (0, _moment.default)(start, _moment.default.ISO_8601, true).isValid() && ((0, _utils.isNone)(end) || (0, _moment.default)(end, _moment.default.ISO_8601, true).isValid());
    },
    isValid(value) {
      const {
        start,
        end
      } = value || {};
      return (0, _moment.default)(start, _moment.default.ISO_8601, true).isValid() && (0, _moment.default)(end, _moment.default.ISO_8601, true).isValid();
    },
    triggerChange(range) {
      const {
        start,
        end
      } = range || {};
      this.get('onChange')(range && {
        start: start && (0, _moment.default)(start).toISOString(),
        end: end && (0, _moment.default)(end).toISOString()
      });
    }
  });
});