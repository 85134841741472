define("ln-ember-toolkit/helpers/inline-css-props", ["exports", "@ember/component/helper", "@ember/string"], function (_exports, _helper, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.inlineCssProps = inlineCssProps;
  function inlineCssProps(params) {
    const [scope, props] = params;
    if (typeof scope !== 'string') {
      throw new Error('you must provide non-empty "scope"');
    }
    const list = Object.keys(props).reduce((accum, propName) => {
      accum.push(`--${scope}-${propName}: ${props[propName]}`);
      return accum;
    }, []);
    return (0, _string.htmlSafe)(list.join('; '));
  }
  var _default = _exports.default = (0, _helper.helper)(inlineCssProps);
});