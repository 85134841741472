define("ln-ember-data-models/models/vdc/fragments/article-review-marketing-object-priority", ["exports", "ember-data", "ember-data-model-fragments/fragment"], function (_exports, _emberData, _fragment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    attr
  } = _emberData.default;

  /**
   * @class VdcArticleReviewMarketingObjectPriorityFragment
   * @extends Fragment
   */
  const VdcArticleReviewMarketingObjectPriorityFragment = _fragment.default.extend({
    /**
     * @memberof VdcArticleReviewMarketingObjectPriorityFragment
     * @instance
     *
     * @type {number}
     */
    marketing_category_id: attr('number'),
    /**
     * @memberof VdcArticleReviewMarketingObjectPriorityFragment
     * @instance
     *
     * @type {string}
     */
    marketing_category_name: attr('string'),
    /**
     * @memberof VdcArticleReviewMarketingObjectPriorityFragment
     * @instance
     *
     * @type {number}
     */
    marketing_object_id: attr('number'),
    /**
     * @memberof VdcArticleReviewMarketingObjectPriorityFragment
     * @instance
     *
     * @type {string}
     */
    marketing_object_name: attr('string'),
    /**
     * @memberof VdcArticleReviewMarketingObjectPriorityFragment
     * @instance
     *
     * @type {number}
     */
    priority: attr('number')
  });
  var _default = _exports.default = VdcArticleReviewMarketingObjectPriorityFragment;
});