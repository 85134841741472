define("ln-ember-data-models/models/myliga/talk-module", ["exports", "ember-data", "ln-ember-data-models/utils/enum-option"], function (_exports, _emberData, _enumOption) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.typeOptions = _exports.default = _exports.componentTypeOptions = void 0;
  const {
    attr,
    belongsTo,
    hasMany,
    Model
  } = _emberData.default;

  /**
   * @name Myliga/TalkModuleModel/typeOptions
   * @type {EnumOption[]}
   */
  const typeOptions = _exports.typeOptions = [_enumOption.default.create({
    id: 'Expectation'
  }), _enumOption.default.create({
    id: 'Review'
  }), _enumOption.default.create({
    id: 'GlobalFeedback'
  }), _enumOption.default.create({
    id: 'Performance'
  }), _enumOption.default.create({
    id: 'Competence'
  }), _enumOption.default.create({
    id: 'Experience'
  }), _enumOption.default.create({
    id: 'Motivation'
  }), _enumOption.default.create({
    id: 'Teamwork'
  }), _enumOption.default.create({
    id: 'Potential'
  }), _enumOption.default.create({
    id: 'LiganovaFIT'
  }), _enumOption.default.create({
    id: 'PersonalSuccessFeedback'
  }), _enumOption.default.create({
    id: 'YourProfile'
  }), _enumOption.default.create({
    id: 'AdditionalNotes'
  })];

  /**
   * @name Myliga/TalkModuleModel/componentTypeOptions
   * @type {EnumOption[]}
   */
  const componentTypeOptions = _exports.componentTypeOptions = [_enumOption.default.create({
    id: 'TextModule'
  }), _enumOption.default.create({
    id: 'Review'
  }), _enumOption.default.create({
    id: 'GlobalFeedbackModule'
  }), _enumOption.default.create({
    id: 'SliderTalkModule'
  }), _enumOption.default.create({
    id: 'FeedbackSlider'
  }), _enumOption.default.create({
    id: 'AdditionalNotes'
  }), _enumOption.default.create({
    id: 'GeneralFeedback'
  }), _enumOption.default.create({
    id: 'TeamFeedback'
  }), _enumOption.default.create({
    id: 'LeadershipFeedback'
  }), _enumOption.default.create({
    id: 'ParentModule'
  }), _enumOption.default.create({
    id: 'QuantitativeGoals'
  }), _enumOption.default.create({
    id: 'QualitativeGoals'
  })];

  /**
   * @class Myliga/TalkModuleModel
   * @extends DS.Model
   */
  const TalkModuleModel = Model.extend({
    /**
     * @memberof Myliga/TalkModuleModel
     * @instance
     *
     * @type {Number}
     */
    version: attr('number'),
    /**
     * @memberof Myliga/TalkModuleModel
     * @instance
     *
     * @type {String}
     */
    name: attr('nullable-string'),
    /**
     * @memberof Myliga/TalkModuleModel
     * @instance
     *
     * @type {{ subordinate: Object[], superior: Object[] }}
     */
    feedback: attr({
      defaultValue: () => ({
        subordinate: [],
        superior: []
      })
    }),
    /**
     * @memberof Myliga/TalkModuleModel
     * @instance
     *
     * @type {String}
     */
    type: attr('enum-string', {
      options: typeOptions
    }),
    /**
     * @memberof Myliga/TalkModuleModel
     * @instance
     *
     * @type {String}
     */
    component_type: attr('enum-string', {
      options: componentTypeOptions
    }),
    /**
     * @memberof Myliga/TalkModuleModel
     * @instance
     *
     * @type {Myliga/TalkModuleModel}
     */
    parent: belongsTo('myliga/talk-module', {
      inverse: 'submodules'
    }),
    /**
     * @memberof Myliga/TalkModuleModel
     * @instance
     *
     * @type {Myliga/TalkModuleModel[]}
     */
    submodules: hasMany('myliga/talk-module', {
      inverse: 'parent'
    })
  });
  TalkModuleModel.reopenClass({
    /**
     * @memberof Myliga/TalkModuleModel
     * @static
     *
     * @type {EnumOption[]}
     */
    typeOptions
  });
  var _default = _exports.default = TalkModuleModel;
});