define("ln-ember-data-models/models/vdc/article-attachment", ["exports", "ember-data", "ln-ember-data-models/utils/enum-option"], function (_exports, _emberData, _enumOption) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.typeOptions = _exports.default = void 0;
  const {
    attr,
    belongsTo,
    Model
  } = _emberData.default;

  /**
   * @name Vdc/ArticleAttachmentModel/typeOptions
   * @type {EnumOption[]}
   */
  const typeOptions = _exports.typeOptions = [_enumOption.default.create({
    id: 'FullFile'
  }), _enumOption.default.create({
    id: 'PreviewFile'
  })];

  /**
   * @class Vdc/ArticleAttachmentModel
   * @extends DS.Model
   */
  const ArticleAttachmentModel = Model.extend({
    /**
     * @memberof Vdc/ArticleAttachmentModel
     * @instance
     *
     * @type {Number}
     */
    version: attr('number'),
    /**
     * @memberof Vdc/ArticleAttachmentModel
     * @instance
     *
     * @type {Number}
     */
    article_id: attr('id'),
    /**
     * @memberof Vdc/ArticleAttachmentModel
     * @instance
     *
     * @type {String}
     */
    type: attr('enum-string', {
      options: typeOptions
    }),
    /**
     * @memberof Vdc/ArticleAttachmentModel
     * @instance
     *
     * @type {Number}
     */
    resource_id: attr('id'),
    /**
     * @memberof Vdc/ArticleAttachmentModel
     * @instance
     *
     * @type {Vdc/Article}
     */
    article: belongsTo('vdc/country', {
      readOnly: true,
      async: false
    }),
    /**
     * @memberof Vdc/ArticleAttachmentModel
     * @instance
     *
     * @type {Vdc/Article}
     */
    resource: belongsTo('vdc/revision', {
      readOnly: true,
      async: false
    })
  });
  ArticleAttachmentModel.reopenClass({
    /**
     * @memberof Vdc/ArticleAttachmentModel
     * @static
     *
     * @type {EnumOption[]}
     */
    typeOptions
  });
  var _default = _exports.default = ArticleAttachmentModel;
});