define("ln-ember-toolkit/services/media-matcher", ["exports", "@ember/object/computed", "@ember/object/evented", "@ember/service"], function (_exports, _computed, _evented, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const MOBILE_MEDIA_QUERY = 'only screen and (max-width: 736px)';
  var _default = _exports.default = _service.default.extend(_evented.default, {
    mediaVersion: null,
    init() {
      this._super(...arguments);
      this.set('matcher', matchMedia(MOBILE_MEDIA_QUERY));
      this.updateMediaVersion();
      this.matcher.addListener(() => {
        this.updateMediaVersion();
        this.trigger('mediaDidChange', this.mediaVersion);
      });
    },
    isMobile: (0, _computed.equal)('mediaVersion', 'Mobile'),
    isDesktop: (0, _computed.equal)('mediaVersion', 'Desktop'),
    updateMediaVersion() {
      this.set('mediaVersion', this.matcher.matches ? 'Mobile' : 'Desktop');
    }
  });
});