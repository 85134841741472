define("ln-ember-form-elements/templates/components/form-elements/styled-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "rSJU/EGg",
    "block": "[[[41,[30,0,[\"loading\"]],[[[1,\"  \"],[10,0],[14,0,\"loader\"],[12],[1,\"\\n    \"],[10,\"svg\"],[14,0,\"circular\"],[14,\"viewBox\",\"25 25 50 50\"],[12],[1,\"\\n      \"],[10,\"circle\"],[14,0,\"path\"],[14,\"cx\",\"50\"],[14,\"cy\",\"50\"],[14,\"r\",\"20\"],[14,\"fill\",\"none\"],[14,\"stroke-width\",\"4\"],[14,\"stroke-miterlimit\",\"10\"],[12],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[18,3,null],[1,\"\\n\\n\"],[41,[30,0,[\"dialogVisible\"]],[[[6,[39,2],null,[[\"confirm\",\"cancel\",\"place\"],[[28,[37,3],[[30,0],\"confirm\"],null],[28,[37,3],[[30,0],\"cancel\"],null],[30,1]]],[[\"default\"],[[[[1,\"    \"],[1,[30,2]],[1,\"\\n\"]],[]]]]]],[]],null]],[\"@confirmPlace\",\"@confirm\",\"&default\"],false,[\"if\",\"yield\",\"dialog-tooltip-on-parent\",\"action\"]]",
    "moduleName": "ln-ember-form-elements/templates/components/form-elements/styled-button.hbs",
    "isStrictMode": false
  });
});