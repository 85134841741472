define("ln-ember-form-elements/templates/components/form-elements/price", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "HdOf1XgJ",
    "block": "[[[44,[\"FormPrice\",[50,\"form-elements/number\",0,null,null]],[[[1,\"  \"],[8,[30,2],[[16,0,[29,[[30,1],\" \",[30,3]]]],[17,4]],[[\"@appendElement\",\"@autocomplete\",\"@autofocus\",\"@disabled\",\"@error\",\"@label\",\"@maxlength\",\"@name\",\"@placeholder\",\"@prependElement\",\"@readonly\",\"@value\",\"@unit\",\"@unitSeparator\",\"@placeUnitBefore\",\"@onAccept\",\"@onFocusIn\",\"@onFocusOut\",\"@onKeyDown\",\"@onKeyPress\",\"@onKeyUp\",\"@min\",\"@max\",\"@padFractionalZeros\",\"@normalizeZeros\",\"@scale\",\"@title\",\"@warning\"],[[30,5],[30,6],[30,7],[30,8],[30,9],[30,10],[30,11],[30,12],[30,13],[30,14],[30,15],[30,16],[30,0,[\"formatSettings\",\"currencySign\"]],\" \",[30,0,[\"formatSettings\",\"isSignBefore\"]],[30,17],[30,18],[30,19],[30,20],[30,21],[30,22],[30,23],[30,24],[30,0,[\"_padFractionalZeros\"]],false,[30,25],[30,26],[30,27]]],null],[1,\"\\n\"]],[1,2]]]],[\"scope\",\"NumberInput\",\"@class\",\"&attrs\",\"@appendElement\",\"@autocomplete\",\"@autoFocus\",\"@disabled\",\"@error\",\"@label\",\"@maxLength\",\"@name\",\"@placeholder\",\"@prependElement\",\"@readonly\",\"@value\",\"@onAccept\",\"@onFocusIn\",\"@onFocusOut\",\"@onKeyDown\",\"@onKeyPress\",\"@onKeyUp\",\"@min\",\"@max\",\"@scale\",\"@title\",\"@warning\"],false,[\"let\",\"component\"]]",
    "moduleName": "ln-ember-form-elements/templates/components/form-elements/price.hbs",
    "isStrictMode": false
  });
});