define("ln-ember-data-models/adapters/vdc/contract", ["exports", "ln-ember-data-models/adapters/application", "@ember/object"], function (_exports, _application, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * @class Vdc/StoreAdapter
   * @extends ApplicationAdapter
   */
  var _default = _exports.default = _application.default.extend({
    buildURL(modelName, id, snapshot) {
      const storeId = (0, _object.get)(snapshot.record, 'store_id');
      let url = [this.baseUrlFor(modelName)];
      let path;
      if (modelName) {
        path = `stores/${storeId}/contracts`;
        url.push(path);
      }
      if (id) {
        url.push(encodeURIComponent(id));
      }
      url = url.join('/');
      return url;
    }
  });
});