define("ln-ember-form-elements/templates/components/form-elements/expanding-textarea", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "B1rKmOAJ",
    "block": "[[[41,[30,1],[[[10,\"label\"],[15,\"for\",[29,[[30,0,[\"elementId\"]],\"-textarea\"]]],[12],[1,[30,1]],[13]],[]],null],[1,\"\\n\\n\"],[10,0],[14,0,\"textarea-wrapper\"],[12],[1,\"\\n  \"],[1,[28,[35,1],null,[[\"id\",\"placeholder\",\"readonly\",\"value\",\"maxlength\",\"focus-in\",\"focus-out\",\"key-up\"],[[28,[37,2],[[30,0,[\"elementId\"]],\"-textarea\"],null],[30,2],[30,3],[30,4],[30,5],[28,[37,3],[[30,0],\"focusIn\"],null],[28,[37,3],[[30,0],\"focusOut\"],null],[28,[37,3],[[30,0],\"keyUp\"],null]]]]],[1,\"\\n\\n\"],[41,[30,5],[[[1,\"    \"],[10,1],[15,0,[29,[\"characters-countdown \",[52,[30,0,[\"limitExceeded\"]],\"limit-exceeded\"]]]],[12],[1,[30,0,[\"remainingCharacters\"]]],[13],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\"]],[\"@label\",\"@placeholder\",\"@readonly\",\"@value\",\"@maxLength\"],false,[\"if\",\"textarea\",\"concat\",\"action\"]]",
    "moduleName": "ln-ember-form-elements/templates/components/form-elements/expanding-textarea.hbs",
    "isStrictMode": false
  });
});