define("ln-ember-form-elements/templates/components/form-elements/calendar/navigation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "2KHYsRQM",
    "block": "[[[10,3],[14,0,\"calendar-navigation__direction previous\"],[15,\"onclick\",[28,[37,0],[[30,0],\"onDirectionClick\",-1],null]],[14,\"role\",\"button\"],[12],[13],[1,\"\\n\"],[10,3],[14,0,\"calendar-navigation__label label\"],[15,\"onclick\",[28,[37,0],[[30,0],\"onClickMonth\"],null]],[14,\"role\",\"button\"],[12],[1,[30,0,[\"label\"]]],[13],[1,\"\\n\"],[10,3],[14,0,\"calendar-navigation__direction next\"],[15,\"onclick\",[28,[37,0],[[30,0],\"onDirectionClick\",1],null]],[14,\"role\",\"button\"],[12],[13],[1,\"\\n\"]],[],false,[\"action\"]]",
    "moduleName": "ln-ember-form-elements/templates/components/form-elements/calendar/navigation.hbs",
    "isStrictMode": false
  });
});