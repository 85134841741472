define("ln-ember-data-models/models/vdc/kpi-category", ["exports", "ember-data", "ln-ember-data-models/utils/enum-option"], function (_exports, _emberData, _enumOption) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.moduleOptions = _exports.default = _exports.colorOptions = void 0;
  const {
    attr,
    Model
  } = _emberData.default;

  /**
   * @name Vdc/KpiCategoryModel/moduleOptions
   * @type {EnumOption[]}
   */
  const moduleOptions = _exports.moduleOptions = [_enumOption.default.create({
    id: 'Project'
  }), _enumOption.default.create({
    id: 'POS'
  }), _enumOption.default.create({
    id: 'Trainings'
  }), _enumOption.default.create({
    id: 'Events'
  }), _enumOption.default.create({
    id: 'Users'
  }), _enumOption.default.create({
    id: 'UserDetails'
  }), _enumOption.default.create({
    id: 'ExpertDetails'
  })];

  /**
   * @name Vdc/KpiCategoryModel/colorOptions
   * @type {EnumOption[]}
   */
  const colorOptions = _exports.colorOptions = [_enumOption.default.create({
    id: 'Yellow'
  }), _enumOption.default.create({
    id: 'Blue'
  }), _enumOption.default.create({
    id: 'Red'
  }), _enumOption.default.create({
    id: 'DarkGreen'
  }), _enumOption.default.create({
    id: 'LightGreen'
  }), _enumOption.default.create({
    id: 'Pink'
  }), _enumOption.default.create({
    id: 'Black'
  })];

  /**
   * @class Vdc/KpiCategoryModel
   * @extends DS.Model
   */
  const KpiCategoryModel = Model.extend({
    /**
     * @memberof Vdc/KpiCategoryModel
     * @instance
     *
     * @type {Number}
     */
    version: attr('number'),
    /**
     * @memberof Vdc/KpiCategoryModel
     * @instance
     *
     * @type {String}
     */
    date_created: attr('date-time-string', {
      readOnly: true
    }),
    /**
     * @memberof Vdc/KpiCategoryModel
     * @instance
     *
     * @type {String}
     */
    name: attr('nullable-string'),
    /**
     * @memberof Vdc/KpiCategoryModel
     * @instance
     *
     * @type {Number}
     */
    position: attr('number', {
      defaultValue: 0
    }),
    /**
     * @memberof Vdc/KpiCategoryModel
     * @instance
     *
     * @type {Number}
     */
    client_id: attr('id'),
    /**
     * @memberof Vdc/UserModel
     * @instance
     *
     * @type {Boolean}
     */
    show_in_cockpit: attr('boolean', {
      defaultValue: () => false
    }),
    /**
     * @memberof Vdc/KpiCategoryModel
     * @instance
     *
     * @type {Number}
     */
    store_type_id: attr('id'),
    /**
     * @memberof Vdc/KpiCategoryModel
     * @instance
     *
     * @type {Number}
     */
    store_subtype_id: attr('id'),
    /**
     * @memberof Vdc/KpiCategoryModel
     * @instance
     *
     * @type {String}
     */
    show_in_module: attr('enum-string', {
      options: moduleOptions
    }),
    /**
     * @memberof Vdc/KpiCategoryModel
     * @instance
     *
     * @type {String}
     */
    kpi_color: attr('enum-string', {
      options: colorOptions
    })
  });
  KpiCategoryModel.reopenClass({
    /**
     * @memberof Vdc/KpiCategoryModel
     * @static
     *
     * @type {EnumOption[]}
     */
    colorOptions,
    /**
     * @memberof Vdc/KpiCategoryModel
     * @static
     *
     * @type {EnumOption[]}
     */
    moduleOptions
  });
  var _default = _exports.default = KpiCategoryModel;
});