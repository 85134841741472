define("ln-ember-form-elements/templates/components/form-elements/radio-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "192Ma0f6",
    "block": "[[[10,\"input\"],[15,3,[30,1]],[15,2,[30,0,[\"buttonValue\"]]],[15,\"checked\",[30,0,[\"checked\"]]],[14,4,\"radio\"],[12],[13],[1,\"\\n\"],[10,1],[15,0,[29,[\"radio-circle \",[52,[30,0,[\"checked\"]],\"selected\"]]]],[12],[13],[1,\"\\n\"],[10,1],[14,0,\"radio-label\"],[12],[1,[30,2]],[13],[1,\"\\n\"],[18,3,null]],[\"@name\",\"@label\",\"&default\"],false,[\"if\",\"yield\"]]",
    "moduleName": "ln-ember-form-elements/templates/components/form-elements/radio-button.hbs",
    "isStrictMode": false
  });
});