define("ln-ember-toolkit/utils/mock-ligaos", ["exports", "@ember/polyfills", "ln-ember-toolkit/utils/liga-os", "rsvp"], function (_exports, _polyfills, _ligaOs, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = mockLigaOS;
  function mockLigaOS(overrides = {}) {
    const config = configFactory(overrides.config);
    const theme = themeFactory(overrides.theme);
    const session = sessionFactory(overrides.session);
    const settings = settingsFactory(overrides.settings);
    const language = overrides.language || 'en';
    return (0, _polyfills.assign)(_ligaOs.default, {
      _openedUrl: '',
      getConfig: () => new Promise(resolve => resolve(config)),
      getLanguage: () => new Promise(resolve => resolve(language)),
      getSession: () => new Promise(resolve => resolve(session)),
      getTheme: () => new Promise(resolve => resolve(theme)),
      getSetting: (key, defaultValue) => (0, _rsvp.resolve)(typeof settings[key] !== 'undefined' ? settings[key] : defaultValue),
      call(name, args = []) {
        if (typeof this[name] !== 'function') {
          throw new Error(`Unknown method to call: "${name}"`);
        }
        return this[name](...args);
      },
      checkBrowserSupport: () => new Promise(resolve => resolve(true)),
      notifyAppLoaded() {},
      hideCommunicator() {},
      showCommunicator() {},
      setUrl() {},
      openUrl(url) {
        this._openedUrl = url;
      },
      // for convenient access from tests
      getOpenedUrl() {
        return this._openedUrl;
      }
    });
  }
  function settingsFactory(overrides) {
    overrides = overrides || {};
    return (0, _polyfills.assign)({
      TimeboardGuideSeen: true
    }, overrides);
  }
  function configFactory(overrides) {
    overrides = overrides || {};
    return (0, _polyfills.assign)({
      'api-myliga': {
        // ln-ember-data-models currently misbehaves when the request path doesn't have a domain name.
        // @see: https://bitbucket.org/ligadigital/ln-ember-data-models/src/7b7348eae3323f6483f0f2418b172a4c1b4eb61c/addon/adapters/application.js?at=master&fileviewer=file-view-default#application.js-238
        url: 'http://somewhere.far.enough/api',
        version: 'v1'
      },
      'api-vdc': {
        url: 'http://somewhere.even.further/api',
        version: 'v1'
      },
      'api-contact': {
        url: 'http://somewhere.where.contacts.live/api',
        version: ''
      },
      'api-timeboard': {
        url: 'http://somewhere.where.timeboard.live/api',
        version: 'v1'
      },
      'api-ex3000': {
        url: 'http://ln-api-ex3000.ln-test.liga.cloud'
      },
      resizer: {
        url: 'http://ln-image-resizer.ln-test.liga.cloud'
      }
    }, overrides);
  }
  function themeFactory(overrides) {
    overrides = overrides || {};
    return (0, _polyfills.assign)({
      type: 'LIGANOVA',
      background_image: {
        preview_url: 'about:blank'
      },
      logo_image: {
        preview_url: 'about:blank'
      }
    }, overrides);
  }
  function sessionFactory(overrides = {}) {
    const current = new Date();
    const exp = new Date(current.getTime() + 1000 * 60 * 60 * 24 * 365);
    const {
      user_id,
      expire_date = exp,
      permissions = []
    } = overrides;
    if (user_id) {
      const tokenObj = {
        expire_date,
        userInfo: {
          id: user_id,
          permissions
        }
      };
      delete overrides.permissions;
      overrides.token = `eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.${btoa(JSON.stringify(tokenObj))}.KWRjB1jgUODDbPe_T85VXfQVtiRVzlYDiQjqRj6-m3Y`;
    }
    return (0, _polyfills.assign)({}, overrides);
  }
});