define("ln-ember-data-models/models/myliga/employee", ["exports", "ember-data-model-fragments/attributes", "ln-ember-data-models/utils/enum-option", "@ember/object", "ember-data"], function (_exports, _attributes, _enumOption, _object, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.statusOptions = _exports.default = void 0;
  const {
    attr,
    belongsTo,
    Model
  } = _emberData.default;

  /**
   * @name Myliga/Employee/statusOptions
   * @type {EnumOption[]}
   */
  const statusOptions = _exports.statusOptions = [_enumOption.default.create({
    id: 'Active',
    active: true
  }), _enumOption.default.create({
    id: 'Inactive',
    active: false
  })];

  /**
   * @class Myliga/EmployeeModel
   * @extends DS.Model
   */
  var _default = _exports.default = Model.extend({
    /**
     * @memberof Myliga/EmployeeModel
     * @instance
     *
     * @type {Number}
     */
    version: attr('number'),
    /**
     * @memberof Myliga/EmployeeModel
     * @instance
     *
     * @type {Boolean}
     */
    active: attr('boolean'),
    /**
     * @memberof Myliga/EmployeeModel
     * @instance
     *
     * @type {Number}
     */
    age: attr('number'),
    /**
     * @memberof Myliga/EmployeeModel
     * @instance
     *
     * @type {String}
     */
    bank_account_number: attr('nullable-string'),
    /**
     * @memberof Myliga/EmployeeModel
     * @instance
     *
     * @type {String}
     */
    bank_name: attr('nullable-string'),
    /**
     * @memberof Myliga/EmployeeModel
     * @instance
     *
     * @type {String}
     */
    bic: attr('nullable-string'),
    /**
     * @memberof Myliga/EmployeeModel
     * @instance
     *
     * @type {String}
     */
    birthplace: attr('nullable-string'),
    /**
     * @memberof Myliga/EmployeeModel
     * @instance
     *
     * @type {String}
     */
    blz: attr('nullable-string'),
    /**
     * @memberof Myliga/EmployeeModel
     * @instance
     *
     * @type {Boolean}
     */
    can_book_all_departments: attr('boolean'),
    /**
     * @memberof Myliga/EmployeeModel
     * @instance
     *
     * @type {Boolean}
     */
    can_book_all_projects: attr('boolean'),
    /**
     * @memberof Myliga/EmployeeModel
     * @instance
     *
     * @type {Boolean}
     */
    can_book_connected_departments: attr('boolean'),
    /**
     * @memberof Myliga/EmployeeModel
     * @instance
     *
     * @type {String}
     */
    cancelation_period_type: attr('nullable-string'),
    /**
     * @memberof Myliga/EmployeeModel
     * @instance
     *
     * @type {Boolean}
     */
    car_available: attr('boolean'),
    /**
     * @memberof Myliga/EmployeeModel
     * @instance
     *
     * @type {String}
     */
    children: attr('nullable-string'),
    /**
     * @memberof Myliga/EmployeeModel
     * @instance
     *
     * @type {String}
     */
    city: attr('nullable-string'),
    /**
     * @memberof Myliga/EmployeeModel
     * @instance
     *
     * @type {Boolean}
     */
    close_nonresponsible_projects: attr('boolean'),
    /**
     * @memberof Myliga/EmployeeModel
     * @instance
     *
     * @type {Boolean}
     */
    company_carholder: attr('boolean'),
    /**
     * @memberof Myliga/EmployeeModel
     * @instance
     *
     * @type {Boolean}
     */
    company_cellphone_holder: attr('boolean'),
    /**
     * @memberof Myliga/EmployeeModel
     * @instance
     *
     * @type {Number}
     */
    compensation_entitlement: attr('number'),
    /**
     * @memberof Myliga/EmployeeModel
     * @instance
     *
     * @type {String}
     */
    confession: attr('nullable-string'),
    /**
     * @memberof Myliga/EmployeeModel
     * @instance
     *
     * @type {Number}
     */
    conversation_cycle: attr('number'),
    /**
     * @memberof Myliga/EmployeeModel
     * @instance
     *
     * @type {String}
     */
    conversation_date: attr('date-time-string'),
    /**
     * @memberof Myliga/EmployeeModel
     * @instance
     *
     * @type {Number}
     */
    country_id: attr('id'),
    /**
     * @memberof Myliga/EmployeeModel
     * @instance
     *
     * @type {Object[]}
     */
    custom_values: attr({
      defaultValue: () => []
    }),
    /**
     * @memberof Myliga/EmployeeModel
     * @instance
     *
     * @type {String}
     */
    date_birth: attr('date-string'),
    /**
     * @memberof Myliga/EmployeeModel
     * @instance
     *
     * @type {String}
     */
    date_contract_end: attr('date-string'),
    /**
     * @memberof Myliga/EmployeeModel
     * @instance
     *
     * @type {String}
     */
    date_entry: attr('date-string'),
    /**
     * @memberof Myliga/EmployeeModel
     * @instance
     *
     * @type {String}
     */
    date_exit: attr('date-string'),
    /**
     * @memberof Myliga/EmployeeModel
     * @instance
     *
     * @type {String}
     */
    date_probation_period_end: attr('date-string'),
    /**
     * @memberof Myliga/EmployeeModel
     * @instance
     *
     * @type {Number}
     */
    disciplinary_superior_id: attr('id'),
    /**
     * @memberof Myliga/EmployeeModel
     * @instance
     *
     * @type {String}
     */
    display_name: attr('nullable-string'),
    /**
     * @memberof Myliga/EmployeeModel
     * @instance
     *
     * @type {Boolean}
     */
    dummy: attr('boolean'),
    /**
     * @memberof Myliga/EmployeeModel
     * @instance
     *
     * @type {String}
     */
    email: attr('nullable-string'),
    /**
     * @memberof Myliga/EmployeeModel
     * @instance
     *
     * @type {String}
     */
    email_private: attr('nullable-string'),
    /**
     * @memberof Myliga/EmployeeModel
     * @instance
     *
     * @type {Number}
     */
    employee_number: attr('number'),
    /**
     * @memberof Myliga/EmployeeModel
     * @instance
     *
     * @type {String}
     */
    equipment: attr('nullable-string'),
    /**
     * @memberof Myliga/EmployeeModel
     * @instance
     *
     * @type {String}
     */
    family_status: attr('nullable-string'),
    /**
     * @memberof Myliga/EmployeeModel
     * @instance
     *
     * @type {String}
     */
    fax_number: attr('nullable-string'),
    /**
     * @memberof Myliga/EmployeeModel
     * @instance
     *
     * @type {String}
     */
    fax_number_private: attr('nullable-string'),
    /**
     * @memberof Myliga/EmployeeModel
     * @instance
     *
     * @type {String}
     */
    forename: attr('nullable-string'),
    /**
     * @memberof Myliga/EmployeeModel
     * @instance
     *
     * @type {Boolean}
     */
    freelancer: attr('boolean'),
    /**
     * @memberof Myliga/EmployeeModel
     * @instance
     *
     * @type {String}
     */
    gender: attr('nullable-string'),
    /**
     * @memberof Myliga/EmployeeModel
     * @instance
     *
     * @type {Boolean}
     */
    habitat: attr('boolean'),
    /**
     * @memberof Myliga/EmployeeModel
     * @instance
     *
     * @type {String}
     */
    health_insurance: attr('nullable-string'),
    /**
     * @memberof Myliga/EmployeeModel
     * @instance
     *
     * @type {Boolean}
     */
    hide_birthday: attr('boolean'),
    /**
     * @memberof Myliga/EmployeeModel
     * @instance
     *
     * @type {Boolean}
     */
    holiday_policy_exclusion: attr('boolean'),
    /**
     * @memberof Myliga/EmployeeModel
     * @instance
     *
     * @type {String}
     */
    iban: attr('nullable-string'),
    /**
     * @memberof Myliga/EmployeeModel
     * @instance
     *
     * @type {Number}
     */
    informative_superior_id: attr('id'),
    /**
     * @memberof Myliga/EmployeeModel
     * @instance
     *
     * @type {String}
     */
    job_position: attr('nullable-string'),
    /**
     * @memberof Myliga/EmployeeModel
     * @instance
     *
     * @type {Boolean}
     */
    job_ticket: attr('boolean'),
    /**
     * @memberof Myliga/EmployeeModel
     * @instance
     *
     * @type {String}
     */
    location: attr('nullable-string'),
    /**
     * @memberof Myliga/EmployeeModel
     * @instance
     *
     * @type {Boolean}
     */
    lockable: attr('boolean'),
    /**
     * @memberof Myliga/EmployeeModel
     * @instance
     *
     * @type {Boolean}
     */
    lockable_invoice: attr('boolean'),
    /**
     * @memberof Myliga/EmployeeModel
     * @instance
     *
     * @type {Boolean}
     */
    lockable_project_closing: attr('boolean'),
    /**
     * @memberof Myliga/EmployeeModel
     * @instance
     *
     * @type {Boolean}
     */
    master_authorized_to_sign: attr('boolean'),
    /**
     * @memberof Myliga/EmployeeModel
     * @instance
     *
     * @type {Boolean}
     */
    mobile_phone_available: attr('boolean'),
    /**
     * @memberof Myliga/EmployeeModel
     * @instance
     *
     * @type {String}
     */
    mobile_phone_number: attr('nullable-string'),
    /**
     * @memberof Myliga/EmployeeModel
     * @instance
     *
     * @type {String}
     */
    mobile_phone_number_private: attr('nullable-string'),
    /**
     * @memberof Myliga/EmployeeModel
     * @instance
     *
     * @type {String}
     */
    name: attr('nullable-string'),
    /**
     * @memberof Myliga/EmployeeModel
     * @instance
     *
     * @type {String}
     */
    notes: attr('nullable-string'),
    /**
     * @memberof Myliga/EmployeeModel
     * @instance
     *
     * @type {String}
     */
    payroll_number: attr('nullable-string'),
    /**
     * @memberof Myliga/EmployeeModel
     * @instance
     *
     * @type {String}
     */
    phone_number: attr('nullable-string'),
    /**
     * @memberof Myliga/EmployeeModel
     * @instance
     *
     * @type {String}
     */
    phone_number_private: attr('nullable-string'),
    /**
     * @memberof Myliga/EmployeeModel
     * @instance
     *
     * @type {Boolean}
     */
    save_rough_calculation_unter_requirements: attr('boolean'),
    /**
     * @memberof Myliga/EmployeeModel
     * @instance
     *
     * @type {Boolean}
     */
    see_open_calendar_weeks: attr('boolean'),
    /**
     * @memberof Myliga/EmployeeModel
     * @instance
     *
     * @type {String}
     */
    short_name: attr('nullable-string'),
    /**
     * @memberof Myliga/EmployeeModel
     * @instance
     *
     * @type {Boolean}
     */
    short_term_employed: attr('boolean'),
    /**
     * @memberof Myliga/EmployeeModel
     * @instance
     *
     * @type {Boolean}
     */
    show_in_communicator: attr('boolean'),
    /**
     * @memberof Myliga/EmployeeModel
     * @instance
     *
     * @type {String}
     */
    signature: attr('nullable-string'),
    /**
     * @memberof Myliga/EmployeeModel
     * @instance
     *
     * @type {String}
     */
    social_security_number: attr('nullable-string'),
    /**
     * @memberof Myliga/EmployeeModel
     * @instance
     *
     * @type {String}
     */
    street: attr('nullable-string'),
    /**
     * @memberof Myliga/EmployeeModel
     * @instance
     *
     * @type {String}
     */
    tariff: attr('nullable-string'),
    /**
     * @memberof Myliga/EmployeeModel
     * @instance
     *
     * @type {String}
     */
    tax_class: attr('nullable-string'),
    /**
     * @memberof Myliga/EmployeeModel
     * @instance
     *
     * @type {String}
     */
    tax_identification_number: attr('nullable-string'),
    /**
     * @memberof Myliga/EmployeeModel
     * @instance
     *
     * @type {Boolean}
     */
    team_global_permission: attr('boolean'),
    /**
     * @memberof Myliga/EmployeeModel
     * @instance
     *
     * @type {Number}
     */
    technical_superior_id: attr('id'),
    /**
     * @memberof Myliga/EmployeeModel
     * @instance
     *
     * @type {Number}
     */
    termination_reason: attr('number'),
    /**
     * @memberof Myliga/EmployeeModel
     * @instance
     *
     * @type {String}
     */
    time_recording_locked_until_date: attr('date-time-string'),
    /**
     * @memberof Myliga/EmployeeModel
     * @instance
     *
     * @type {String}
     */
    entry_date: attr('date-time-string'),
    /**
     * @memberof Myliga/EmployeeModel
     * @instance
     *
     * @type {String}
     */
    exit_date: attr('date-time-string'),
    /**
     * @memberof Myliga/EmployeeModel
     * @instance
     *
     * @type {Boolean}
     */
    use_only_offered_services_of_group: attr('boolean'),
    /**
     * @memberof Myliga/EmployeeModel
     * @instance
     *
     * @type {Number}
     */
    user_id: attr('id'),
    /**
     * @memberof Myliga/EmployeeModel
     * @instance
     *
     * @type {String}
     */
    user_name: attr('nullable-string'),
    /**
     * @memberof Myliga/EmployeeModel
     * @instance
     *
     * @type {Number}
     */
    vacation_entitlement: attr('number'),
    /**
     * @memberof Myliga/EmployeeModel
     * @instance
     *
     * @type {String}
     */
    zipcode: attr('nullable-string'),
    /**
     * @memberof Myliga/EmployeeModel
     * @instance
     *
     * @type {Myliga/PersonalJobprofileModel}
     */
    personal_jobprofile: belongsTo('myliga/personal-jobprofile'),
    /**
     * @memberof Myliga/EmployeeModel
     * @instance
     *
     * @type {MyLiga/fragments/PictureMeta}
     */
    picture: (0, _attributes.fragment)('myliga/fragments/picture'),
    /**
     * @memberof Myliga/EmployeeModel
     * @instance
     *
     * @type {String}
     * @readonly
     */
    fullName: (0, _object.computed)('name', 'forename', function () {
      return `${this.get('forename')} ${this.get('name')}`;
    }).readOnly()
  });
});