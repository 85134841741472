define("ln-ember-data-models/models/vdc/fit-criterion", ["exports", "ember-data", "@ember/array", "ln-ember-data-models/utils/enum-option", "ember-inflector"], function (_exports, _emberData, _array, _enumOption, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    attr,
    Model
  } = _emberData.default;
  _emberInflector.default.inflector.irregular('fit_criterion', 'fit_criteria');

  /**
   * @memberof Vdc/FitCriterionModel
   * @static
   *
   * @type {EnumOption[]}
   */
  const compareOperatorOptions = [_enumOption.default.create({
    id: 'Greater',
    name: 'Is greater than',
    type: 'Number'
  }), _enumOption.default.create({
    id: 'Smaller',
    name: 'Is smaller than',
    type: 'Number'
  }), _enumOption.default.create({
    id: 'Equal',
    name: 'Equals',
    type: 'Number'
  }), _enumOption.default.create({
    id: 'Not',
    name: 'Is not',
    type: 'Other'
  }), _enumOption.default.create({
    id: 'Equal',
    name: 'Equals',
    type: 'Other'
  })];

  /**
   * @class Vdc/FitCriterionModel
   * @extends DS.Model
   */
  const FitCriterionModel = Model.extend({
    /**
     * @memberof Vdc/FitCriterionModel
     * @instance
     *
     * @type {Number}
     */
    version: attr('number'),
    /**
     * @memberof Vdc/FitCriterionModel
     * @instance
     *
     * @type {Number}
     */
    article_id: attr('id'),
    /**
     * @memberof Vdc/FitCriterionModel
     * @instance
     *
     * @type {Number}
     */
    attribute_id: attr('id'),
    /**
     * @memberof Vdc/FitCriterionModel
     * @instance
     *
     * @type {Number}
     */
    compare_operator: attr('enum-string', {
      options: compareOperatorOptions
    }),
    /**
     * @memberof Vdc/FitCriterionModel
     * @instance
     *
     * @type {}
     */
    value: attr()
  });
  FitCriterionModel.reopenClass({
    /**
     * @memberof Vdc/FitCriterionModel
     * @static
     *
     * @type {EnumOption[]}
     */
    compareOperatorOptionsForTypeNumber: (0, _array.A)(compareOperatorOptions).filterBy('type', 'Number'),
    /**
     * @memberof Vdc/FitCriterionModel
     * @static
     *
     * @type {EnumOption[]}
     */
    compareOperatorOptionsForOther: (0, _array.A)(compareOperatorOptions).filterBy('type', 'Other')
  });
  var _default = _exports.default = FitCriterionModel;
});