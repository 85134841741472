define("ln-ember-data-models/models/vdc/article-attribute", ["exports", "ember-data", "ln-ember-data-models/utils/enum-option"], function (_exports, _emberData, _enumOption) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.typeOptions = _exports.fitTypeOptions = _exports.default = void 0;
  const {
    attr,
    hasMany,
    Model
  } = _emberData.default;

  /**
   * @name Vdc/ArticleAttributeModel/typeOptions
   * @type {EnumOption[]}
   */
  const typeOptions = _exports.typeOptions = [_enumOption.default.create({
    id: 'Text'
  }), _enumOption.default.create({
    id: 'Number'
  }), _enumOption.default.create({
    id: 'Width'
  }), _enumOption.default.create({
    id: 'Height'
  }), _enumOption.default.create({
    id: 'Depth'
  }), _enumOption.default.create({
    id: 'Weight'
  }), _enumOption.default.create({
    id: 'Boolean'
  }), _enumOption.default.create({
    id: 'Options'
  }), _enumOption.default.create({
    id: 'Language'
  }), _enumOption.default.create({
    id: 'Motif',
    deprecated: true
  }), _enumOption.default.create({
    id: 'Material'
  }), _enumOption.default.create({
    id: 'LanguageOptions',
    name: 'Language (Options)'
  }), _enumOption.default.create({
    id: 'MotifOptions',
    deprecated: true,
    name: 'Motif (Options)'
  })];

  /**
   * @name Vdc/ArticleAttributeModel/fitTypeOptions
   * @type {EnumOption[]}
   */
  const fitTypeOptions = _exports.fitTypeOptions = [_enumOption.default.create({
    id: 'Fixture'
  }), _enumOption.default.create({
    id: 'Store',
    name: 'POS'
  }), _enumOption.default.create({
    id: 'Window'
  })];

  /**
   * @class Vdc/ArticleAttributeModel
   * @extends DS.Model
   */
  const ArticleAttributeModel = Model.extend({
    /**
     * @memberof Vdc/ArticleAttributeModel
     * @instance
     *
     * @type {Number}
     */
    version: attr('number'),
    /**
     * @memberof Vdc/ArticleAttributeModel
     * @instance
     *
     * @type {Number}
     */
    match_id: attr('id'),
    /**
     * @memberof Vdc/ArticleAttributeModel
     * @instance
     *
     * @type {Number}
     */
    client_id: attr('id'),
    /**
     * @memberof Vdc/ArticleAttributeModel
     * @instance
     *
     * @type {String}
     */
    name: attr('nullable-string'),
    /**
     * @memberof Vdc/ArticleAttributeModel
     * @instance
     *
     * @type {String}
     */
    type: attr('enum-string', {
      options: typeOptions
    }),
    /**
     * @memberof Vdc/ArticleAttributeModel
     * @instance
     *
     * @type {String}
     */
    fit_type: attr('enum-string', {
      options: fitTypeOptions
    }),
    /**
     * @memberof Vdc/ArticleAttributeModel
     * @instance
     *
     * @type {Number}
     */
    position: attr('number', {
      defaultValue: 0
    }),
    /**
     * @memberof Vdc/ArticleAttributeModel
     * @instance
     *
     * @type {Boolean}
     */
    show_in_list: attr('boolean'),
    /**
     * @memberof Vdc/ArticleAttributeModel
     * @instance
     *
     * @type {AttributeOptionModel[]}
     */
    attribute_options: hasMany('vdc/attribute-option', {
      async: true
    })
  });
  ArticleAttributeModel.reopenClass({
    /**
     * @memberof Vdc/ArticleAttributeModel
     * @static
     *
     * @type {EnumOption[]}
     */
    typeOptions
  });
  var _default = _exports.default = ArticleAttributeModel;
});