define("ln-ember-data-models/models/myliga/event-participant", ["exports", "ember-data", "@ember/object", "@ember/object/computed", "@ember/service", "ember-data-model-fragments/attributes", "ln-ember-data-models/utils/enum-option"], function (_exports, _emberData, _object, _computed, _service, _attributes, _enumOption) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.statusOptions = _exports.default = void 0;
  const {
    Model,
    attr
  } = _emberData.default;

  /**
   * @name Myliga/EventParticipantModel/statusOptions
   * @type {EnumOption[]}
   */
  const statusOptions = _exports.statusOptions = [_enumOption.default.create({
    id: 'ParticipationRequested'
  }), _enumOption.default.create({
    id: 'Invited'
  }), _enumOption.default.create({
    id: 'Accepted'
  }), _enumOption.default.create({
    id: 'Declined'
  }), _enumOption.default.create({
    id: 'Rejected'
  }), _enumOption.default.create({
    id: 'WaitingList'
  })];

  /**
   * @class Myliga/EventParticipantModel
   * @extends Model
   */
  const EventParticipantModel = Model.extend({
    /**
     * @memberof Myliga/EventParticipantModel
     * @instance
     *
     * @type {Number}
     */
    version: attr('number'),
    /**
     * @memberof Myliga/EventParticipantModel
     * @instance
     *
     * @type {Number}
     */
    event_id: attr('id'),
    /**
     * @memberof Myliga/EventParticipantModel
     * @instance
     *
     * @type {Number}
     */
    user_id: attr('id'),
    /**
     * @memberof Myliga/EventParticipantModel
     * @instance
     *
     * @type {String}
     */
    user_name: attr('nullable-string'),
    /**
     * @memberof Myliga/EventParticipantModel
     * @instance
     *
     * @type {String}
     */
    user_email: attr('nullable-string'),
    /**
     * @memberof Myliga/EventParticipantModel
     * @instance
     *
     * @type {String}
     */
    status: attr('enum-string', {
      options: statusOptions,
      defaultValue: 'Invited'
    }),
    /**
     * @memberof Myliga/EventParticipantModel
     * @instance
     *
     * @type {MyLiga/fragments/PictureMeta}
     */
    user_picture: (0, _attributes.fragment)('myliga/fragments/picture'),
    /**
     * @memberof Myliga/EventParticipantModel
     * @instance
     *
     * @type {Boolean}
     */
    isOpen: (0, _computed.equal)('status', 'Open'),
    /**
     * @memberof Myliga/EventParticipantModel
     * @instance
     *
     * @type {Boolean}
     */
    isAccepted: (0, _computed.equal)('status', 'Accepted'),
    /**
     * @memberof Myliga/EventParticipantModel
     * @instance
     *
     * @type {Boolean}
     */
    isDeclined: (0, _computed.equal)('status', 'Declined'),
    /**
     * @memberof Myliga/EventParticipantModel
     * @instance
     *
     * @type {Boolean}
     */
    isInvited: (0, _computed.equal)('status', 'Invited'),
    /**
     * @memberof Myliga/EventParticipantModel
     * @instance
     *
     * @type {Boolean}
     */
    isRejected: (0, _computed.equal)('status', 'Rejected'),
    /**
     * @memberof Myliga/EventParticipantModel
     * @instance
     *
     * @type {Boolean}
     */
    isParticipationRequested: (0, _computed.equal)('status', 'isParticipationRequested'),
    /**
     * @memberof Myliga/EventParticipantModel
     * @instance
     *
     * @type {Boolean}
     */
    isWaitingList: (0, _computed.equal)('status', 'WaitingList'),
    /**
     * @memberof Myliga/EventParticipantModel
     * @instance
     *
     * @type {Boolean}
     */
    isCurrentUser: (0, _object.computed)('user_id', 'apiEvents.session.user_id', function () {
      return this.get('user_id') === this.get('apiEvents.session.user_id');
    }),
    apiEvents: (0, _service.inject)()
  });
  EventParticipantModel.reopenClass({
    /**
     * @memberof Myliga/EventParticipantModel
     * @static
     *
     * @type {EnumOption[]}
     */
    statusOptions
  });
  var _default = _exports.default = EventParticipantModel;
});