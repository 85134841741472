define("ln-ember-toolkit/templates/components/ln-tooltip", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "MYi1ZGob",
    "block": "[[[8,[39,0],null,[[\"@class\",\"@isShown\",\"@showOn\",\"@hideOn\"],[\"LnTooltip\",[30,1],[30,2],[99,1,[\"@hideOn\"]]]],[[\"default\"],[[[[1,\"\\n\"],[41,[48,[30,4]],[[[1,\"    \"],[18,4,null],[1,\"\\n\"]],[]],[[[1,\"    \"],[1,[30,3]],[1,\"\\n\"]],[]]]],[]]]]],[1,\"\\n\"]],[\"@isShown\",\"@showOn\",\"@content\",\"&default\"],false,[\"attach-tooltip\",\"hideOn\",\"if\",\"has-block\",\"yield\"]]",
    "moduleName": "ln-ember-toolkit/templates/components/ln-tooltip.hbs",
    "isStrictMode": false
  });
});