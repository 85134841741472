define("ln-ember-data-models/models/vdc/document", ["exports", "ember-data", "ember-data-model-fragments/attributes", "ln-ember-data-models/utils/enum-option"], function (_exports, _emberData, _attributes, _enumOption) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.typeOptions = _exports.trainingTypeOptions = _exports.stateOptions = _exports.kindOptions = _exports.eventTypeOptions = _exports.eventGuidelineTypeOptions = _exports.default = void 0;
  const {
    attr,
    hasMany,
    Model
  } = _emberData.default;

  /**
   * @name Vdc/DocumentModel/stateOptions
   * @type {EnumOption[]}
   */
  const stateOptions = _exports.stateOptions = [_enumOption.default.create({
    id: 'Proposed'
  }), _enumOption.default.create({
    id: 'Approved'
  }), _enumOption.default.create({
    id: 'Declined'
  })];

  /**
   * @name Vdc/DocumentModel/typeOptions
   * @type {EnumOption[]}
   */
  const typeOptions = _exports.typeOptions = [_enumOption.default.create({
    id: 'FloorPlan'
  }), _enumOption.default.create({
    id: 'Offer'
  }), _enumOption.default.create({
    id: 'Order'
  }), _enumOption.default.create({
    id: 'Invoice'
  }), _enumOption.default.create({
    id: 'Event'
  }), _enumOption.default.create({
    id: 'Agenda'
  }), _enumOption.default.create({
    id: 'EventGuideline'
  }), _enumOption.default.create({
    id: 'ServiceAgreement'
  }), _enumOption.default.create({
    id: 'Other'
  })];

  /**
   * @name Vdc/DocumentModel/kindOptions
   * @type {EnumOption[]}
   */
  const kindOptions = _exports.kindOptions = [_enumOption.default.create({
    id: 'Project'
  }), _enumOption.default.create({
    id: 'Store'
  }), _enumOption.default.create({
    id: 'Import'
  }), _enumOption.default.create({
    id: 'Task'
  }), _enumOption.default.create({
    id: 'Training'
  }), _enumOption.default.create({
    id: 'Event'
  }), _enumOption.default.create({
    id: 'StoreSubtype'
  }), _enumOption.default.create({
    id: 'Client'
  }), _enumOption.default.create({
    id: 'Article'
  })];

  /**
   * In case of documents related to Trainings, just these options should be available.
   *
   * @name Vdc/DocumentModel/trainingTypeOptions
   * @type {EnumOption[]}
   */
  const trainingTypeOptions = _exports.trainingTypeOptions = [_enumOption.default.create({
    id: 'Agenda'
  }), _enumOption.default.create({
    id: 'Other'
  })];

  /**
   * In case of documents related to Events, just these options should be available.
   *
   * @name Vdc/DocumentModel/eventTypeOptions
   * @type {EnumOption[]}
   */
  const eventTypeOptions = _exports.eventTypeOptions = [_enumOption.default.create({
    id: 'ServiceAgreement'
  }), _enumOption.default.create({
    id: 'Other'
  })];

  /**
   * In case of documents related to Events Guidelines, just this option should be available.
   *
   * @name Vdc/DocumentModel/eventGuidelineTypeOptions
   * @type {EnumOption[]}
   */
  const eventGuidelineTypeOptions = _exports.eventGuidelineTypeOptions = [_enumOption.default.create({
    id: 'EventGuideline'
  }), _enumOption.default.create({
    id: 'Other'
  })];

  /**
   * @class Vdc/DocumentModel
   * @extends DS.Model
   */
  const DocumentModel = Model.extend({
    /**
     * @memberof Vdc/DocumentModel
     * @instance
     *
     * @type {Number}
     */
    version: attr('number'),
    /**
     * @memberof Vdc/DocumentModel
     * @instance
     *
     * @type {Number[]}
     */
    country_ids: attr('number-array', {
      defaultValue: () => []
    }),
    /**
     * @memberof Vdc/DocumentModel
     * @instance
     *
     * @type {String}
     */
    document_name: attr('nullable-string'),
    /**
     * @memberof Vdc/DocumentModel
     * @instance
     *
     * @type {Number}
     */
    owner_id: attr('id'),
    /**
     * @memberof Vdc/DocumentModel
     * @instance
     *
     * @type {String}
     */
    kind: attr('enum-string', {
      options: kindOptions
    }),
    /**
     * @memberof Vdc/DocumentModel
     * @instance
     *
     * @type {String}
     */
    resource_state: attr('enum-string', {
      options: stateOptions
    }),
    /**
     * @memberof Vdc/DocumentModel
     * @instance
     *
     * @type {String}
     */
    language_id: attr('id'),
    /**
     * @memberof Vdc/DocumentModel
     * @instance
     *
     * @type {String}
     */
    resource_type: attr('enum-string', {
      options: typeOptions
    }),
    /**
     * @memberof Vdc/DocumentModel
     * @instance
     *
     * @type {Boolean}
     */
    post_process: attr('boolean', {
      defaultValue: () => false
    }),
    /**
     * @memberof Vdc/DocumentModel
     * @instance
     *
     * @type {Vdc/RevisionModel[]}
     */
    revisions: hasMany('vdc/revision', {
      async: true
    }),
    /**
     * @memberof Vdc/DocumentModel
     * @instance
     *
     * @type {Vdc/fragments/AttributeValueFragment[]}
     */
    attribute_values: (0, _attributes.fragmentArray)('vdc/fragments/attribute-value')
  });
  DocumentModel.reopenClass({
    /**
     * @memberof Vdc/DocumentModel
     * @static
     *
     * @type {EnumOption[]}
     */
    kindOptions,
    /**
     * @memberof Vdc/DocumentModel
     * @static
     *
     * @type {EnumOption[]}
     */
    stateOptions,
    /**
     * @memberof Vdc/DocumentModel
     * @static
     *
     * @type {EnumOption[]}
     */
    typeOptions,
    /**
     * @memberof Vdc/DocumentModel
     * @static
     *
     * @type {EnumOption[]}
     */
    trainingTypeOptions,
    /**
     * @memberof Vdc/DocumentModel
     * @static
     *
     * @type {EnumOption[]}
     */
    eventTypeOptions,
    /**
     * @memberof Vdc/DocumentModel
     * @static
     *
     * @type {EnumOption[]}
     */
    eventGuidelineTypeOptions
  });
  var _default = _exports.default = DocumentModel;
});