define("ln-ember-api-service/services/api", ["exports", "@ember/debug", "@ember/object", "@ember/service", "@ember/utils", "jquery", "rsvp", "ln-ember-api-service/utils/url", "ln-ember-api-service/utils/normalize-error", "@ember/application/deprecations"], function (_exports, _debug, _object, _service, _utils, _jquery, _rsvp, _url, _normalizeError, _deprecations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * @class ApiService
   * @extends Ember.Service
   */
  var _default = _exports.default = _service.default.extend({
    _token: null,
    apiEvents: (0, _service.inject)(),
    config: null,
    init() {
      this._super(...arguments);
      const apiEvents = this.get('apiEvents');
      this.set('config', apiEvents.getConfig());
      apiEvents.on('config-change', config => {
        this.set('config', config);
      });
      if (false === 'loadToken' in this) {
        // the whole api-events pattern is bad.
        // The recommended approach is to implement a "loadToken" method in the ApiService extension
        // so it does not depend on the `trigger-session` event
        (false && !(false) && (0, _deprecations.deprecate)('"loadToken" is not defined', false, {
          id: 'ln-ember-api-service.missing-loadToken',
          until: '5.0.0',
          url: 'https://ligadigital.atlassian.net/browse/LGOS-764'
        }));
        this.set('session', apiEvents.getSession());
        apiEvents.on('session-change', session => {
          this.set('session', session);
        });
      }
      this.set('language', apiEvents.getLanguage());
      apiEvents.on('language-change', language => {
        this.set('language', language);
      });
    },
    /**
     * ```javascript
     * this.get('api').read('vdc', 'stores')
     *   .then(function() { console.log('\o/'); })
     *   .catch(function() { console.log('/o\'); });
     * ```
     * ```javascript
     * this.get('api').read('myliga', ['pins', id, 'likes'])
     *   .then(function() { console.log('\o/'); })
     *   .catch(function() { console.log('/o\'); });
     * ```
     *
     * @memberof ApiService
     * @instance
     *
     * @method read
     * @param  {String} namespace
     * @param  {String|String[]} uri
     * @return {Ember.RSVP.Promise}
     */
    read(namespace, uri) {
      return this.request({
        url: this.buildUrl(namespace, uri),
        method: 'GET'
      });
    },
    /**
     * ```javascript
     * this.get('api').create('vdc', ['/stores', {name: 'test'}])
     *   .then(function() { console.log('\o/'); })
     *   .catch(function() { console.log('/o\'); });
     * ```
     *
     * @memberof ApiService
     * @instance
     *
     * @method create
     * @param  {String} namespace
     * @param  {String|String[]} uri
     * @param  {Object} data
     * @param  {Object} options
     * @returns {Ember.RSVP.Promise}
     */
    create(namespace, uri, data, options = {}) {
      (false && !((0, _utils.typeOf)(namespace) === 'string') && (0, _debug.assert)('create(): namespace needs to be a string', (0, _utils.typeOf)(namespace) === 'string'));
      options = Object.assign({
        // defaults (can be overridden)
        contentType: 'application/json'
      }, options);
      options.url = this.buildUrl(namespace, uri);
      options.method = 'POST';
      options.data = this.encodeData(data, options.contentType);
      return this.request(options);
    },
    /**
     * ```javascript
     * this.get('api').update('vdc', ['/stores', {name: 'test'}])
     *   .then(function() { console.log('\o/'); })
     *   .catch(function() { console.log('/o\'); });
     * ```
     *
     * @memberof ApiService
     * @instance
     *
     * @method put
     * @param  {String} namespace
     * @param  {String|String[]} uri
     * @param  {Object} data
     * @param  {Object} options
     * @return {Ember.RSVP.Promise}
     */
    update(namespace, uri, data, options = {}) {
      options = Object.assign({
        // defaults (can be overridden)
        contentType: 'application/json'
      }, options);
      options.url = this.buildUrl(namespace, uri);
      options.method = 'PUT';
      options.data = this.encodeData(data, options.contentType);
      return this.request(options);
    },
    /**
     * ```javascript
     * this.get('api').partialUpdate('vdc', ['/stores', { name: 'test' }])
     *   .then(() => console.log('Success'))
     *   .catch((error) => console.error(error.message));
     * ```
     *
     * @memberof ApiService
     * @instance
     *
     * @method patch
     * @param  {String} namespace
     * @param  {String|String[]} uri
     * @param  {Object} data
     * @param  {Object} options
     * @return {Ember.RSVP.Promise}
     */
    partialUpdate(namespace, uri, data, options = {}) {
      options = Object.assign({
        // defaults (can be overridden)
        contentType: 'application/json'
      }, options);
      options.url = this.buildUrl(namespace, uri);
      options.method = 'PATCH';
      options.data = this.encodeData(data, options.contentType);
      return this.request(options);
    },
    /**
     * ```javascript
     * this.get('api').delete('vdc', ['stores', 1])
     *   .then(function() { console.log('\o/'); })
     *   .catch(function() { console.log('/o\'); });
     * ```
     *
     * @memberof ApiService
     * @instance
     *
     * @method delete
     * @param  {String} namespace
     * @param  {String|String[]} uri
     * @param  {Object} data
     * @param  {Object} options
     * @return {Ember.RSVP.Promise}
     */
    delete(namespace, uri, data, options = {}) {
      let requestOptions = {
        url: this.buildUrl(namespace, uri),
        method: 'DELETE'
      };
      if (typeof data === 'object') {
        requestOptions = Object.assign({
          // defaults (can be overridden)
          contentType: 'application/json'
        }, requestOptions, options);
        requestOptions.data = this.encodeData(data, requestOptions.contentType);
      }
      return this.request(requestOptions);
    },
    /**
     * ```javascript
     * this.get('api').copy('vdc', ['stores'])
     *   .then(function() { console.log('\o/'); })
     *   .catch(function() { console.log('/o\'); });
     * ```
     *
     * @memberof ApiService
     * @instance
     *
     * @method copy
     * @param  {String} namespace
     * @param  {String|String[]} uri
     * @return {Ember.RSVP.Promise}
     */
    copy(namespace, uri) {
      return this.request({
        url: this.buildUrl(namespace, uri),
        method: 'COPY'
      });
    },
    /**
     * @memberof ApiService
     * @instance
     *
     * @private
     * @method encodeData
     * @param  {Object} data
     * @param  {String|String[]} contentType
     * @return {Object}
     */
    encodeData(data, contentType) {
      if (contentType === 'application/json') {
        return JSON.stringify(data);
      } else if (contentType === 'multipart/form-data') {
        const formData = new FormData();
        Object.keys(data).forEach(key => {
          const value = data[key];
          if (value instanceof File) {
            formData.append(key, value, value.name);
          } else {
            formData.append(key, value);
          }
        });
        return formData;
      }
      return data;
    },
    /**
     * @memberof ApiService
     * @instance
     *
     * @private
     * @method headers
     * @param  {String} contentType
     * @return {object}
     */
    headers(contentType = 'application/json') {
      const headers = {
        'Accept': 'application/json',
        'Accept-Language': this.get('language')
      };
      const token = this._token;
      if (token) {
        headers.Authorization = `Bearer ${token}`;
      }
      if (contentType === 'application/json') {
        contentType += '; charset=UTF-8';
      }
      if (contentType) {
        headers['Content-Type'] = contentType;
      }
      return headers;
    },
    /**
     * @memberof ApiService
     * @instance
     *
     * @private
     * @method buildUrl
     * @param  {String} namespace
     * @param  {String|String[]} uri
     * @return {Array}
     */
    buildUrl(namespace, uri) {
      if ((0, _utils.typeOf)(uri) === 'array') {
        uri = _url.default.api(...uri);
      }
      (false && !((0, _utils.typeOf)(uri) === 'string') && (0, _debug.assert)('[ln-ember-api-service] buildUrl() uri needs to be a string', (0, _utils.typeOf)(uri) === 'string'));
      (false && !((0, _utils.typeOf)(namespace) === 'string') && (0, _debug.assert)('[ln-ember-api-service] buildUrl() namespace needs to be a string', (0, _utils.typeOf)(namespace) === 'string'));
      (false && !(this.namespaceExists(namespace)) && (0, _debug.assert)(`[ln-ember-api-service] buildUrl() namespace "${namespace}" does not exits for Url  "${uri}"`, this.namespaceExists(namespace)));
      if (!uri.match(/^\//)) {
        uri = `/${uri}`;
      }
      const baseUrl = this.baseUrlForNamespace(namespace);
      return `${baseUrl}${uri}`;
    },
    /**
     * @memberof ApiService
     * @instance
     *
     * @private
     * @method request
     * @param  {object} options
     * @return {Object|Ember.RSVP.Promise}
     */
    request(options) {
      const tokenPromise = _rsvp.default.resolve('loadToken' in this ? this.loadToken() : this.get('session.token'));
      return tokenPromise.then(token => {
        this.set('_token', token);
        this.requestOptionsFor(options);
        const data = this.getRequestedData(options);
        return Object.assign(data.promise, {
          abort: () => data.xhr.abort()
        });
      });
    },
    /**
     * @memberof ApiService
     * @instance
     *
     * @private
     * @method getRequestedData
     * @param  {object} options
     * @return {Object|Ember.RSVP.Promise}
     */
    getRequestedData(options) {
      let xhr;
      return {
        promise: new _rsvp.default.Promise((resolve, reject) => {
          xhr = _jquery.default.ajax(Object.assign({}, options, {
            success: resp => resolve(resp),
            error: response => {
              if (xhr.statusText === 'abort') {
                // Resolve is here, because we don't need an error/failure message
                // and it's empty because we don't have something for aborted request
                resolve();
                return;
              }
              this.get('apiEvents').triggerErrorResponse(response);
              const payload = (0, _object.get)(response, 'responseJSON') || (0, _object.get)(response, 'responseText');
              reject((0, _normalizeError.default)((0, _object.get)(response, 'status'), payload));
            }
          }));
          return xhr;
        }),
        xhr
      };
    },
    /**
     * @memberof ApiService
     * @instance
     *
     * @private
     * @method requestOptionsFor
     * @param  {Object} options
     * @return {Object}
     */
    requestOptionsFor(options) {
      if (options.contentType === 'multipart/form-data') {
        // Content-Type Header including the boundary is set automatically by
        // jQuery / FormData.
        options.contentType = false;
      }
      Object.assign(options, {
        dataType: 'json',
        headers: this.headers(options.contentType),
        processData: options.contentType === 'application/json'
      });
      if (options.progress && typeof options.progress === 'function') {
        Object.assign(options, {
          xhr: () => {
            const myXhr = _jquery.default.ajaxSettings.xhr();
            if (myXhr.upload) {
              myXhr.upload.addEventListener('progress', (...args) => {
                options.progress(...args);
              }, false);
            }
            return myXhr;
          }
        });
      }
      return options;
    },
    /**
     * @memberof ApiService
     * @instance
     *
     * @private
     * @method baseUrlForNamespace
     * @param  {String} namespace
     * @return {String}
     */
    baseUrlForNamespace(namespace) {
      const {
        url,
        version
      } = this.get(`config.api-${namespace}`);
      return `${url}/${version}`;
    },
    namespaceExists(namespace) {
      const {
        url = null,
        version = null
      } = this.get(`config.api-${namespace}`) || {};
      return !(0, _utils.isNone)(url) && !(0, _utils.isNone)(version);
    }
  });
});