define("ln-ember-form-elements/utils/weak-find", ["exports", "@ember/object"], function (_exports, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = weakFind;
  /**
   * Checks if the array includes the val regarding datatype differences.
   *
   * @param {Array} array  array to check
   * @param {Object|String|Number} val value to be checked
   * @param {?String} property property to save
   * @returns {Boolean}
   */
  function weakFind(array, val, property = null) {
    return property ? array.find(item => String((0, _object.get)(item, property)) === String((0, _object.get)(val, property) || val)) : array.find(item => String(item) === String(val));
  }
});