define("ember-collection/utils/translate", ["exports", "ember-collection/utils/style-properties"], function (_exports, _styleProperties) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.translateCSS = _exports.translate = _exports.supports3D = _exports.supports2D = void 0;
  _exports.translatePosition = translatePosition;
  _exports.translatePositionCSS = translatePositionCSS;
  _exports.translateTransform2D = translateTransform2D;
  _exports.translateTransform2DCSS = translateTransform2DCSS;
  _exports.translateTransform3D = translateTransform3D;
  _exports.translateTransform3DCSS = translateTransform3DCSS;
  const transformCSSProp = (0, _styleProperties.cssProperty)('transform');
  const transformStyleProp = (0, _styleProperties.styleProperty)('transform');
  const supports3D = _exports.supports3D = !!(0, _styleProperties.styleProperty)('perspectiveOrigin');
  const supports2D = _exports.supports2D = !!transformStyleProp;
  function translatePosition(el, x, y) {
    el.style.left = x + 'px';
    el.style.top = y + 'px';
  }
  function translateTransform2D(el, x, y) {
    el.style[transformStyleProp] = matrix2D(x, y);
  }
  function translateTransform3D(el, x, y) {
    el.style[transformStyleProp] = matrix3D(x, y);
  }
  function translatePositionCSS(x, y) {
    return `left:${x}px;top:${y}px;`;
  }
  function translateTransform2DCSS(x, y) {
    return `${transformCSSProp}:${matrix2D(x, y)};`;
  }
  function translateTransform3DCSS(x, y) {
    return `${transformCSSProp}:${matrix3D(x, y)};`;
  }
  function matrix2D(x, y) {
    return `matrix(1, 0, 0, 1, ${x}, ${y})`;
  }
  function matrix3D(x, y) {
    return `matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, ${x}, ${y}, 0, 1)`;
  }
  const translate = _exports.translate = supports3D ? translateTransform3D : supports2D ? translateTransform2D : translatePosition;
  const translateCSS = _exports.translateCSS = supports3D ? translateTransform3DCSS : supports2D ? translateTransform2DCSS : translatePositionCSS;
});