define("ln-ember-data-models/models/vdc/sales-figure", ["exports", "ember-data", "ln-ember-data-models/utils/enum-option"], function (_exports, _emberData, _enumOption) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.typeOptions = _exports.linkTypeOptions = _exports.default = void 0;
  const {
    attr,
    Model
  } = _emberData.default;

  /**
   * @name Vdc/SalesFigureModel/linkTypeOptions
   * @type {EnumOption[]}
   */
  const linkTypeOptions = _exports.linkTypeOptions = [_enumOption.default.create({
    id: 'POSType'
  }), _enumOption.default.create({
    id: 'Country'
  }), _enumOption.default.create({
    id: 'Project'
  }), _enumOption.default.create({
    id: 'Market'
  }), _enumOption.default.create({
    id: 'POS'
  }), _enumOption.default.create({
    id: 'Training'
  }), _enumOption.default.create({
    id: 'Event'
  })];

  /**
   * @name Vdc/SalesFigureModel/typeOptions
   * @type {EnumOption[]}
   */
  const typeOptions = _exports.typeOptions = [_enumOption.default.create({
    id: 'Plan'
  }), _enumOption.default.create({
    id: 'Actual'
  })];

  /**
   * @class Vdc/SalesFigureModel
   * @extends DS.Model
   */
  const SalesFigureModel = Model.extend({
    /**
     * @memberof Vdc/SalesFigureModel
     * @instance
     *
     * @type {Number}
     */
    version: attr('number'),
    /**
     * @memberof Vdc/SalesFigureModel
     * @instance
     *
     * @type {String}
     */
    date_created: attr('date-time-string'),
    /**
     * @memberof Vdc/SalesFigureModel
     * @instance
     *
     * @type {String}
     */
    date_changed: attr('date-time-string'),
    /**
     * @memberof Vdc/SalesFigureModel
     * @instance
     *
     * @type {Number}
     */
    client_id: attr('id'),
    /**
     * @memberof Vdc/SalesFigureModel
     * @instance
     *
     * @type {String}
     */
    resource_link_type: attr('enum-string', {
      options: linkTypeOptions
    }),
    /**
     * @memberof Vdc/SalesFigureModel
     * @instance
     *
     * @type {Number}
     */
    resource_id: attr('id'),
    /**
     * @memberof Vdc/SalesFigureModel
     * @instance
     *
     * @type {String}
     */
    resource_name: attr('nullable-string'),
    /**
     * @memberof Vdc/SalesFigureModel
     * @instance
     *
     * @type {String}
     */
    start: attr('date-time-string'),
    /**
     * @memberof Vdc/SalesFigureModel
     * @instance
     *
     * @type {String}
     */
    end: attr('date-time-string'),
    /**
     * @memberof Vdc/SalesFigureModel
     * @instance
     *
     * @type {String}
     */
    date_data: attr('date-time-string'),
    /**
     * @memberof Vdc/SalesFigureModel
     * @instance
     *
     * @type {String}
     */
    type: attr('enum-string', {
      options: typeOptions
    }),
    /**
     * @memberof Vdc/SalesFigureModel
     * @instance
     *
     * @type {Number}
     */
    sales_volume_cost: attr('number'),
    /**
     * @memberof Vdc/SalesFigureModel
     * @instance
     *
     * @type {Number}
     */
    sales_volume_count: attr('number'),
    /**
     * @memberof Vdc/SalesFigureModel
     * @instance
     *
     * @type {Number}
     */
    network_sales_share_percentage: attr('number'),
    /**
     * @memberof Vdc/SalesFigureModel
     * @instance
     *
     * @type {Number}
     */
    market_share_percentage: attr('number'),
    /**
     * @memberof Vdc/SalesFigureModel
     * @instance
     *
     * @type {Number}
     */
    mix_share_percentage: attr('number'),
    /**
     * @memberof Vdc/SalesFigureModel
     * @instance
     *
     * @type {Number}
     */
    total_number_of_segments: attr('number'),
    /**
     * @memberof Vdc/SalesFigureModel
     * @instance
     *
     * @type {Number}
     */
    number_of_segment_leaderships: attr('number')
  });
  SalesFigureModel.reopenClass({
    /**
     * @memberof Vdc/SalesFigureModel
     * @static
     *
     * @type {EnumOption[]}
     */
    typeOptions,
    /**
     * @memberof Vdc/SalesFigureModel
     * @static
     *
     * @type {EnumOption[]}
     */
    linkTypeOptions
  });
  var _default = _exports.default = SalesFigureModel;
});