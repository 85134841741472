define("ln-ember-toolkit/mixins/media-events", ["exports", "@ember/object/computed", "@ember/service", "@ember/object/mixin"], function (_exports, _computed, _service, _mixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function triggerMediaDidChange(mediaVersion) {
    this.trigger('mediaDidChange', mediaVersion);
  }
  var _default = _exports.default = _mixin.default.create({
    mediaMatcher: (0, _service.inject)(),
    isDesktop: (0, _computed.alias)('mediaMatcher.isDesktop'),
    isMobile: (0, _computed.alias)('mediaMatcher.isMobile'),
    didInsertElement() {
      this._super(...arguments);
      this.set('mediaVersion', this.get('mediaMatcher.mediaVersion'));
      this.mediaMatcher.on('mediaDidChange', this, triggerMediaDidChange);
    },
    willDestroyElement() {
      this._super(...arguments);
      this.mediaMatcher.off('mediaDidChange', this, triggerMediaDidChange);
    }
  });
});