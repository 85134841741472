define("ln-ember-form-elements/templates/components/form-elements/dropdown-button/button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "H3OtPt2n",
    "block": "[[[6,[39,0],null,[[\"action\",\"theme\",\"disabled\",\"loading\"],[[52,[51,[30,1]],[30,2],[30,3]],[52,[30,1],\"light-highlight\",\"light\"],[30,4],[30,5]]],[[\"default\"],[[[[1,\"  \"],[18,7,null],[1,\"\\n\"],[41,[30,1],[[[1,\"    \"],[10,1],[15,0,[29,[\"dropdown-arrow \",[52,[30,6],\"up\"]]]],[12],[13],[1,\"\\n\"]],[]],null]],[]]]]]],[\"@hasDropdown\",\"@action\",\"@toggleDropdown\",\"@disabled\",\"@loading\",\"@isOpen\",\"&default\"],false,[\"form-elements/styled-button\",\"unless\",\"if\",\"yield\"]]",
    "moduleName": "ln-ember-form-elements/templates/components/form-elements/dropdown-button/button.hbs",
    "isStrictMode": false
  });
});