define("ln-ember-form-elements/templates/components/form-elements/image-upload", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "yUv/GOPq",
    "block": "[[[41,[30,0,[\"label\"]],[[[1,\"  \"],[10,0],[14,0,\"label\"],[12],[1,[30,0,[\"label\"]]],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[10,0],[14,0,\"scroll-content\"],[12],[1,\"\\n  \"],[10,\"input\"],[14,0,\"file-input\"],[14,\"title\",\"\"],[14,\"accept\",\"image/*\"],[14,\"multiple\",\"\"],[14,4,\"file\"],[12],[13],[1,\"\\n\\n\"],[41,[30,0,[\"fileObjects\",\"length\"]],[[[42,[28,[37,2],[[28,[37,2],[[30,0,[\"fileObjects\"]]],null]],null],\"id\",[[[1,\"      \"],[10,0],[15,\"data-id\",[30,1,[\"id\"]]],[15,0,[29,[\"preview-image \",[52,[30,1,[\"isRemoved\"]],\"is-removed\"],\" \",[52,[30,1,[\"isFile\"]],\"is-file\"]]]],[15,5,[30,1,[\"style\"]]],[12],[1,\"\\n\"],[1,\"        \"],[1,[28,[35,3],null,[[\"action\",\"icon\",\"theme\"],[[28,[37,4],[[30,0],\"removeFile\",[30,1]],null],\"close\",\"dark\"]]]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[1]],null],[1,\"\\n    \"],[10,0],[14,0,\"dummy\"],[15,\"onclick\",[28,[37,4],[[30,0],\"clickDummy\"],null]],[14,\"role\",\"button\"],[12],[13],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\"]],[\"file\"],false,[\"if\",\"each\",\"-track-array\",\"form-elements/styled-button\",\"action\"]]",
    "moduleName": "ln-ember-form-elements/templates/components/form-elements/image-upload.hbs",
    "isStrictMode": false
  });
});