define("ln-ember-data-models/models/vdc/cost-rule", ["exports", "ember-data", "ln-ember-data-models/utils/enum-option"], function (_exports, _emberData, _enumOption) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.typeOptions = _exports.replacePriceOptions = _exports.default = void 0;
  const {
    attr,
    hasMany,
    Model
  } = _emberData.default;

  /**
   * @name Vdc/CostRuleModel/typeOptions
   * @type {EnumOption[]}
   */
  const typeOptions = _exports.typeOptions = [_enumOption.default.create({
    id: 'PerOrder'
  }), _enumOption.default.create({
    id: 'PerArticle'
  })];

  /**
   * TODO Add documentation: What is this used for?
   *
   * TODO Should probably look like this:
   *
   * ```
   * EnumOption.create({ id: true,  name: 'Create Additional Cost' })
   * EnumOption.create({ id: false: name: 'Replace Article Price' }
   * ```
   *
   * @name Vdc/CostRuleModel/replacePriceOptions
   * @type {EnumOption[]}
   */
  const replacePriceOptions = _exports.replacePriceOptions = [_enumOption.default.create({
    id: 'CreateAdditionalCost'
  }), _enumOption.default.create({
    id: 'ReplaceArticlePrice'
  })];

  /**
   * @class Vdc/CostRuleModel
   * @extends DS.Model
   */
  const CostRuleModel = Model.extend({
    /**
     * @memberof Vdc/CostRuleModel
     * @instance
     *
     * @type {Number}
     */
    version: attr('number'),
    /**
     * @memberof Vdc/CostRuleModel
     * @instance
     *
     * @type {String}
     */
    name: attr('nullable-string'),
    /**
     * @memberof Vdc/CostRuleModel
     * @instance
     *
     * @type {String}
     */
    group_name: attr('nullable-string'),
    /**
     * @memberof Vdc/CostRuleModel
     * @instance
     *
     * @type {String}
     */
    description: attr('nullable-string'),
    /**
     * @memberof Vdc/CostRuleModel
     * @instance
     *
     * @type {Number}
     */
    priority: attr('number'),
    /**
     * @memberof Vdc/CostRuleModel
     * @instance
     *
     * @type {Boolean}
     */
    active: attr('boolean', {
      defaultValue: () => true
    }),
    /**
     * @memberof Vdc/CostRuleModel
     * @instance
     *
     * @type {String}
     */
    type: attr('enum-string', {
      options: typeOptions,
      defaultValue: 'PerOrder'
    }),
    /**
     * @memberof Vdc/CostRuleModel
     * @instance
     *
     * @type {Boolean}
     */
    replace_price: attr('boolean', {
      defaultValue: false
    }),
    /**
     * @memberof Vdc/CostRuleModel
     * @instance
     *
     * @type {Number}
     */
    project_id: attr('id'),
    /**
     *
     * @memberof Vdc/CostRuleModel
     * @instance
     *
     * @type {Vdc/CostRulePosition[]}
     */
    cost_rule_positions: hasMany('vdc/cost-rule-position', {
      async: false
    }),
    /**
     *
     * @memberof Vdc/CostRuleModel
     * @instance
     *
     * @type {Vdc/CostRuleCondition[]}
     */
    conditions: hasMany('vdc/cost-rule-condition', {
      async: false
    })
  });
  CostRuleModel.reopenClass({
    /**
     * @memberof Vdc/CostRuleModel
     * @static
     *
     * @type {EnumOption[]}
     */
    typeOptions,
    /**
     * TODO should be renamed to `replacePriceOptions`
     *
     * @memberof Vdc/CostRuleModel
     * @static
     *
     * @type {EnumOption[]}
     */
    replaceOptions: replacePriceOptions
  });
  var _default = _exports.default = CostRuleModel;
});