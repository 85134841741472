define("ln-ember-data-models/models/vdc/attribute-set", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    attr,
    hasMany,
    Model
  } = _emberData.default;

  /**
   * @class Vdc/AttributeSetModel
   * @extends DS.Model
   */
  var _default = _exports.default = Model.extend({
    /**
     * @memberof Vdc/AttributeSetModel
     * @instance
     *
     * @type {Number}
     */
    version: attr('number'),
    /**
     * @memberof Vdc/AttributeSetModel
     * @instance
     *
     * @type {Number}
     */
    client_id: attr('id'),
    /**
     * @memberof Vdc/AttributeSetModel
     * @instance
     *
     * @type {String}
     */
    name: attr('nullable-string'),
    /**
     * @memberof Vdc/AttributeSetModel
     * @instance
     *
     * @type {String}
     */
    type: attr('nullable-string'),
    /**
     * @memberof Vdc/AttributeSetModel
     * @instance
     *
     * @type {Number}
     */
    picture_id: attr('id'),
    /**
     * @memberof Vdc/AttributeSetModel
     * @instance
     *
     * @type {Object}
     */
    picture: attr(),
    /**
     * @memberof Vdc/AttributeSetModel
     * @instance
     *
     * @type {Vdc/Attribute[]}
     */
    attributes: hasMany('vdc/attribute')
  });
});