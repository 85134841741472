define("ln-ember-data-models/models/myliga/jobprofile", ["exports", "ember-data", "ln-ember-data-models/utils/enum-option", "ember-data-model-fragments/attributes"], function (_exports, _emberData, _enumOption, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.projectBonusTypeOptions = _exports.default = void 0;
  const {
    attr,
    Model
  } = _emberData.default;

  /**
   * @name Myliga/JobprofileModel/projectBonusTypeOptions
   * @type {EnumOption[]}
   */
  const projectBonusTypeOptions = _exports.projectBonusTypeOptions = [_enumOption.default.create({
    id: 'Junior'
  }), _enumOption.default.create({
    id: 'Senior'
  }), _enumOption.default.create({
    id: 'Manager'
  })];

  /**
   * @class Myliga/JobprofileModel
   * @extends DS.Model
   */
  const JobprofileModel = Model.extend({
    /**
     * @memberof Myliga/JobprofileModel
     * @instance
     *
     * @type {Number}
     */
    version: attr('number'),
    /**
     * @memberof Myliga/JobprofileModel
     * @instance
     *
     * @type {String}
     */
    name: attr('nullable-string'),
    /**
     * @memberof Myliga/JobprofileModel
     * @instance
     *
     * @type {Number}
     */
    byd_id: attr('nullable-string'),
    // eslint-disable-line ligadigital/ember-model-custom-types

    /**
     * @memberof Myliga/JobprofileModel
     * @instance
     *
     * @type {Number}
     */
    category_id: attr('id'),
    /**
     * @memberof Myliga/JobprofileModel
     * @instance
     *
     * @type {String}
     */
    description: attr('nullable-string'),
    /**
     * @memberof Myliga/JobprofileModel
     * @instance
     *
     * @type {Number}
     */
    work_experience_years: attr('number'),
    /**
     * @memberof Myliga/JobprofileModel
     * @instance
     *
     * @type {Number}
     */
    hierarchy_level: attr('number'),
    /**
     * @memberof Myliga/JobprofileModel
     * @instance
     *
     * @type {Number}
     */
    min_salary: attr('number'),
    /**
     * @memberof Myliga/JobprofileModel
     * @instance
     *
     * @type {Number}
     */
    max_salary: attr('number'),
    /**
     * @memberof Myliga/JobprofileModel
     * @instance
     *
     * @type {Number}
     */
    max_bonus_pament: attr('number'),
    /**
     * @memberof Myliga/JobprofileModel
     * @instance
     *
     * @type {Object[]}
     */
    jobprofile_links: attr({
      defaultValue: () => []
    }),
    /**
     * @memberof Myliga/JobprofileModel
     * @instance
     *
     * @type {Boolean}
     */
    management: attr('boolean'),
    /**
     * @memberof Myliga/JobprofileModel
     * @instance
     *
     * @type {Boolean}
     */
    miles_qualified: attr('boolean'),
    /**
     * @memberof Myliga/JobprofileModel
     * @instance
     *
     * @type {Number}
     */
    expert_type_id: attr('id'),
    /**
     * @memberof Myliga/JobprofileModel
     * @instance
     *
     * @type {String}
     */
    project_bonus_type: attr('enum-string', {
      options: projectBonusTypeOptions
    }),
    /**
     * @memberof Myliga/JobprofileModel
     * @instance
     *
     * @type {Object[]}
     */
    jobprofile_talents: attr({
      defaultValue: () => []
    }),
    /**
     * @memberof Myliga/JobprofileModel
     * @instance
     *
     * @type {Number}
     */
    profile_type_id: attr('id'),
    /**
     * @memberof Myliga/JobprofileModel
     * @instance
     *
     * @type {Myliga/fragments/JobprofileObjectiveSettingFragment}
     */
    qualitative_objective_settings: (0, _attributes.fragment)('myliga/fragments/jobprofile-objective-setting', {
      defaultValue: {
        bonus_payment_type: null,
        max_bonus_payment: null,
        objective_enabled: false
      }
    }),
    /**
     * @memberof Myliga/JobprofileModel
     * @instance
     *
     * @type {Myliga/fragments/JobprofileObjectiveSettingFragment}
     */
    quantitative_objective_settings: (0, _attributes.fragment)('myliga/fragments/jobprofile-objective-setting', {
      defaultValue: {
        bonus_payment_type: null,
        max_bonus_payment: null,
        objective_enabled: false
      }
    })
  });
  JobprofileModel.reopenClass({
    /**
     * @memberof Myliga/JobprofileModel
     * @static
     *
     * @type {EnumOption[]}
     */
    projectBonusTypeOptions
  });
  var _default = _exports.default = JobprofileModel;
});