define("ln-ember-contact-menu/components/contact-menu-handle", ["exports", "@ember/component", "@ember/object", "@ember/service", "@ember/object/internals", "ln-ember-contact-menu/templates/components/contact-menu-handle"], function (_exports, _component, _object, _service, _internals, _contactMenuHandle) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let ContactMenuHandleComponent = _exports.default = (_dec = (0, _service.inject)('micro-menu'), _dec2 = (0, _object.computed)('microMenu.elementId'), _dec3 = (0, _object.computed)('microMenu.isEnabled', 'disabled'), _class = class ContactMenuHandleComponent extends _component.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "microMenu", _descriptor, this);
      _defineProperty(this, "tagName", '');
      _defineProperty(this, "layout", _contactMenuHandle.default);
      _defineProperty(this, "_elementId", (0, _internals.guidFor)(this));
    }
    get isOpen() {
      return this.microMenu.elementId === this._elementId;
    }
    get isEnabled() {
      return this.microMenu.isEnabled && !this.disabled;
    }
    openContactMenu(component, e) {
      // prevents transition in wrapper components
      e.stopPropagation();
      if (this.microMenu.isEnabled) {
        this.microMenu.open(this._elementId, component);
      }
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "microMenu", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "isOpen", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "isOpen"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isEnabled", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "isEnabled"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "openContactMenu", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "openContactMenu"), _class.prototype), _class);
});