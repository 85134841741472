define("ln-ember-data-models/serializers/application", ["exports", "ember-data", "@ember/application", "@ember/debug", "@ember/object", "@ember/polyfills", "@ember/string", "ember-inflector"], function (_exports, _emberData, _application, _debug, _object, _polyfills, _string, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    RESTSerializer,
    EmbeddedRecordsMixin
  } = _emberData.default;

  /**
   * @class ApplicationSerializer
   * @extends DS.RESTSerializer
   */
  var _default = _exports.default = RESTSerializer.extend(EmbeddedRecordsMixin, {
    config: (0, _object.computed)(function () {
      const config = (0, _application.getOwner)(this).resolveRegistration('config:environment');
      return config.APP.config;
    }),
    isNewSerializerAPI: true,
    normalizeArrayResponse(store, typeClass, payload) {
      const meta = (0, _polyfills.assign)(payload.meta, {
        summary: payload.summary || {}
      });
      if (typeof meta.count !== 'undefined') {
        meta.result_count = meta.count;
      } else if (typeof meta.result_count !== 'undefined') {
        meta.count = meta.result_count;
      }
      const normalized = {
        meta,
        [this.typeKey(typeClass)]: payload.result
      };
      return this._super(store, typeClass, normalized);
    },
    normalizeSingleResponse(store, typeClass, payload, id, requestType) {
      payload = {
        meta: payload.meta || {},
        [this.typeKey(typeClass)]: payload
      };
      return this._super(store, typeClass, payload, id, requestType);
    },
    normalizeDeleteRecordResponse(/* store, primaryModelClass, payload, id, requestType */
    ) {
      return {
        meta: []
      };
    },
    typeKey(typeClass) {
      return (0, _emberInflector.pluralize)(typeClass.modelName);
    },
    serializeIntoHash(hash, typeClass, snapshot, options) {
      const serialized = this.serialize(snapshot, options);
      Object.keys(serialized).forEach(key => {
        if ((0, _object.get)(typeClass, 'relationshipNames.hasMany').includes(key)) {
          hash[key] = null;
          return;
        }
        if ((0, _object.get)(typeClass, 'relationshipNames.belongsTo').includes(key)) {
          hash[key] = null;
          return;
        }
        hash[key] = serialized[key];
      });
    },
    serialize(snapshot, options) {
      const json = this._super(snapshot, options);
      json[(0, _object.get)(this, 'primaryKey')] = Number(snapshot.id);
      return json;
    },
    extractErrors(store, typeClass, payload) {
      return (payload.errors || []).filterBy('reference').reduce((out, {
        reference,
        message
      }) => {
        out[reference] = message;
        return out;
      }, {});
    },
    extractRelationships(modelClass, resourceHash) {
      const relationships = {};
      modelClass.eachRelationship(function (key, relationshipMeta) {
        let relationship = null;
        if (relationshipMeta.kind === 'hasMany' && !resourceHash[key]) {
          const related = this.buildRelationshipLink(key, relationshipMeta, modelClass, resourceHash);
          if (related) {
            relationship = {
              links: {
                related
              }
            };
          }
        } else if (relationshipMeta.kind === 'hasMany') {
          relationship = {
            data: resourceHash[key]
          };
        } else if (relationshipMeta.kind === 'belongsTo') {
          relationship = {
            data: resourceHash[key]
          };
        }
        if (relationship) {
          relationships[key] = relationship;
        }
      }, this);
      return relationships;
    },
    buildRelationshipLink(key, relationshipMeta, modelClass, resourceHash) {
      const {
        modelName
      } = modelClass;
      const baseUrl = this.baseUrlFor(modelName);
      const routelink = (0, _object.get)(resourceHash, `links.${key}`);
      if (routelink) {
        return baseUrl + routelink;
      }
      const linkKey = this.keyForLink(key, relationshipMeta.kind);
      const contextType = (0, _object.get)(relationshipMeta, 'options.contextType');
      if (contextType) {
        return `${baseUrl}/${linkKey}?context_type=${contextType}&context=${resourceHash.id}`;
      }
      if (key === 'files' || key === 'participants' || key === 'likes' || key === 'contracts') {
        const resource = this.pathForType(modelName);
        return `${baseUrl}/${resource}/${resourceHash.id}/${linkKey}`;
      }
      const [, actualModelName] = modelClass.modelName.split('/');
      return `${baseUrl}/${linkKey}?${actualModelName}=${resourceHash.id}`;
    },
    pathForType(type) {
      (false && !(type.match('^[^/]+/[^/]+$')) && (0, _debug.assert)('All models must have a valid namespace: <api>/<resource>', type.match('^[^/]+/[^/]+$')));
      const [, path] = type.split('/');
      return (0, _emberInflector.pluralize)((0, _string.underscore)(path));
    },
    baseUrlFor(modelName = '') {
      (false && !(modelName.match('^[^/]+/[^/]+$')) && (0, _debug.assert)('All models must have a valid namespace: <api>/<resource>', modelName.match('^[^/]+/[^/]+$')));
      const [api] = modelName.split('/');
      const {
        url,
        version
      } = this.get(`config.api-${api}`);
      (false && !(url && version) && (0, _debug.assert)(`There is no valid config for "api-${api}" for model "${modelName}"`, url && version));
      return `${url}/${version}`;
    },
    /**
     * If an attribute is readonly, it won't be serialized.
     * Usage: In the model set for example:
     * ```
     *   name: attr('string', { readOnly: true })
     * ```
     *
     * @param {DS.Snapshot} snapshot
     * @param {Object} json
     * @param {String} key
     * @param {Object} attribute
     */
    serializeAttribute(snapshot, json, key, attribute) {
      const isReadOnly = (0, _object.get)(attribute, 'options.readOnly') === undefined ? false : (0, _object.get)(attribute, 'options.readOnly');
      if (isReadOnly) {
        json[key] = null;
        return;
      }
      this._super(...arguments);
    },
    /**
     * If an hasMany attribute is readonly, it won't be serialized.
     * Usage: In the model set for example:
     * ```
     *   name: hasMany('className', { readOnly: true })
     * ```
     *
     * @param {DS.Snapshot} snapshot
     * @param {Object} json
     * @param {Object} relationship
     */
    serializeHasMany(snapshot, json, relationship) {
      const isReadOnly = (0, _object.get)(relationship, 'options.readOnly') === undefined ? false : (0, _object.get)(relationship, 'options.readOnly');
      if (isReadOnly) {
        json[relationship.key] = null;
        return;
      }
      this._super(...arguments);
    },
    /**
     * If an belongsTo attribute is readonly, it won't be serialized.
     * Usage: In the model set for example:
     * ```
     *   name: belongsTo('className', { readOnly: true })
     * ```
     *
     * @param {DS.Snapshot} snapshot
     * @param {Object} json
     * @param {Object} relationship
     */
    serializeBelongsTo(snapshot, json, relationship) {
      const isReadOnly = (0, _object.get)(relationship, 'options.readOnly') === undefined ? true : (0, _object.get)(relationship, 'options.readOnly');
      if (isReadOnly) {
        json[relationship.key] = null;
        return;
      }
      this._super(...arguments);
    },
    /**
     * Overwrites the requirement for the relationship to be explicitly stated.
     * https://github.com/emberjs/data/blob/v2.15.3/addon/serializers/embedded-records-mixin.js#L527
     * @param {*} attr
     * @returns {Object}
     */
    attrsOption(attr) {
      return this._super(attr) || {
        serialize: 'records',
        deserialize: 'records'
      };
    }
  });
});