define("ln-ember-data-models/models/myliga/salary", ["exports", "ember-data", "ember-data-model-fragments/attributes"], function (_exports, _emberData, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint ligadigital/ember-model-version-attribute: Off */

  const {
    attr,
    Model
  } = _emberData.default;

  /**
   * @class Myliga/SalaryModel
   * @extends DS.Model
   */
  var _default = _exports.default = Model.extend({
    /**
     * @memberof Myliga/SalaryModel
     * @instance
     *
     * @type {Number}
     */
    weekly_hours: attr('number'),
    /**
     * @memberof Myliga/SalaryModel
     * @instance
     *
     * @type {Number}
     */
    employee_monthly_gross_salary: attr('number'),
    /**
     * @memberof Myliga/SalaryModel
     * @instance
     *
     * @type {Number}
     */
    employer_monthly_gross_salary: attr('number'),
    /**
     * @memberof Myliga/SalaryModel
     * @instance
     *
     * @type {Number}
     */
    employer_annual_gross_salary: attr('number'),
    /**
     * @memberof Myliga/SalaryModel
     * @instance
     *
     * @type {Number}
     */
    christmas_gross_bonus: attr('number'),
    /**
     * @memberof Myliga/SalaryModel
     * @instance
     *
     * @type {String}
     */
    start_date: attr('date-string'),
    /**
     * @memberof Myliga/SalaryModel
     * @instance
     *
     * @type {MyLiga/fragments/UserMetaFragment}
     */
    user: (0, _attributes.fragment)('myliga/fragments/user-meta')
  });
});