define("ln-ember-data-models/models/vdc/store", ["exports", "ember-data", "ember-data-model-fragments/attributes", "@ember/object", "ln-ember-data-models/utils/enum-option"], function (_exports, _emberData, _attributes, _object, _enumOption) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.storeStatuses = _exports.default = _exports.assemblyTargetTypes = _exports.aggregatedAssembliesStatuses = void 0;
  const {
    Model,
    belongsTo,
    attr,
    hasMany
  } = _emberData.default;

  /**
   * @name Vdc/StoreModel/aggregatedAssembliesStatuses
   * @type {EnumOption[]}
   */
  const aggregatedAssembliesStatuses = _exports.aggregatedAssembliesStatuses = [_enumOption.default.create({
    id: 'NotAssembled'
  }), _enumOption.default.create({
    id: 'Assembled'
  }), _enumOption.default.create({
    id: 'Approved'
  }), _enumOption.default.create({
    id: 'NotApproved'
  }), _enumOption.default.create({
    id: 'Estimated'
  }), _enumOption.default.create({
    id: 'AdaptWithComments'
  }), _enumOption.default.create({
    id: 'Revised'
  }), _enumOption.default.create({
    id: 'Ordered'
  }), _enumOption.default.create({
    id: 'Proposal'
  })];

  /**
   * @name Vdc/StoreModel/assemblyTargetTypes
   * @type {EnumOption[]}
   */
  const assemblyTargetTypes = _exports.assemblyTargetTypes = [_enumOption.default.create({
    id: 'Fixture'
  }), _enumOption.default.create({
    id: 'Store'
  }), _enumOption.default.create({
    id: 'Window'
  })];

  /**
   * @name Vdc/StoreModel/storeStatuses
   * @type {EnumOption[]}
   */
  const storeStatuses = _exports.storeStatuses = [{
    id: 'closed',
    name: 'Closed'
  }, {
    id: 'in_progress',
    name: 'In Progress'
  }, {
    id: 'opened',
    name: 'Open'
  }];

  /**
   * @class Vdc/StoreModel
   * @extends DS.Model
   */
  const StoreModel = Model.extend({
    /**
     * @memberof Vdc/StoreModel
     * @instance
     *
     * @type {Number}
     */
    version: attr('number'),
    /**
     * @memberof Vdc/StoreModel
     * @instance
     *
     * @type {String}
     */
    name: attr('nullable-string'),
    /**
     * @memberof Vdc/StoreModel
     * @instance
     *
     * @type {Number}
     */
    store_type_id: attr('id'),
    /**
     * @memberof Vdc/StoreModel
     * @instance
     *
     * @type {Number}
     */
    store_subtype_id: attr('id'),
    /**
     * @memberof Vdc/StoreModel
     * @instance
     *
     * @type {String}
     */
    store_type_name: attr('nullable-string'),
    /**
     * @memberof Vdc/StoreModel
     * @instance
     *
     * @type {String}
     */
    store_subtype_name: attr('nullable-string'),
    /**
     * @memberof Vdc/StoreModel
     * @instance
     *
     * @type {String}
     */
    opening_date: attr('date-string'),
    /**
     * @memberof Vdc/StoreModel
     * @instance
     *
     * @type {String}
     */
    closing_date: attr('date-string'),
    /**
     * @memberof Vdc/StoreModel
     * @instance
     *
     * @type {Number}
     */
    country_id: attr('id'),
    /**
     * @memberof Vdc/StoreModel
     * @instance
     *
     * @type {String}
     */
    number: attr('nullable-string'),
    /**
     * @memberof Vdc/StoreModel
     * @instance
     *
     * @type {Number}
     */
    client_id: attr('id'),
    /**
     * @memberof Vdc/StoreModel
     * @instance
     *
     * @type {transforms/number-array}
     */
    language_ids: attr('number-array', {
      defaultValue: () => []
    }),
    /**
     * @memberof Vdc/StoreModel
     * @instance
     *
     * @type {String}
     */
    delivery_number: attr('nullable-string'),
    /**
     * @memberof Vdc/StoreModel
     * @instance
     *
     * @type {String}
     */
    last_pos_update: attr('nullable-string'),
    /**
     * @memberof Vdc/StoreModel
     * @instance
     *
     * @type {String}
     */
    last_pos_update_completed: attr('nullable-string'),
    /**
     * @memberof Vdc/StoreModel
     * @instance
     *
     * @type {EnumString}
     */
    status: attr('enum-string', {
      options: storeStatuses
    }),
    /**
     * @memberof Vdc/StoreModel
     * @instance
     *
     * @type {Boolean}
     */
    default_address_is_delivery_address: attr('boolean', {
      defaultValue: true
    }),
    /**
     * @memberof Vdc/StoreModel
     * @instance
     *
     * @type {Boolean}
     */
    default_address_is_invoice_address: attr('boolean', {
      defaultValue: true
    }),
    /**
     * @memberof Vdc/StoreModel
     * @instance
     *
     * @type {Number}
     */
    default_address_id: attr('id'),
    /**
     * @memberof Vdc/StoreModel
     * @instance
     *
     * @type { Number }
     */
    delivery_address_id: attr('id'),
    /**
     * @memberof Vdc/StoreModel
     * @instance
     *
     * @type { Number }
     */
    invoice_address_id: attr('id'),
    /**
     * @memberof Vdc/StoreModel
     * @instance
     *
     * @type {Number}
     */
    budget: attr('number'),
    /**
     * @memberof Vdc/StoreModel
     * @instance
     *
     * @type {String}
     */
    customer_identifier: attr('nullable-string'),
    /**
     * @memberof Vdc/StoreModel
     * @instance
     *
     * @type {String}
     */
    aggregated_assembly_status: attr('enum-string', {
      options: aggregatedAssembliesStatuses
    }),
    /**
     * @memberof Vdc/StoreModel
     * @instance
     *
     * @type {Number}
     */
    aggregated_assembly_article_amount: attr('number'),
    /**
     * @memberof Vdc/StoreModel
     * @instance
     *
     * API sends a fragment of this attribute set, with only those attributes that belong to store_type
     * but with still the same id
     * If we set this as `belongsTo('vdc/attribute-set')`, then EmberData will overwrite the values.
     *
     * @type {Vdc/AttributeSetModel}
     */
    attribute_set: attr(),
    /**
     * @memberof Vdc/StoreModel
     * @instance
     *
     * @type {Vdc/AddressModel}
     */
    default_address: belongsTo('vdc/address', {
      async: false
    }),
    /**
     * @memberof Vdc/StoreModel
     * @instance
     *
     * @type {Vdc/MarketModel}
     */
    market: belongsTo('vdc/market', {
      async: false
    }),
    /**
     * @memberof Vdc/StoreModel
     * @instance
     *
     * @type {Vdc/AssemblyModel}
     */
    assembly: belongsTo('vdc/assembly'),
    /**
     * @memberof Vdc/StoreModel
     * @instance
     *
     * @type {Vdc/AddressModel}
     */
    delivery_address: belongsTo('vdc/address', {
      async: false
    }),
    /**
     * @memberof Vdc/StoreModel
     * @instance
     *
     * @type {Vdc/AddressModel}
     */
    invoice_address: belongsTo('vdc/address', {
      async: false
    }),
    /**
     * @memberof Vdc/StoreModel
     * @instance
     *
     * @type {Vdc/ContractModel}
     */
    contracts: hasMany('vdc/contract', {
      async: true
    }),
    /**
     * @memberof Vdc/StoreModel
     * @instance
     *
     * @type {Array}
     */
    pictures: hasMany('vdc/picture', {
      async: false
    }),
    /**
     * @memberof Vdc/StoreModel
     * @instance
     *
     * @type {Vdc/fragments/AttributeValueFragment[]}
     */
    attribute_values: (0, _attributes.fragmentArray)('vdc/fragments/attribute-value'),
    /**
     * @memberof Vdc/StoreModel
     * @instance
     *
     * @type {Boolean}
     */
    isClosed: (0, _object.computed)('closing_date', function () {
      const closingDate = this.get('closing_date');
      const [today] = new Date().toJSON().split('T'); // To get the timestamp as '2017-01-24'

      if (!closingDate) {
        return false;
      }
      return closingDate < today;
    }),
    /**
     * @memberof Vdc/StoreModel
     * @instance
     *
     * @type {Boolean}
     */
    isInProgress: (0, _object.computed)('opening_date', function () {
      const openingDate = this.get('opening_date');
      const [today] = new Date().toJSON().split('T'); // To get the timestamp as '2017-01-24'

      if (!openingDate) {
        return false;
      }
      return openingDate > today;
    })
  });
  StoreModel.reopenClass({
    /**
     * @memberof Vdc/StoreModel
     * @instance
     *
     * @type {EnumOption[]}
     */
    aggregatedAssembliesStatuses,
    /**
     * @memberof Vdc/StoreModel
     * @instance
     *
     * @type {EnumOption[]}
     */
    assemblyTargetTypes,
    /**
     * @memberof Vdc/StoreModel
     * @instance
     *
     * @type {EnumOption[]}
     */
    storeStatuses
  });
  var _default = _exports.default = StoreModel;
});