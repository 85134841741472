define("ln-ember-contact-menu/components/clap-form", ["exports", "@ember/component", "@ember/service", "@ember/object", "@ember/utils", "@ember/object/proxy", "@ember/object/promise-proxy-mixin", "ln-ember-contact-menu/templates/components/clap-form"], function (_exports, _component, _service, _object, _utils, _proxy, _promiseProxyMixin, _clapForm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const PromiseObject = _proxy.default.extend(_promiseProxyMixin.default);
  var _default = _exports.default = _component.default.extend({
    store: (0, _service.inject)(),
    api: (0, _service.inject)(),
    layout: _clapForm.default,
    classNames: ['clap-form-component'],
    /**
     * Represent employee to be clapped
     *
     * @type Models/Myliga/Employee
     */
    employee: null,
    /**
     * Clap creation response
     *
     * @type Object
     */
    result: null,
    isDisabled: (0, _object.computed)('text', function () {
      return (0, _utils.isBlank)(this.get('text'));
    }),
    userImages: (0, _object.computed)('employee.picture.preview_url', 'leader.picture.preview_url', function () {
      const dummyImage = 'assets/images/user.svg';
      return {
        employee: this.get('employee.picture.preview_url') || dummyImage,
        leader: this.get('leader.picture.preview_url') || dummyImage
      };
    }),
    leader: (0, _object.computed)('employee', function () {
      const userId = this.get('employee.id');
      return PromiseObject.create({
        promise: this._loadEmployee(userId)
      });
    }),
    _loadEmployee(userId) {
      return this.get('api').read('myliga', ['users', {
        subordinate: userId
      }]).then(({
        result
      }) => result && result[0]);
    },
    actions: {
      sendClap(visibleForUser, visibleForSuperior) {
        const text = this.get('text');
        const user_id = this.get('employee.id');
        return this.get('api').create('myliga', 'hr/claps', {
          id: null,
          version: null,
          text,
          user_id,
          visible_for_superior: visibleForSuperior,
          visible_for_user: visibleForUser
        }).then(result => {
          this.set('result', result);
        });
      }
    }
  });
});