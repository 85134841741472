define("ln-ember-data-models/models/vdc/quality-audit", ["exports", "ember-data", "ln-ember-data-models/utils/enum-option"], function (_exports, _emberData, _enumOption) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.statusOptions = _exports.default = void 0;
  const {
    attr,
    belongsTo,
    hasMany,
    Model
  } = _emberData.default;

  /**
   * @name Vdc/QualityAuditModel/statusOptions
   * @type {EnumOption[]}
   */
  const statusOptions = _exports.statusOptions = [_enumOption.default.create({
    id: 'Open'
  }), _enumOption.default.create({
    id: 'Closed'
  }), _enumOption.default.create({
    id: 'InProgress'
  })];

  /**
   * @class Vdc/QualityAuditModel
   * @extends DS.Model
   */
  const QualityAuditModel = Model.extend({
    /**
     * @memberof Vdc/QualityAuditModel
     * @instance
     *
     * @type {Number}
     */
    version: attr('number'),
    /**
     * @memberof Vdc/QualityAuditModel
     * @instance
     *
     * @type {String}
     */
    date_created: attr('date-time-string'),
    /**
     * @memberof Vdc/QualityAuditModel
     * @instance
     *
     * @type {String}
     */
    date_changed: attr('date-time-string'),
    /**
     * @memberof Vdc/QualityAuditModel
     * @instance
     *
     * @type {Number}
     */
    created_by: attr('id'),
    /**
     * @memberof Vdc/QualityAuditModel
     * @instance
     *
     * @type {Number}
     */
    changed_by: attr('id'),
    /**
     * @memberof Vdc/QualityAuditModel
     * @instance
     *
     * @type {Number}
     */
    project_id: attr('id'),
    /**
     * @memberof Vdc/QualityAuditModel
     * @instance
     *
     * @type {Number}
     */
    store_id: attr('id'),
    /**
     * @memberof Vdc/QualityAuditModel
     * @instance
     *
     * @type {String}
     */
    store_name: attr('nullable-string'),
    /**
     * @memberof Vdc/QualityAuditModel
     * @instance
     *
     * @type {String}
     */
    country_name: attr('nullable-string'),
    /**
     * @memberof Vdc/QualityAuditModel
     * @instance
     *
     * @type {String}
     */
    status: attr('enum-string', {
      options: statusOptions
    }),
    /**
     * @memberof Vdc/QualityAuditModel
     * @instance
     *
     * @type {Vdc/UserModel}
     */
    created_by_user: belongsTo('vdc/user'),
    /**
     * @memberof Vdc/QualityAuditModel
     * @instance
     *
     * @type {QualityAuditPositionModel[]}
     */
    quality_audit_positions: hasMany('vdc/quality-audit-position', {
      async: true
    })
  });
  QualityAuditModel.reopenClass({
    /**
     * @memberof Vdc/QualityAuditModel
     * @static
     *
     * @type {EnumOption[]}
     */
    statusOptions
  });
  var _default = _exports.default = QualityAuditModel;
});