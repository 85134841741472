define("ln-ember-form-elements/templates/components/form-elements/dropdown-button/dropdown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "1b3JQF6U",
    "block": "[[[18,1,null]],[\"&default\"],false,[\"yield\"]]",
    "moduleName": "ln-ember-form-elements/templates/components/form-elements/dropdown-button/dropdown.hbs",
    "isStrictMode": false
  });
});