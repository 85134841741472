define("ln-ember-toolkit/services/rights", ["exports", "@ember/array/proxy", "@ember/object", "@ember/object/promise-proxy-mixin", "@ember/service"], function (_exports, _proxy, _object, _promiseProxyMixin, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const PromiseArray = _proxy.default.extend(_promiseProxyMixin.default);

  /**
   * @class Rights
   * @extends Ember.Service
   */
  var _default = _exports.default = _service.default.extend({
    api: (0, _service.inject)(),
    rights: (0, _object.computed)(function () {
      const promise = this.api.read('myliga', ['rights', {
        permission: true
      }]).then(resp => {
        return resp.result;
      });
      return PromiseArray.create({
        promise
      });
    }),
    /**
     * @memberof Rights
     * @instance
     *
     * @method
     * @param  {String} type
     * @param  {String} resource
     * @return {Ember.RSVP.Promise}
     */
    can(type, resource) {
      return this.rights.then(rights => {
        const right = rights.filterBy('type', type).filterBy('resource', resource);
        return right.length > 0;
      });
    },
    /**
     * @memberof Rights
     * @instance
     *
     * @method
     */
    reset() {
      this.notifyPropertyChange('rights');
    }
  });
});