define("ln-ember-contact-menu/templates/components/micro-menu-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "AKrNsRbD",
    "block": "[[[10,3],[15,6,[30,0,[\"href\"]]],[15,\"target\",[30,0,[\"target\"]]],[12],[1,\"\\n\"],[41,[30,1],[[[1,\"    \"],[10,\"i\"],[15,0,[29,[\"micro-menu--icon \",[52,[30,0,[\"hasIcon\"]],\"fake-icon\"]]]],[12],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,\"i\"],[15,0,[29,[\"micro-menu--icon \",[36,1],\"-icon\"]]],[12],[13],[1,\"\\n\"]],[]]],[1,\"\\n  \"],[10,\"section\"],[12],[1,\"\\n    \"],[10,\"h4\"],[14,0,\"micro-menu-item--title\"],[15,\"title\",[30,0,[\"titleTooltip\"]]],[12],[1,\"\\n      \"],[41,[51,[30,1]],[[[1,[30,0,[\"title\"]]]],[]],null],[1,\"\\n    \"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"description\"]],[[[1,\"      \"],[10,2],[14,0,\"micro-menu-item--description\"],[15,\"title\",[30,0,[\"descriptionTooltip\"]]],[12],[1,\"\\n        \"],[41,[51,[30,1]],[[[1,[30,0,[\"description\"]]]],[]],null],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@isFake\"],false,[\"if\",\"name\",\"unless\"]]",
    "moduleName": "ln-ember-contact-menu/templates/components/micro-menu-item.hbs",
    "isStrictMode": false
  });
});