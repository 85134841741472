define("ln-ember-data-models/models/vdc/fragments/article-review-marketing-object", ["exports", "ember-data", "ember-data-model-fragments/fragment", "@ember/object"], function (_exports, _emberData, _fragment, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    attr
  } = _emberData.default;

  /**
   * @typedef {Object} VdcArticleReviewMarketingObjectFragmentModelAttributes
   * @property {number} id
   * @property {number} marketing_category_id
   * @property {string} marketing_category_name
   * @property {string} name
   */

  /**
   * @class VdcArticleReviewMarketingObjectFragment
   * @extends Fragment
   */
  const VdcArticleReviewMarketingObjectFragment = _fragment.default.extend({
    /**
     * @memberof VdcArticleReviewMarketingObjectFragment
     * @instance
     *
     * @type {VdcArticleReviewMarketingObjectFragmentModelAttributes}
     */
    model_attributes: attr('raw'),
    /**
     * @memberof VdcArticleReviewMarketingObjectFragment
     * @instance
     *
     * @type {Number}
     */
    position: attr('number'),
    marketing_object: (0, _object.computed)('_marketingObjectLoaded', 'model_attributes.id', function () {
      if (!this.model_attributes.id) {
        return null;
      }
      const marketingObject = this.store.peekRecord('vdc/marketing-object', this.model_attributes.id);
      if (!marketingObject && !this._marketingObjectLoaded) {
        return this.store.findRecord('vdc/marketing-object', this.model_attributes.id).finally(() => {
          // Side-effect is needed for caching
          // eslint-disable-next-line ember/no-side-effects
          this.set('_marketingObjectLoaded', true);
        });
      }
      return marketingObject;
    }),
    _marketingObjectLoaded: null
  });
  var _default = _exports.default = VdcArticleReviewMarketingObjectFragment;
});