define("ln-ember-toolkit/utils/format-compact-price", ["exports", "ln-ember-toolkit/utils/format-compact-number", "ln-ember-toolkit/utils/format-number"], function (_exports, _formatCompactNumber, _formatNumber) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = formatCompactPrice;
  /**
    1234.56 → '1,2k€'
    123456 → '123,5k€'
    123456789 → '123,5m€'
  */
  function formatCompactPrice(rawPrice, options = {}) {
    const {
      currency = true
    } = options;
    if (rawPrice === null || typeof rawPrice === 'undefined') {
      return '';
    }
    const strVal = String(rawPrice).trim();
    if (!(0, _formatNumber.isNumeric)(strVal)) {
      return strVal;
    }
    const localized = (0, _formatCompactNumber.default)(rawPrice);
    if (localized === '') {
      return '';
    }
    return `${localized}${currency ? '€' : ''}`;
  }
});