define("ln-ember-form-elements/utils/tabbable-element", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.nextTabbableElementFor = nextTabbableElementFor;
  _exports.previousTabbableElementFor = previousTabbableElementFor;
  function tabIndex(element) {
    return Number(element.getAttribute('tabindex')) || 0;
  }
  function uniqueTabIndexes(elements, {
    reversed = false
  } = {}) {
    return elements.reduce((idxs, element) => {
      const idx = tabIndex(element);
      if (!idxs.includes(idx)) {
        idxs.push(idx);
      }
      idxs.sort();
      if (reversed) {
        idxs.reverse();
      }
      return idxs;
    }, []);
  }
  function findFirstSame(elements, index) {
    return elements.find(element => tabIndex(element) === index);
  }
  const querySelectables = element => {
    const selectors = ['[tabindex]:not([tabindex="-1"])', 'input:not([tabindex="-1"])', 'select:not([tabindex="-1"])', 'textarea:not([tabindex="-1"])', 'button:not([tabindex="-1"])', 'object:not([tabindex="-1"])', 'a[href]:not([tabindex="-1"])', 'area[href]:not([tabindex="-1"])'];
    const originalId = element.id;
    if (element && element.tagName.toLowerCase() !== 'body') {
      if (element.id === '') {
        element.id = `random-id-${new Date().getTime()}-${Math.floor(Math.random() * 1000)}`;
      }
      selectors.push(`#${element.id}`);
    }
    const selectables = Array.prototype.slice.call(document.querySelectorAll(selectors.join(',')));
    if (element.id !== originalId) {
      element.id = originalId;
    }
    return selectables;
  };
  function findTabbableElemementFor(element, dir) {
    if (!element) {
      return document.querySelector('body');
    }
    dir = dir || 1;
    const elementTabIndex = tabIndex(element);
    const selectables = querySelectables(element);
    if (dir === -1) {
      selectables.reverse();
    }
    const selectablesNext = selectables.slice(selectables.indexOf(element) + 1);
    const nextSame = findFirstSame(selectablesNext, elementTabIndex);
    if (nextSame) {
      return nextSame;
    }
    const nextTabIndex = uniqueTabIndexes(selectables, {
      reversed: dir === -1
    }).find(idx => dir === 1 ? idx > elementTabIndex : idx < elementTabIndex);
    const nextHigh = findFirstSame(selectables, nextTabIndex);
    if (nextHigh) {
      return nextHigh;
    }
    return document.querySelector('body');
  }
  function nextTabbableElementFor(element) {
    return findTabbableElemementFor(element, 1);
  }
  function previousTabbableElementFor(element) {
    return findTabbableElemementFor(element, -1);
  }
});