define("ln-ember-form-elements/services/form-elements", ["exports", "@ember/service", "@ember/polyfills"], function (_exports, _service, _polyfills) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  /**
   * @class FormElementsService
   * @extends Ember/Service
   */
  class FormElementsService extends _service.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "defaults", {
        locale: 'de',
        currency: 'EUR',
        formatDate: 'YYYY-MM-DD',
        formatDateTime: 'YYYY-MM-DD HH:mm',
        formatDateWeek: 'YYYY-[CW]WW',
        theme: 'light',
        removeSuccessAfter: 5000,
        removeWarningAfter: null,
        removeErrorAfter: null,
        minimumResultsForSearch: 10
      });
    }
    /**
     * Allows to override certain default values for all form elements.
     *
     * ### Example:
     *
     * ```JavaScript
     * this.get('formElements').setDefaults({
     *   formatDate: 'YYYY/MM/DD',
     *   theme: 'dark'
     * })
     * ```
     *
     * Default values are:
     *
     * ```JavaScript
     * {
     *   formatDate: 'YYYY-MM-DD',
     *   formatDateTime: 'YYYY-MM-DD HH:mm',
     *
     *   theme: 'light',
     *
     *   // Time after which messages are removed
     *   removeSuccessAfter: 5000,
     *   removeWarningAfter: null,
     *   removeErrorAfter: null,
     *
     *   minimumResultsForSearch: 10
     * }
     * ```
     *
     * @memberof FormElementsService
     * @instance
     *
     * @type {function}
     * @param {object} defaults
     */
    setDefaults(defaults) {
      this.set('defaults', (0, _polyfills.assign)({}, this.get('defaults'), defaults));
    }
  }
  _exports.default = FormElementsService;
});