define("ln-ember-form-elements/components/form-elements/button", ["exports", "rsvp", "@ember/component", "@ember/object", "@ember/object/internals", "@ember-decorators/component", "ln-ember-form-elements/templates/components/form-elements/button"], function (_exports, _rsvp, _component, _object, _internals, _component2, _button) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _class2; // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  let Button = _exports.default = (_dec = (0, _component2.tagName)(''), _dec2 = (0, _object.computed)('id'), _dec(_class = (_class2 = class Button extends _component.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "layout", _button.default);
      _defineProperty(this, "id", void 0);
      _defineProperty(this, "onClick", void 0);
      _defineProperty(this, "type", null);
      _defineProperty(this, "_processing", false);
    }
    get _elementId() {
      return this.id ?? `button-${(0, _internals.guidFor)(this)}`;
    }
    interruptionHandler(e, ...args) {
      if (typeof this.onClick !== 'function') {
        return;
      }
      e.preventDefault();
      (0, _object.set)(this, '_processing', true);
      return (0, _rsvp.resolve)().then(() => {
        return this.onClick(e, ...args);
      }).finally(() => {
        if (!this.isDestroyed) {
          (0, _object.set)(this, '_processing', false);
        }
      });
    }
  }, _applyDecoratedDescriptor(_class2.prototype, "_elementId", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "_elementId"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "interruptionHandler", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "interruptionHandler"), _class2.prototype), _class2)) || _class);
});