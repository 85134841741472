define("ln-ember-form-elements/components/form-elements/input", ["exports", "@ember/object", "@ember/runloop", "@ember/object/internals", "@ember/component", "@ember-decorators/component", "ln-ember-form-elements/templates/components/form-elements/input"], function (_exports, _object, _runloop, _internals, _component, _component2, _input) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _class2; // @ts-ignore
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  let FormElementsInput = _exports.default = (_dec = (0, _component2.tagName)(''), _dec2 = (0, _object.computed)(), _dec3 = (0, _object.computed)('readonly'), _dec4 = (0, _object.computed)('hasFocus', 'value', 'serialize'), _dec5 = (0, _object.computed)('maxLength', 'displayValue.length'), _dec(_class = (_class2 = class FormElementsInput extends _component.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "layout", _input.default);
      _defineProperty(this, "onInput", void 0);
      _defineProperty(this, "onFocusIn", void 0);
      _defineProperty(this, "onFocusOut", void 0);
      _defineProperty(this, "serialize", void 0);
      _defineProperty(this, "appendElement", null);
      _defineProperty(this, "prependElement", null);
      _defineProperty(this, "autoFocus", null);
      _defineProperty(this, "hasFocus", false);
      _defineProperty(this, "type", 'text');
      _defineProperty(this, "autocomplete", 'on');
      _defineProperty(this, "readonly", false);
      _defineProperty(this, "value", null);
      _defineProperty(this, "maxLength", void 0);
    }
    didInsertElement() {
      this._super();
      if (this.autoFocus) {
        (0, _runloop.next)(this, this.forceFocus);
      }
    }
    get inputId() {
      return `${(0, _internals.guidFor)(this)}-input`;
    }
    get readonlyAttribute() {
      return Boolean(this.get('readonly')) || undefined;
    }
    get displayValue() {
      if (!['', null, undefined].includes(this.value) && typeof this.serialize === 'function' && (this.readonly || !this.hasFocus)) {
        return this.serialize(this.value);
      } else {
        return this.value;
      }
    }
    get _remainingCharacters() {
      if (this.maxLength) {
        return this.maxLength - (this.displayValue?.length || 0);
      }
      return undefined;
    }
    forceFocus() {
      if (!this.isDestroyed) {
        const input = document.getElementById(this.inputId);
        input && input.focus();
      }
    }
    _focusIn(event) {
      this.set('hasFocus', true);
      return this.onFocusIn && this.onFocusIn(event);
    }
    _focusOut(event) {
      if (this.hasFocus) {
        this.set('hasFocus', false);
        this.onFocusOut && this.onFocusOut(event);
        if (!this.readonly) {
          this.notifyPropertyChange('value');
        }
      }
    }
    _onInput(event) {
      const target = event.target;
      if (typeof this.onInput === 'function') {
        return this.onInput(target.value, event);
      } else {
        (0, _object.set)(this, 'value', event.target.value);
      }
    }
  }, _applyDecoratedDescriptor(_class2.prototype, "inputId", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "inputId"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "readonlyAttribute", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "readonlyAttribute"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "displayValue", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "displayValue"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "_remainingCharacters", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "_remainingCharacters"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "_focusIn", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "_focusIn"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "_focusOut", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "_focusOut"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "_onInput", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "_onInput"), _class2.prototype), _class2)) || _class);
});