define("ln-ember-data-models/models/vdc/country", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    attr,
    belongsTo,
    hasMany,
    Model
  } = _emberData.default;

  /**
   * @class Vdc/Country
   * @extends DS.Model
   */
  var _default = _exports.default = Model.extend({
    /**
     * @memberof Vdc/Country
     * @instance
     *
     * @type {Number}
     */
    version: attr('number'),
    /**
     * @memberof Vdc/Country
     * @instance
     *
     * @type {String}
     */
    name: attr('nullable-string'),
    /**
     * @memberof Vdc/Country
     * @instance
     *
     * @type {String}
     */
    code: attr('nullable-string'),
    /**
     * @memberof Vdc/Country
     * @instance
     *
     * @type {Number}
     */
    pictureId: attr('number'),
    /**
     * @memberof Vdc/Country
     * @instance
     *
     * @type {Number}
     */
    latitude: attr('number'),
    /**
     * @memberof Vdc/Country
     * @instance
     *
     * @type {Number}
     */
    longitude: attr('number'),
    /**
     * @memberof Vdc/Country
     * @instance
     *
     * @type {Boolean}
     */
    eu: attr('boolean', {
      defaultValue: () => false
    }),
    /**
     * @memberof Vdc/Country
     * @instance
     *
     * @type {transforms/number-array}
     */
    language_ids: attr('number-array', {
      defaultValue: () => []
    }),
    /**
     * @memberof Vdc/Country
     * @instance
     *
     * @type {Vdc/PictureModel}
     */
    picture: belongsTo('vdc/picture'),
    /**
     * @memberof Vdc/Country
     * @instance
     *
     * @type {Vdc/Contact}
     */
    contact: belongsTo('vdc/contact', {
      async: false
    }),
    /**
     * @memberof Vdc/Country
     * @instance
     *
     * @type {Vdc/Language[]}
     */
    languages: hasMany('vdc/language', {
      async: true
    })
  });
});