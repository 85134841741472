define("ln-ember-form-elements/templates/components/form-elements/percent", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "iQ7jUe+9",
    "block": "[[[44,[\"FormPercent\",[50,\"form-elements/number\",0,null,null]],[[[1,\"  \"],[8,[30,2],[[17,3],[16,0,[29,[[30,4],\" \",[30,1]]]]],[[\"@appendElement\",\"@autocomplete\",\"@autofocus\",\"@deserialize\",\"@disabled\",\"@error\",\"@inputScale\",\"@label\",\"@max\",\"@maxlength\",\"@min\",\"@name\",\"@normalizeZeros\",\"@onAccept\",\"@onFocusIn\",\"@onFocusOut\",\"@onKeyDown\",\"@onKeyPress\",\"@onKeyUp\",\"@padFractionalZeros\",\"@placeholder\",\"@placeUnitBefore\",\"@prependElement\",\"@readonly\",\"@scale\",\"@serialize\",\"@shouldRound\",\"@title\",\"@unit\",\"@unitSeparator\",\"@value\",\"@warning\"],[[30,5],[30,6],[30,7],[30,0,[\"_deserialize\"]],[30,8],[30,9],[30,0,[\"_inputScale\"]],[30,10],[30,11],[30,12],[30,13],[30,14],false,[30,15],[30,16],[30,17],[30,18],[30,19],[30,20],[30,21],[30,22],false,[30,23],[30,24],[30,0,[\"_scale\"]],[30,0,[\"_serialize\"]],[30,25],[30,26],[30,0,[\"_unit\"]],[30,0,[\"_unitSeparator\"]],[30,27],[30,28]]],null],[1,\"\\n\"]],[1,2]]]],[\"scope\",\"NumberInput\",\"&attrs\",\"@class\",\"@appendElement\",\"@autocomplete\",\"@autoFocus\",\"@disabled\",\"@error\",\"@label\",\"@max\",\"@maxLength\",\"@min\",\"@name\",\"@onAccept\",\"@onFocusIn\",\"@onFocusOut\",\"@onKeyDown\",\"@onKeyPress\",\"@onKeyUp\",\"@padFractionalZeros\",\"@placeholder\",\"@prependElement\",\"@readonly\",\"@shouldRound\",\"@title\",\"@value\",\"@warning\"],false,[\"let\",\"component\"]]",
    "moduleName": "ln-ember-form-elements/templates/components/form-elements/percent.hbs",
    "isStrictMode": false
  });
});