define("ln-ember-form-elements/helpers/is-object", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.isObject = isObject;
  function isObject([element]) {
    return typeof element === 'object' && element !== null;
  }
  var _default = _exports.default = (0, _helper.helper)(isObject);
});