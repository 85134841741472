define("ln-ember-data-models/models/vdc/fragments/article-marketing-category", ["exports", "ember-data", "ember-data-model-fragments/fragment", "@ember/object"], function (_exports, _emberData, _fragment, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    attr
  } = _emberData.default;

  /**
   * @class Vdc/fragments/ArticleMarketingCategoryFragment
   * @extends Fragment
   */
  const ArticleMarketingCategoryFragment = _fragment.default.extend({
    /**
     * @memberof Vdc/fragments/ArticleMarketingCategoryFragment
     * @instance
     *
     * @type {Number}
     */
    marketing_category_id: attr('id'),
    /**
     * @memberof Vdc/fragments/ArticleMarketingCategoryFragment
     * @instance
     *
     * @type {Number}
     */
    max_objects_amount: attr('number'),
    marketing_category: (0, _object.computed)('_marketingCategoryLoaded', 'marketing_category_id', function () {
      if (!this.marketing_category_id) {
        return null;
      }
      const marketingCategory = this.store.peekRecord('vdc/marketing-category', this.marketing_category_id);
      if (!marketingCategory && !this._marketingCategoryLoaded) {
        return this.store.findRecord('vdc/marketing-category', this.marketing_category_id).finally(() => {
          // Side-effect is needed for caching
          // eslint-disable-next-line ember/no-side-effects
          this.set('_marketingCategoryLoaded', true);
        });
      }
      return marketingCategory;
    }),
    _marketingCategoryLoaded: null
  });
  var _default = _exports.default = ArticleMarketingCategoryFragment;
});