define("ln-ember-form-elements/components/form-elements/radio-group", ["exports", "@ember/component", "@ember/service", "@ember/object", "@ember/runloop", "ln-ember-form-elements/templates/components/form-elements/radio-group", "jquery"], function (_exports, _component, _service, _object, _runloop, _radioGroup, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const RadioOption = _object.default.extend({
    id: null,
    text: null,
    readonly: (0, _object.computed)('value', 'group.mandatory', function () {
      return Boolean(this.get('value') && this.get('group.mandatory'));
    }),
    value: (0, _object.computed)('id', 'group.inputValue', function () {
      return this.get('id') === this.get('group.inputValue');
    })
  });

  /**
   * @class formElements/RadioGroupComponent
   * @extends @ember/component
   */
  var _default = _exports.default = _component.default.extend({
    formElements: (0, _service.inject)(),
    layout: _radioGroup.default,
    classNames: [
    // Deprecated: { id: ln-ember-form-elements-class-names, until: 3.0.0 }
    'radio-group-component', 'form-elements--radio-group-component'],
    /**
     * Defines which attribute of an options object should be used as the value of
     * the option.<br>
     * For example if the value is the id of the object the `optionValuePath`
     * should be set to `id`
     *
     * @memberof formElements/RadioGroupComponent
     * @instance
     *
     * @type String
     * @default "value"
     */
    optionValuePath: 'value',
    /**
     * Defines which attribute of an options object should be used as the label of
     * the option.<br>
     * For example if the attribute `name` should be used as the label
     * `optionLabelPath` should be set to `name`
     *
     * @memberof formElements/RadioGroupComponent
     * @instance
     *
     * @type String
     * @default "label"
     */
    optionLabelPath: 'label',
    /**
     * An array of all options that are listed in the select field.
     *
     * @memberof formElements/RadioGroupComponent
     * @instance
     *
     * @type Array
     * @default null
     */
    options: null,
    /**
     * If the radio group is mandatory, the value cannot be unset.
     *
     * @memberof formElements/RadioGroupComponent
     * @instance
     *
     * @type Boolean
     * @default false
     */
    mandatory: false,
    /**
     * Fired when the input value changes.
     *
     * **If the `onChange` handler is set, the value does not get updated by the
     * StyledInputComponent anymore.**
     *
     * @memberof formElements/RadioGroupComponent
     * @instance
     *
     * @type {function}
     */
    onChange() {},
    formatedOptions: (0, _object.computed)('options.[]', 'optionValuePath', 'optionLabelPath', function () {
      const valuePath = this.get('optionValuePath');
      const labelPath = this.get('optionLabelPath');
      return (this.get('options') || []).map(item => {
        const option = typeof item !== 'object' ? {
          group: this,
          id: item,
          text: item
        } : {
          group: this,
          id: (0, _object.get)(item, valuePath),
          text: (0, _object.get)(item, labelPath),
          model: item
        };
        return RadioOption.create(option);
      });
    }),
    inputValue: (0, _object.computed)('value', {
      get() {
        return this.get('value');
      },
      set(k, value) {
        this.get('onChange')(value);
        return value;
      }
    }),
    actions: {
      onOptionChange(value, checked) {
        if (checked) {
          this.set('inputValue', value);
        } else if (!this.get('mandatory')) {
          (0, _runloop.debounce)(this, this.checkRemove, 10);
        }
      }
    },
    checkRemove() {
      if ((0, _jquery.default)(this.element) && (0, _jquery.default)('input:checked', this.element).length === 0) {
        this.set('inputValue', null);
      }
    }
  });
});