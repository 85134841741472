define("ln-ember-form-elements/components/form-elements/calendar/month-list", ["exports", "moment", "@ember/component", "@ember/object", "@ember/runloop", "@ember/string", "jquery", "ln-ember-form-elements/templates/components/form-elements/calendar/month-list", "ln-ember-form-elements/utils/computed-properties"], function (_exports, _moment, _component, _object, _runloop, _string, _jquery, _monthList, _computedProperties) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    abs,
    cos,
    round,
    ceil,
    floor,
    PI
  } = Math;
  var _default = _exports.default = _component.default.extend({
    theme: null,
    layout: _monthList.default,
    classNames: ['form-elements--calendar--month-list-component'],
    classNameBindings: ['animated', 'themeClass'],
    targetPosition: null,
    numberOfElments: 9,
    elementHeight: 50,
    speed: 0,
    lastTouchY: 0,
    position: (0, _computedProperties.resetOnChange)('value', 0),
    // Format: YYYY-MM
    value: (0, _computedProperties.assertValue)((0, _moment.default)().format('YYYY-MM'), function (value) {
      return /^\d\d\d\d-\d\d$/.test(value);
    }),
    // Value format: YYYY-MM
    onSelect: (0, _computedProperties.defaultAction)(function (value) {
      this.set('value', value);
    }),
    valueOffset: (0, _computedProperties.resetOnChange)('value', 0),
    lastTime: 0,
    touchMoved: false,
    // px / ms
    animationSpeed: 0.2,
    themeClass: (0, _object.computed)('theme', function () {
      return `theme-${this.get('theme') || 'light'}`;
    }),
    offset: (0, _object.computed)('position', function () {
      return this.get('position') % this.get('elementHeight');
    }),
    valueOffsetUpdate: (0, _object.observer)('position', function () {
      const position = this.get('position');
      const fn = position < 0 ? floor : ceil;
      const valueOffset = fn(this.get('position') / this.get('elementHeight') * -1);
      if (valueOffset !== this.get('valueOffset')) {
        this.set('valueOffset', valueOffset);
      }
    }),
    elements: (0, _object.computed)('numberOfElments', 'valueOffset', 'value', function () {
      return [...Array(this.get('numberOfElments'))].map((e, i) => {
        const date = this.date(i - 4 + this.get('valueOffset'));
        return {
          year: date.format('YYYY'),
          month: date.format('MMMM'),
          value: date.format('YYYY-MM')
        };
      });
    }),
    elementStyle: (0, _object.computed)('offset', function () {
      return (0, _string.htmlSafe)(`transform: translate(0, ${this.get('offset') - this.get('elementHeight')}px)`);
    }),
    stopPositionOffset: (0, _object.computed)('offset', function () {
      const offset = this.get('offset');
      let correction = 0;
      if (offset > 25) {
        correction = 50 - offset;
      } else if (offset < -25) {
        correction = (50 + offset) * -1;
      } else {
        correction = offset * -1;
      }
      return correction;
    }),
    date(idx) {
      return (0, _moment.default)(this.get('value'), 'YYYY-MM').add(idx, 'months').startOf('month');
    },
    didInsertElement() {
      this._super(...arguments);
      (0, _jquery.default)(this.element).on('wheel', event => this.onWheel(event));
      (0, _jquery.default)(this.element).on('touchstart', event => this.onTouchStart(event));
      (0, _jquery.default)(this.element).on('touchmove', event => this.onTouchMove(event));
    },
    willREmoveElement() {
      this._super(...arguments);
      (0, _jquery.default)(this.element).off('wheel touchstart touchmove');
    },
    onWheel(event) {
      event.preventDefault();
      this.stopAnimatation();
      const {
        originalEvent: {
          deltaY
        },
        timeStamp
      } = event;
      const {
        lastTime,
        position
      } = this.getProperties(['lastTime', 'position']);
      const timeDelta = lastTime - timeStamp;
      this.setProperties({
        lastTime: timeStamp,
        position: position - deltaY,
        speed: deltaY / timeDelta
      });
      (0, _runloop.debounce)(this, this.setStopPosition, 100);
    },
    onTouchStart(event) {
      event.preventDefault();
      this.stopAnimatation();
      const {
        timeStamp,
        changedTouches: [{
          clientY: touchY
        }]
      } = event;
      this.setProperties({
        lastTouchY: touchY,
        lastTime: timeStamp
      });
    },
    onTouchMove(event) {
      event.preventDefault();
      const {
        timeStamp,
        changedTouches: [{
          clientY: touchY
        }]
      } = event;
      const {
        lastTime,
        lastTouchY,
        position
      } = this.getProperties(['lastTime', 'lastTouchY', 'position']);
      const deltaY = lastTouchY - touchY;
      const timeDelta = lastTime - timeStamp;
      this.setProperties({
        lastTouchY: touchY,
        lastTime: timeStamp,
        position: position - deltaY,
        speed: deltaY / timeDelta,
        touchMoved: position !== 0 ? true : false
      });
      (0, _runloop.debounce)(this, this.setStopPosition, 100);
    },
    setStopPosition() {
      this.startAnimatation(this.get('stopPositionOffset'), () => {
        (0, _runloop.next)(() => this.get('onSelect')(this.date(this.get('valueOffset')).format('YYYY-MM'), {
          clicked: false
        }));
      });
    },
    stopAnimatation() {
      this.set('targetPosition', null);
    },
    startAnimatation(offset, done = () => {}) {
      this.set('startPosition', this.get('position'));
      this.set('targetPosition', round((this.get('position') + offset) * 50) / 50);
      const speed = this.get('animationSpeed') * ceil(abs(offset / this.get('elementHeight')));
      let animationLastTime = performance.now();
      const animate = time => {
        if (this.get('targetPosition') !== null) {
          const progress = abs((this.get('position') - this.get('startPosition')) / (this.get('targetPosition') - this.get('startPosition')));
          const easing = isNaN(progress) ? 1 : 0.5 + (cos(PI * progress) + 1) / 2;
          const distance = (time - animationLastTime) * (speed * easing);
          const targetPosition = round(this.get('targetPosition'));
          const position = isNaN(this.get('position')) ? 0 : round(this.get('position'));
          if (speed === 0 || targetPosition < position + distance && targetPosition > position - distance) {
            this.setProperties({
              targetPosition: null,
              position: targetPosition
            });
            return done();
          }
          const dir = targetPosition > position ? 1 : -1;
          this.set('position', position + dir * distance);
          animationLastTime = time;
          requestAnimationFrame(animate);
        }
      };
      requestAnimationFrame(animate);
    },
    actions: {
      onElementClick(value) {
        const touchMoved = this.get('touchMoved');
        const monthDiff = (0, _moment.default)(value, 'YYYY-MM').diff((0, _moment.default)(this.get('value'), 'YYYY-MM'), 'months');

        /*
         * Since 'touchMove' is basically animating/scrolling to the right position,
         * we just want to set the value and fire 'onSelect' in order to update
         * parent component value state.
         *
         * The reason we are triggerring this action on 'touchEnd' is because
         * mobile devices do not trigger div onclick. And the reason we are
         * using 'selectedMonth' as next value is because 'value' is basically
         * data from where touch started (where user tapped on initially).
         */
        if (touchMoved) {
          this.set('touchMoved', false);
          const selectedMonth = this.date(this.get('valueOffset')).format('YYYY-MM');
          return (0, _runloop.next)(() => this.get('onSelect')(selectedMonth, {
            clicked: true,
            touchMoved: true
          }));
        }
        if (monthDiff === 0) {
          return (0, _runloop.next)(() => this.get('onSelect')(value, {
            clicked: true
          }));
        }
        if (abs(monthDiff) > 3) {
          return (0, _runloop.next)(() => this.get('onSelect')(value, {
            clicked: true
          }));
        }
        this.startAnimatation(this.get('position') - monthDiff * this.get('elementHeight'), () => {
          (0, _runloop.next)(() => this.get('onSelect')(this.date(this.get('valueOffset')).format('YYYY-MM'), {
            clicked: true
          }));
        });
      }
    }
  });
});