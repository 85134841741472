define("ln-ember-form-elements/utils/weak-union-by", ["exports", "@ember/array", "@ember/polyfills", "ln-ember-form-elements/utils/weak-find"], function (_exports, _array, _polyfills, _weakFind) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = weakUnionBy;
  /**
   * Add unique item to collection.<br>
   * Unique is checked by the property parameter. </br>
   * Mainly used for add Options in the option list
   *
   * @param {array} collection
   * @param {array} newItems
   * @param {string} [property="id"]
   * @return {array}
   */
  function weakUnionBy(collection, newItems, property = 'id') {
    newItems.forEach(item => {
      const newItem = (0, _polyfills.assign)({}, item);
      const foundItem = (0, _weakFind.default)(collection, item, property);
      if (foundItem) {
        (0, _array.A)(collection).removeObject(foundItem);
      }
      collection.push(newItem);
    });
    return collection;
  }
});