define("ln-ember-form-elements/templates/components/form-elements/number", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "4vDB1D9l",
    "block": "[[[44,[\"FormNumber\",[50,\"form-elements/-masked-input\",0,null,null]],[[[1,\"  \"],[8,[30,2],[[16,0,[29,[[30,1],\" \",[30,3]]]],[17,4]],[[\"@appendElement\",\"@autocomplete\",\"@autofocus\",\"@label\",\"@disabled\",\"@error\",\"@maxlength\",\"@name\",\"@placeholder\",\"@prependElement\",\"@readonly\",\"@value\",\"@options\",\"@onAccept\",\"@onFocusIn\",\"@onFocusOut\",\"@onKeyDown\",\"@onKeyPress\",\"@onKeyUp\",\"@onCopy\",\"@title\",\"@warning\"],[[30,5],[30,6],[30,7],[30,8],[30,9],[30,10],[30,11],[30,12],[30,0,[\"_placeholder\"]],[30,13],[30,14],[30,0,[\"_value\"]],[30,0,[\"maskOptions\"]],[30,0,[\"_onAccept\"]],[30,15],[30,16],[30,17],[30,18],[30,19],[30,0,[\"_onCopy\"]],[30,20],[30,21]]],null],[1,\"\\n\"]],[1,2]]]],[\"scope\",\"MaskedInput\",\"@class\",\"&attrs\",\"@appendElement\",\"@autocomplete\",\"@autoFocus\",\"@label\",\"@disabled\",\"@error\",\"@maxLength\",\"@name\",\"@prependElement\",\"@readonly\",\"@onFocusIn\",\"@onFocusOut\",\"@onKeyDown\",\"@onKeyPress\",\"@onKeyUp\",\"@title\",\"@warning\"],false,[\"let\",\"component\"]]",
    "moduleName": "ln-ember-form-elements/templates/components/form-elements/number.hbs",
    "isStrictMode": false
  });
});