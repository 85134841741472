define("ln-ember-form-elements/templates/components/form-elements/calendar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "47rmejsP",
    "block": "[[[1,[28,[35,0],null,[[\"visibleDate\",\"disabled\",\"theme\",\"onNavigate\",\"onClickMonth\",\"onSelect\"],[[30,0,[\"visibleDate\"]],[30,0,[\"disabled\"]],[30,0,[\"theme\"]],[30,0,[\"onNavigate\"]],[28,[37,1],[[30,0],\"onNavigationMonthClick\"],null],[28,[37,1],[[30,0],\"onMonthChange\"],null]]]]],[1,\"\\n\\n\"],[1,[28,[35,2],null,[[\"value\",\"theme\",\"onSelect\"],[[30,0,[\"visibleMonth\"]],[30,0,[\"theme\"]],[28,[37,1],[[30,0],\"onMonthChange\"],null]]]]],[1,\"\\n\\n\"],[1,[28,[35,3],null,[[\"date\",\"range\",\"theme\",\"visibleDate\",\"disabled\",\"onSelect\",\"showCalendarWeek\",\"showWeekHighlight\",\"allowHalfDays\",\"weekStatusComponent\"],[[30,0,[\"date\"]],[30,0,[\"range\"]],[30,0,[\"theme\"]],[30,0,[\"visibleDate\"]],[30,0,[\"disabled\"]],[30,0,[\"onSelect\"]],[30,0,[\"showCalendarWeek\"]],[30,1],[30,0,[\"allowHalfDays\"]],[30,0,[\"weekStatusComponent\"]]]]]]],[\"@showWeekHighlight\"],false,[\"form-elements/calendar/navigation\",\"action\",\"form-elements/calendar/month-list\",\"form-elements/calendar/month\"]]",
    "moduleName": "ln-ember-form-elements/templates/components/form-elements/calendar.hbs",
    "isStrictMode": false
  });
});