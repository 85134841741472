define("ln-ember-form-elements/templates/components/form-elements/styled-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "NpFHkv9X",
    "block": "[[[1,\"\\n\"],[41,[30,0,[\"label\"]],[[[10,\"label\"],[15,\"for\",[28,[37,1],[[30,0,[\"elementId\"]],\"-select\"],null]],[12],[1,[30,0,[\"label\"]]],[13]],[]],null],[1,\"\\n\\n\"],[41,[30,0,[\"multiple\"]],[[[1,\"  \"],[10,1],[15,0,[29,[\"multiple-label \",[52,[30,0,[\"hasMultipleSelected\"]],\"selection\",\"placeholder\"],\" \",[52,[30,0,[\"showCount\"]],\"padded\",\"\"]]]],[12],[1,[30,0,[\"labelMultipleSelected\"]]],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[10,\"select\"],[15,1,[28,[37,1],[[30,0,[\"elementId\"]],\"-select\"],null]],[15,\"tabindex\",[30,1]],[15,\"multiple\",[30,0,[\"multiple\"]]],[14,5,\"width:100%\"],[12],[13],[1,\"\\n\"],[1,\"\\n\"],[41,[30,0,[\"showCount\"]],[[[1,\"  \"],[10,0],[14,0,\"selected-count\"],[15,\"onclick\",[28,[37,2],[[30,0],\"removeAll\"],null]],[14,\"role\",\"button\"],[12],[1,\"\\n    \"],[1,[30,0,[\"selectedCount\"]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"removeIsAllowed\"]],[[[1,\"  \"],[1,[28,[35,3],null,[[\"action\",\"icon\",\"mouse-enter\",\"mouse-leave\",\"tabindex\",\"theme\"],[[28,[37,2],[[30,0],\"removeAll\"],null],\"remove\",[28,[37,2],[[30,0],\"removeMouseEnter\"],null],[28,[37,2],[[30,0],\"removeMouseLeave\"],null],\"-1\",[30,0,[\"theme\"]]]]]],[1,\"\\n\"]],[]],null]],[\"@tabindex\"],false,[\"if\",\"concat\",\"action\",\"form-elements/styled-button\"]]",
    "moduleName": "ln-ember-form-elements/templates/components/form-elements/styled-select.hbs",
    "isStrictMode": false
  });
});