define("ln-ember-contact-menu/helpers/sanitize-phone-number", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.sanitazePhoneNumber = sanitazePhoneNumber;
  function sanitazePhoneNumber([phoneNumber]) {
    return phoneNumber.replace(/ /g, '');
  }
  var _default = _exports.default = (0, _helper.helper)(sanitazePhoneNumber);
});