define("ln-ember-form-elements/components/form-elements/date-input-base", ["exports", "moment", "@ember/object", "@ember/runloop", "@ember/debug", "@ember/utils", "ln-ember-form-elements/components/form-elements/base"], function (_exports, _moment, _object, _runloop, _debug, _utils, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Abstract base class for all date inputs.
   *
   * @class formElements/DateInputBaseComponent
   * @extends formElements/BaseComponent
   * @abstract
   */
  var _default = _exports.default = _base.default.extend({
    /**
     * @memberof formElements/DateInputBaseComponent
     * @instance
     *
     * @type {any}
     * @default null
     */
    value: null,
    /**
     * ```Handlebars
     * {{form-elements/date-input-component}}
     * ```
     *
     * @memberof formElements/DateInputBaseComponent
     * @instance
     *
     * @type {string}
     * @default null
     */
    showCalendarWeek: false,
    focusedElements: 0,
    classNames: ['form-elements--date-input-component'],
    classNameBindings: ['themeClass'],
    /**
     * @memberof formElements/DateInputComponent
     * @instance
     *
     * @type {string} - Format YYYY-MM-DD
     */
    visibleDate: (0, _object.computed)({
      get() {
        return this.getVisibleDate();
      },
      set(key, value) {
        return value;
      }
    }),
    displayValue: (0, _object.computed)('value', 'showDropDown', {
      get() {
        return this.formatDate(this.get('value'));
      },
      set(key, value) {
        return value;
      }
    }),
    showDropDown: (0, _object.computed)('disabled', 'readonly', 'focusedElements', {
      get() {
        return !(this.get('disabled') || this.get('readonly')) && this.get('focusedElements') > 0;
      },
      set(key, value) {
        return value;
      }
    }),
    didReceiveAttrs() {
      this._super(...arguments);
      const value = this.get('value');
      (false && !(this.isValueEmpty(value) || this.isValidInput(value)) && (0, _debug.assert)(`Value should be valid (${JSON.stringify(value)})`, this.isValueEmpty(value) || this.isValidInput(value)));
    },
    actions: {
      onCalendarChange() {
        throw Error('actions.onCalendarChange() needs to be implemented');
      },
      onInputChange() {
        throw Error('actions.onInputChange() needs to be implemented');
      },
      onIncrementFocusedElements() {
        this.incrementProperty('focusedElements');
      },
      onCalendarClick() {
        if (!this.showDropDown) {
          this.element.querySelector('input').focus();
        }
      },
      onDecrementFocusedElements() {
        (0, _runloop.later)(() => {
          if (this.get('isDestroyed')) {
            return;
          }
          this.decrementProperty('focusedElements');
        }, 100);
      }
    },
    getVisibleDate(/* value */
    ) {
      throw Error('getVisibleDate() needs to be implemented');
    },
    triggerChange(/* value */
    ) {
      throw Error('triggerChange() needs to be implemented');
    },
    formatDate(/* date */
    ) {
      throw Error('formatDate() needs to be implemented');
    },
    normalizeDate(date) {
      return (0, _moment.default)(date, this.get('formElements.defaults.formatDate')).format('YYYY-MM-DD');
    },
    applyTime(value, time) {
      if (value && (0, _moment.default)(value).isValid() && /^\d?\d+:\d+\d+$/.test(time)) {
        return (0, _moment.default)((0, _moment.default)(value).format(`YYYY-MM-DD [${time}] Z`), 'YYYY-MM-DD HH:mm Z').toISOString();
      }
      return value;
    },
    isValidInput(value) {
      return this.isValid(value);
    },
    isValid() {
      throw Error('isValid() needs to be implemented');
    },
    isValueEmpty(value) {
      return (0, _utils.isNone)(value);
    }
  });
});