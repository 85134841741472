define("ln-ember-form-elements/templates/components/form-elements/upload-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "aNUjJtSI",
    "block": "[[[10,\"input\"],[15,\"onchange\",[28,[37,0],[[30,0],\"upload\"],null]],[15,\"disabled\",[30,0,[\"disabled\"]]],[15,\"multiple\",[30,0,[\"multiple\"]]],[14,4,\"file\"],[12],[13],[1,\"\\n\"],[18,1,null]],[\"&default\"],false,[\"action\",\"yield\"]]",
    "moduleName": "ln-ember-form-elements/templates/components/form-elements/upload-button.hbs",
    "isStrictMode": false
  });
});