define("ln-ember-data-models/models/vdc/fragments/additional-cost", ["exports", "ember-data", "ember-data-model-fragments/fragment"], function (_exports, _emberData, _fragment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    attr
  } = _emberData.default;

  /**
   * @class Vdc/fragments/AdditionalCostFragment
   * @extends Fragment
   */
  const AdditionalCostFragment = _fragment.default.extend({
    /**
     * @memberof Vdc/fragments/AdditionalCostFragment
     * @instance
     *
     * @type {Number}
     */
    order_id: attr('id'),
    /**
     * @memberof Vdc/fragments/AdditionalCostFragment
     * @instance
     *
     * @type {String}
     */
    name: attr('nullable-string'),
    /**
     * @memberof Vdc/fragments/AdditionalCostFragment
     * @instance
     *
     * @type {Number}
     */
    sales_price: attr('number'),
    /**
     * @memberof Vdc/fragments/AdditionalCostFragment
     * @instance
     *
     * @type {Number}
     */
    purchase_price: attr('number'),
    /**
     * @memberof Vdc/fragments/AdditionalCostFragment
     * @instance
     *
     * @type {Number}
     */
    quantity: attr('number')
  });
  var _default = _exports.default = AdditionalCostFragment;
});