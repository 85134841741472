define("ln-ember-toolkit/utils/cache-promise", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = cachePromise;
  const queryToPromiseMap = {};
  function cachePromise(identifier, handler) {
    if (queryToPromiseMap[identifier]) {
      return queryToPromiseMap[identifier];
    }
    const promise = handler();
    queryToPromiseMap[identifier] = promise;
    promise.finally(() => delete queryToPromiseMap[identifier]);
    return promise;
  }
});