define("ln-ember-data-models/models/myliga/objective-component", ["exports", "ember-data", "ln-ember-data-models/utils/enum-option"], function (_exports, _emberData, _enumOption) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.typeOptions = _exports.default = void 0;
  const {
    Model,
    attr
  } = _emberData.default;

  /**
   * @name Myliga/ObjectiveComponent/typeOptions
   * @type {EnumOption[]}
   */
  const typeOptions = _exports.typeOptions = [_enumOption.default.create({
    id: 'MinimumAccountProfitPercentage'
  }), _enumOption.default.create({
    id: 'MinimumAccountSalesEuro'
  }), _enumOption.default.create({
    id: 'Miscellaneous'
  })];

  /**
   * @class Myliga/ObjectiveComponent
   * @extends DS.Model
   */
  const ObjectiveComponentModel = Model.extend({
    /**
     * @memberof Myliga/ObjectiveComponent
     * @instance
     *
     * @type {Number}
     */
    version: attr('number'),
    /**
     * @memberof Myliga/ObjectiveComponent
     * @instance
     *
     * @type {String}
     */
    type: attr('enum-string', {
      options: typeOptions
    }),
    /**
     * @memberof Myliga/ObjectiveComponent
     * @instance
     *
     * @type {String}
     */
    title: attr('nullable-string'),
    /**
     * @memberof Myliga/ObjectiveComponent
     * @instance
     *
     * @type {String}
     */
    description: attr('nullable-string'),
    /**
     * @memberof Myliga/ObjectiveComponent
     * @instance
     *
     * @type {Number}
     */
    previous_value: attr('number', {
      defaultValue: 0
    }),
    /**
     * @memberof Myliga/ObjectiveComponent
     * @instance
     *
     * @type {Number}
     */
    actual_value: attr('number', {
      defaultValue: 0
    }),
    /**
     * @memberof Myliga/ObjectiveComponent
     * @instance
     *
     * @type {Number}
     */
    target_value: attr('number', {
      defaultValue: 0
    }),
    /**
     * Must be beween 0 - 100
     *
     * @memberof Myliga/ObjectiveComponent
     * @instance
     *
     * @type {Number}
     */
    percentage_achieved: attr('number', {
      defaultValue: 0
    }),
    /**
     * Must be beween 0 - 100
     *
     * @memberof Myliga/ObjectiveComponent
     * @instance
     *
     * @type {Number}
     */
    objective_id: attr('id')
  });
  ObjectiveComponentModel.reopenClass({
    /**
     * @memberof Myliga/ObjectiveComponent
     * @static
     *
     * @type {EnumOption[]}
     */
    typeOptions
  });
  var _default = _exports.default = ObjectiveComponentModel;
});