define("ln-ember-data-models/models/vdc/event-type", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    attr,
    hasMany,
    Model
  } = _emberData.default;

  /**
   * @class Vdc/EventTypeModel
   * @extends DS.Model
   */
  var _default = _exports.default = Model.extend({
    /**
     * @memberof Vdc/EventTypeModel
     * @instance
     *
     * @type {Number}
     */
    version: attr('number'),
    /**
     * @memberof Vdc/EventTypeModel
     * @instance
     *
     * @type {String}
     */
    name: attr('nullable-string'),
    /**
     * @memberof Vdc/EventTypeModel
     * @instance
     *
     * @type {Number}
     */
    client_id: attr('id'),
    /**
     * @memberof Vdc/EventTypeModel
     * @instance
     *
     * @type {Number}
     */
    attribute_set_id: attr('id'),
    /**
     * @memberof Vdc/EventTypeModel
     * @instance
     *
     * @type {Boolean}
     */
    respect_weekends: attr('boolean', {
      defaultValue: () => false
    }),
    /**
     * @memberof Vdc/EventTypeModel
     * @instance
     *
     * @type {Array}
     */
    attribute_ids: attr('number-array', {
      defaultValue: () => []
    }),
    /**
     * @memberof Vdc/EventTypeModel
     * @instance
     *
     * @type {Array}
     */
    event_category_ids: attr('number-array', {
      defaultValue: () => []
    }),
    /**
     * @memberof Vdc/EventTypeModel
     * @instance
     *
     * @type {Vdc/EventCategoryModel[]}
     */
    event_categories: hasMany('vdc/event-category'),
    /**
     * @memberof Vdc/EventTypeModel
     * @instance
     *
     * @type {Vdc/Picture[]}
     */
    pictures: hasMany('vdc/picture', {
      async: false
    })
  });
});