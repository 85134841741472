define("ln-ember-data-models/models/vdc/application-setting", ["exports", "ember-data", "ln-ember-data-models/utils/enum-option"], function (_exports, _emberData, _enumOption) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.flowOptions = _exports.default = void 0;
  const {
    attr,
    Model
  } = _emberData.default;

  /**
   * @memberof Vdc/ApplicationSettingModel/flowOptions
   * @type {EnumOption[]}
   */
  const flowOptions = _exports.flowOptions = [_enumOption.default.create({
    id: 'Assembly'
  }), _enumOption.default.create({
    id: 'Order'
  })];

  /**
   * @class Vdc/ApplicationSettingModel
   * @extends DS.Model
   */
  const ApplicationSettingsModel = Model.extend({
    /**
     * @memberof Vdc/ApplicationSettingModel
     * @instance
     *
     * @type {Number}
     */
    version: attr('number'),
    /**
     * @memberof Vdc/ApplicationSettingModel
     * @instance
     *
     * @type {Number}
     */
    application_id: attr('id'),
    /**
     * @memberof Vdc/ApplicationSettingModel
     * @instance
     *
     * @type {String}
     */
    context_type: attr('nullable-string'),
    /**
     * @memberof Vdc/ApplicationSettingModel
     * @instance
     *
     * @type {Number}
     */
    context_id: attr('id'),
    /**
     * @memberof Vdc/ApplicationSettingModel
     * @instance
     *
     * @type {Number[]}
     */
    context_attribute_ids: attr('number-array'),
    /**
     * @memberof Vdc/ApplicationSettingModel
     * @instance
     *
     * @type {Boolean}
     */
    show_review: attr('boolean', {
      defaultValue: false
    }),
    /**
     * @memberof Vdc/ApplicationSettingModel
     * @instance
     *
     * @type {String}
     */
    shop_flow: attr('enum-string', {
      options: flowOptions,
      defaultValue: 'Order'
    })
  });
  var _default = _exports.default = ApplicationSettingsModel.reopenClass({
    /**
     * @memberof Vdc/ApplicationSettingModel
     * @static
     *
     * @type {EnumOption[]}
     */
    flowOptions
  });
});