define("ln-ember-data-models/utils/enum-option", ["exports", "@ember/object"], function (_exports, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _object.default.extend({
    name: (0, _object.computed)('id', '_name', {
      get() {
        return String(this.get('id') || '').replace(/([a-z])([A-Z])/g, '$1 $2');
      },
      set(key, value) {
        this.set('_name', value);
        return value;
      }
    }),
    dasherizedId: (0, _object.computed)('name', function () {
      return String(this.get('id')).dasherize();
    }),
    id: null,
    _name: null
  });
});