define("ln-ember-data-models/models/vdc/address", ["exports", "ember-data", "ln-ember-data-models/utils/enum-option"], function (_exports, _emberData, _enumOption) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.personalTitleOptions = _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo
  } = _emberData.default;

  /**
   * @name Vdc/AddressModel/personalTitleOptions
   * @type {EnumOption[]}
   */
  const personalTitleOptions = _exports.personalTitleOptions = [_enumOption.default.create({
    id: 'Mr'
  }), _enumOption.default.create({
    id: 'Mrs'
  })];

  /**
   * @class Vdc/Address
   * @extends DS.Model
   */
  const AddressModel = Model.extend({
    /**
     * @memberof Vdc/Address
     * @instance
     *
     * @type {Number}
     */
    version: attr('number'),
    /**
     * @memberof Vdc/Address
     * @instance
     *
     * @type {String}
     */
    street1: attr('nullable-string'),
    /**
     * @memberof Vdc/Address
     * @instance
     *
     * @type {String}
     */
    street2: attr('nullable-string'),
    /**
     * @memberof Vdc/Address
     * @instance
     *
     * @type {String}
     */
    zip: attr('nullable-string'),
    /**
     * @memberof Vdc/Address
     * @instance
     *
     * @type {String}
     */
    city: attr('nullable-string'),
    /**
     * @memberof Vdc/Address
     * @instance
     *
     * @type {Number}
     */
    country_id: attr('id'),
    /**
     * @memberof Vdc/Address
     * @instance
     *
     * @type {Boolean}
     */
    shared: attr('boolean', {
      defaultValue: false
    }),
    /**
     * @memberof Vdc/Address
     * @instance
     *
     * @type {Number}
     */
    client_id: attr('id'),
    /**
     * @memberof Vdc/Address
     * @instance
     *
     * @type {String}
     */
    owner_kind: attr('nullable-string'),
    /**
     * @memberof Vdc/Address
     * @instance
     *
     * @type {Array}
     */
    owners: attr({
      defaultValue: () => []
    }),
    /**
     * @memberof Vdc/Address
     * @instance
     *
     * @type {String}
     */
    type: attr('nullable-string'),
    /**
     * @memberof Vdc/Address
     * @instance
     *
     * @type {String}
     */
    contact_personal_title: attr('enum-string', {
      options: personalTitleOptions
    }),
    /**
     * @memberof Vdc/Address
     * @instance
     *
     * @type {String}
     */
    contact_forename: attr('nullable-string'),
    /**
     * @memberof Vdc/Address
     * @instance
     *
     * @type {String}
     */
    contact_name: attr('nullable-string'),
    /**
     * @memberof Vdc/Address
     * @instance
     *
     * @type {String}
     */
    contact_phone: attr('nullable-string'),
    /**
     * @memberof Vdc/Address
     * @instance
     *
     * @type {String}
     */
    contact_email: attr('nullable-string'),
    /**
     * @memberof Vdc/Address
     * @instance
     *
     * @type {String}
     */
    legal_name: attr('nullable-string'),
    /**
     * @memberof Vdc/Address
     * @instance
     *
     * @type {String}
     */
    vat_number: attr('nullable-string'),
    /**
     * @memberof Vdc/Address
     * @instance
     *
     * @type {String}
     */
    delivery_number: attr('nullable-string'),
    /**
     * @memberof Vdc/Address
     * @instance
     *
     * @type {String}
     */
    contact_fax: attr('nullable-string'),
    /**
     * @memberof Vdc/Address
     * @instance
     *
     * @type {String}
     */
    contact_homepage: attr('nullable-string'),
    /**
     * @memberof Vdc/Address
     * @instance
     *
     * @type {Number}
     */
    latitude: attr('number'),
    /**
     * @memberof Vdc/Address
     * @instance
     *
     * @type {Number}
     */
    longitude: attr('number'),
    /**
     * @memberof Vdc/Address
     * @instance
     *
     * @type {Number}
     */
    country: belongsTo('vdc/country')
  });
  AddressModel.reopenClass({
    /**
     * @memberof Vdc/AddressModel
     * @static
     *
     * @type {EnumOption[]}
     */
    personalTitleOptions
  });
  var _default = _exports.default = AddressModel;
});