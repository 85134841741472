define("ln-ember-contact-menu/templates/components/micro-menu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "h8e23/nD",
    "block": "[[[41,[30,0,[\"microMenu\",\"isActive\"]],[[[1,\"  \"],[10,0],[14,0,\"micro-menu--overlay\"],[14,\"tabindex\",\"0\"],[15,\"onclick\",[30,0,[\"_close\"]]],[12],[13],[1,\"\\n\\n\"],[6,[39,1],null,[[\"class\",\"isShown\",\"hideOn\",\"popperTarget\",\"onChange\",\"popperContainer\"],[[28,[37,2],[\"micro-menu--container\",[52,[30,0,[\"isNorthern\"]],\" is-northern\",\"\"],[52,[30,0,[\"isRendered\"]],\" is-rendered\",\"\"]],null],true,\"escapekey\",[30,0,[\"targetElement\"]],[30,0,[\"_onChangePopoverVisibility\"]],[30,0,[\"element\"]]]],[[\"default\"],[[[[1,\"    \"],[46,[30,0,[\"component\"]],null,[[\"didActivateItem\"],[[30,0,[\"_didActivateItem\"]]]],null],[1,\"\\n\"]],[]]]]]],[]],null]],[],false,[\"if\",\"attach-popover\",\"concat\",\"component\"]]",
    "moduleName": "ln-ember-contact-menu/templates/components/micro-menu.hbs",
    "isStrictMode": false
  });
});