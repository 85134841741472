define("ln-ember-contact-menu/helpers/contact-menu/loc", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.loc = loc;
  // historically the Ember `{{loc` helper was used to translate strings
  // but there was never actual translations registered in apps for this addon,
  // so English strings were always returned.
  //
  // Now, since the `loc` helper is deprecated in Ember, we drop usages of the `{{loc
  // helper, and preserve an ability for translations in the future by using our own
  // `{{contact-menu/loc` helper that does nothing for now.
  function loc([key]) {
    return key;
  }
  var _default = _exports.default = (0, _helper.helper)(loc);
});