define("ln-ember-toolkit/templates/components/svg-icon", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "h/b1YYsx",
    "block": "[[[11,\"i\"],[16,0,[29,[\"SvgIcon SvgIcon--\",[30,1],\" \",[30,2]]]],[16,\"title\",[30,3]],[16,\"data-icon\",[30,1]],[16,\"onclick\",[30,4]],[17,5],[12],[1,\"\\n  \"],[1,[28,[35,0],[[30,1]],null]],[1,\"\\n\"],[41,[48,[30,6]],[[[1,\"    \"],[18,6,null],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\"]],[\"@name\",\"@class\",\"@title\",\"@onClick\",\"&attrs\",\"&default\"],false,[\"svg-jar\",\"if\",\"has-block\",\"yield\"]]",
    "moduleName": "ln-ember-toolkit/templates/components/svg-icon.hbs",
    "isStrictMode": false
  });
});