define("ln-ember-data-models/models/vdc/material", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr
  } = _emberData.default;

  /**
   * @class Vdc/MaterialModel
   * @extends DS.Model
   */
  var _default = _exports.default = Model.extend({
    /**
     * @memberof Vdc/MaterialModel
     * @instance
     *
     * @type {Number}
     */
    version: attr('number'),
    /**
     * @memberof Vdc/MaterialModel
     * @instance
     *
     * @type {String}
     */
    number: attr('nullable-string'),
    /**
     * @memberof Vdc/MaterialModel
     * @instance
     *
     * @type {String}
     */
    name: attr('nullable-string'),
    /**
     * @memberof Vdc/MaterialModel
     * @instance
     *
     * @type {String}
     */
    description: attr('nullable-string'),
    /**
     * @memberof Vdc/MaterialModel
     * @instance
     *
     * @type {Number}
     */
    material_category_id: attr('id'),
    /**
     * @memberof Vdc/MaterialModel
     * @instance
     *
     * @type {Number}
     */
    position: attr('number', {
      defaultValue: 0
    }),
    /**
     * @memberof Vdc/MaterialModel
     * @instance
     *
     * @type {Number}
     */
    vendor_id: attr('id'),
    /**
     * @memberof Vdc/MaterialModel
     * @instance
     *
     * @type {Number}
     */
    country_id: attr('id'),
    /**
     * @memberof Vdc/MaterialModel
     * @instance
     *
     * @type {Number}
     */
    hs_code: attr('number')
  });
});