define("ln-ember-data-models/transforms/enum-string", ["exports", "@ember/utils", "@ember/array", "ember-data"], function (_exports, _utils, _array, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Transform
  } = _emberData.default;
  function validateEnum(value, options) {
    if ((0, _utils.isNone)(value)) {
      return null;
    }
    const values = (0, _array.isArray)(options) ? options.map(option => typeof option === 'string' ? option : option.id) : Object.values(options);
    if (!values.includes(value)) {
      return null;
    }
    return value;
  }
  var _default = _exports.default = Transform.extend({
    deserialize(serialized) {
      return serialized;
    },
    serialize(deserialized, {
      options
    }) {
      return validateEnum(deserialized, options);
    }
  });
});