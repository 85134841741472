define("ln-ember-form-elements/components/form-elements/calendar/navigation", ["exports", "moment", "@ember/component", "@ember/object", "ln-ember-form-elements/utils/computed-properties", "ln-ember-form-elements/templates/components/form-elements/calendar/navigation"], function (_exports, _moment, _component, _object, _computedProperties, _navigation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    visibleDate: null,
    disabled: false,
    layout: _navigation.default,
    classNames: ['form-elements--calendar--navigation-component'],
    classNameBindings: ['disabled', 'themeClass'],
    onClickMonth: () => {},
    label: (0, _object.computed)('visibleDate', function () {
      return (0, _moment.default)(this.get('visibleDate')).format('MMMM YYYY');
    }),
    themeClass: (0, _object.computed)('theme', function () {
      return `theme-${this.get('theme') || 'light'}`;
    }),
    actions: {
      onDirectionClick(dir) {
        if (!this.get('disabled')) {
          this.get('onNavigate')((0, _moment.default)(this.get('visibleDate')).add(dir, 'month').toDate());
        }
      },
      onClickMonth() {
        if (!this.get('disabled')) {
          this.get('onClickMonth')();
        }
      }
    },
    onNavigate: (0, _computedProperties.defaultAction)(function (visibleDate) {
      this.set('visibleDate', visibleDate);
    })
  });
});