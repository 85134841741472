define("ln-ember-api-service/utils/normalize-error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = normalizeError;
  function normalizeError(status, payload = 'unknown error') {
    // ⚠️ Error 500s in the myliga api are plain text (Content-Type:text/plain)
    if (typeof payload === 'string') {
      return {
        status_code: status,
        message: payload,
        details: []
      };
    }

    // ⚠️ Error responses look diffrent in myliga and vdc
    // - vdc:    `{ status_code, error_code, message, details }`
    // - myliga: `{ status_code, error_message, details }`

    let {
      status_code,
      error_message,
      message,
      details,
      error_code
    } = payload;

    // (1) Normalize all detailts to { reference, message }
    details = details.map(({
      reference,
      message,
      error_message
    }) => ({
      reference: reference && reference.replace(/^obj\./, ''),
      message: message || error_message
    }));

    // (2) Normalize the message and add details without a reference to it
    const unreferencedDetails = details.filter(({
      reference
    }) => !reference).map(({
      message
    }) => message);
    message = [message || error_message].concat(unreferencedDetails).join('\n');

    // (3) Remove details without a refernce
    details = details.filter(({
      reference
    }) => reference);
    error_code = error_code ? error_code : null;
    return {
      status_code,
      message,
      details,
      error_code
    };
  }
});