define("ln-ember-form-elements/components/form-elements/date-input", ["exports", "moment", "ln-ember-form-elements/utils/computed-properties", "ln-ember-form-elements/components/form-elements/date-input-base", "ln-ember-form-elements/templates/components/form-elements/date-input", "ln-ember-form-elements/utils/date-helper"], function (_exports, _moment, _computedProperties, _dateInputBase, _dateInput, _dateHelper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   *
   * ### Value
   *
   * The value is a string in the format `YYYY-MM-DD` or `null`,
   *
   * ### Examples
   *
   * #### Basic usage:
   *
   * For the basic usage the only required attributes are the current `value` and
   * handler for `onChange` events.
   *
   * ```Handlebars
   * {{form-elements/date-input
   *   value=dateValue
   *   onChange=(action "onDateChange")
   * }}
   * ```
   *
   * ```JavaScript
   * Component.extend({
   *   actions: {
   *     onDateChange(value) {
   *       this.set('dateValue', value)
   *     }
   *   }
   * })
   * ```
   * <br>
   *
   * #### Inject a component
   *
   * To inject a sub component into the date-input, it can be used as a block
   * component. The date-input yields a `api` hash, which holds the `value` and
   * the necessary actions.
   *
   * Important to notice is, that if the sub component can hold the focus, you
   * have to trigger the in `onFocusIn` and `onFocusOut` actions to prevent the
   * dropdown to close.
   *
   * **API hash**
   *
   * - `value`
   * - `onChange` triggers a change event in the `DateInputComponent`
   * - `onFocusIn` / `onFocusOut` should be triggered if the sub component
   *   receives or looses focus.
   *
   * ```Handlebars
   * {{#form-elements/date-input
   *   value=dateValue
   *   subValue=subValue
   *   as |api|
   *  }}
   *   {{input
   *     value=api.value
   *     onchange=api.onChange
   *     focus-in=api.onFocusIn
   *     focus-out=api.onFocusOut
   *   }}
   * {{/form-elements/date-input}}
   * ```
   *
   * ```JavaScript
   * Component.extend({
   *   actions: {
   *     onDateChange(value, subValue) {
   *       this.set('dateValue', value)
   *       this.set('subValue', subValue)
   *     }
   *   }
   * })
   * ```
   *
   * ### Demo
   *
   * <iframe src="showcase/index.html#/demo/date-input" style="width: 360px;border: 1px solid black;height: 410px;padding: 20px;"></iframe>
   *
   * @class formElements/DateInputComponent
   * @extends formElements/DateInputBaseComponent
   */
  var _default = _exports.default = _dateInputBase.default.extend({
    layout: _dateInput.default,
    /**
     * The value should be in the format `YYYY-MM-DD`.
     *
     * @memberof formElements/DateInputComponent
     * @instance
     *
     * @type {string}
     * @default null
     */
    value: null,
    /**
     * @memberof formElements/DateInputComponent
     * @instance
     *
     * @type {any}
     * @default null
     */
    subValue: null,
    classNames: ['form-elements--date-input-component'],
    customFormatter: null,
    actions: {
      onCalendarChange(value) {
        this.triggerChange({
          value
        });
      },
      onInputChange(value) {
        if (!value) {
          return this.triggerChange(null);
        }
        if (this.isValid(value)) {
          this.triggerChange({
            value
          });
        }
      },
      onSubValueChange(subValue) {
        this.triggerChange({
          subValue
        });
      }
    },
    /**
     * @memberof formElements/DateInputComponent
     * @instance
     *
     * @type {function}
     * @default null
     */
    onChange: (0, _computedProperties.defaultAction)(function (value, subValue) {
      this.set('value', value);
      this.set('subValue', subValue);
    }),
    getVisibleDate() {
      return (0, _moment.default)(this.get('value') || undefined).format('YYYY-MM-DD');
    },
    isValid(value) {
      return (0, _moment.default)(value, 'YYYY-MM-DD', true).isValid();
    },
    formatDate(date) {
      if (this.customFormatter) {
        return this.customFormatter(date);
      }
      return (0, _dateHelper.formatDate)(date, this.get('formElements.defaults.formatDate'));
    },
    triggerChange(values) {
      if (values) {
        let {
          value,
          subValue
        } = values;
        const keys = Object.keys(values);
        if (!keys.includes('value')) {
          value = this.get('value');
        }
        if (!keys.includes('subValue')) {
          subValue = this.get('subValue');
        }
        this.get('onChange')(value && this.normalizeDate(value), subValue);
      } else {
        this.get('onChange')(null, null);
      }
    }
  });
});