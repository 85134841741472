define("ln-ember-data-models/serializers/vdc/article-attribute", ["exports", "ln-ember-data-models/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * @class Vdc/KpiSerializer
   * @extends ApplicationSerializer
   */
  var _default = _exports.default = _application.default.extend({
    get attrs() {
      return {
        attribute_options: {
          serialize: false,
          deserialize: 'records'
        }
      };
    },
    buildRelationshipLink(key, relationshipMeta, modelClass, resourceHash) {
      if (key === 'attribute_options') {
        const baseUrl = this.baseUrlFor(modelClass.modelName);
        const linkKey = this.keyForLink(key, relationshipMeta.kind);
        return `${baseUrl}/${linkKey}?attribute=${resourceHash.id}`;
      }
      return this._super(...arguments);
    }
  });
});