define("ln-ember-form-elements/components/form-elements/expanding-textarea", ["exports", "jquery", "@ember/component", "@ember/object/computed", "@ember/application/deprecations", "@ember/service", "@ember/utils", "@ember/object", "@ember/runloop", "ln-ember-form-elements/templates/components/form-elements/expanding-textarea"], function (_exports, _jquery, _component, _computed, _deprecations, _service, _utils, _object, _runloop, _expandingTextarea) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Renders an textarea that grows and shrinks based on the content.<br>
   *
   * ### Examples:
   *
   * ```Handlebars
   * {{form-elements/expanding-textarea
   *   value="Lorem ipsum"
   *   autoFocus=true
   * }}
   * ```
   *
   * ```Handlebars
   * {{form-elements/expanding-textarea
   *    value="Lorem ipsum"
   *    onKeyUp=(action "handleKeyUp")
   *    onFocusIn=(action "handleFocusIn")
   *    onPaste=(action "handlePaste")
   *  }}
   * ```
   *
   * @class formElements/ExpandingTextareaComponent
   * @extends @ember/component
   */
  var _default = _exports.default = _component.default.extend({
    formElements: (0, _service.inject)(),
    /**
     * Fired on an key-up event
     *
     * @deprecated Use `onKeyUp` instead.
     *
     * @memberof formElements/ExpandingTextareaComponent
     * @instance
     *
     * @type {function}
     */
    'key-up': null,
    /**
     * Fired when the textarea gets focused
     *
     * @deprecated Use `onFocusIn` instead.
     *
     * @memberof formElements/ExpandingTextareaComponent
     * @instance
     *
     * @type {function}
     */
    'focusIn': null,
    /**
     * Fired when the textarea loses focus
     *
     * @deprecated Use `onFocusOut` instead.
     *
     * @memberof formElements/ExpandingTextareaComponent
     * @instance
     *
     * @type {function}
     */
    'focus-out': null,
    /**
     * Fired when something gets pasted into the textarea
     *
     * @deprecated Use `onPaste` instead.
     *
     * @memberof formElements/ExpandingTextareaComponent
     * @instance
     *
     * @type {function}
     */
    paste: null,
    /**
     * Fired when the height of the textarea changes
     *
     * @deprecated Use `onDidUpdateHeight` instead.
     *
     * @memberof formElements/ExpandingTextareaComponent
     * @instance
     *
     * @type {function}
     */
    didUpdateHeight: null,
    /**
     * @memberof formElements/ExpandingTextareaComponent
     * @instance
     *
     * @type {boolean}
     */
    error: null,
    /**
     * @memberof formElements/ExpandingTextareaComponent
     * @instance
     *
     * @type {boolean}
     * @default false
     */
    readonly: false,
    /**
    * The maximal length of characters that can be entered into the textarea field.<br>
    * If max length is `null` or another falsy value then there is no limit.
    *
    * @memberof formElements/ExpandingTextareaComponent
    * @instance
    *
    * @type Number
    * @default null
    */
    maxLength: null,
    /**
     * The label of the textarea
     *
     * @memberof formElements/ExpandingTextareaComponent
     * @instance
     *
     * @type String
     * @default null
     */
    label: null,
    layout: _expandingTextarea.default,
    classNames: [
    // Deprecated: { id: ln-ember-form-elements-class-names, until: 3.0.0 }
    'expanding-textarea-component', 'form-elements--expanding-textarea-component'],
    classNameBindings: ['hasFocus', 'value:has-content', 'themeClass', 'error:has-error', 'maxLength:has-max-length', 'readonly'],
    /**
     * The value of the text area.
     *
     * @memberof formElements/ExpandingTextareaComponent
     * @instance
     *
     * @type String
     * @default null
     */
    value: null,
    /**
     * @memberof formElements/ExpandingTextareaComponent
     * @instance
     *
     * @type String
     * @default 'light'
     */
    theme: null,
    /**
     * If set to true the textarea will be focused as soon as it gets rendered.
     *
     * @memberof formElements/ExpandingTextareaComponent
     * @instance
     *
     * @type boolean
     * @default false
     */
    autoFocus: false,
    lastHeight: null,
    limitExceeded: (0, _computed.lt)('remainingCharacters', 0),
    themeClass: (0, _object.computed)('theme', function () {
      return `theme-${this.get('theme') || 'light'}`;
    }),
    /**
     * @memberof formElements/ExpandingTextareaComponent
     * @instance
     *
     * @type {function}
     */
    onKeyUp() {
      // TODO Remove this in version 3.0.0 and change to `onKeyUp: () => {}`
      this._compatibleSendAction('key-up', ...arguments);
    },
    /**
     * Fired when the textarea gets focused
     *
     * @memberof formElements/ExpandingTextareaComponent
     * @instance
     *
     * @type {function}
     */
    onFocusIn() {
      // TODO Remove this in version 3.0.0 and change to `onFocusIn: () => {}`
      this._compatibleSendAction('focus-in', ...arguments);
    },
    /**
     * Fired when the textarea loses focus
     *
     * @memberof formElements/ExpandingTextareaComponent
     * @instance
     *
     * @type {function}
     */
    onFocusOut() {
      // TODO Remove this in version 3.0.0 and change to `onFocusOut: () => {}`
      this._compatibleSendAction('focus-out', ...arguments);
    },
    /**
     * Fired when something gets pasted into the textarea
     *
     * @memberof formElements/ExpandingTextareaComponent
     * @instance
     *
     * @type {function}
     */
    onPaste() {
      // TODO Remove this in version 3.0.0 and change to `onPaste: () => {}`
      this._compatibleSendAction('paste');
    },
    /**
     * Fired when the height of the textarea changes
     *
     * @memberof formElements/ExpandingTextareaComponent
     * @instance
     *
     * @type {function}
     */
    onDidUpdateHeight() {
      // TODO Remove this in version 3.0.0
      this._compatibleSendAction('didUpdateHeight');
    },
    init() {
      this._super(...arguments);
      if (!this.get('theme')) {
        this.set('theme', this.get('formElements.defaults.theme'));
      }
      (false && !(!this.get('key-up')) && (0, _deprecations.deprecate)('[ExpandingTextareaComponent] key-up deprecated. Use onKeyUp instead.', !this.get('key-up'), {
        for: 'ln-ember-form-elements',
        id: 'expanding-textarea-component-action-key-up',
        since: '3.0.0-beta.115',
        until: '3.0.0'
      }));
      (false && !(!this.get('focus-in')) && (0, _deprecations.deprecate)('[ExpandingTextareaComponent] focus-in deprecated. Use onFocusIn instead.', !this.get('focus-in'), {
        for: 'ln-ember-form-elements',
        id: 'expanding-textarea-component-action-focus-in',
        since: '3.0.0-beta.115',
        until: '3.0.0'
      }));
      (false && !(!this.get('focus-out')) && (0, _deprecations.deprecate)('[ExpandingTextareaComponent] focus-out deprecated. Use onFocusOut instead.', !this.get('focus-out'), {
        for: 'ln-ember-form-elements',
        id: 'expanding-textarea-component-action-focus-out',
        since: '3.0.0-beta.115',
        until: '3.0.0'
      }));
      (false && !(!this.get('didUpdateHeight')) && (0, _deprecations.deprecate)('[ExpandingTextareaComponent] didUpdateHeight is deprecated. Use onDidUpdateHeight instead.', !this.get('didUpdateHeight'), {
        for: 'ln-ember-form-elements',
        id: 'expanding-textarea-component-action-did-update-height',
        since: '3.0.0-beta.115',
        until: '3.0.0'
      }));
    },
    didInsertElement() {
      (0, _jquery.default)('textarea').on('paste', () => {
        (0, _runloop.scheduleOnce)('afterRender', () => this.onPaste());
      });
      (0, _runloop.scheduleOnce)('afterRender', () => {
        this.update();
        (0, _jquery.default)('textarea', this.element).expanding();
        if (this.get('autoFocus') === 'true') {
          (0, _jquery.default)('textarea', this.element).focus();
          this.eventBus.send('expanding-textarea:focused');
        }
      });
    },
    willDestroyElement() {
      (0, _jquery.default)('textarea').off('paste');
      (0, _jquery.default)('textarea', this.element).expanding('destroy');
    },
    update() {
      const height = (0, _jquery.default)(this.element).height();
      const lastHeight = this.get('lastHeight');
      if (height !== lastHeight && !(0, _utils.isNone)(lastHeight)) {
        this.onDidUpdateHeight();
      }
      this.set('lastHeight', height);
    },
    valueHasChangedObs: (0, _object.observer)('value', function () {
      (0, _runloop.scheduleOnce)('afterRender', () => {
        if (!(0, _jquery.default)('textarea', this.element)) {
          return;
        }
        // $().trigger doesn't seem to work here so we have to trigger a native event
        (0, _jquery.default)('textarea', this.element).get(0).dispatchEvent(new Event('change'));
        this.update();
      });
    }),
    remainingCharacters: (0, _object.computed)('maxLength', 'value.length', function () {
      return this.get('maxLength') - (this.get('value.length') || 0);
    }),
    _compatibleSendAction(action = 'action', ...args) {
      if (typeof this.get(action) === 'string') {
        return this.sendAction(action, ...args);
      } else if (typeof this.get(action) === 'function') {
        return this.get(action)(...args);
      }
    },
    actions: {
      focusIn() {
        this.set('hasFocus', true);
        this.onFocusIn(...arguments);
      },
      focusOut() {
        this.set('hasFocus', false);
        this.onFocusOut(...arguments);
      },
      keyUp() {
        this.onKeyUp(...arguments);
      }
    }
  });
});