define("ln-ember-api-service/utils/api-error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * @class ApiError
   * @extends Error
   *
   * @param  {String} message
   * @param  {Number} [status = 500]
   */
  class ApiError extends Error {
    constructor(message, status = 500) {
      super(message);

      /**
       * @type {Number}
       */
      this.status = status;
    }
  }
  var _default = _exports.default = ApiError;
});