define("ln-ember-data-models/models/myliga/user-workload", ["exports", "ember-data", "ember-data/attr", "ember-data-model-fragments/attributes"], function (_exports, _emberData, _attr, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint ligadigital/ember-model-version-attribute: Off */

  const {
    Model,
    hasMany
  } = _emberData.default;

  /**
   * @class Myliga/UserWorkloadModel
   * @extends DS.Model
   */
  var _default = _exports.default = Model.extend({
    /**
     * @memberof Myliga/UserWorkloadModel
     * @instance
     *
     * @type {Number}
     */
    actual_workload: (0, _attr.default)('number'),
    /**
     * @memberof Myliga/UserWorkloadModel
     * @instance
     *
     * @type {Boolean}
     */
    all_calendar_weeks_closed: (0, _attr.default)('boolean'),
    /**
     * @memberof Myliga/UserWorkloadModel
     * @instance
     *
     * @type {Number}
     */
    average_actual_workload: (0, _attr.default)('number'),
    /**
     * @memberof Myliga/UserWorkloadModel
     * @instance
     *
     * @type {Number}
     */
    average_required_workload: (0, _attr.default)('number'),
    /**
     * @memberof Myliga/UserWorkloadModel
     * @instance
     *
     * @type {String}
     */
    date_from: (0, _attr.default)('date-time-string'),
    /**
     * @memberof Myliga/UserWorkloadModel
     * @instance
     *
     * @type {String}
     */
    date_till: (0, _attr.default)('date-time-string'),
    /**
     * @memberof Myliga/UserWorkloadModel
     * @instance
     *
     * @type {Number}
     */
    open_absence_requests: (0, _attr.default)('number'),
    /**
     * @memberof Myliga/UserWorkloadModel
     * @instance
     *
     * @type {Number}
     */
    required_workload: (0, _attr.default)('number'),
    /**
     * @memberof Myliga/UserWorkloadModel
     * @instance
     *
     * @type {Number}
     */
    user_id: (0, _attr.default)('id'),
    /**
     * @memberof Myliga/UserWorkloadModel
     * @instance
     *
     * @type {Array}
     */
    direct_superiors_ids: (0, _attr.default)('number-array', {
      defaultValue: () => []
    }),
    /**
     * @memberof Myliga/UserWorkloadModel
     * @instance
     *
     * @type {Object}
     */
    absence_stats: (0, _attr.default)(),
    /**
     * @memberof Myliga/UserWorkloadModel
     * @instance
     *
     * @type {Myliga/AbsenceModel[]}
     */
    absences: hasMany('myliga/absence', {
      async: false
    }),
    /**
     * @memberof Myliga/UserWorkloadModel
     * @instance
     *
     * @type {Myliga/fragments/UserMetaFragment}
     */
    user_meta: (0, _attributes.fragment)('myliga/fragments/user-meta'),
    /**
     * @memberof Myliga/UserWorkloadsModel
     * @instance
     *
     * @type {Myliga/fragments/UserWorkloadModel[]}
     */
    time_entry_metas: (0, _attributes.fragmentArray)('myliga/fragments/time-entry-meta'),
    /**
     * @memberof Myliga/UserWorkloadsModel
     * @instance
     *
     * @type {Myliga/fragments/PlannedUserWorkloadModel[]}
     */
    planned_time_entry_metas: (0, _attributes.fragmentArray)('myliga/fragments/time-entry-meta')
  });
});