define("ln-ember-data-models/models/vdc/marketing-object", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    attr,
    belongsTo,
    Model
  } = _emberData.default;

  /**
   * @class Vdc/MarketingObjectModel
   * @extends DS.Model
   */
  var _default = _exports.default = Model.extend({
    /**
     * @memberof Vdc/MarketingObjectModel
     * @instance
     *
     * @type {Number}
     */
    client_id: attr('id'),
    /**
     * @memberof Vdc/MarketingObjectModel
     * @instance
     *
     * @type {Number[]}
     */
    fits_on_ids: attr('number-array'),
    /**
     * @memberof Vdc/MarketingObjectModel
     * @instance
     *
     * @type {String[]}
     */
    fits_on_names: attr('string-array'),
    /**
     * @memberof Vdc/MarketingObjectModel
     * @instance
     *
     * @type {Number}
     */
    image_id: attr('id'),
    /**
     * @memberof Vdc/MarketingObjectModel
     * @instance
     *
     * @type {Number}
     */
    marketing_category_id: attr('id'),
    /**
     * @memberof Vdc/MarketingObjectModel
     * @instance
     *
     * @type {string}
     */
    marketing_category_name: attr('string'),
    /**
     * @memberof Vdc/MarketingObjectModel
     * @instance
     *
     * @type {string}
     */
    name: attr('string'),
    /**
     * @memberof Vdc/MarketingObjectModel
     * @instance
     *
     * @type {Number}
     */
    position: attr('number', {
      defaultValue: 0
    }),
    /**
     * @memberof Vdc/MarketingObjectModel
     * @instance
     *
     * @type {Number}
     */
    version: attr('number'),
    /**
     * @memberof Vdc/MarketingCategoryModel
     * @instance
     *
     * @type {Vdc/PictureModel}
     */
    image: belongsTo('vdc/picture')
  });
});