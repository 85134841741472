define("ln-ember-data-models/models/vdc/fragments/attribute-value", ["exports", "ember-data", "ember-data-model-fragments/fragment"], function (_exports, _emberData, _fragment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    attr
  } = _emberData.default;

  /**
   * @class Vdc/fragments/ObjectAreaFragment
   * @extends Fragment
   */
  var _default = _exports.default = _fragment.default.extend({
    attribute_id: attr('id'),
    value: attr()
  });
});