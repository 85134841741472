define("ln-ember-toolkit/models/versioned-model", ["exports", "@ember/service", "ember-data", "@ember/object/observers", "ln-ember-toolkit/utils/rollback-on-destroy-reject"], function (_exports, _service, _emberData, _observers, _rollbackOnDestroyReject) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.isVersionedModel = isVersionedModel;
  var _dec, _dec2, _class, _class2, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const {
    attr,
    Model
  } = _emberData.default;
  function isVersionedModel(model) {
    return model instanceof VersionedModel && model.__LATEST_STATE__ !== undefined;
  }
  let VersionedModel = _exports.default = (_dec = (0, _service.inject)('store'), _dec2 = attr('number'), (0, _rollbackOnDestroyReject.default)(_class = (_class2 = class VersionedModel extends Model {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "store", _descriptor, this);
      _initializerDefineProperty(this, "version", _descriptor2, this);
      _defineProperty(this, "_previousVersion", void 0);
      _defineProperty(this, "__LATEST_STATE__", void 0);
    }
    get _changedRelationships() {
      const relationships = {};
      if (!this.__LATEST_STATE__) {
        return relationships;
      }
      this.eachRelationship((_name, {
        kind,
        key
      }) => {
        if (kind === 'belongsTo') {
          const currentId = this.belongsTo(key).id();
          const latestStateId = this.__LATEST_STATE__[key];
          if (currentId !== latestStateId) {
            relationships[key] = [latestStateId, currentId];
          }
        }
        if (kind === 'hasMany') {
          const currentIds = this.hasMany(key).ids();
          const latestStateIds = this.__LATEST_STATE__[key] || [];
          const removedIds = latestStateIds.filter(id => !currentIds.includes(id));
          const addedIds = currentIds.filter(id => !latestStateIds.includes(id));
          if (removedIds.length > 0 || addedIds.length > 0) {
            relationships[key] = [latestStateIds, currentIds];
          }
        }
      });
      return relationships;
    }
    isRelationshipDirty(name) {
      return name in this._changedRelationships;
    }
    get hasDirtyRelationships() {
      return Object.keys(this._changedRelationships).length > 0;
    }
    get isDirty() {
      return this.hasDirtyAttributes || this.hasDirtyRelationships;
    }
    init() {
      super.init();
      (0, _observers.addObserver)(this, 'version', this, this.onUpdateVersion);
    }
    ready() {
      this._updateLatestState();
    }
    save(options) {
      return super.save(options).catch(error => {
        if (this.get('isDeleted')) {
          this.rollbackAttributes();
        }
        throw error;
      });
    }
    onUpdateVersion() {
      if (this.isLoaded && this.version !== this._previousVersion) {
        this._updateLatestState();
      }
    }
    rollback() {
      this.rollbackRelationships();
      this.rollbackAttributes();
    }
    rollbackRelationships() {
      const relationships = Object.entries(this._changedRelationships).reduce((relationships, [key, [lastValue]]) => {
        const {
          type,
          kind
        } = this.relationshipFor(key);
        let value = null;
        if (kind === 'belongsTo' && lastValue) {
          value = this.store.peekRecord(type, lastValue);
        }
        if (kind === 'hasMany') {
          value = lastValue.map(id => this.store.peekRecord(type, id));
        }
        return {
          ...relationships,
          [key]: value
        };
      }, {});
      this.setProperties(relationships);
    }
    recalculateLatestState() {
      this.set('__LATEST_STATE__', this.toJSON());
    }
    _updateLatestState() {
      this._previousVersion = this.version;
      this.recalculateLatestState();

      // For some reason ED's built-in JSON serializer doesn't serialize oneToMany
      // see: https://github.com/emberjs/data/blob/340f21d21293569783ce671c363f4423be6fbe5b/packages/serializer/addon/json.js#L877
      // So do it ourselves
      this.eachRelationship((name, meta) => {
        if (meta.kind === 'hasMany') {
          this.__LATEST_STATE__[name] = this.hasMany(meta.name).ids();
        }
      });
    }
  }, _descriptor = _applyDecoratedDescriptor(_class2.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "version", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class2)) || _class);
});