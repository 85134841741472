define("ln-ember-form-elements/components/form-elements/number", ["exports", "ln-ember-form-elements/templates/components/form-elements/number", "@ember/component", "@ember/object", "@ember-decorators/component", "ln-ember-form-elements/utils/-private/escape-mask", "ln-ember-toolkit/utils/format-number"], function (_exports, _number, _component, _object, _component2, _escapeMask, _formatNumber) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _class2; // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  let FormElementsInput = _exports.default = (_dec = (0, _component2.tagName)(''), _dec2 = (0, _object.computed)('max'), _dec3 = (0, _object.computed)('_scale', 'inputScale'), _dec4 = (0, _object.computed)('placeholder', '_scale'), _dec5 = (0, _object.computed)('placeUnitBefore', 'unit', 'unitSeparator'), _dec6 = (0, _object.computed)('_inputScale', '_mask', 'formatSettings', 'max', 'min', 'normalizeZeros', 'padFractionalZeros', 'signed'), _dec7 = (0, _object.computed)('scale'), _dec(_class = (_class2 = class FormElementsInput extends _component.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "layout", _number.default);
      _defineProperty(this, "onAccept", void 0);
      _defineProperty(this, "placeholder", void 0);
      _defineProperty(this, "shouldRound", void 0);
      _defineProperty(this, "_prevShouldRound", void 0);
      // Unit settings
      _defineProperty(this, "unit", void 0);
      _defineProperty(this, "placeUnitBefore", false);
      _defineProperty(this, "unitSeparator", '');
      // imask options
      // see: https://imask.js.org/guide.html#masked-number
      _defineProperty(this, "scale", void 0);
      _defineProperty(this, "signed", true);
      _defineProperty(this, "padFractionalZeros", false);
      _defineProperty(this, "normalizeZeros", true);
      _defineProperty(this, "min", void 0);
      _defineProperty(this, "max", void 0);
      _defineProperty(this, "inputScale", void 0);
      _defineProperty(this, "_value", void 0);
      _defineProperty(this, "_prevValue", void 0);
      _defineProperty(this, "value", void 0);
      _defineProperty(this, "formatSettings", detectNumberFormatSettings('de-DE'));
    }
    get _max() {
      return this.max ?? 9999999999;
    }
    get _inputScale() {
      return this.inputScale ?? this._scale;
    }
    get _placeholder() {
      return typeof this.placeholder === 'string' ? this.placeholder : (0, _formatNumber.default)(0, {
        minimumFractionDigits: this._inputScale,
        maximumFractionDigits: this._inputScale
      });
    }
    get _mask() {
      const unit = (0, _escapeMask.default)(this.unit);
      const unitSeparator = (0, _escapeMask.default)(this.unitSeparator);
      if (unit) {
        return this.placeUnitBefore ? `${unit}${unitSeparator}OUTLET` : `OUTLET${unitSeparator}${unit}`;
      }
      return 'OUTLET';
    }
    get maskOptions() {
      return {
        mask: [{
          mask: ''
        }, {
          mask: this._mask,
          lazy: false,
          blocks: {
            OUTLET: {
              mask: Number,
              scale: this._inputScale,
              signed: this.signed,
              thousandsSeparator: this.formatSettings.thousandsSeparator,
              padFractionalZeros: this.padFractionalZeros,
              normalizeZeros: this.normalizeZeros,
              radix: this.formatSettings.radix,
              mapToRadix: [this.formatSettings.thousandsSeparator],
              min: this.min,
              max: this._max
            }
          }
        }]
      };
    }
    get _scale() {
      return typeof this.scale === 'number' ? this.scale : 2;
    }
    didReceiveAttrs() {
      super.didReceiveAttrs();
      const numberValue = Number.parseFloat(this.value);
      if (!Number.isNaN(numberValue)) {
        if (this.shouldRound !== false) {
          const multiplier = 10 ** this._scale;
          // see: https://stackoverflow.com/a/11832950
          const rounded = Math.round(numberValue * multiplier) / multiplier;
          (0, _object.set)(this, '_value', this.serialize(rounded.toString()));
        } else {
          (0, _object.set)(this, '_value', this.serialize(this.value));
        }
      } else {
        (0, _object.set)(this, '_value', this.value);
      }
    }
    deserialize(value) {
      return value;
    }
    serialize(value) {
      return value;
    }
    _parse(s) {
      if (typeof s === 'string') {
        return Number.isNaN(Number(s)) || s.trim().length === 0 ? null : this.deserialize(Number(s));
      }
      return s;
    }
    _onAccept(v, e) {
      const target = e.target;
      const emptyMinusValue = this._mask.replace('OUTLET', '-');
      if (typeof this.onAccept === 'function' && target.value !== emptyMinusValue) {
        return this.onAccept(this._parse(v), e);
      }
    }
    _onCopy(unmaskedValue, e) {
      const selection = document.getSelection();
      if (e.target instanceof HTMLInputElement && e.clipboardData) {
        const selectedText = selection.toString();
        if (selectedText === e.target.value) {
          e.clipboardData.setData('text/plain', unmaskedValue);
        } else {
          e.clipboardData.setData('text/plain', selectedText);
        }
        e.preventDefault();
      }
    }
  }, _applyDecoratedDescriptor(_class2.prototype, "_max", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "_max"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "_inputScale", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "_inputScale"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "_placeholder", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "_placeholder"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "_mask", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "_mask"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "maskOptions", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "maskOptions"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "_scale", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "_scale"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "_onAccept", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "_onAccept"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "_onCopy", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "_onCopy"), _class2.prototype), _class2)) || _class);
  function detectNumberFormatSettings(locale) {
    const formatter = new Intl.NumberFormat(locale, {
      useGrouping: true,
      minimumFractionDigits: 2
    });
    const str = formatter.format(2000);
    return {
      thousandsSeparator: str[1],
      radix: str[5]
    };
  }
});