define("ln-ember-form-elements/components/form-elements/base", ["exports", "@ember/component", "@ember/service", "@ember/object", "ln-ember-form-elements/utils/computed-properties"], function (_exports, _component, _service, _object, _computedProperties) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Abstract base class for all form element components.
   *
   * @class formElements/BaseComponent
   * @abstract
   */
  var _default = _exports.default = _component.default.extend({
    formElements: (0, _service.inject)(),
    /**
     * @memberof formElements/BaseComponent
     * @instance
     *
     * @type {any}
     * @default null
     */
    value: null,
    /**
     * @memberof formElements/BaseComponent
     * @instance
     *
     * @type {string|boolean}
     * @default null
     */
    error: null,
    /**
     * If `disabled` is set to true the component is not editable.
     *
     * ### Example:
     *
     * ```Handlebars
     * {{form-elements/my-input disabled=true}}
     * ```
     *
     * @memberof formElements/BaseComponent
     * @instance
     *
     * @type {boolean}
     * @default false
     */
    disabled: false,
    /**
     * Placeholder that is shown if the input does not hold a value.
     *
     * @memberof formElements/BaseComponent
     * @instance
     *
     * @type {string}
     * @default null
     */
    placeholder: null,
    /**
     * Determs wether the component should be shown in the `dark` or `light` theme.
      * ### Example:
     *
     * ```Handlebars
     * {{form-elements/my-input theme="dark"}}
     * ```
     *
     * For apps using a custom theme a arbitrary string can be passed which will
     * be added as a class to the component (prefixed with `class-`). This will
     * strip the default styling.
     *
     * ```Handlebars
     * {{form-elements/my-input theme="vdc-dark"}}
     * ```
     * Custom styles should be added using the theme class:
     *
     * ```CSS
     * .form-elements--my-input-component.theme-vdc-dark {
     *   background: darkmagenta;
     * }
     * ```
     *
     * @memberof formElements/BaseComponent
     * @instance
     *
     * @type {string}
     * @default null
     */
    theme: null,
    classNames: ['form-elements--base-component'],
    classNameBindings: ['hasError', 'themeClass'],
    hasError: (0, _object.computed)('error', function () {
      return Boolean(this.get('error'));
    }),
    themeClass: (0, _object.computed)('theme', function () {
      const theme = this.get('theme') || this.get('formElements.defaults.theme') || 'light';
      return `theme-${theme}`;
    }),
    /**
     * The onChange action handles all changes. The value should be passed back
     * into the component as value attribute.
     *
     * ### Example:
     *
     * ```Handlebars
     * {{form-elements/my-input
     *   value=inputValue
     *   onChange=(action "onInputChange")
     * }}
     * ```
     *
     * ```JavaScript
     * Component.extend({
     *   actions: {
     *     onInputChange(value) {
     *       this.set('inputValue', value)
     *     }
     *   }
     * })
     * ```
     *
     * @memberof formElements/BaseComponent
     * @instance
     *
     * @type {function}
     */
    onChange: (0, _computedProperties.defaultAction)(function (value) {
      this.set('value', value);
    })
  });
});