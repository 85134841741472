define("ln-ember-data-models/models/vdc/role-assignment", ["exports", "ember-data", "ln-ember-data-models/utils/enum-option"], function (_exports, _emberData, _enumOption) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.contextTypeOptions = void 0;
  const {
    attr,
    belongsTo,
    Model
  } = _emberData.default;

  /**
   * @name Vdc/RoleAssignmentModel/contextTypeOptions
   * @type {EnumOption[]}
   */
  const contextTypeOptions = _exports.contextTypeOptions = [_enumOption.default.create({
    id: 'General'
  }), _enumOption.default.create({
    id: 'Project'
  }), _enumOption.default.create({
    id: 'Market'
  }), _enumOption.default.create({
    id: 'Store'
  }), _enumOption.default.create({
    id: 'Event'
  })];

  /**
   * @class Vdc/RoleAssignmentModel
   * @extends DS.Model
   */
  const RoleAssignmentModel = Model.extend({
    /**
     * @memberof Vdc/RoleAssignmentModel
     * @instance
     *
     * @type {Number}
     */
    version: attr('number'),
    /**
     * @memberof Vdc/RoleAssignmentModel
     * @instance
     *
     * @type {Number}
     */
    user_id: attr('id'),
    /**
     * @memberof Vdc/RoleAssignmentModel
     * @instance
     *
     * @type {Number}
     */
    role_id: attr('id'),
    /**
     * @memberof Vdc/RoleAssignmentModel
     * @instance
     *
     * @type {Number}
     */
    client_id: attr('id'),
    /**
     * @memberof Vdc/RoleAssignmentModel
     * @instance
     *
     * @type {Number}
     */
    context_id: attr('id'),
    /**
     * @memberof Vdc/RoleAssignmentModel
     * @instance
     *
     * @type {String}
     */
    context_type: attr('enum-string', {
      options: contextTypeOptions
    }),
    /**
     * @memberof Vdc/RoleAssignmentModel
     * @instance
     *
     * @type {Vdc/RoleModel}
     */
    role: belongsTo('vdc/role')
  });
  RoleAssignmentModel.reopenClass({
    /**
     * @memberof Vdc/RoleAssignmentModel
     * @static
     *
     * @type {EnumOption[]}
     */
    contextTypeOptions
  });
  var _default = _exports.default = RoleAssignmentModel;
});