define("ln-ember-form-elements/templates/components/flash-messages/flash-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "4aWGCddV",
    "block": "[[[10,2],[12],[1,[30,0,[\"message\",\"text\"]]],[13],[1,\"\\n\\n\"],[10,\"button\"],[14,0,\"close-message\"],[15,\"onclick\",[28,[37,0],[[30,0],\"removeMessage\"],null]],[14,4,\"button\"],[12],[1,\"\\n  \"],[1,[28,[35,1],[\"exit\"],[[\"class\"],[\"Button-icon\"]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"action\",\"svg-icon\"]]",
    "moduleName": "ln-ember-form-elements/templates/components/flash-messages/flash-message.hbs",
    "isStrictMode": false
  });
});