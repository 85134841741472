define("ln-ember-toolkit/utils/rollback-on-destroy-reject", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = rollbackOnDestroyReject;
  function rollbackOnDestroyReject(constructor) {
    return class extends constructor {
      save(...args) {
        if (this.get('isDeleted')) {
          return super.save(...args).catch(error => {
            this.rollbackAttributes();
            throw error;
          });
        }
        return super.save();
      }
    };
  }
});