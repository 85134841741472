define("ln-ember-data-models/models/myliga/block-category", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint ligadigital/ember-model-version-attribute: Off */

  const {
    attr,
    Model
  } = _emberData.default;

  /**
   * @class Myliga/BlockCategoryModel
   * @extends DS.Model
   */
  var _default = _exports.default = Model.extend({
    /**
     * @memberof Myliga/BlockCategoryModel
     * @instance
     *
     * @type {String}
     */
    identifier: attr('nullable-string'),
    /**
     * @memberof Myliga/BlockCategoryModel
     * @instance
     *
     * @type {String}
     */
    name: attr('nullable-string'),
    /**
     * @memberof Myliga/BlockCategoryModel
     * @instance
     *
     * @type {Number}
     */
    position: attr('number'),
    /**
     * @memberof Myliga/BlockCategoryModel
     * @instance
     *
     * @type {Boolean}
     */
    is_pin_category: attr('boolean'),
    /**
     * @memberof Myliga/BlockCategoryModel
     * @instance
     *
     * @type {String}
     */
    block_sort: attr('nullable-string', {
      defaultValue: null
    })
  });
});