define("ln-ember-form-elements/services/flash-messages", ["exports", "@ember/array", "@ember/utils", "@ember/runloop", "@ember/service", "@ember/string", "@ember/object", "@ember/debug"], function (_exports, _array, _utils, _runloop, _service, _string, _object, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * @typedef {object} FlashMessage
   * @property {number} id
   * @property {string} type
   * @property {string} text
   */
  /**
   * The `FlashMessagesService` is used to show flash messages.
   *
   * There three types of messages:
   *
   * - `error`
   * - `warning`
   * - `success`
   *
   * ### Examples:
   *
   * Add a message
   *
   * ```JavaScript
   * const message = this.get('flashMessages').addErrorMessage('Unexpected error!')
   * ```
   * ```JavaScript
   * const message = this.get('flashMessages').addWarningMessage('Opps, this should not happen!')
   * ```
   * ```JavaScript
   * const message = this.get('flashMessages').addSuccessMessage('You are fine, don't worry!')
   * ```
   *
   * Remove a specific message:
   *
   * ```JavaScript
   * this.get('flashMessages').removeMessage(message)
   * ```
   *
   * Remove all messages:
   *
   * ```JavaScript
   * this.get('flashMessages').removeAllMessages()
   * ```
   *
   *
   *
   * @class FlashMessagesService
   * @extends Ember.Service
   */
  var _default = _exports.default = _service.default.extend({
    /**
     * @memberof FlashMessagesService
     * @instance
     *
     * @private
     * @type {number}
     */
    _uid: 0,
    /**
     * @memberof FlashMessagesService
     * @instance
     *
     * @private
     * @type {array}
     */
    messages: null,
    formElements: (0, _service.inject)(),
    init() {
      this._super(...arguments);
      this.set('messages', []);
    },
    /**
     * @memberof FlashMessagesService
     * @instance
     *
     * @private
     *
     * @param {string} type
     * @param {string} text
     *
     * @return {FlashMessage}
     */
    addMessage(type, text) {
      const messageTypes = ['success', 'warning', 'error'];
      (false && !(messageTypes.indexOf(type) !== -1) && (0, _debug.assert)(`A flash-message has to be one of the following types: ${messageTypes.join(', ')}`, messageTypes.indexOf(type) !== -1));
      const id = this.incrementProperty('_uid');
      const message = {
        id,
        type,
        text
      };
      (0, _array.A)(this.get('messages')).addObject(message);
      const defaultsKey = (0, _string.camelize)(`remove-${type}-after`);
      const timeout = this.get(`formElements.defaults.${defaultsKey}`);
      if (!(0, _utils.isNone)(timeout)) {
        (0, _runloop.later)(() => this.removeMessage(message), timeout);
      }
      return message;
    },
    /**
     * @memberof FlashMessagesService
     * @instance
     *
     * @param {string} message
     *
     * @return {FlashMessage}
     */
    addSuccessMessage(message) {
      return this.addMessage('success', message);
    },
    /**
     * @memberof FlashMessagesService
     * @instance
     *
     * @param {string} message
     *
     * @return {FlashMessage}
     */
    addWarningMessage(message) {
      return this.addMessage('warning', message);
    },
    /**
     * @memberof FlashMessagesService
     * @instance
     *
     * @param {string} message
     *
     * @return {FlashMessage}
     */
    addErrorMessage(message) {
      return this.addMessage('error', message);
    },
    /**
     * @memberof FlashMessagesService
     * @instance
     *
     * @param {object|string} error
     *
     * @return {FlashMessage[]}
     */
    addErrorMessages(error) {
      if (typeof error === 'string') {
        return [this.addErrorMessage(error)];
      }
      const errorDetails = (0, _object.get)(error, 'errors') || (0, _object.get)(error, 'details') || [];
      const messagesCount = this.get('messages.length');
      if ((0, _object.get)(error, 'message')) {
        this.addErrorMessage((0, _object.get)(error, 'message'));
      } else {
        (0, _debug.debug)(error);
      }
      errorDetails.forEach(detail => {
        if ((0, _object.get)(detail, 'message')) {
          this.addErrorMessage((0, _object.get)(detail, 'message'));
        } else {
          (0, _debug.debug)(detail);
        }
      });
      return this.get('messages').slice(messagesCount);
    },
    /**
     * @memberof FlashMessagesService
     * @instance
     *
     * @param {object} message
     */
    removeMessage(message) {
      (0, _array.A)(this.get('messages')).removeObject(message);
    },
    /**
     * @memberof FlashMessagesService
     * @instance
     */
    removeAllMessages() {
      this.set('messages', []);
    }
  });
});