define("ln-ember-form-elements/templates/components/form-elements/-html-element", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "DGi+27ba",
    "block": "[[[41,[48,[30,2]],[[[1,\"  \"],[18,2,null],[1,\"\\n\"]],[]],[[[1,\"  \"],[1,[30,1]],[1,\"\\n\"]],[]]]],[\"@content\",\"&default\"],false,[\"if\",\"has-block\",\"yield\"]]",
    "moduleName": "ln-ember-form-elements/templates/components/form-elements/-html-element.hbs",
    "isStrictMode": false
  });
});