define("ln-ember-data-models/models/vdc/acl-class", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    attr,
    Model
  } = _emberData.default;

  /**
   * @class Vdc/AclClassModel
   * @extends DS.Model
   */
  var _default = _exports.default = Model.extend({
    /**
     * @memberof Vdc/AclClassModel
     * @instance
     *
     * @type {Number}
     */
    version: attr('number'),
    /**
     * @memberof Vdc/AclClassModel
     * @instance
     *
     * @type {String}
     */
    name: attr('nullable-string')
  });
});