define("ln-ember-data-models/serializers/vdc/article", ["exports", "ln-ember-data-models/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * @class Vdc/KpiSerializer
   * @extends ApplicationSerializer
   */
  var _default = _exports.default = _application.default.extend({
    // Serializer's `attr` property is included in `mergedProperties`, so it's not leaking.
    // eslint-disable-next-line ember/avoid-leaking-state-in-ember-objects
    attrs: {
      attributes: {
        serialize: 'ids',
        deserialize: 'records'
      },
      marketing_objects: {
        deserialize: 'records',
        serialize: 'ids'
      }
    },
    extractRelationships(modelClass, resourceHash) {
      const result = this._super(modelClass, resourceHash);

      // API incorrectly returns `fit_criteria` in `fit_criterion` field.
      result.fit_criteria = {
        data: resourceHash.fit_criterion
      };
      return result;
    },
    keyForRelationship(key, kind) {
      if (key === 'attributes' && kind === 'hasMany') {
        return 'attribute_ids';
      }
      return this._super(...arguments);
    },
    serializeHasMany(snapshot, json, relationship) {
      const key = this.keyForRelationship(relationship.key, relationship.kind);
      this._super(snapshot, json, relationship);

      // API accepts only number[]
      if (key === 'attribute_ids') {
        json[key] = (json[key] || []).map(Number);
      }
    },
    serializeIntoHash(hash, typeClass, snapshot, options) {
      this._super(...arguments);
      const serialized = this.serialize(snapshot, options);
      if (serialized.marketing_objects && serialized.marketing_objects.length) {
        hash.marketing_objects = serialized.marketing_objects.map(Number);
      }
    }
  });
});