define("ln-ember-form-elements/components/flash-messages/flash-message", ["exports", "@ember/component", "@ember/object", "@ember/object/evented", "@ember/runloop", "@ember/string", "jquery", "ln-ember-toolkit/mixins/resize-events", "ln-ember-form-elements/templates/components/flash-messages/flash-message"], function (_exports, _component, _object, _evented, _runloop, _string, _jquery, _resizeEvents, _flashMessage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * @class flashMessages/FlashMessageComponent
   * @extends @ember/component
   *
   * @see flashMessages/FlashMessagesComponent
   */
  var _default = _exports.default = _component.default.extend(_resizeEvents.default, {
    layout: _flashMessage.default,
    attributeBindings: ['style'],
    classNames: [
    // Deprecated: { id: ln-ember-form-elements-class-names, until: 3.0.0 }
    'flash-message-component', 'flash-messages--flash-message-component'],
    classNameBindings: ['message.type', 'message.visible', 'resizing'],
    allMessages: null,
    message: null,
    onHeightChange() {},
    onRemove() {},
    onCloseClick() {},
    style: (0, _object.computed)('allMessages.@each.{visible,height}', function () {
      const allMessages = this.get('allMessages');
      const message = this.get('message');
      if (!allMessages) {
        return null;
      }
      const offset = allMessages.reduce((height, current) => {
        if ((0, _object.get)(current, 'visible') !== true && (0, _object.get)(current, 'id') > (0, _object.get)(message, 'id') || (0, _object.get)(current, 'id') < (0, _object.get)(message, 'id')) {
          return height;
        }
        return height + (0, _object.get)(current, 'height');
      }, 0);
      const z = message.visible ? 1 : 0;
      return (0, _string.htmlSafe)(`transform: translate3d(0, -${offset}px, ${z}px)`);
    }),
    onResize: (0, _evented.on)('resize', function () {
      this.set('resizing', true);
      this.updateHeight();
      (0, _runloop.later)(() => {
        this.set('resizing', false);
      }, 50);
    }),
    didReceiveAttrs() {
      if (this.get('message.visible') === false) {
        (0, _jquery.default)(this.element).one('transitionend', () => {
          this.get('onRemove')();
        });
      }
    },
    didRender() {
      this._super(...arguments);
      this.updateHeight();
    },
    actions: {
      removeMessage(event) {
        this.get('onCloseClick')(event.altKey);
      }
    },
    updateHeight() {
      (0, _runloop.scheduleOnce)('afterRender', () => {
        if (!(0, _jquery.default)(this.element) || this.get('isDestroyed')) {
          return;
        }
        const height = (0, _jquery.default)(this.element).outerHeight(true);
        this.set('height', height);
        this.get('onHeightChange')(height);
      });
    }
  });
});