define("ln-ember-data-models/models/vdc/market-assignment", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    attr,
    Model
  } = _emberData.default;

  /**
   * @class Vdc/MarketAssignmentModel
   * @extends DS.Model
   */
  var _default = _exports.default = Model.extend({
    /**
     * @memberof Vdc/MarketAssignmentModel
     * @instance
     *
     * @type {Number}
     */
    version: attr('number'),
    /**
     * @memberof Vdc/MarketAssignmentModel
     * @instance
     *
     * @type {Number}
     */
    slots: attr('number'),
    /**
     * @memberof Vdc/MarketAssignmentModel
     * @instance
     *
     * @type {Number}
     */
    market_id: attr('id'),
    /**
     * @memberof Vdc/MarketAssignmentModel
     * @instance
     *
     * @type {String}
     */
    market_name: attr('nullable-string'),
    /**
     * @memberof Vdc/MarketAssignmentModel
     * @instance
     *
     * @type {Number}
     */
    available_slots: attr('number'),
    /**
     * @memberof Vdc/MarketAssignmentModel
     * @instance
     *
     * @type {Array}
     */
    registered_audience: attr({
      defaultValue: () => []
    })
  });
});