define("ln-ember-data-models/adapters/vdc/contextual-role-assignment", ["exports", "ln-ember-data-models/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * @class Vdc/ContextualRoleAssignmentAdapter
   * @extends ApplicationAdapter
   */
  var _default = _exports.default = _application.default.extend({
    urlForDeleteRecord(id, modelName, snapshot) {
      return this.buildDeleteUrl(modelName, id, snapshot);
    },
    buildDeleteUrl(modelName, id, snapshot) {
      if (modelName) {
        let path = this.pathForType(modelName);
        if (path) {
          const role = snapshot.record.get('role_id');
          const context = snapshot.record.get('context_id');
          const context_type = snapshot.record.get('role_type');
          const client = snapshot.record.get('client_id');
          path += `?role=${role}&context=${context}&context_type=${context_type}&client=${client}`;
          return this.buildURLFor(path, null, modelName, null);
        }
      }
    }
  });
});