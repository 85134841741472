define("ln-ember-data-models/models/vdc/fixture", ["exports", "ember-data", "@ember/object/computed", "ember-data-model-fragments/attributes"], function (_exports, _emberData, _computed, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    attr,
    belongsTo,
    Model
  } = _emberData.default;

  /**
   * @class Vdc/FixtureModel
   * @extends DS.Model
   */
  var _default = _exports.default = Model.extend({
    /**
     * @memberof Vdc/FixtureModel
     * @instance
     *
     * @type {Number}
     */
    version: attr('number'),
    /**
     * @memberof Vdc/FixtureModel
     * @instance
     *
     * @type {String}
     */
    name: attr('nullable-string'),
    /**
     * @memberof Vdc/FixtureModel
     * @instance
     *
     * @type {String}
     */
    number: attr('nullable-string'),
    /**
     * @memberof Vdc/FixtureModel
     * @instance
     *
     * @type {Number}
     */
    language_id: attr('id'),
    /**
     * @memberof Vdc/FixtureModel
     * @instance
     *
     * @type {Number}
     */
    fixture_type_id: attr('id'),
    /**
     * @memberof Vdc/FixtureModel
     * @instance
     *
     * @type {String}
     */
    fixture_type_name: attr('nullable-string'),
    /**
     * @memberof Vdc/FixtureModel
     * @instance
     *
     * API sends a fragment of this attribute set, with only those attributes that belong to fixture_type
     * but with still the same id
     * If we set this as `belongsTo('vdc/attribute-set')`, then EmberData will overwrite the values.
     *
     * @type {Vdc/AttributeSetModel}
     */
    attribute_set: attr(),
    /**
     * @memberof Vdc/FixtureModel
     * @instance
     *
     * @type {Number}
     */
    instore_area_id: attr('id'),
    /**
     * @memberof Vdc/FixtureModel
     * @instance
     *
     * @type {String}
     */
    instore_area_name: attr('nullable-string'),
    /**
     * @memberof Vdc/FixtureModel
     * @instance
     *
     * @type {Object}
     */
    window_area: attr(),
    /**
     * @memberof Vdc/FixtureModel
     * @instance
     *
     * @type {Number}
     */
    window_id: attr('id'),
    /**
     * @memberof Vdc/FixtureModel
     * @instance
     *
     * @type {String}
     */
    window_name: attr('nullable-string'),
    /**
     * @memberof Vdc/FixtureModel
     * @instance
     *
     * @type {Number}
     */
    store_id: attr('id'),
    /**
     * @memberof Vdc/FixtureModel
     * @instance
     *
     * @type {String}
     */
    store_name: attr('nullable-string'),
    /**
     * @memberof Vdc/FixtureModel
     * @instance
     *
     * @type {String}
     */
    store_legal_name: attr('nullable-string'),
    /**
     * @memberof Vdc/FixtureModel
     * @instance
     *
     * @type {String}
     */
    store_number: attr('nullable-string'),
    /**
     * @memberof Vdc/FixtureModel
     * @instance
     *
     * @type {String}
     */
    store_street1: attr('nullable-string'),
    /**
     * @memberof Vdc/FixtureModel
     * @instance
     *
     * @type {String}
     */
    store_street2: attr('nullable-string'),
    /**
     * @memberof Vdc/FixtureModel
     * @instance
     *
     * @type {String}
     */
    city_name: attr('nullable-string'),
    /**
     * @memberof Vdc/FixtureModel
     * @instance
     *
     * @type {Number}
     */
    amount: attr('number'),
    /**
     * @memberof Vdc/FixtureModel
     * @instance
     *
     * @type {Number}
     */
    country_id: attr('id'),
    /**
     * @memberof Vdc/FixtureModel
     * @instance
     *
     * @type {String}
     */
    country_name: attr('nullable-string'),
    /**
     * @memberof Vdc/FixtureModel
     * @instance
     *
     * @type {String}
     */
    market_name: attr('nullable-string'),
    /**
     * @memberof Vdc/FixtureModel
     * @instance
     *
     * @type {Vdc/PictureModel}
     */
    picture: belongsTo('vdc/picture', {
      async: true
    }),
    /**
     * @memberof Vdc/FixtureModel
     * @instance
     *
     * @type {Vdc/PictureModel}
     */
    preview_picture: belongsTo('vdc/picture'),
    /**
     * @memberof Vdc/FixtureModel
     * @instance
     *
     * @type {Vdc/AssemblyModel}
     */
    assembly: belongsTo('vdc/assembly'),
    /**
     * @memberof Vdc/FixtureModel
     * @instance
     *
     * @type {Vdc/fragments/AttributeValueFragment[]}
     */
    attribute_values: (0, _attributes.fragmentArray)('vdc/fragments/attribute-value'),
    /**
     * @memberof Vdc/FixtureModel
     * @instance
     *
     * @type {String}
     */
    localization: (0, _computed.or)('window_name', 'instore_area_name')
  });
});