define("ln-ember-form-elements/templates/components/form-elements/option-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "fxDHzCXg",
    "block": "[[[41,[30,0,[\"label\"]],[[[10,\"label\"],[15,\"for\",[30,0,[\"elementId\"]]],[12],[1,[30,0,[\"label\"]]],[13]],[]],null],[1,\"\\n\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,0,[\"renderOptions\"]]],null]],null],null,[[[6,[39,3],null,[[\"theme\",\"action\"],[[52,[30,1,[\"active\"]],[30,0,[\"highlightTheme\"]],[30,0,[\"theme\"]]],[28,[37,4],[[30,0],\"setValue\",[30,1]],null]]],[[\"default\"],[[[[1,\"    \"],[1,[30,1,[\"label\"]]],[1,\"\\n\"]],[]]]]]],[1]],null]],[\"option\"],false,[\"if\",\"each\",\"-track-array\",\"form-elements/styled-button\",\"action\"]]",
    "moduleName": "ln-ember-form-elements/templates/components/form-elements/option-button.hbs",
    "isStrictMode": false
  });
});