define("ln-ember-form-elements/components/form-elements/date-time-input", ["exports", "moment", "@ember/runloop", "ln-ember-form-elements/components/form-elements/date-input-base", "ln-ember-form-elements/templates/components/form-elements/date-time-input", "ln-ember-form-elements/utils/date-helper", "@ember/object/computed"], function (_exports, _moment, _runloop, _dateInputBase, _dateTimeInput, _dateHelper, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   *
   * ### Value
   *
   * The value is a string in the iso format or `null`.
   *
   * ### Examples
   *
   * #### Basic usage:
   *
   * For the basic usage the only required attributes are the current `value` and
   * handler for `onChange` events.
   *
   * ```Handlebars
   * {{form-elements/date-time-input
   *   value=dateValue
   *   onChange=(action "onDateChange")
   * }}
   * ```
   *
   * ```JavaScript
   * Component.extend({
   *   actions: {
   *     onDateChange(value) {
   *       this.set('dateValue', value)
   *     }
   *   }
   * })
   * ```
   *
   * ### Demo
   *
   * <iframe src="showcase/index.html#/demo/date-time-input" style="width: 360px;border: 1px solid black;height:450px;padding: 20px;"></iframe>
   *
   * @class formElements/DateTimeInputComponent
   * @extends formElements/DateInputBaseComponent
   */
  var _default = _exports.default = _dateInputBase.default.extend({
    /**
     * The value should be in the format `YYYY-MM-DD`.
     *
     * @memberof formElements/DateTimeInputComponent
     * @instance
     *
     * @type {string} - ISO date format
     * @default null
     */
    value: null,
    classNames: ['form-elements--date-time-input-component'],
    layout: _dateTimeInput.default,
    hasValue: (0, _computed.bool)('value').readOnly(),
    init() {
      this._super(...arguments);
      this.set('timeValue', (0, _dateHelper.formatDate)(this.get('value'), 'HH:mm'));
    },
    actions: {
      onIncrementFocusedElements() {
        this._super(...arguments);
        this.ensureHasValue();
      },
      onDecrementFocusedElements() {
        const dateFormat = this.get('formElements.defaults.formatDateTime');

        /**
         * If input is cleared, or displayValue does not match required date-time format,
         * reset the value to the original one.
         */
        if (!this.get('displayValue') || this.get('displayValue') !== (0, _moment.default)(this.get('value')).format(dateFormat)) {
          const date = (0, _moment.default)(this.get('value'), `${dateFormat} Z`);
          this.triggerChange(date);
        }
        this._super(...arguments);
      },
      onCalendarChange(value) {
        value = this.applyTime(value, (0, _moment.default)(this.get('value')).format('HH:mm'));
        this.triggerChange((0, _moment.default)(value));
      },
      onInputChange(value) {
        if (!value) {
          return;
        }
        const dateFormat = this.get('formElements.defaults.formatDateTime');
        const date = (0, _moment.default)(`${value}`, `${dateFormat}`, true);
        if (this.isValid(date)) {
          this.triggerChange(date);
        }
      },
      onTimeInputFocusOut(time) {
        const value = this.applyTime(this.get('value'), time);
        if (value) {
          this.triggerChange((0, _moment.default)(value));
        }
        (0, _runloop.next)(() => {
          this.send('onDecrementFocusedElements');
        });
      }
    },
    formatDate(date) {
      return (0, _dateHelper.formatDate)(date, this.get('formElements.defaults.formatDateTime'));
    },
    triggerChange(value) {
      this.set('displayValue', (0, _moment.default)(value).format(this.get('formElements.defaults.formatDateTime')));
      this.set('timeValue', (0, _dateHelper.formatDate)(value, 'HH:mm'));
      this.get('onChange')(value && (0, _moment.default)(value).toISOString());
    },
    getVisibleDate() {
      return (0, _moment.default)(this.get('value') || undefined).format('YYYY-MM-DD');
    },
    isValid(value) {
      return (0, _moment.default)(value).isValid();
    },
    ensureHasValue() {
      if (!this.get('hasValue')) {
        const date = new Date();
        if (this.get('defaultTime')) {
          const [hours, minutes] = this.get('defaultTime').split(':');
          date.setHours(hours, minutes);
        }
        this.triggerChange((0, _moment.default)(date));
      }
    }
  });
});