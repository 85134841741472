define("ln-ember-toolkit/services/logger", ["exports", "ember", "@ember/service", "jquery"], function (_exports, _ember, _service, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable no-console */

  let origOnError;

  /**
   * @class Logger
   * @extends Ember.Service
   */
  var _default = _exports.default = _service.default.extend({
    init() {
      origOnError = _ember.default.onerror;
      // Set `Ember.onerror` handler, to handle all uncaught exceptions.
      _ember.default.onerror = error => this.error(error);
      this._super(...arguments);
    },
    destroy() {
      _ember.default.onerror = origOnError;
    },
    /**
     * @memberof Logger
     * @instance
     *
     * @param {String|Object} message
     */
    error(message) {
      if (typeof message === 'object' && message.stack) {
        message = (message.stack || message.message).replace(/^Error: /, '');
      }
      if (!this.config.get('logger.quiet')) {
        console.error(message);
      }
      this.push({
        level: 'Error',
        message
      });
    },
    /**
     * @memberof Logger
     * @instance
     *
     * @param {String|Object} message
     */
    warn(message) {
      if (!this.config.get('logger.quiet')) {
        // eslint-disable-next-line no-console
        console.warn(message);
      }
      this.push({
        level: 'Warning',
        message
      });
    },
    /**
     * @memberof Logger
     * @instance
     *
     * @param {String|Object} message
     */
    log(message) {
      if (!this.config.get('logger.quiet')) {
        // eslint-disable-next-line no-console
        console.log(message);
      }
      this.push({
        level: 'Log',
        message
      });
    },
    /**
     * @memberof Logger
     * @instance
     *
     * @private
     * @param {Object} data
     */
    push(data) {
      const url = this.config.get('logger.url');
      if (url) {
        data.source = 'myliga';
        _jquery.default.ajax({
          type: 'POST',
          url,
          crossDomain: true,
          dataType: 'json',
          data
        });
      }
    }
  });
});