define("ln-ember-data-models/models/vdc/attribute", ["exports", "ember-data", "ln-ember-data-models/utils/enum-option"], function (_exports, _emberData, _enumOption) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.typeOptions = _exports.default = void 0;
  const {
    attr,
    hasMany,
    Model
  } = _emberData.default;

  /**
   * @name Vdc/AttributeModel/typeOptions
   * @type {EnumOption[]}
   */
  const typeOptions = _exports.typeOptions = [_enumOption.default.create({
    id: 'Text'
  }), _enumOption.default.create({
    id: 'Number'
  }), _enumOption.default.create({
    id: 'Boolean'
  }), _enumOption.default.create({
    id: 'Options'
  }), _enumOption.default.create({
    id: 'Language'
  }), _enumOption.default.create({
    id: 'Motif'
  }), _enumOption.default.create({
    id: 'Material'
  }), _enumOption.default.create({
    id: 'Date'
  })];

  /**
   * @class Vdc/AttributeModel
   * @extends DS.Model
   */
  const AttributeModel = Model.extend({
    /**
     * @memberof Vdc/AttributeModel
     * @instance
     *
     * @type {Number}
     */
    version: attr('number'),
    /**
     * @memberof Vdc/AttributeModel
     * @instance
     *
     * @type {String}
     */
    name: attr('nullable-string'),
    /**
     * @memberof Vdc/AttributeModel
     * @instance
     *
     * @type {String}
     */
    type: attr('enum-string', {
      options: typeOptions
    }),
    /**
     * @memberof Vdc/AttributeModel
     * @instance
     *
     * @type {Boolean}
     */
    mandatory: attr('boolean', {
      defaultValue: () => false
    }),
    /**
     * @memberof Vdc/AttributeModel
     * @instance
     *
     * @type {Boolean}
     */
    show_in_list: attr('boolean', {
      defaultValue: () => false
    }),
    /**
     * @memberof Vdc/AttributeModel
     * @instance
     *
     * @type {Boolean}
     */
    show_in_assemblymaster: attr('boolean', {
      defaultValue: () => false
    }),
    /**
     * @memberof Vdc/AttributeModel
     * @instance
     *
     * @type {Boolean}
     */
    show_in_filter: attr('boolean', {
      defaultValue: () => false
    }),
    /**
     * @memberof Vdc/AttributeModel
     * @instance
     *
     * @type {Boolean}
     */
    show_in_map: attr('boolean', {
      defaultValue: () => false
    }),
    /**
     * @memberof Vdc/AttributeModel
     * @instance
     *
     * @type {Number}
     */
    position: attr('number'),
    /**
     * @memberof Vdc/AttributeModel
     * @instance
     *
     * @type {Number}
     */
    attribute_set_id: attr('id'),
    /**
     * @memberof Vdc/AttributeModel
     * @instance
     *
     * @type {Number}
     */
    linked_attribute_id: attr('id'),
    /**
     * @memberof Vdc/AttributeModel
     * @instance
     *
     * @type {AttributeOptionModel[]}
     */
    attribute_options: hasMany('vdc/attribute-option', {
      readOnly: true,
      async: true
    })
  });
  AttributeModel.reopenClass({
    /**
     * @memberof Vdc/AttributeModel
     * @static
     *
     * @type {EnumOption[]}
     */
    typeOptions
  });
  var _default = _exports.default = AttributeModel;
});