define("ln-ember-data-models/models/vdc/right-group", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    attr,
    hasMany,
    Model
  } = _emberData.default;

  /**
   * @class Vdc/RightGroupModel
   * @extends DS.Model
   */
  var _default = _exports.default = Model.extend({
    /**
     * @memberof Vdc/RightGroupModel
     * @instance
     *
     * @type {Number}
     */
    version: attr('number'),
    /**
     * @memberof Vdc/RightGroupModel
     * @instance
     *
     * @type {String}
     */
    name: attr('nullable-string'),
    /**
     * @memberof Vdc/RightGroupModel
     * @instance
     *
     * @type {String}
     */
    date_created: attr('date-time-string'),
    /**
     * @memberof Vdc/RightGroupModel
     * @instance
     *
     * @type {Number}
     */
    application_id: attr('id'),
    /**
     * @memberof Vdc/RightGroupModel
     * @instance
     *
     * @type {Vdc/RightGroupEntry[]}
     */
    entries: hasMany('vdc/right-group-entry')
  });
});