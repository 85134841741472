define("ln-ember-form-elements/templates/components/form-elements/validation-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "A/kjsOGH",
    "block": "[[[44,[\"FormElementsValidationIcon\"],[[[1,\"  \"],[8,[39,1],[[16,0,[30,1]],[16,1,[30,0,[\"_elementId\"]]],[17,2]],[[\"@name\"],[[28,[37,2],[\"exclamation\",[30,3]],null]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,3],null,[[\"@arrow\",\"@id\"],[true,[29,[[30,0,[\"_elementId\"]],\"-validationTooltip\"]]]],[[\"default\"],[[[[1,\"\\n      \"],[10,1],[15,0,[29,[[30,1],\"-validationMessage\\n        \",[52,[30,4],[28,[37,5],[[30,1],\"-validationMessage--error\"],null],[28,[37,5],[[30,1],\"-validationMessage--warning\"],null]],\"\\n      \"]]],[12],[1,\"\\n        \"],[1,[28,[35,2],[[30,4],[30,5]],null]],[1,\"\\n      \"],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[1]]]],[\"scope\",\"&attrs\",\"@icon\",\"@errorMessage\",\"@warningMessage\"],false,[\"let\",\"svg-icon\",\"or\",\"ln-tooltip\",\"if\",\"concat\"]]",
    "moduleName": "ln-ember-form-elements/templates/components/form-elements/validation-icon.hbs",
    "isStrictMode": false
  });
});