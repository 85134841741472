define("ln-ember-form-elements/components/form-elements/styled-select", ["exports", "jquery", "@ember/polyfills", "@ember/component", "@ember/object/computed", "@ember/debug", "@ember/application/deprecations", "@ember/object/internals", "@ember/service", "@ember/array", "@ember/utils", "@ember/object", "rsvp", "@ember/runloop", "@ember/string", "ln-ember-form-elements/templates/components/form-elements/styled-select", "ln-ember-form-elements/utils/enforce-array", "ln-ember-form-elements/utils/weak-find", "ln-ember-form-elements/utils/weak-equals", "ln-ember-form-elements/utils/weak-union-by", "ln-ember-form-elements/utils/tabbable-element"], function (_exports, _jquery, _polyfills, _component, _computed, _debug, _deprecations, _internals, _service, _array, _utils, _object, _rsvp, _runloop, _string, _styledSelect, _enforceArray, _weakFind, _weakEquals, _weakUnionBy, _tabbableElement) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const HELPER_DIV = document.createElement('div');

  /**
   *
   * Renders an styled select.<br> Options can either be passed in as a property
   * or a resource to load options through ajax can be defined<br> If the options
   * get loaded through ajax requests the [API
   * service](https://bitbucket.org/ligadigital/ln-ember-api-service) has to be
   * injected into the component.
   *
   * ### Examples:
   *
   * ```Handlebars
   * {{form-elements/styled-select}}
   * ```
   *
   * ```Handlebars
   * {{form-elements/styled-select
   *   options=allItems
   *   preloadedOptions=preloadedOptions
   *   value=model.type_id
   *   optionValuePath="id"
   *   optionLabelPath="name"
   *   placeholder=(loc "Choose category")
   *   mandatory=true
   *   closeOnScrollContainer=".dialog"
   *   onChange=(action (mut model.type_id) value='value')
   *   onMissingOptions=(action "onMissingOptions")
   * }}
   * ```
   *
   * @class formElements/StyledSelectComponent
   * @extends @ember/component
  */
  var _default = _exports.default = _component.default.extend({
    api: (0, _service.inject)(),
    formElements: (0, _service.inject)(),
    layout: _styledSelect.default,
    tagName: 'div',
    classNames: [
    // Deprecated: { id: ln-ember-form-elements-class-names, until: 3.0.0 }
    'styled-select-component', 'form-elements--styled-select-component'],
    classNameBindings: ['readonly', 'error:has-error', 'searchIsOpen', 'isOpen', 'isActive:is-active:is-inactive', 'theme', 'themeClass', 'removeIsVisible', 'hasLabel'],
    removeIsVisible: false,
    isActive: true,
    /**
     * The label of the select field
     *
     * @memberof formElements/StyledSelectComponent
     * @instance
     *
     * @type String
     * @default null
     */
    label: null,
    /**
     * If this is a truthy value the input field will be set into the error state.
     *
     * @memberof formElements/StyledSelectComponent
     * @instance
     *
     * @type {any}
     * @default null
     */
    error: null,
    /**
     * An array of all options that are listed in the select field.
     *
     * @memberof formElements/StyledSelectComponent
     * @instance
     *
     * @type {Array}
     * @default null
     */
    options: null,
    /**
     * The value of the current selected option.<br>
     *
     * @memberof formElements/StyledSelectComponent
     * @instance
     * @private
     *
     * @type {any}
     * @default null
     */
    value: null,
    /**
     * Defines which attribute of an options object should be used as the value of
     * the option.<br>
     * For example if the value is the id of the object the `optionValuePath`
     * should be set to `id`
     *
     * @memberof formElements/StyledSelectComponent
     * @instance
     *
     * @type {String}
     * @default null
     */
    optionValuePath: null,
    /**
     * Defines which attribute of an options object should be used as the label of
     * the option.<br>
     * For example if the attribute `name` should be used as the label
     * `optionLabelPath` should be set to `name`
     *
     * @memberof formElements/StyledSelectComponent
     * @instance
     *
     * @type {String}
     * @default null
     */
    optionLabelPath: null,
    /**
     * If the options are loaded per ajax the `pagingLimit` sets the amount of
     * data to be loaded per page.
     *
     * @memberof formElements/StyledSelectComponent
     * @instance
     *
     * @type {Number}
     * @default 25
     */
    pagingLimit: 25,
    /**
     * TotalCount for all options in the select.
     *
     * @memberof formElements/StyledSelectComponent
     * @instance
     *
     * @type {Number}
     * @default 0
     */
    totalCount: 0,
    /**
     * If the select is mandatory, there is no button to unset the current
     * selected item.
     *
     * @memberof formElements/StyledSelectComponent
     * @instance
     *
     * @type {Boolean}
     * @default false
     */
    mandatory: false,
    /**
     * Defines if the select field is a multiple or single select field.
     *
     * @memberof formElements/StyledSelectComponent
     * @instance
     *
     * @type {Boolean}
     * @default false
     */
    multiple: false,
    /**
     * Because of technical limitations of select2 the dropdown won't update the
     * position if the select field is positioned in a scrollable container and
     * the user scrolls within this container.<br>
     * `closeOnScrollContainer` can be set to an jQuery selector string in which
     * case the select dropdown will be closed if the user is scrolling in that
     * container.
     *
     * @memberof formElements/StyledSelectComponent
     * @instance
     *
     * @type {String}
     * @default null
     */
    closeOnScrollContainer: null,
    /**
     * **The API service has to be injected into this component if you want to use
     * this feature!**<br>
     *
     * Defines the resource where select2 loads options per ajax requests.
     *
     * ### Example:
     *
     * ```JavaScript
     * { namespace: vdc, uri: '/users', parameters: { param1: 'test' } }
     * ```
     *
     * @memberof formElements/StyledSelectComponent
     * @instance
     *
     * @type {Object}
     * @default null
     * @requires API Service
     */
    optionsResource: null,
    /**
     * @memberof formElements/StyledSelectComponent
     * @instance
     *
     * @type {String}
     * @default 'light'
     */
    theme: null,
    /**
     * Can provide a function, which returns a HTML string, to change the option
     * template.
     *
     * The template function, gets the option passed in as paramter.
     *
     * ### Example 1:**
     *
     * ```JavaScript
     * Controller.extend({
     *
     *   options: [
     *      { id: 1, name: 'Project A', status: 'active' },
     *      { id: 2, name: 'Project B', status: 'inactive' }
     *   ]
     *
     *   optionTemplate: ({ id, name, status }) => `
     *     <span class="status-${status}">${name}</span>
     *   `
     * })
     * ```
     *
     * ```Handlebars
     * {{styled-select
     *   options=options
     *   optionTemplate=optionTemplate
     * }}
     * ```
      * ### Example 2:**
     *
     * You can also pass an action if you need to access `this`.
     *
     * ```JavaScript
     * Controller.extend({
     *
     *   options: [
     *     'Active',
     *     'Inactive'
     *   ]
     *
     *   actions: {
     *     optionTemplate(status) {
     *       const name = this.get('locale').translate(status)
      *       return `
     *         <span class="status-${status}">${name}</span>
     *       `
     *     }
     *   }
     * })
     * ```
     *
     * ```Handlebars
     * {{styled-select
     *   options=options
     *   optionTemplate=(action "optionTemplate")
     * }}
     * ```
     * @memberof formElements/StyledSelectComponent
     * @instance
     *
     * @type {Function}
     * @default null
     */
    optionTemplate: null,
    /**
     * @memberof formElements/StyledSelectComponent
     * @instance
     *
     * @type {function}
     * @default null
     */
    selectionTemplate: null,
    /**
     * Property to map the value change Event.
     *
     * ```JavaScript
     * onChange({filter, value, selectedOptions})
     * ```
     *
     * @memberof formElements/StyledSelectComponent
     * @instance
     *
     * @type {Function}
     */
    onChange: () => {},
    /**
     * Defines a placeholder to be shown in the select field if there is no option
     * selected.
     *
     * @memberof formElements/StyledSelectComponent
     * @instance
     *
     * @type {String}
     * @default 'Select an Option'
     */
    placeholder: (0, _string.loc)('Select an Option'),
    /**
     * If the number of options in the select field is higher then the
     * `minimumResultsForSearch` a search field will be shown.
     *
     * @memberof formElements/StyledSelectComponent
     * @instance
     *
     * @type {Number}
     * @default 10
     */
    minimumResultsForSearch: (0, _computed.alias)('formElements.defaults.minimumResultsForSearch'),
    /**
     * Function to map the missing option on external value change.
     *
     * `onMissingOptions({ filter, value })`
     *
     * @memberof formElements/StyledSelectComponent
     * @instance
     *
     * @type {Function}
     */
    onMissingOptions: () => {},
    /**
     * Function called when select is opening.
     *
     * `onOpening(select)`
     *
     * Return `false` to prevent opening the select.
     *
     * @memberof formElements/StyledSelectComponent
     * @instance
     *
     * @type {Function}
     */
    onOpening: () => {},
    /**
     * @memberof formElements/StyledSelectComponent
     * @instance
     *
     * @type {String}
     * @default 'Searching...'
     */
    searchingMessage: (0, _string.loc)('Searching...'),
    /**
     * @memberof formElements/StyledSelectComponent
     * @instance
     *
     * @type {String}
     * @default 'No results found'
     */
    noResultsMessage: (0, _string.loc)('No results found'),
    hasLabel: (0, _object.computed)('label', function () {
      return Boolean(this.get('label'));
    }),
    selectedCount: (0, _object.computed)('internalValues.[]', function () {
      const values = this.get('internalValues');
      return (0, _array.isArray)(values) ? values.length : 0;
    }),
    removeIsAllowed: (0, _object.computed)('_removeIsAllowed', 'mandatory', 'internalValues.length', 'readonly', {
      get() {
        if (this._removeIsAllowed !== undefined) {
          return this._removeIsAllowed;
        }
        return Boolean(!this.get('mandatory') && !this.get('readonly') && this.get('internalValues.length'));
      },
      set(_key, value) {
        this.set('_removeIsAllowed', value);
        return value;
      }
    }),
    themeClass: (0, _object.computed)('theme', function () {
      return `theme-${this.get('theme') || 'light'}`;
    }),
    _select2Placeholder: (0, _object.computed)('multiple', 'placeholder', function () {
      return this.get('multiple') ? null : String(this.get('placeholder'));
    }),
    searchIsOpen: (0, _object.computed)('isOpen', function () {
      if (!this.get('multiple') && this.get('isOpen') && (0, _jquery.default)('select', this.element)) {
        const {
          $dropdown
        } = this.select2();
        return $dropdown.find('.select2-search__field:visible').length > 0;
      }
      return false;
    }),
    /**
     * Function called to process ajax results.
     *
     * @memberof formElements/StyledSelectComponent
     * @instance
     *
     * @type {Function}
     * @param {Array} results
     * @returns {Array}
     */
    onProcessResults: results => results,
    /**
     * Private options list required to maintain the value type and
     * currentSelected Values
     *
     * @memberof formElements/StyledSelectComponent
     * @instance
     * @private
     *
     * @type {Array}
     * @default null
     */
    internalOptions: null,
    /**
     * List of preloadedOptions for the styled select, the component is watching
     * this property to update itself this is the only way to update the component
     * values from outside.
     *
     * @memberof formElements/StyledSelectComponent
     * @instance
     *
     * @type {Array}
     * @default null
     */
    preloadedOptions: null,
    /**
     * defines Select2 behavior on select element (close or not )
     *
     * @memberof formElements/StyledSelectComponent
     * @instance
     *
     * @type {Boolean}
     * @default true
     */
    closeOnSelect: true,
    /**
     * Open select options on focus.
     *
     * @memberof formElements/StyledSelectComponent
     * @instance
     *
     * @type {Boolean}
     * @default false
     */
    openOnFocus: false,
    /**
     * Passed to select2 to fix the width of the options list
     *
     * @see https://github.com/ligadigital/select2/commit/c6414e52000eb2a9668fda7e02c3b99cb60ece14
     *
     * @memberof formElements/StyledSelectComponent
     * @instance
     *
     * @type {Boolean}
     * @default false
     */
    dropdownAutoWidth: false,
    /**
     * Set the search query param for the api as it differs between VDC and
     * MyLIGA.
     *
     * @memberof formElements/StyledSelectComponent
     * @instance
     *
     * @type {string}
     * @default search_param  (MyLIGA value)
     */
    searchParam: 'search_param',
    /**
     * TBD
     *
     * @memberof formElements/StyledSelectComponent
     * @instance
     *
     * @type {Boolean}
     * @default false
     */
    animate: false,
    /**
     * If set to true, select will be disabled/readonly
     *
     * @memberof formElements/StyledSelectComponent
     * @instance
     *
     * @type {Boolean}
     * @default null
     */
    readonly: null,
    /**
     * TBD
     *
     * @memberof formElements/StyledSelectComponent
     * @instance
     *
     * @type {String}
     * @default null
     *
     * @deprecated
     */
    filter: null,
    /**
     * @memberof formElements/StyledSelectComponent
     *
     * @instance
     * @private
     *
     * @type {String}
     * @default null
     */
    optionsHash: null,
    /**
     * Should display the count of selected options
     *
     * @memberof formElements/StyledSelectComponent
     * @instance
     *
     * @type {Boolean}
     * @default false
     */
    showCount: false,
    /**
     * This class will be assigned to select2s dropdown element. This is achieved
     * by setting the `dropdownCssClass` option of select2.
     *
     * @memberof formElements/StyledSelectComponent
     * @instance
     *
     * @type {String|Function}
     */
    dropdownClass: null,
    internalValues: null,
    isOpen: null,
    isTouchDevice: false,
    _removeIsAllowed: undefined,
    init() {
      this._super(...arguments);
      if (!this.get('theme')) {
        this.set('theme', this.get('formElements.defaults.theme'));
      }
      this.totalCountSetter();
      this.initLastValues();
      this.set('isTouchDevice', 'ontouchstart' in window);
      this.handleMouseEnter = () => {
        if (this.get('animate') && !this.get('isTouchDevice')) {
          const label = (0, _jquery.default)('.multiple-label', this.element);
          if ((0, _jquery.default)('.full-text', this.element).length === 0) {
            label.wrapInner('<span class="full-text"></span>');
          }
          const longText = (0, _jquery.default)('.full-text', this.element);
          if (longText.outerWidth() > label.width()) {
            label.css('text-overflow', 'clip');
            longText.css('margin-left', `${label.width() - longText.outerWidth()}px`);
          }
        }
      };
      this.handleMouseLeave = () => {
        if (this.get('animate')) {
          const longText = (0, _jquery.default)('.full-text', this.element);
          (0, _jquery.default)('.multiple-label', this.element).one('transitionend', () => {
            (0, _jquery.default)('.multiple-label', this.element).css('text-overflow', '');
          });
          longText.css('margin-left', '0px');
        }
      };
      (false && !(!this.get('filter')) && (0, _deprecations.deprecate)('[StyledSelectComponent] Filter is deprecated. Use this bind identifiers: `onChange=(action "onChange" "myIdentifier")`', !this.get('filter'), {
        for: 'ln-ember-form-elements',
        id: 'styled-select-filter-attribute',
        since: '3.0.0-beta.115',
        until: '3.0.0'
      }));
    },
    initLastValues() {
      if (this.get('optionsResource')) {
        return;
      }
      if ((0, _array.isArray)(this.get('options'))) {
        this.set('_lastOptions', (0, _polyfills.assign)([], this.get('options')));
      } else {
        this.set('_lastOptions', []);
      }
      if ((0, _array.isArray)(this.get('value'))) {
        this.set('_lastValue', (0, _polyfills.assign)([], this.get('value')));
      } else {
        this.set('_lastValue', this.get('value'));
      }
    },
    didReceiveAttrs() {
      if ((0, _utils.isNone)(this.get('_lastValue'))) {
        if ((0, _array.isArray)(this.get('value'))) {
          this.set('_lastValue', (0, _polyfills.assign)([], this.get('value')));
        } else {
          this.set('_lastValue', this.get('value'));
        }
      }
    },
    createTemplate(property) {
      const template = this.get(property);
      if (typeof template !== 'function') {
        // Default behavior
        return undefined;
      }
      return ({
        text,
        option
      }) => {
        if (!text && option) {
          // Do not handle preLoadedOptions
          return;
        }
        if (!option) {
          const preloadedOption = this.formatOptions(this.get('preloadedOptions')).find(({
            text: pText
          }) => pText === text);
          if (preloadedOption) {
            ({
              option
            } = preloadedOption);
          } else {
            // Do not handle status messages (like "Loading...")
            return text;
          }
        }

        // Create DOMElement from string
        HELPER_DIV.innerHTML = template(option);
        return HELPER_DIV.childNodes;
      };
    },
    /**
     * Sets totalCount for all types of Arrays.<br>
     * used for initial render before open the select while there is preloadedOptions
     *
     * @memberof formElements/StyledSelectComponent
     * @instance
     * @private
     */
    totalCountSetter() {
      if (!this.get('multiple')) {
        // For single selects totalCount does not need to be updated, since it is
        // never displayed.

        return;
      }
      (0, _rsvp.resolve)(this.get('preloadedOptions')).then(preloadedOptions => {
        const preloadedOptionsLength = (0, _object.get)((0, _enforceArray.default)(preloadedOptions), 'length') || 0;
        if (this.get('options')) {
          (0, _rsvp.resolve)(this.get('options')).then(({
            length
          }) => {
            if (this.get('isDestroyed')) {
              return;
            }
            this.set('totalCount', length + preloadedOptionsLength);
          });
        } else if (this.get('optionsResource')) {
          const {
            namespace,
            uri
          } = this.get('optionsResource');

          // FIXME this should not be the default behavior.
          //
          // If you have many Selects on one page, this could add up to a lot of
          // requests. Just to get the total count.
          //
          // => Showing the total count for remote Selects should be optional
          //    and not default!

          this.get('api').read(namespace, [uri, {
            paging_offset: 0,
            paging_limit: 0
          }]).then(result => {
            if (this.get('isDestroyed')) {
              return;
            }

            // FIXME `preloadedOptionsLength` should not be added, since it is included in `unfiltered_count` or `total_count`
            this.set('totalCount', ((0, _object.get)(result, 'meta.unfiltered_count') || (0, _object.get)(result, 'meta.total_count') || 0) + preloadedOptionsLength);
          });
        }
      });
    },
    hasMultipleSelected: (0, _object.computed)('multiple', 'internalValues.[]', function () {
      return this.get('multiple') && (this.get('internalValues.length') || 0) > 0;
    }),
    labelMultipleSelected: (0, _object.computed)('hasMultipleSelected', 'internalValues.[]', 'totalCount', 'showCount', function () {
      if (this.get('showCount')) {
        return this.get('placeholder');
      }
      if (this.get('hasMultipleSelected')) {
        const selected = this.get('internalValues.length') || 0;

        // TODO This should be configurable and translated.
        return `${selected} selected of ${this.get('totalCount')}`;
      }
      return this.get('placeholder');
    }),
    /**
     * All types of options are treated as remote resource. data, transport,
     * processResults are overridden to have the same behavior in select2
     *
     * @memberof formElements/StyledSelectComponent
     * @instance
     * @private
     *
     * @returns {Object} full select2 adapter
     */
    prepareSelect2AjaxOptions() {
      if (!this.get('optionsResource')) {
        return;
      }
      return {
        cache: true,
        delay: 250,
        data: params => this.ajaxProcessParams(params),
        transport: (params, success, failure) => this.ajaxRequest(params, success, failure),
        processResults: (data, params) => this.ajaxProcessResults(data, params)
      };
    },
    ajaxProcessParams(params) {
      return {
        paging_limit: this.get('pagingLimit'),
        paging_offset: this.get('pagingLimit') * ((params.page || 1) - 1),
        [this.get('searchParam')]: params.term || null
      };
    },
    ajaxRequest(params, success, failure) {
      const {
        namespace,
        uri,
        parameters: optionsResourceParameters
      } = typeof this.get('optionsResource') === 'function' ? this.get('optionsResource')(params.data[this.get('searchParam')]) : this.get('optionsResource');
      const request = this.get('api').read(namespace, [uri, {
        ...params.data,
        ...optionsResourceParameters
      }]);
      const promise = new _rsvp.default.Promise((resolve, reject) => {
        request.then(data => {
          // api service resolves with undefined on aborted request.
          // @see https://bitbucket.org/ligadigital/ln-ember-api-service/src/bb5072392a25c3c9f149ca43ae09cbf2bccc90ce/addon/services/api.js#lines-353
          if (!data) {
            reject();
          } else {
            resolve(data);
          }
        }, reject);
      }).then(success, failure);
      if (request && typeof request.abort === 'function') {
        promise.abort = request.abort.bind(request);
      }
      return promise;
    },
    ajaxProcessResults(data, params) {
      const limit = this.get('pagingLimit');
      const page = params.page || 1;
      const totalCount = (0, _object.get)(data, 'meta.unfiltered_count') || (0, _object.get)(data, 'meta.total_count') || (0, _object.get)(data, 'total');
      let records = (0, _object.get)(data, 'result') || data || [];
      const more = typeof totalCount === 'number' ? page * limit < totalCount : records.length >= limit;
      try {
        records = this.get('onProcessResults')(records);
      } catch (error) {
        console.error('onProcessResults', error.stack || error);
      }
      let results = this.formatOptions(records);
      const preloadedOptions = this.formatOptions(this.get('preloadedOptions'));
      if (page === 1) {
        results = (0, _weakUnionBy.default)(preloadedOptions, results);
      } else {
        // TODO is this necessary? It seems expensive.
        results = results.filter(item => !(0, _weakFind.default)(preloadedOptions, item, 'id'));
      }
      (0, _weakUnionBy.default)(this.get('internalOptions'), results);
      return {
        pagination: {
          more
        },
        // select2 has a problem only for ajax / multiple / unselect  when the option.id is of type number
        // TODO Why is this not done in formatOptions()?
        results: results.map(item => (0, _polyfills.assign)(item, {
          id: String(item.id)
        }))
      };
    },
    /**
     * Fill the normal DOM select element with options[selected=true]
     * before initialize the select2 library.
     *
     * @memberof formElements/StyledSelectComponent
     * @instance
     * @private
     *
     * @returns {RSVP.Promise}
     */
    renderSelectOptions() {
      if (!this.get('optionsResource')) {
        return (0, _rsvp.resolve)();
      }
      return (0, _rsvp.resolve)(this.get('preloadedOptions')).then(preloadedOptions => {
        const externalValues = (0, _enforceArray.default)(this.get('value'));
        preloadedOptions = this.formatOptions(preloadedOptions);
        (0, _weakUnionBy.default)(this.get('internalOptions'), preloadedOptions);

        // Render options to Select
        (0, _jquery.default)('select', this.element).html(this.get('internalOptions').map(item => {
          if ((0, _weakFind.default)(externalValues, (0, _object.get)(item, 'id'))) {
            return new Option((0, _object.get)(item, 'text'), (0, _object.get)(item, 'id'), null, true);
          }
        }));
      }).catch(err => (0, _debug.debug)(err));
    },
    onInternalValueChange() {
      const selectedValues = (0, _enforceArray.default)((0, _jquery.default)('select', this.element).val());
      const selectedOptions = this.get('internalOptions').filter(item => (0, _weakFind.default)(selectedValues, (0, _object.get)(item, 'id')));
      const values = selectedOptions.map(item => item.id);
      if (!(0, _weakEquals.default)(this.get('internalValues'), values)) {
        this.set('internalValues', values);
        const value = this.get('multiple') ? values : values[0];
        const filter = this.get('filter');
        const deprecatedOptions = () => this.unformatOptions(selectedOptions);
        this.get('onChange')({
          value,
          selectedOptions: selectedOptions.map(({
            option
          }) => option),
          get filter() {
            (false && !(false) && (0, _deprecations.deprecate)('[StyledSelectComponent] Filter is deprecated. Use this bind identifiers: `onChange=(action "onChange" "myIdentifier")`', false, {
              for: 'ln-ember-form-elements',
              id: 'styled-select-filter-attribute',
              since: '3.0.0-beta.115',
              until: '3.0.0'
            }));
            return filter;
          },
          get options() {
            (false && !(false) && (0, _deprecations.deprecate)('[StyledSelectComponent] options are deprecated use selectedOptions instead', false, {
              for: 'ln-ember-form-elements',
              id: 'styled-select-filter-on-change-options-param',
              since: '3.0.0-beta.115',
              until: '3.0.0'
            }));
            return deprecatedOptions();
          }
        });
      }
    },
    onExternalValueChange() {
      const externalValues = (0, _enforceArray.default)(this.get('value'));
      const selectedOptions = this.get('internalOptions').filter(item => (0, _weakFind.default)(externalValues, (0, _object.get)(item, 'id')));
      const values = selectedOptions.map(item => item.id);
      (0, _jquery.default)('select', this.element).val(values);
      const selectValues = (0, _enforceArray.default)((0, _jquery.default)('select', this.element).val());
      if (!(0, _weakEquals.default)(externalValues, selectValues)) {
        (0, _debug.debug)('value passed is not existing in the options');
        // TODO Something is weird here. This seems to happen a lot for resource options.

        const filter = this.get('filter');
        this.get('onMissingOptions')({
          value: externalValues,
          get filter() {
            (false && !(false) && (0, _deprecations.deprecate)('[StyledSelectComponent] Filter is deprecated. Use this bind identifiers: `onChange=(action "onChange" "myIdentifier")`', false, {
              for: 'ln-ember-form-elements',
              id: 'styled-select-filter-attribute',
              since: '3.0.0-beta.115',
              until: '3.0.0'
            }));
            return filter;
          }
        });
        this.set('internalValues', []);
        (0, _jquery.default)('select', this.element).val(null).trigger('change');
      } else {
        this.set('internalValues', values);
        (0, _jquery.default)('select', this.element).val(values).trigger('change');
      }
    },
    valueOrPreloadedChange() {
      this.renderSelectOptions().then(() => this.onExternalValueChange());
    },
    valueOrPreloadObserver: (0, _object.observer)('value.[]', 'preloadedOptions.[]', function () {
      if (this.valueDidChange()) {
        (0, _runloop.once)(this, 'valueOrPreloadedChange');
      }
    }),
    valueDidChange() {
      const lastValue = this.get('_lastValue');
      let didChange = false;
      if ((0, _array.isArray)(this.get('value'))) {
        didChange = lastValue && this.get('value') && !(0, _weakEquals.default)(lastValue, this.get('value'));
      } else {
        didChange = lastValue !== this.get('value');
      }
      if ((0, _array.isArray)(this.get('value'))) {
        this.set('_lastValue', (0, _polyfills.assign)([], this.get('value')));
      } else {
        this.set('_lastValue', this.get('value'));
      }
      return didChange;
    },
    /**
     * Used to format the options into `[{id, text}]`
     *
     * @memberof formElements/StyledSelectComponent
     * @instance
     * @private
     *
     * @param {Array} options
     * @returns {Array}
     */
    formatOptions(options) {
      (false && !(arguments.length === 1) && (0, _debug.assert)('formatOptions() paramter `isExternalSource` is not supported anymore. Use unformatOptions()', arguments.length === 1));
      options = (0, _enforceArray.default)(options);
      const valuePath = this.get('optionValuePath') || 'value';
      const labelPath = this.get('optionLabelPath') || 'label';
      return options.map(option => {
        if (typeof option !== 'object') {
          return {
            id: option,
            text: option,
            option
          };
        }
        return {
          id: (0, _object.get)(option, valuePath),
          text: (0, _object.get)(option, labelPath),
          option
        };
      });
    },
    /**
     * Used to format the options into `[{valuePath, labelPath}]`
     *
     * Do not use this. Use this instead:
     *
     * ```JavaScript
     * options.map(({ option }) => option)
     * ```
     *
     * Remove this in `3.0.0` { id: `styled-select-filter-on-change-options-param` }!
     *
     * @memberof formElements/StyledSelectComponent
     * @instance
     * @private
     * @deprecated
     *
     * @param {Array} options
     * @returns {Array}
     */
    unformatOptions(options) {
      const valuePath = this.get('optionValuePath') || 'value';
      const labelPath = this.get('optionLabelPath') || 'label';
      return options.map(item => ({
        [valuePath]: (0, _object.get)(item, 'id'),
        [labelPath]: (0, _object.get)(item, 'text')
      }));
    },
    /**
     * load the options and returns back the correct dataObject for Select2
     *
     * @memberof formElements/StyledSelectComponent
     * @instance
     * @private
     *
     * @returns {Object}
     */
    loadOptions() {
      return (0, _rsvp.resolve)(this.get('preloadedOptions')).then(preloadedOptions => this.formatOptions(preloadedOptions)).then(preloadedOptions => {
        if (this.get('options')) {
          // Options are passed in as array or promise
          return (0, _rsvp.resolve)(this.get('options')).then(result => ({
            data: (0, _weakUnionBy.default)(preloadedOptions, this.formatOptions(result))
          }));
        } else if (this.get('optionsResource')) {
          // Options have to be loaded from API resource
          return {
            ajax: this.prepareSelect2AjaxOptions()
          };
        } else {
          // TODO should this throw an error?
          // Fallback: no options are provided
          return {
            data: []
          };
        }
      }).catch(err => {
        (0, _debug.debug)(err);
        return {
          data: []
        };
      });
    },
    refreshSelect() {
      // TODO Is this necessary? It seems expensive. Can we refresh the select somehow?
      this._removeListeners();
      this._setup();
    },
    _removeListeners() {
      (0, _jquery.default)('select.select2-initialized', this.element).off('change').off('select2:open').off('select2:close').off('select2:opening').html('');
    },
    _setup() {
      if (this.get('isDestroyed')) {
        return;
      }
      this.set('internalOptions', []);
      let dataSource;
      this.loadOptions().then(_dataSource => {
        if (this.get('isDestroyed')) {
          return;
        }
        dataSource = _dataSource;
        return this.renderSelectOptions();
      }).then(() => {
        if (this.get('isDestroyed')) {
          return;
        }
        this.set('internalOptions', (0, _object.get)(dataSource, 'data') || this.get('internalOptions'));
        this._select2Init(dataSource);
        this._onIsActiveChange();
        this.onExternalValueChange();
      });
    },
    _select2Init(dataSource) {
      (0, _jquery.default)('select', this.element).select2((0, _polyfills.assign)({
        dropdownCssClass: this.get('dropdownClass'),
        minimumResultsForSearch: this.get('minimumResultsForSearch'),
        placeholder: this.get('_select2Placeholder'),
        closeOnSelect: this.get('closeOnSelect'),
        dropdownAutoWidth: this.get('dropdownAutoWidth'),
        theme: this.get('themeClass'),
        templateResult: this.createTemplate('optionTemplate'),
        templateSelection: this.createTemplate('selectionTemplate'),
        language: {
          noResults: () => this.get('noResultsMessage'),
          searching: () => this.get('searchingMessage')
        }
      }, dataSource)).addClass('select2-initialized').on('change', evt => this.onSelectChange(evt)).on('select2:open', evt => this.onSelectOpen(evt)).on('select2:close', evt => this.onSelectClose(evt)).on('select2:opening', evt => this.onSelectOpening(evt)).on('select2:open', () => {
        if (this.get('isDestroyed')) {
          return;
        }
        const {
          $container,
          $dropdown,
          $selection
        } = this.select2();
        const onKeyDown = event => this.onInputKeyDown(event);
        $container.find('.select2-search__field').on(`keydown.${this.elementId}`, onKeyDown);
        $dropdown.find('.select2-search__field').on(`keydown.${this.elementId}`, onKeyDown);
        $selection.on(`keydown.${this.elementId}`, onKeyDown);
        const [input] = $dropdown.find('.select2-search__field');
        if (input) {
          input.focus();
        }
      }).on('select2:close', () => {
        if (this.get('isDestroyed')) {
          return;
        }
        const {
          $container,
          $dropdown,
          $selection
        } = this.select2();
        $container.find('.select2-search__field').off(`keydown.${this.elementId}`);
        $dropdown.find('.select2-search__field').off(`keydown.${this.elementId}`);
        $selection.off(`keydown.${this.elementId}`);
      });
      (0, _runloop.next)(() => {
        if (this.get('readonly')) {
          this._onReadonlyChange();
        }
      });
      return;
    },
    didInsertElement() {
      this._super(...arguments);
      this.element.addEventListener('mouseenter', this.handleMouseEnter);
      this.element.addEventListener('mouseleave', this.handleMouseLeave);
      this._setup();
    },
    willDestroyElement() {
      this._super(...arguments);
      this.element.removeEventListener('mouseenter', this.handleMouseEnter);
      this.element.removeEventListener('mouseleave', this.handleMouseLeave);
      this._destroy();
    },
    onOptionsChange: (0, _object.observer)('options.[]', 'optionsResource', function () {
      if (!this.get('optionsResource') && !this.optionsDidChange()) {
        return;
      }
      this.refreshSelect();
    }),
    optionsDidChange() {
      const lastOptions = this.get('_lastOptions');
      const didChange = lastOptions && this.get('options') && !(0, _weakEquals.default)(lastOptions, this.get('options'));
      if ((0, _array.isArray)(this.get('options'))) {
        this.set('_lastOptions', (0, _polyfills.assign)([], this.get('options')));
      } else if (this.get('options')) {
        this.set('_lastOptions', [this.get('options')]);
      }
      return didChange;
    },
    focusIn() {
      if (this.get('openOnFocus') && !this.get('ignoreFocus')) {
        (0, _runloop.debounce)(this, this.open, 100, false);
      }
    },
    moveFocus(next = true) {
      const {
        $selection
      } = this.select2();
      const element = (0, _jquery.default)('.select2-search__field', this.element).get(0) || $selection.get(0);
      const targetElement = next ? (0, _tabbableElement.nextTabbableElementFor)(element) : (0, _tabbableElement.previousTabbableElementFor)(element);
      (0, _jquery.default)(targetElement).focus();
    },
    open() {
      if (!this.select2()) {
        return;
      }
      const {
        $container,
        $dropdown
      } = this.select2();
      if ($container.is('.select2-container--focus') && !this.select2().isOpen()) {
        this.select2Call('open');
        $dropdown.focus();
      }
    },
    close() {
      const {
        $container
      } = this.select2();
      $container.blur();
      this.select2Call('close');
    },
    select2() {
      if (this.get('isDestroyed')) {
        return;
      }
      return (0, _jquery.default)('select', this.element).data('select2');
    },
    select2Call(method) {
      (0, _jquery.default)('select', this.element).select2(method);
    },
    _destroy() {
      if (this.get('closeOnScrollContainer')) {
        const scrollContainer = this.get('closeOnScrollContainer');
        (0, _jquery.default)(scrollContainer).off(`scroll.select2-close-${(0, _internals.guidFor)(this)}`);
      }
      (0, _jquery.default)('.full-text', this.element).contents().unwrap();
      (0, _jquery.default)('select.select2-initialized', this.element).select2('destroy');
    },
    _onIsActiveChange: (0, _object.observer)('isActive', function () {
      (0, _runloop.scheduleOnce)('afterRender', () => {
        if ((0, _jquery.default)('select', this.element)) {
          (0, _jquery.default)('select', this.element).prop('disabled', !this.get('isActive'));
        }
      });
    }),
    _onReadonlyChange: (0, _object.observer)('readonly', function () {
      if (!this.isDestroyed) {
        (0, _jquery.default)('select.select2-initialized', this.element).prop('disabled', this.get('readonly'));
      }
    }),
    onSelectChange(/* evt */
    ) {
      this.set('ignoreFocus', true);
      (0, _runloop.run)(() => this.onInternalValueChange());
      (0, _runloop.later)(() => this.get('isDestroyed') || this.set('ignoreFocus', false), 500);
    },
    onSelectOpening() {
      (0, _runloop.run)(() => {
        if (this.get('isDestroyed')) {
          return;
        }
        if (this.get('closeOnScrollContainer')) {
          const scrollContainer = this.get('closeOnScrollContainer');
          (0, _jquery.default)(scrollContainer).one(`scroll.select2-close-${(0, _internals.guidFor)(this)}`, () => {
            this.select2Call('close');
          });
        }
      });

      // TODO document: selectOpening
      if (this.attrs.selectOpening) {
        (false && !(false) && (0, _deprecations.deprecate)('[StyledSelectComponent] selectOpening is deprecated use onOpening instead', false, {
          for: 'ln-ember-form-elements',
          id: 'styled-select-select-opening',
          since: '3.0.0-beta.115',
          until: '3.0.0'
        }));
        return this.attrs.selectOpening(this) !== false;
      }
      return this.onOpening(this) !== false;
    },
    onSelectOpen() {
      // TODO run() and later()? I do not think we need both.
      (0, _runloop.run)(() => (0, _runloop.later)(() => this.get('isDestroyed') || this.set('isOpen', true)));
    },
    onSelectClose() {
      (0, _runloop.run)(() => {
        if (this.get('isDestroyed')) {
          return;
        }
        (0, _runloop.later)(() => this.get('isDestroyed') || this.set('isOpen', false));
        (0, _jquery.default)('input', this.element).blur();
      });
    },
    onInputKeyDown({
      key,
      shiftKey
    }) {
      switch (key) {
        case 'Tab':
          return this.onInputTabKeyDown(shiftKey);
        case 'Enter':
          return this.onInputEnterKeyDown(shiftKey);
        case 'Escape':
          return this.onInputEscapeKeyDown(shiftKey);
      }
    },
    onInputTabKeyDown(shiftKey) {
      if (this.get('closeOnSelect')) {
        this.close();
        this.moveFocus(!shiftKey);
      }
    },
    onInputEnterKeyDown(shiftKey) {
      if (this.get('closeOnSelect')) {
        this.moveFocus(!shiftKey);
      }
    },
    onInputEscapeKeyDown() {
      (0, _jquery.default)(document.activeElement).blur();
      this.close();
    },
    actions: {
      removeAll() {
        if (this.get('readonly')) {
          return;
        }
        (0, _jquery.default)('select', this.element).val(null).change();
        this.onInternalValueChange();
        this.set('removeIsVisible', false);
      },
      removeMouseEnter() {
        if (this.get('readonly')) {
          return;
        }
        this.set('removeIsVisible', true);
      },
      removeMouseLeave() {
        this.set('removeIsVisible', false);
      }
    }
  });
});